import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import {
  getAlbumDataJamaahService,
  getEvisaDataJamaahService,
  saveDataQuestionService,
  updateHidePhotoAlbumJamaahService,
} from "utils/api/paket/ibadahku";

export const getAlbumDataJamaah = (id) => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING_DATA_ALBUM_JAMAAH", payload: true });
    const res = await getAlbumDataJamaahService(id);
    if (res.status === 200) {
      dispatch({ type: "SET_LOADING_DATA_ALBUM_JAMAAH", payload: false });
      dispatch({ type: "SET_DATA_ALBUM_JAMAAH", payload: res.data.data });
    } else {
      dispatch({ type: "SET_LOADING_DATA_ALBUM_JAMAAH", payload: false });
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch({ type: "SET_LOADING_DATA_ALBUM_JAMAAH", payload: false });
  }
};

export const updateHidePhotoAlbumJamaah = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING_HIDE_PHOTO", payload: true });
    const res = await updateHidePhotoAlbumJamaahService(id, payload);
    if (res.status === 200) {
      dispatch({ type: "SET_LOADING_HIDE_PHOTO", payload: false });
      dispatch(handleSuccess(res));
      const res2 = await getAlbumDataJamaahService(id);
      if (res2.status === 200) {
        dispatch({ type: "SET_LOADING_DATA_ALBUM_JAMAAH", payload: false });
      }
    } else {
      dispatch({ type: "SET_LOADING_HIDE_PHOTO", payload: false });
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch({ type: "SET_LOADING_HIDE_PHOTO", payload: false });
    dispatch(handleError(error));
  }
};

export const saveRatingQuestion = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING_SAVE_QUESTION", payload: true });
    const res = await saveDataQuestionService(payload);
    if (res.status === 200) {
      dispatch({ type: "SET_LOADING_SAVE_QUESTION", payload: false });
      dispatch({ type: "SET_DATA_RATING_QUESTION", payload: res.data.data });
      dispatch(handleSuccess(res));
    } else {
      dispatch({ type: "SET_LOADING_SAVE_QUESTION", payload: false });
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch({ type: "SET_LOADING_SAVE_QUESTION", payload: false });
  }
};

export const getEvisaDataJamaah = (id, payload, successAction) => async (dispatch, getState) => {
  try {
    if(!getState().ibadahku.dataEvisaJamaah.length) {
      dispatch({ type: "SET_LOADING_EVISA", payload: true });
    }    
    
    const res = await getEvisaDataJamaahService(id, payload);
    if (res.status === 200) {
      dispatch({ type: "SET_LOADING_EVISA", payload: false });
      dispatch({ type: "SET_DATA_EVISA_JAMAAH", payload: res.data.data });

      successAction(res.data)
      
    } else {
      dispatch({ type: "SET_LOADING_EVISA", payload: false });
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch({ type: "SET_LOADING_EVISA", payload: false });
  }
};




