import React, { useEffect } from "react";
import ICUploadImage from "../../assets/icons/upload_image.svg";
import ICCloseUpload from "../../assets/icons/close_upload.svg";
import { FileUploader } from "react-drag-drop-files";
import { CloseCircle } from "iconsax-react";
import PropTypes from "prop-types";
import style from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataUpload,
  setDataUploadMulti,
  uploadFileSubmite,
} from "../../stores/actions/uploadFile";
import uuid from "react-uuid";
import { IMAGE } from "constant/constanta";

const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];

function ImageField(props) {
  const dispatch = useDispatch();
  const dataUpload = useSelector(({ uploadFile }) => uploadFile.dataUpload);
  const dataUploadMulti = useSelector(
    ({ uploadFile }) => uploadFile.dataMultiple
  );
  const {
    setFile,
    isMulti,
    label,
    error,
    errorMesssage,
    customImageContainer,
    customWrapper,
    customContainer,
    customPlaceholder,
    name,
    onBlur,
    onRemove,
    customeLabel,
    height,
    width
  } = props;

  const removeImage = (id, name) => {
    let data = isMulti
      ? dataUploadMulti?.filter((v) => v?.name === name)
      : dataUpload;
    let data2 = dataUploadMulti?.filter((v) => v?.name !== name);

    let image = data?.filter((value) => value?.id !== id);

    if (isMulti) {
      dispatch(setDataUploadMulti([...image, ...data2]));
    } else {
      dispatch(setDataUpload(image));
    }
  };

  const handleChange = (e) => {
    const validImageFiles = [...dataUpload];
    const files = e;
    validImageFiles.push(files);

    dispatch(
      uploadFileSubmite([files], name, uuid(), isMulti ? "multiple" : "single")
    );
  };

  useEffect(() => {
    setFile(dataUpload?.find((data) => data?.name === name));
  }, [dataUpload, name]);

  const fileRender = isMulti
    ? dataUploadMulti?.filter((type) => {
        if (type?.path) {
          return type?.path?.split("/")[2] === IMAGE;
        }
      })
    : dataUpload;
  const fileCheck = fileRender?.find((v) => v?.name === name);
  const isMultiEmpty =
    fileRender.length !== 0
      ? Object.keys(fileCheck ? fileCheck : {})?.length > 0
      : false;

  return (
    <>
      <label className={`font-semibold ${customeLabel}`}>{label}</label>
      <div
        className={`${style.container} ${
          error && style.error
        } ${customContainer} h-[${height}] !w-[${width}]`}
      >
        {isMulti ? (
          <div
            className={`${style.dashWrapper} ${style.scrollStyle} ${customWrapper ? customWrapper : ""} ${style.wrapperMultiple} ${!isMultiEmpty ? 'flex justify-center items-center' : 'flex flex-col justify-center items-center gap-2'}`}
          >
            {!isMultiEmpty ? (
              <FileUploader
                onBlur={onBlur}
                multiple={isMulti}
                handleChange={handleChange}
                name="fileSingle"
                types={fileTypes}
                hoverTitle=" "
              >
                <div className={`${style.uploadContainer} cursor-pointer`}>
                  {customPlaceholder ? (
                    customPlaceholder
                  ) : (
                    <>
                      <img className="w-10" src={ICUploadImage} alt="upload" />

                      <p>
                        Drag & drop or
                        <span className="underline ml-1 cursor-pointer`">
                          <label htmlFor="upload">browse</label>
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </FileUploader>
            ) : (
              <>
              
                {isMulti && (
                  <div className="h-1/2 w-full flex justify-center items-center bg-red-5 rounded-lg">
                    <FileUploader
                      onBlur={onBlur}
                      multiple={isMulti}
                      handleChange={handleChange}
                      name="fileMulti"
                      types={fileTypes}
                      hoverTitle=" "
                    >
                      <div className={`${style.multiUploadContainer} cursor-pointer`}>
                        <img className="w-10" src={ICUploadImage} alt="upload" />
                        <p className="whitespace-nowrap">
                          Drag & drop or
                          <span className="underline ml-1 cursor-pointer">
                            <label htmlFor="upload">browse</label>
                          </span>
                        </p>
                      </div>
                    </FileUploader>
                  </div>
                )}
                <div className={`${style.multiContainerImage} flex flex-row h-1/2 gap-2 w-full overflow-x-scroll ${style.scrollStyle} pb-2`}>
                  {fileRender?.filter((v) => v?.name === name)?.map((item) => {
                      return (
                        <React.Fragment key={item.id}>
                          <div className="flex">
                            <div
                              className={`${customImageContainer} relative`}
                            >
                              <img
                                className={`${style.image} min-w-[7.5rem]`}
                                src={item.path}
                                alt="img"
                              />
                              <div
                                onClick={() => removeImage(item.id, item.name)}
                                className={style.closeContainer}
                              >
                                <img
                                onClick={onRemove}
                                src={ICCloseUpload}
                                alt="src"
                              />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        ) : fileRender?.find((image) => image?.name === name) ? (
          <div
            className={`${style.dashWrapper} ${style.scrollStyle} ${customWrapper ? customWrapper : ""} ${style.wrapperSingle} 2`}
          >
            {fileRender && fileRender?.length ? (
              <div className="w-full">
                <div
                  className={`relative`}
                >
                  <img
                    className={style.image}
                    style={{"width" : props?.width , "height" : `calc(${props.height} - 3.3rem)`}}
                    src={fileRender?.find((image) => image?.name === name)?.path}
                    alt="img"
                  />
                  <div
                    onClick={() =>
                      removeImage(
                        fileRender?.find((image) => image?.name === name).id
                      )
                    }
                    className={style.closeContainer}
                  >
                    <img onClick={onRemove} src={ICCloseUpload} alt="src" />
                  </div>
                </div>
                {isMulti && (
                  <FileUploader
                    onBlur={onBlur}
                    multiple={isMulti}
                    handleChange={handleChange}
                    name="fileMulti"
                    types={fileTypes}
                    hoverTitle=" "
                  >
                    <div className={style.multiUploadContainer}>
                      <img className="w-10" src={ICUploadImage} alt="upload" />
                    </div>
                  </FileUploader>
                )}
              </div>
            ) : (
              <FileUploader
                onBlur={onBlur}
                multiple={isMulti}
                handleChange={handleChange}
                name="fileSingle"
                types={fileTypes}
                hoverTitle=" "
              >
                <div className={`${style.uploadContainer} cursor-pointer`}>
                  {customPlaceholder ? (
                    customPlaceholder
                  ) : (
                    <>
                      <img className="w-10" src={ICUploadImage} alt="upload" />

                      <p>
                        Drag & drop or
                        <span className="underline ml-1 cursor-pointer">
                          <label htmlFor="upload">browse</label>
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </FileUploader>
            )}
          </div>
        ) : (
          <div
            className={`${style.dashWrapper} ${style.scrollStyle} ${customWrapper ? customWrapper : ""} ${style.wrapperSingle} 3`}
          >
            <FileUploader
              onBlur={onBlur}
              multiple={isMulti}
              handleChange={handleChange}
              name="fileSingle"
              types={fileTypes}
              hoverTitle=" "
            >
              <div className={`${style.uploadContainer} cursor-pointer`}>
                {customPlaceholder ? (
                  customPlaceholder
                ) : (
                  <>
                    <img className="w-10" src={ICUploadImage} alt="upload" />

                    <p>
                      Drag & drop or
                      <span className="underline ml-1 cursor-pointer">
                        <label htmlFor="upload">browse</label>
                      </span>
                    </p>
                  </>
                )}
              </div>
            </FileUploader>
          </div>
        )}
      </div>

      {error && (
        <div className={style.errorMessage}>
          <CloseCircle size={17} />
          <p className="ml-1">{errorMesssage}</p>
        </div>
      )}
      <input className="hidden" type="file" id="upload" />
    </>
  );
}

ImageField.propTypes = {
  label: PropTypes.string,
  setFile: PropTypes.func,
  onBlur: PropTypes.func,
  isMulti: PropTypes.bool,
  errorMesssage: PropTypes.string,
  error: PropTypes.bool,
  customPlaceholder: PropTypes.any,
  customContainer: PropTypes.string,
  customWrapper: PropTypes.string,
  customImageContainer: PropTypes.string,
  name: PropTypes.string,
  onRemove: PropTypes.func,
  customeLabel: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

ImageField.defaultProps = {
  isMulti: false,
  error: false,
  errorMesssage: "This field is required",
  label: "Image field label",
  customeLabel: "",
  width : "100%",
  height: "100%",
  customImageContainer: ""
};

export default ImageField;
