import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import SettingAboutAction from "stores/actions/settings/about";

import Buttons from "../../../../components/buttons";
import Cards from "../../../../components/cards";
import PopupConfirm from "components/popup/error-handling";
import { toBase64 } from "utils/helpers";
import { useFormik } from "formik";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import Tab from "components/tab";
import AboutAppForm from "./components/AboutAppForm";

const SettingAbout = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ settingAbout }) => settingAbout);
  const [input, setInput] = useState();
  const [edit, setEdit] = useState(false);
  const [openPopup, setOpenPopup] = useState({
    show: false,
    type: "",
    title: "",
    subtitle: "",
  });

  useEffect(() => {
    dispatch(SettingAboutAction.fetchData({ category: "travel" }));
  }, []);

  useEffect(() => {
    formik.setValues({ 
      setting_travel_about_app_mobile_jamaah: store?.data?.travel_about_app_mobile_jamaah,
      setting_travel_about_app_mobile_agent: store?.data?.travel_about_app_mobile_agent
    });
  }, [store.data]);

  const formik = useFormik({
    initialValues: {
      setting_travel_about_app_mobile_jamaah: "",
      setting_travel_about_app_mobile_agent: ""
    },
  });

  // useEffect(() => {
  //   if (store && store.data) {
  //     setInput(window?.atob(store?.data?.tentang_aplikasi));
  //   }
  // }, [store, store.data]);

  const handleSaveAbout = async () => {
    const status = await dispatch(
      SettingAboutAction.postData(formik.values)
    );
    if (status === 200) {
      dispatch(handleSuccess(null, { message: "Berhasil mengupdate tentang aplikasi", code: 200 }));
    } else {
      dispatch(handleError(status.data));
    }
  };

  return (
    <>
      <PopupConfirm
        open={openPopup.show}
        handleClose={() =>
          setOpenPopup({
            show: false,
            type: "",
            title: "",
            subtitle: "",
          })
        }
        type={openPopup.type}
        title={openPopup.title}
        subtitle={openPopup.subtitle}
      />
      <Cards containerClass="!p-0">
        <div className="flex flex-row justify-between items-center p-4">
          <div className="text-[20px] font-semibold">Tentang Aplikasi</div>
          <Buttons
              text="Simpan"
              loading={store?.isLoading}
              customClass={`btn-primary btn-small ${
                store?.isLoading && "loading"
              }`}
              onClick={() => {
                handleSaveAbout();
              }}
            />
        </div>
        <div className="divider"></div>
        <Tab
           containerClass="p-4 mb-4"
           defaultActiveKey="1"
           destroyInactiveTabPane={true}
           items={[
            {
              label: "Mobile Jamaah",
              key: "1",
              children: 
                <AboutAppForm 
                  values={formik.values.setting_travel_about_app_mobile_jamaah} 
                  setFielValue={(val) => {
                    formik.setFieldValue("setting_travel_about_app_mobile_jamaah", val);
                  }} 
                />,
            },
            {
              label: "Mobile Agen",
              key: "2",
              children: 
                <AboutAppForm 
                  values={formik.values.setting_travel_about_app_mobile_agent} 
                  setFielValue={(val) => {
                    formik.setFieldValue("setting_travel_about_app_mobile_agent", val);
                  }} 
                />,
            },
           ]}
          />
      </Cards>
    </>
  );
};

export default React.memo(SettingAbout);
