// adminAdditional Actions
// --------------------------------------------------------

import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import { setValidation, set_after_validation } from "stores/actions/general";
import { clearData } from "stores/actions/uploadFile";
import {
  createOrUpdateAdditionalApi,
  deleteIdAdditionalApi,
  duplicateAdditionalApi,
  getAdditionalApi,
} from "utils/api/datamaster/additional";
import { getAllDataMaster } from "..";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "adminAdditional/SET_LOADING";
export const CLEAR_ERROR = "adminAdditional/CLEAR_ERROR";
export const SET_ERROR = "adminAdditional/SET_ERROR";
export const INIT_DATA = "adminAdditional/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "adminAdditional/SET_DOUBLE_SUBMIT";
export const SET_DATA_ADDITIONAL = "adminAdditional/SET_DATA_ADDITIONAL";
export const SET_DELETE_ID_ADDITIONAL =
  "adminAdditional/SET_DELETE_ID_ADDITIONAL";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setDataAdditional = (payload) => ({
  type: SET_DATA_ADDITIONAL,
  payload,
});

export const setDeleteIdAdditional = (payload) => ({
  type: SET_DELETE_ID_ADDITIONAL,
  payload,
});

export const getMasterAdditional = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await getAdditionalApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setDataAdditional(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteIdMasterAdditional = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await deleteIdAdditionalApi(id);
    dispatch(setLoading(false));
    if (res.status === 200) {
      // dispatch(setDeleteIdAdditional(res.data));
      dispatch(handleSuccess(res));
      dispatch(getAllDataMaster());
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createOrUpdateAdditional = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await createOrUpdateAdditionalApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(clearData());
      dispatch(getAllDataMaster());
      dispatch(handleSuccess(res));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicateAdditional = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await duplicateAdditionalApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(set_after_validation(res.data));
      dispatch(getAllDataMaster());
      dispatch(handleSuccess(null, {message: "Berhasil menduplikat additional", code: 200}))
      // dispatch(handleSuccess(res));
    } else if (res.status === 400) {
      dispatch(setValidation(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};
