import axios from "axios";
import { BASE_URL_ENDPOINT_V2 } from "constant/constanta";

export const getRoomlistByPackageV2 = (id, payload) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/room-list`, {params: payload}
  );
export const getJamaahByRoomlistIdV2 = (id_package, payload) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_package}/room-list/jamaah`, {params: payload}
  );

export const getJamaahExportByRoomlistIdV2 = (id_package) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_package}/room-list/jamaah/export`
  );


export const getAllRelationV2 = (id) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/package/${id}/room-list/relation`);

export const updatedRelationV2 = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/room-list/relation/update`,
    payload
  );

export const getSetRoomlistV2 = (id_package, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_package}/room-list/set-room-list`,
    payload
  );

export const updatedSetRoomlistV2 = (id_package, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_package}/room-list/update-set-room-list`,
    payload
  );

export const getFileRelationV2 = (id_paket, id_relasi) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_paket}/room-list/relation/get-file/${id_relasi}`
  );
