import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Buttons from "../../../../../../components/buttons";
import Cards from "../../../../../../components/cards";
import MoslemeTable from "../../../../../../components/Table";

import Logo1 from "../../../../../../assets/images/logo1.png";
import Logo2 from "../../../../../../assets/images/logo2.png";
import Badge from "../../../../../../components/badge";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalState } from "stores/actions/general";
import { columns } from "../../columns";
import UploadArea from "components/Uploadarea";
import Inputs from "components/form/inputs";
import RichTextEditor from "components/richTextEditor";

import { getTravelAction, saveTravelAction } from "stores/actions/setting/travel";

import * as Yup from 'yup';
import { useFormik } from "formik";

import { Trash, CloseCircle } from "iconsax-react";

const ViewTravel = ({ setIsEdit }) => {
    const dispatch = useDispatch();
    const { dataTravel } = useSelector((state) => state.settingTravel);
    const [selectionType, setSelectionType] = useState("checkbox");
    const { global } = useSelector((state) => state.general);
    const { dataAllCabang, isLoading } = useSelector((state) => state.cabang);

    const [isLoadingTravelLogo, setIsLoadingTravelLogo] = useState(false);
    const [isLoadingTravelGeneral, setIsLoadingTravelGeneral] = useState(false);
    const [isLoadingTravelLegalDocuments, setIsLoadingTravelLegalDocuments] = useState(false);

    const total = 0;

    const formikSettingLogo = useFormik({
        initialValues: {
            setting_travel_logo_first: dataTravel?.travel_logo_first,
            setting_travel_logo_second: dataTravel?.travel_logo_second,
        },
        validationSchema: Yup.object().shape({
            setting_travel_logo_first: Yup.string().required("Logo Travel wajib diisi."),
            setting_travel_logo_second: Yup.string().required("Logo Travel wajib diisi."),
        }),
    });

    const formikSettingGeneral = useFormik({
        initialValues: {
            setting_travel_short_name: dataTravel?.travel_short_name,
            setting_travel_long_name: dataTravel?.travel_long_name,
            setting_travel_description: dataTravel?.travel_description,
        },
        validationSchema: Yup.object().shape({
            setting_travel_short_name: Yup.string().required("Short Name Travel wajib diisi."),
            setting_travel_long_name: Yup.string().required("Long Name Travel wajib diisi."),
            setting_travel_description: Yup.string().required("Description Travel wajib diisi."),
        }),
    });

    const formikSettingLegalDocuments = useFormik({
        initialValues: {
            setting_travel_legal_documents: typeof dataTravel?.travel_legal_documents === 'string' ? [] : dataTravel?.travel_legal_documents || [],
        },
    });
    
    const handleSaveTravelLogo = async (payload) => {
        setIsLoadingTravelLogo(true);
        const res = await dispatch(saveTravelAction(payload));
        setIsLoadingTravelLogo(false);
    };

    const handleSaveTravelGeneral = async (payload) => {
        setIsLoadingTravelGeneral(true);
        const res = await dispatch(saveTravelAction(payload));
        setIsLoadingTravelGeneral(false);
    };

    const handleSaveTravelLegalDocuments = async (payload) => {
        setIsLoadingTravelLegalDocuments(true);
        const res = await dispatch(saveTravelAction(payload));
        setIsLoadingTravelLegalDocuments(false);
    };

    const removeLegalDocuments = (index) => {
        const temp = formikSettingLegalDocuments.values.setting_travel_legal_documents;
        temp.splice(index, 1);
        formikSettingLegalDocuments.setFieldValue("setting_travel_legal_documents", temp);
    };

    const addLegalDocuments = () => {
        const temp = formikSettingLegalDocuments.values.setting_travel_legal_documents;
        temp.push({
            name: "",
            path: "",
        });
        formikSettingLegalDocuments.setFieldValue("setting_travel_legal_documents", temp);
    };

    const pagination = {
        showSizeChanger: true,
        total: total,
        // current: currentpage,
        // pageSize: size,
        current: global?.currentpage,
        pageSize: global?.size,
        defaultPageSize: 10,
        pageSizeOptions: ["10", "20"],
    };

    const allCabang =
        dataAllCabang?.total_data !== 0 &&
        dataAllCabang?.data?.map((item, idx) => {
            return {
                ...item,
                key: idx + 1,
            };
        });

    return (
        <Cards containerClass="!p-0">
            <div className="flex flex-row justify-between items-center p-4">
                <div className="text-[20px] font-semibold">
                    Informasi Travel
                </div>
                {/* <Buttons
                    text="Edit Data"
                    customClass="btn-primary btn-small"
                    onClick={() => setIsEdit(true)}
                /> */}
            </div>
            <div className="divider"></div>
            <div className="p-4">
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4">
                    <div className="text-[16px] font-semibold flex justify-between items-center">
                        Logo Travel
                        <Buttons
                            text="Simpan"
                            loading={isLoadingTravelLogo}
                            onClick={() => handleSaveTravelLogo(formikSettingLogo.values)}
                            customClass="btn-primary btn-small"
                            disabled={!(formikSettingLogo.isValid && formikSettingLogo.dirty)}
                        />
                    </div>
                    <div className="mt-4">
                        <Grid container spacing={2}>
                            <Grid item md={3}>
                                <UploadArea 
                                    name="setting_travel_logo_first"
                                    defaultUrl={formikSettingLogo.values.setting_travel_logo_first}
                                    label="Logo Pertama"
                                    type="chose"
                                    handleRemove={() => formikSettingLogo.setFieldValue("setting_travel_logo_first", "")}
                                    choseType="image"
                                    customClass="!w-[250px] !h-[250px] !justify-left"
                                    onChange={(url) => {
                                        formikSettingLogo.setFieldValue("setting_travel_logo_first", url)
                                    }}
                                    />
                            </Grid>
                            <Grid item md={9}>
                                <UploadArea 
                                    name="setting_travel_logo_second"
                                    defaultUrl={formikSettingLogo.values.setting_travel_logo_second}
                                    label="Logo Kedua"
                                    type="chose"
                                    handleRemove={() => formikSettingLogo.setFieldValue("setting_travel_logo_second", "")}
                                    choseType="image"
                                    customClass="!w-full !h-[250px] !justify-left"
                                    onChange={(url) => {
                                        formikSettingLogo.setFieldValue("setting_travel_logo_second", url)
                                    }}
                                    />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4 mt-4">
                    <div className="text-[16px] font-semibold flex justify-between items-center">
                        Informasi Umum
                        <Buttons
                            text="Simpan"
                            loading={isLoadingTravelGeneral}
                            onClick={() => handleSaveTravelGeneral(formikSettingGeneral.values)}
                            disabled={!(formikSettingGeneral.isValid && formikSettingGeneral.dirty)}
                            customClass="btn-primary btn-small"
                        />
                    </div>
                    <div className="flex flex-row gap-2 mt-4 items-center">
                        <div className="min-w-[9%]">Nama Pendek Travel</div>
                        <div className="min-w-[1%]">:</div>
                        <div className="w-full">
                            <Inputs onChange={formikSettingGeneral.handleChange} name="setting_travel_short_name" value={formikSettingGeneral.values.setting_travel_short_name} placeholder="Masukan nama travel" />
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 mt-4 items-center">
                        <div className="min-w-[9%]">Nama Panjang Travel</div>
                        <div className="min-w-[1%]">:</div>
                        <div className="w-full">
                            <Inputs onChange={formikSettingGeneral.handleChange} name="setting_travel_long_name" value={formikSettingGeneral.values.setting_travel_long_name} placeholder="Masukan nama travel" />
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 mt-4">
                        <div className="min-w-[9%]">Deskripsi Travel</div>
                        <div className="min-w-[1%]">:</div>
                        <div className="w-full">
                            <RichTextEditor onChange={(val) => formikSettingGeneral.setFieldValue("setting_travel_description", val)} data={{ value: formikSettingGeneral.values.setting_travel_description }} label="" />
                        </div>
                    </div>
                </div>
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4 mt-4">
                    <div className="text-[16px] font-semibold flex justify-between items-center">
                        Legalitas Travel
                        <Buttons
                            text="Simpan"
                            loading={isLoadingTravelLegalDocuments}
                            onClick={() => handleSaveTravelLegalDocuments(formikSettingLegalDocuments.values)}
                            customClass="btn-primary btn-small"
                        />
                    </div>
                    <div className="mt-4">
                        <Grid container spacing={3}>
                            {formikSettingLegalDocuments.values.setting_travel_legal_documents?.map((document, idx) => {
                                return (
                                    <Grid item md={4} className="relative">
                                        <div 
                                            onClick={() => removeLegalDocuments(idx)}
                                            className="bg-white flex justify-center items-center p-2 !h-[30px] !w-[30px] shadow-lg rounded-full cursor-pointer absolute right-0 top-6 z-50" >
                                            <CloseCircle size={16}/>
                                        </div>
                                        <UploadArea 
                                            type="chose"
                                            defaultUrl={document?.path}
                                            onChange={(url) => {
                                                formikSettingLegalDocuments.setFieldValue(`setting_travel_legal_documents[${idx}].path`, url)
                                            }}
                                            label={`File Dokumen ${idx + 1}`} />
                                        <Inputs
                                            name={`setting_travel_legal_documents[${idx}].name`}
                                            value={document?.name}
                                            onChange={formikSettingLegalDocuments.handleChange}
                                            containerClass="mt-4"
                                            placeholder="Masukan Nama Dokumen"
                                            />
                                    </Grid>
                                )
                            })}
                            <Grid item md={4}>
                                <div className="h-[26px]"></div>
                                <div 
                                    onClick={() => addLegalDocuments()}
                                    className="flex w-full justify-center items-center bg-[#F2F2F2] rounded-lg h-64 cursor-pointer">
                                    <div className="text-[20px] font-semibold text-[#757575]">+ Tambah File</div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4 mt-4">
                    <div className="text-[16px] font-semibold">List Cabang</div>
                    <div className="border mt-2 rounded">
                        <MoslemeTable
                            expandable={false}
                            columns={columns(dispatch)}
                            data={allCabang}
                            withSelection={false}
                            withPagination={false}
                            pagination={pagination}
                            customPaginationClass="pr-4"
                            loading={isLoading}
                            onPageChange={(page) =>
                                dispatch(
                                    globalState({
                                        currentpage: page,
                                        size: global.size,
                                    })
                                )
                            }
                            onSizeChange={(currentpage, pageSize) =>
                                dispatch(
                                    globalState({
                                        currentpage: currentpage,
                                        size: pageSize,
                                    })
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </Cards>
    );
};

export default React.memo(ViewTravel);
