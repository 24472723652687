import styled from "styled-components";

const SelectStyle = styled.div`
  width: 100%;

  .ic-left {
    position: absolute;
    left: 24px;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  /* .ic-right {
    position: absolute;
    right: 0;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  } */

  .custom-select.select-padding--left .ant-select-selector {
    padding: 16px 48px 16px 42px;
  }

  .custom-select.select-padding--left
    .ant-select-selector
    .ant-select-selection-search-input {
    padding: 16px 48px 16px 32px;
  }

  .custom-select {
    width: 100%;
    &.ant-select-single {
      height: unset!important;
    }

    &.size-default {
      .ant-select-selector {
        padding: 16px 48px 16px 16px;
      }
    }

    &.size-small {
      .ant-select-selector {
        padding: 8px 12px 8px 12px;
      }
    }

    &.theme-default {
      &.ant-select-single {
        height: 54px!important;
      }
      .ant-select-selector {
        border-color: transparent;
        box-shadow: none !important;
        min-height: 54px;
        width: 100%;
        background: rgb(242, 242, 242);
        border: 0;
        border-radius: 12px;

        &::placeholder {
          color: #828282;
        }

        &:hover {
          background: #ffdcdc;
        }

        &:focus {
          box-shadow: none;
        }

        /* &::placeholder {
          color: #bdbdbd;
        }

        &:focus {
          border-color: red !important;
          box-shadow: none !important;
        } */

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: inherit;
        }

        .ant-select-selection-search-input {
          height: 54px;
          padding: 16px 48px 16px 4px;
        }
      }

      &.ant-select-disabled .ant-select-selector {
        background: rgb(242, 242, 242);
      }
    }

    &.theme-light-border {
      .ant-select-selector {
        background: #fff;
        border-color: #E0E0E0 !important;
      }

      &:focus,
      &:active,
      &:hover {
        border-color: red !important;
        box-shadow: none !important;
      }
    }
  }

  .select-error {
    border: 1px solid #e82320;
    border-radius: 12px;
  }

  .select-error-msg {
    color: #e82320;
    margin-left: 8px;
  }

  .select-success {
    border: 1px solid #97e03b;
    border-radius: 12px;
  }

  .select-success-msg {
    color: #97e03b;
    margin-left: 8px;
  }

  .custom-select.select-disabled {
    border: 1px solid rgba(204, 204, 204, 0.3);
    border-radius: 12px;
  }

  .custom-select.select-disabled + .ic-right {
    opacity: 0.3;
  }

  .custom-select.select-disabled .ant-select-selector {
    background: rgba(242, 242, 242, 0.3) !important;
  }

  .select-disabled {
    .ant-select-selector {
      &:hover,
      &:active {
        background: #f2f2f2;
        border-radius: 12px;
      }

      .ant-select-selection-item {
        color: #828282;
        }
      }

    }

    &:hover,
    &:active {
      cursor: not-allowed;
    }
  }

  .select-label {
    font-size: 14px;
    font-weight: 600;
    color: #4f4f4f;
    margin-bottom: 8px;

    &--disabled {
      color: #828282;
    }
  }

  .ant-select-dropdown {
    box-shadow:  0px 6px 32px 0px rgba(0, 0, 0, 0.04), 0px 10px 16px 0px rgba(0, 0, 0, 0.04), 0px 6px 64px -4px rgba(0, 0, 0, 0.04)!important;
  }

  .ant-select-selection-item {
    .select-custom-secondary {
      img {
        display: none;
      }
      .content {
        .desc {
          display: none;
        }
        .label {
          font-weight: normal!important;
        }
      }
    }
  }

  .select-custom-option-secondary {
    padding-top: 16px!important;
    padding-bottom: 16px!important;
    padding-left: 24px!important;
    padding-right: 24px!important;
  }


  // BADGE OPTIONS
  .ant-select-selection-item {
    .select-custom-badge {
      img {
        display: none;
      }
      div {
        background-color: transparent!important;
        color: #4f4f4f!important;
        font-weight:400!important;
        padding: 0!important;
        font-size: 14px!important;
        font-family: GeneralSans!important;
      }
    }
  }

  .select-custom-option-badge {
    padding-top: 16px!important;
    padding-bottom: 16px!important;
    padding-left: 24px!important;
    padding-right: 24px!important;
  }

  .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-vertical {
    width: 4px!important;
  }

  .rc-virtual-list-scrollbar {
    .rc-virtual-list-scrollbar-thumb {
      background-color: #e0e0e0!important;
      width: 4px!important;
      border-radius: 16px!important;
    }
  }

  .rc-virtual-list-holder {
    &::-webkit-scrollbar: {
      width: "4px",
      // height: scrollHorizontal ? "5px" : "100px",
    },
    &::-webkit-scrollbar-track: {
      width: "4px",
      borderRight: "10px solid #fff",
      borderLeft: "10px solid #fff",
    },
    scrollBehavior: 'smooth'
  }

`;

export default SelectStyle;
