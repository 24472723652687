import React, { useState} from "react";
import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";

import Badge from "../../../../../../components/badge";
import Buttons from "../../../../../../components/buttons";

import IconDotsBlue from "../../../../../../assets/icons/dots-blue.svg";
import IconAirplaneBlue from "../../../../../../assets/icons/airplane-blue.svg";
import DashedBlue from "../../../../../../assets/icons/dashed-blue.svg";
import DashedGrey from "../../../../../../assets/icons/dashed-grey.svg";
import Garuda from "../../../../../../assets/images/garuda.jpg";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

import { useSelector } from 'react-redux'


import { deleteFlightInternational } from 'utils/api/paket'
import { handleSuccess } from "stores/actions/errorGeneral";
import Alert from "components/popup/alert";


const Departure = ({
  first,
  last,
  data,
  setOpenPopupAddOrEditFlight,
  setIsDomestik,
  isInternational = false,
  handleEditKeberangkatan = (message) => {},
  reloadData
}) => {
  const {
    kode_penerbangan,

    waktu_keberangkatan,
  } = data;

  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const params = useParams();

  const [removePopup, setRemovePopup] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const handleRemoveFlightInternational = async () => {
    setIsLoadingDelete(true);
    const res = await deleteFlightInternational(params.id, data.id);
    setIsLoadingDelete(false);

    if (res.status === 200) {
      dispatch(handleSuccess(res)),
      setRemovePopup(false)
      reloadData()
    } else {
      dispatch(handleError(res.data));
    }

  }

  return (
    <>

    <Alert
      open={removePopup}
      title="Hapus Penerbangan"
      subtitle={`Yakin ingin menghapus penerbangan ini?`}
      handleClose={() => {
        setRemovePopup(false)
      }}
      handleContinue={() => handleRemoveFlightInternational()}
      text_accept="Yakin"
      text_cancel="Tidak"
      loading={isLoadingDelete}
      />


      <div className={`bg-[#FBF3F2] rounded-[24px] p-4`}>
        <div className="bg-white rounded-[32px] p-4 min-w-[281px]">
          <div className="flex flex-row justify-between items-start">
            <div className="w-1/4">
              <div className="text-[#71BE0E] text-[18px] font-semibold">
                {data?.airport_departure_code}
              </div>
              <div className="text-[#828282]" alt={data?.airport_departure_city}>
                <span className="line-clamp-1">{data?.airport_departure_city}</span>
              </div>
            </div>

            <div className="w-2/4 relative flex flex-row justify-between items-center mt-2">
              <img src={IconDotsBlue} alt="dots-left" />
              <div className="h-fit w-full left-0 right-0">
                <div
                  className="w-full h-[1px]"
                  style={{
                    backgroundImage: `url(${DashedBlue})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                  }}
                ></div>
                <div>
                  <img
                    className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white"
                    src={IconAirplaneBlue}
                    alt="airplane"
                  />
                  <div className="absolute top-[16px] left-[50%] translate-x-[-50%]  text-[8px] font-medium text-[#828282]">
                    {data?.duration}
                  </div>
                </div>
              </div>
              <img src={IconDotsBlue} alt="dots-left" />
            </div>

            <div className="w-1/4">
              <div className="txt-green text-[18px] font-semibold text-right">
                {data?.airport_arrival_code}
              </div>
              <div className="text-[#828282] text-right line-clamp-1">
                {data?.airport_arrival_city}
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between mt-6">
            <div>
              <div className="text-[#828282] text-[12px]">Keberangkatan</div>
              <div className="txt-green font-semibold">
                {data?.departure_time}
              </div>
              <div className="text-[#828282] text-[8px] font-medium">
                {data?.departure_date !== null &&
                  moment(data?.departure_date, "DD-MM-YYYY").format(
                    "DD MMMM YYYY"
                  )}
              </div>
            </div>
            <div className="text-right">
              <div className="text-[#828282] text-[12px]">Kedatangan</div>
              <div className="txt-green font-semibold">
                {data?.arrival_time}
              </div>
              <div className="text-[#828282] text-[8px] font-medium">
                {data?.arrival_date !== null &&
                  moment(data?.arrival_date, "DD-MM-YYYY").format(
                    "DD MMMM YYYY"
                  )}
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between mt-6">
            {isInternational ? (
              <div className="text-left">
                <div className="text-[#828282] text-[12px]">
                  Kode Penerbangan
                </div>
                <div className="txt-green font-semibold">
                  {data?.flight_code}
                </div>
              </div>
            ) : (
              <div>
                <div className="text-[#828282] text-[12px]">Jumlah Pax</div>
                <div className="txt-green font-semibold">
                  {data?.pax_count ? `${data.pax_count} pax` : "-"}
                </div>
              </div>
            )}

            {!isInternational ? (
              <div className="text-right">
                <div className="text-[#828282] text-[12px]">
                  Kode Penerbangan
                </div>
                <div className="txt-green font-semibold">
                  {data?.flight_code}
                </div>
              </div>
            ) : (
              <div className="text-right">
                <div className="text-[#828282] text-[12px]">Terminal</div>
                <div className="txt-green font-semibold">{data?.terminal}</div>
              </div>
            )}
          </div>

          {!isInternational && (
            <div className="flex flex-row justify-between mt-6">
              <div>
                <div className="text-[#828282] text-[12px]">Starting</div>
                <Badge
                  label={`${data?.starting?.name}`}
                  backgroundColor={`${data?.starting?.color_bg}`}
                  color={`${data?.starting?.color_text}`}
                  customClass="mt-1"
                />
              </div>
            </div>
          )}

          <div className="relative flex flex-row justify-between items-center mt-6">
            <div className="w-[26px] h-[26px] bg-[#FBF3F2] rounded-full absolute left-[-28px]"></div>
            <div
              className="h-[1px] m-auto"
              style={{
                backgroundImage: `url(${DashedGrey})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                width: "calc(100% - 20px)",
              }}
            ></div>
            <div className="w-[26px] h-[26px] bg-[#FBF3F2] rounded-full absolute right-[-28px]"></div>
          </div>

          <div className="flex flex-row justify-between mt-4">
            <div className="flex flex-row justify-start items-center">
              {data?.airplane.airplane_id ? (
                <>
                  <div className="font-semibold text-[12px]">
                    {data?.airplane?.name}
                  </div>

                  <img
                    className="w-[46px] h-[46px] rounded-full ml-[4px]"
                    src={data?.airplane?.logo}
                    alt="maskapai"
                  />
                </>
              ) : (
                <>
                  
                </>
              )}
            </div>
            {!isInternational && (
              <div className="text-right">
                <div className="text-[#828282] text-[12px]">Terminal</div>
                <div className="txt-green font-semibold">
                  {data?.terminal ? data.terminal : "-"}
                </div>
              </div>
            )}
          </div>

          {auth?.user?.access_data === 'all_branches' && (
            <>
              {permissions?.includes('update_flight') && (
                <>
                  <div className="flex gap-4">
                    <Buttons
                      text="Edit"
                      containerClass="mt-4 !w-full"
                      customClass="btn-outline btn-medium !py-1"
                      onClick={() => {
                        handleEditKeberangkatan(data);
                      }}
                    />

                    {isInternational && (
                      <>
                        <Buttons
                          text="Hapus"
                          containerClass="mt-4 !w-full"
                          customClass="btn-outline btn-medium !py-1"
                          onClick={() => {
                            setRemovePopup(true);
                          }}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}  
          
        </div>
      </div>
    </>
  );
};

export default React.memo(Departure);
