import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Drawers from "components/drawer";
import AlertPopup from "components/popup/alert";
import MoslemeTable from "components/Table";
import { resetDataUpload } from "stores/actions/uploadFile";
import Popup from "components/popup";
import IconSearch from "assets/icons/search-normal.svg";

import { columns } from "./columns";
import EditItinenary from "../../components/itinenary/EditItinenary";
import Duplicate from "../../components/itinenary/Duplicate";
import ModalImage from "../../components/itinenary/ModalImage";
import DetailItinenary from "../../components/itinenary/DetailItinenary";

import { useParams } from "react-router-dom";
import { IcJamPasir } from "assets/icons/dashboard";
import {
  deleteItenaryServiceV2,
  duplicatedItenaryServiceV2,
  getDetailItenary,
  getListItinenary,
  getListStartingItenaryServiceV2,
  insertItenaryServiceV2,
} from "stores/actions/paket/itinenary";
import { handleError, handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import useDebounce from "utils/helpers/useDebounce";
import Inputs from "components/form/inputs";

import { paketItenaryV2 } from "utils/api/paket/itenary";


const Itinenary = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [currentpage, setCurrentpage] = useState(1);
  const { listItinenary, isLoading } = useSelector((state) => state?.itinenary);
  const [isDrawer, setIsDrawer] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEditItenary, setIsEditItenary] = useState(false);
  const [isDuplicatedI, setIsDuplicated] = useState(false);
  const [isModalImage, setIsModalImage] = useState(false);
  const [listItenaryData, setListDataItenary] = useState([]);
  const [detailItenary, setDetailItenary] = useState({});
  const [listDataGalery, setListDataGalery] = useState({});
  const [choice, setChoice] = useState(null);
  const [rowData, setRowData] = useState({});
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [listStarting, setListStarting] = useState([]);
  const [itenary, setItenary] = useState(null);
  const [input, setInput] = useState("");
  const [isConfirmDuplicated, setIsCompirDuplicated] = useState(false);
  const [payloadDuplicated, setPayloadDuplicated] = useState(null);
  const [size, setSize] = useState(10);

  const [generating, setGenerating] = useState(false)
  const [generatingIndex, setGeneratingIndex] = useState(null)

  const search = useDebounce(input, 3000);

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  useEffect(() => {
    const payload = {
      per_page: size,
      keyword: search,
      sort_column: "",
      sort_order: "",
      page_number: currentpage,
      package_id: id,
    };

    dispatch(getListItinenary(id, payload));
  }, [id, search, currentpage, size]);

  useEffect(() => {
    if (listItinenary) {
      setListDataItenary(listItinenary?.data);
    }
  }, [listItinenary]);

  const getListItenaryV2 = () => {
    const payload = {
      per_page: size,
      keyword: "",
      sort_column: "",
      sort_order: "",
      page_number: currentpage,
      package_id: id,
    };

    dispatch(getListItinenary(id, payload));
  };


  const total = listItinenary?.total_data;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  const getDetailItenaryService = (data) => {
    setRowData(data);

    dispatch(
      getDetailItenary(
        id,
        data?.id,
        (message) => {
          setDetailItenary(message.data?.data);
          setIsDrawer(true);
        },
        (error) => {
          dispatch(handleErrorBE(error));
        },
        false
      )
    );
  };

  const deleteItenary = () => {
    setIsLoadingAction(true);
    const payload = {
      id_itinerarie: choice?.id_itinerarie,
    };
    dispatch(
      deleteItenaryServiceV2(
        id,
        payload,
        (message) => {
          setIsLoadingAction(false);
          getListItenaryV2();
          dispatch(
            getDetailItenary(
              id,
              rowData?.id,
              (message) => {
                setIsDelete(false);
                setDetailItenary(message.data?.data);
                dispatch(handleSuccess(message));
              },
              (error) => {
                setIsLoadingAction(false);
                setIsDelete(false);
                dispatch(handleErrorBE(error));
              }
            )
          );
        },
        (error) => {
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const inserItenary = (data) => {
    setIsLoadingAction(true);

    const payload = {
      ...data,
      package_id: id,
      starting_id: rowData?.id,
    };
    console.log(payload);
    dispatch(
      insertItenaryServiceV2(
        id,
        payload,
        (message) => {
          setIsLoadingAction(false);
          setIsEditItenary(false);
          dispatch(resetDataUpload());
          getListItenaryV2();
          dispatch(
            getDetailItenary(
              id,
              rowData?.id,
              (message) => {
                setDetailItenary(message.data?.data);
                dispatch(handleSuccess(message));
              },
              (error) => {
                setIsLoadingAction(false);
                setIsDelete(false);
                dispatch(handleErrorBE(error));
              }
            )
          );
        },
        (error) => {
          setIsLoadingAction(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const getListStartingItenaryV2 = (data = true) => {
    dispatch(
      getListStartingItenaryServiceV2(
        id,
        (message) => {
          setListStarting(message?.data?.data);
          setIsDuplicated(true);
        },
        (error) => {
          dispatch(handleErrorBE(error));
        },
        data
      )
    );
  };

  const handleGenerateItinerary = async (starting_id, index) => {
    const payload = {
      starting_id: starting_id
    };

    setGenerating(true)
    setGeneratingIndex(index)

    const response = await paketItenaryV2.generateItinenaryService(id, payload);
    setGenerating(false)
    if(response.status === 200) {
      var a = document.createElement("a")
      a.href = `${response.data.data.url}`; //Image Base64 Goes here
      a.download = `${response.data.data.url}`; //File name Here
      a.target = "_blank"
      a.click();
    } else {
      dispatch(handleError(response.data))
    }


  }

  const duplicatdItenaryV2 = (data) => {
    setIsLoadingAction(true);

    dispatch(
      duplicatedItenaryServiceV2(
        id,
        data,
        (message) => {
          setIsLoadingAction(false);
          setIsDuplicated(false);
          setIsCompirDuplicated(false);
          dispatch(
            getDetailItenary(
              id,
              rowData?.id,
              (message) => {
                setDetailItenary(message.data?.data);
                dispatch(handleSuccess(message));
                getListItenaryV2();
              },
              (error) => {
                setIsLoadingAction(false);
                setIsDelete(false);
                dispatch(handleErrorBE(error));
              }
            )
          );
        },
        (error) => {
          setIsLoadingAction(false);
          setIsDuplicated(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  return (
    <div className="">
      <Popup
        open={isEditItenary}
        handleClose={() => {
          dispatch(resetDataUpload());
          setIsEditItenary(false);
        }}
        title={itenary ? "Edit Detail itinerary" : "Add Detail itinerary"}
        width={"1080px"}
        containerClass="!z-[1000]"
        children={
          <EditItinenary
            inserItenary={inserItenary}
            isLoadingAction={isLoadingAction}
            itenary={itenary}
          />
        }
      />
      <Popup
        open={isDuplicatedI}
        handleClose={() => {
          dispatch(resetDataUpload());
          setIsDuplicated(false);
        }}
        title={"Duplicate Itinerary"}
        width={"608px"}
        containerClass="!z-[1000]"
        children={
          <Duplicate
            listStarting={listStarting}
            isLoadingAction={isLoadingAction}
            rowData={rowData}
            duplicatdItenaryV2={duplicatdItenaryV2}
            setIsCompirDuplicated={setIsCompirDuplicated}
            setPayloadDuplicated={setPayloadDuplicated}
            setIsDuplicated={setIsDuplicated}
          />
        }
      />
      <Popup
        open={isModalImage}
        handleClose={() => {
          dispatch(resetDataUpload());
          setIsModalImage(false);
        }}
        title={"Galeri Rute"}
        width={"720px"}
        containerClass="!z-[1000]"
        children={<ModalImage listDataGalery={listDataGalery} />}
      />
      <AlertPopup
        open={isDelete}
        handleClose={() => {
          setIsDelete(false);
        }}
        handleContinue={() => deleteItenary()}
        loading={isLoadingAction}
        title="Hapus Data"
        subtitle="Apakah Anda sudah yakin ingin menghapus id Itinenary? "
      />

      <AlertPopup
        open={isConfirmDuplicated}
        handleClose={() => {
          setIsCompirDuplicated(false);
          setPayloadDuplicated(null);
        }}
        handleContinue={() => duplicatdItenaryV2(payloadDuplicated)}
        loading={isLoadingAction}
        text_accept="Duplikat"
        title="Apakah anda yakin?"
        subtitle="Apakah anda yakin ingin menduplikat data ini? "
      />

      <Drawers
        open={isDrawer}
        onClose={() => setIsDrawer(!isDrawer)}
        containerClass="!w-[540px]"
        customClass="!w-[540px]"
        isItenary={true}
      >
        <DetailItinenary
          setIsDuplicated={setIsDuplicated}
          setIsDrawer={setIsDrawer}
          setIsEditItenary={setIsEditItenary}
          setIsDelete={setIsDelete}
          setIsModalImage={setIsModalImage}
          detailItenary={detailItenary}
          setListDataGalery={setListDataGalery}
          setChoice={setChoice}
          getListStartingItenaryV2={getListStartingItenaryV2}
          setItenary={setItenary}
        />
      </Drawers>

      {listItenaryData?.length > 0 ? (
        <MoslemeTable
          customClass="w-full"
          loading={isLoading}
          expandable={false}
          columns={columns(dispatch, setIsDrawer, getDetailItenaryService, handleGenerateItinerary, generating, generatingIndex)}
          data={listItenaryData}
          withSelection={false}
          rowId={(record) => record.id}
          rowKey={(record) => record.id}
          selectionType={'"checkbox"'}
          withPagination={true}
          pagination={pagination}
          customPaginationClass="pr-4"
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      ) : (
        <main className="flex flex-col items-center gap-y-4 justify-center h-[69vh]">
          <img alt="hourglass" src={IcJamPasir} />
          <div>Belum ada Itenary di paket ini</div>
        </main>
      )}
    </div>
  );
};

export default Itinenary;
