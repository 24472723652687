import React, { useEffect, useState } from "react";
import Buttons from "components/buttons";
import { Select } from "antd";

const Duplicate = ({
  listStarting,
  isLoadingAction,
  rowData,
  duplicatdItenaryV2,
  setIsCompirDuplicated,
  setPayloadDuplicated,
  setIsDuplicated,
}) => {
  const [choiceStarting, setChoiceStarting] = useState(null);
  const [listStartingStarting, setListStartingState] = useState([]);

  useEffect(() => {
    if (listStarting) {
      let _resultMap = listStarting?.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setListStartingState(_resultMap);
    }
  }, [listStarting]);

  return (
    <div className=" border-[#E0E0E0] border-solid border-[1px] rounded-2xl  flex flex-col justify-start ">
      <div className="m-[16px] gap-y-[16px]">
        <div className="font-semibold text-[#4F4F4F] mb-[8px] text-[14px] ">
          Starting
        </div>
        <Select
          placeholder="Pilih rute"
          className="!w-full"
          size="large"
          value={choiceStarting}
          onChange={(value) => {
            setChoiceStarting(value);
          }}
          options={listStartingStarting}
        />
      </div>
      <div className="divider"></div>
      <div className="flex flex-row justify-end  w-full p-[16px]">
        <Buttons
          text="Simpan"
          customClass="btn-primary btn-medium"
          onClick={() => {
            setPayloadDuplicated({
              from_starting_id: rowData?.id,
              to_starting_id: choiceStarting,
            });
            setIsDuplicated(false);

            setIsCompirDuplicated(true);
          }}
          loading={isLoadingAction}
          disabled={choiceStarting === null ? true : false}
        />
      </div>
    </div>
  );
};

export default Duplicate;
