import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Card from "components/cards";
import Inputs from "components/form/inputs";
import Textareas from "components/form/textarea";
import Grid from "@mui/material/Grid";
import Buttons from "components/buttons";
import Selects from "components/form/selects";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { getAllDataBandara, getMasterId } from "stores/actions/datamaster";
import { setDataBandara } from "stores/actions/datamaster";
import {
  createOrUpdateStarting,
  getMasterStarting,
} from "stores/actions/datamaster/starting";
import { setValidation, set_after_validation } from "stores/actions/general";
import ColorPicker from "components/color_picker";
import { Select } from "antd";
import RichTextEditor from "components/richTextEditor";
import InputsCurrency from "components/form/inputsCurrency";

function StartingComponentModal() {
  const dispatch = useDispatch();
  const { masterId, dataBandara, keyData } = useSelector(
    (state) => state?.datamaster
  );
  const [selectedColor, setSelectedColor] = useState("");

  const numberValidation = (value) => {
    if (!/^\d+$/.test(value)) {
      return false;
    }
    return true;
  };
  Yup.addMethod(Yup.string, "number", function (message) {
    return this.test("number", message, numberValidation);
  });

  useEffect(() => {
    (keyData === "starting" && masterId?.tambahData === true) ||
    (keyData === "starting" && masterId?.modalEdit === true)
      ? dispatch(
          getAllDataBandara({
            page_number: 1,
            per_page: 1000,
            keyword: "",
            sort_column: "",
            sort_order: "DESC",
          })
        )
      : dispatch(setDataBandara({}));
  }, [dispatch, keyData, masterId?.modalEdit, masterId?.tambahData]);

  const starting = useFormik({
    initialValues: {
      name: masterId?.data?.name || "",
      id_bandara_departure: masterId?.data?.id_bandara_departure || "",
      id_bandara_return: masterId?.data?.id_bandara_return || "",
      price_single: masterId?.data?.price_single || "",
      price_double: masterId?.data?.price_double || "",
      price_tripple: masterId?.data?.price_tripple || "",
      price_quad: masterId?.data?.price_quad || "",
      price_single_bussiness: masterId?.data?.price_single_bussiness || "",
      price_double_bussiness: masterId?.data?.price_double_bussiness || "",
      price_tripple_bussiness: masterId?.data?.price_tripple_bussiness || "",
      price_quad_bussiness: masterId?.data?.price_quad_bussiness || "",
      color_bg: masterId?.data?.color_bg || "",
      description: masterId?.data?.description || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama starting wajib diisi"),
      id_bandara_departure: Yup.string().required("Berangkat wajib diisi"),
      id_bandara_return: Yup.string().required("Pulang wajib diisi"),
      price_single: Yup.string()
        .number("harus angka")
        .required("Price single harus disi"),
      price_double: Yup.string()
        .number("harus angka")
        .required("Price double harus disi"),
      price_tripple: Yup.string()
        .number("harus angka")
        .required("Price triple harus disi"),
      price_quad: Yup.string()
        .number("harus angka")
        .required("Price Quad harus disis"),
      description: Yup.string().required("Deskripsi harus disi"),
    }),
    onSubmit: (val) => {
      if (masterId?.data) {
        val.id_starting = masterId?.data?.id_starting;

        dispatch(createOrUpdateStarting(val));
      } else {
        dispatch(createOrUpdateStarting(val));
      }
    },
  });

  const { global, validation, afterValidation } = useSelector(
    ({ general }) => general
  );

  const { isLoading } = useSelector(({ adminStarting }) => adminStarting);

  useEffect(() => {
    if (afterValidation?.code === 200) {
      dispatch(getMasterId({}));
      dispatch(
        getMasterStarting({
          page_number: global?.currentpage,
          per_page: global?.size,
          keyword: "",
          sort_column: "id_starting",
          sort_order: "DESC",
        })
      );
      dispatch(setValidation({}));
      dispatch(set_after_validation({}));
    }
  }, [afterValidation?.code, dispatch, global?.currentpage, global?.size]);

  return (
    <Card containerClass="!px-0 !pb-4 !pt-4 !rounded-2xl !shadow-none !bg-transparent !border !border-gray-200">
      <div className="w-full px-4 overflow-y-auto h-[34rem]">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12}>
            <Inputs
              id="name"
              name="name"
              onChange={starting.handleChange}
              onBlur={starting.handleBlur}
              value={starting.values.name}
              error={
                starting.touched.name && starting.errors.name
                  ? starting.errors.name
                  : ""
              }
              label="Nama Starting"
              placeholder="Masukan nama starting"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Selects
              label="Berangkat"
              placeholder={
                masterId?.data
                  ? masterId?.data?._bandara_departure?.name
                  : "Pilih Bandara"
              }
              onChange={(e) =>
                e.length !== 0 &&
                starting.setFieldValue("id_bandara_departure", e[0]?.value)
              }
              value={
                masterId && masterId?.data?._bandara_departure?.name
                  ? [
                      {
                        label: masterId?.data?._bandara_departure?.name,
                        value:
                          masterId?.data?._bandara_departure
                            ?.id_bandara_departure,
                      },
                    ]
                  : []
              }
              options={
                dataBandara?.total_data !== 0 &&
                dataBandara?.data?.map((v) => {
                  return {
                    label: v?.name,
                    value: v?.id_bandara,
                  };
                })
              }
              error={
                (starting.touched.id_bandara_departure &&
                  starting.errors.id_bandara_departure) ||
                (validation?.data?.id_bandara &&
                  validation?.data?.id_bandara[0])
              }
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Selects
              placeholder={
                masterId?.data
                  ? masterId?.data?._bandara_return?.name
                  : "Pilih Bandara"
              }
              label="Pulang"
              onChange={(e) =>
                e.length !== 0 &&
                starting.setFieldValue("id_bandara_return", e[0]?.value)
              }
              value={
                masterId && masterId?.data?._bandara_return?.name
                  ? [
                      {
                        label: masterId?.data?._bandara_return?.name,
                        value:
                          masterId?.data?._bandara_return?.id_bandara_return,
                      },
                    ]
                  : []
              }
              options={
                dataBandara?.total_data !== 0 &&
                dataBandara?.data?.map((v) => {
                  return {
                    label: v?.name,
                    value: v?.id_bandara,
                  };
                })
              }
              error={
                (starting.touched.id_bandara_return &&
                  starting.errors.id_bandara_return) ||
                (validation?.data?.id_bandara_return &&
                  validation?.data?.id_bandara_return[0])
              }
            />
          </Grid>
        </Grid>

        <div className="p-4 border-dashed border border-gray-200 mt-6 rounded-xl">
          <label className="text-lg font-semibold text-gray-600">
            Domestik Harga Tambahan
          </label>
          <div className="mt-4">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item sm={3}>
                <InputsCurrency
                  label="Single"
                  placeholder="Masukan nominal"
                  id="price_single"
                  name="price_single"
                  customLabel="text-[#4f4f4f]"
                  onChange={(value) =>
                    starting.setFieldValue("price_single", value)
                  }
                  onBlur={starting.handleBlur}
                  value={starting.values.price_single}
                  error={
                    starting.touched.price_single &&
                    starting.errors.price_single
                      ? starting.errors.price_single
                      : ""
                  }
                />
              </Grid>
              <Grid item sm={3}>
                <InputsCurrency
                  label="Double"
                  placeholder="Masukan nominal"
                  id="price_double"
                  name="price_double"
                  onChange={(value) =>
                    starting.setFieldValue("price_double", value)
                  }
                  onBlur={starting.handleBlur}
                  value={starting.values.price_double}
                  error={
                    starting.touched.price_double &&
                    starting.errors.price_double
                      ? starting.errors.price_double
                      : ""
                  }
                />
              </Grid>
              <Grid item sm={3}>
                <InputsCurrency
                  label="Triple"
                  placeholder="Masukan nominal"
                  id="price_tripple"
                  name="price_tripple"
                  onChange={(value) =>
                    starting.setFieldValue("price_tripple", value)
                  }
                  onBlur={starting.handleBlur}
                  value={starting.values.price_tripple}
                  error={
                    starting.touched.price_tripple &&
                    starting.errors.price_tripple
                      ? starting.errors.price_tripple
                      : ""
                  }
                />
              </Grid>
              <Grid item sm={3}>
                <InputsCurrency
                  label="Quad"
                  placeholder="Masukan nominal"
                  id="price_quad"
                  name="price_quad"
                  onChange={(value) =>
                    starting.setFieldValue("price_quad", value)
                  }
                  onBlur={starting.handleBlur}
                  value={starting.values.price_quad}
                  error={
                    starting.touched.price_quad && starting.errors.price_quad
                      ? starting.errors.price_quad
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="mt-4 ">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item sm={6}>
              <RichTextEditor
                placeholder="Masukkan Deskripsi Additional"
                data={{ value: starting.values.description }}
                onChange={(value) => {
                  starting.setFieldValue("description", value);
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <ColorPicker
                name="color_bg"
                customWrapper="py-11 text-center"
                customStyle={
                  starting.values?.color_bg !== ""
                    ? starting.values?.color_bg
                    : selectedColor
                }
                placeholder={
                  starting.values?.color_bg !== ""
                    ? starting.values?.color_bg
                    : "Pilih Latar Belakang"
                }
                label="Pilih Latar Belakang"
                onChange={(color_bg) => {
                  setSelectedColor(color_bg.hex);
                  starting.setFieldValue("color_bg", color_bg.hex);
                }}
                color_bg={
                  starting.values?.color_bg !== ""
                    ? starting.values?.color_bg
                    : selectedColor
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="w-full flex justify-end border-t border-gray-200 pt-4 mt-4">
        <div className="w-64 px-4 h-auto">
          <Buttons
            text="Selanjutnya"
            onClick={() => starting.handleSubmit()}
            disabled={
              !(
                starting.isValid &&
                starting.dirty &&
                starting.values.description !== "<p><br></p>"
              )
            }
            loading={isLoading ? true : false}
            customClass="btn-primary btn-large"
          />
        </div>
      </div>
    </Card>
  );
}

export default StartingComponentModal;
