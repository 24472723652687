import Buttons from "components/buttons"
import Inputs from "components/form/inputs"
import InputsCurrency from "components/form/inputsCurrency"

import IconTrash from "assets/icons/trash-maroon.svg";

import AlertPopup from "components/popup/alert";
import { useState } from "react";

const OperationalEquipmentAdditionalForm = ({
  data,
  onChange
}) => {

  const [removeConfirmShow, setRemoveConfirmShow] = useState(false)
  const [equipmentSelectedIndex, setEquipmentSelectedIndex] = useState(null)

  const handleUpdateData = (key, value, index) => {
    let _tempData = data
    _tempData[index][key] = value

    onChange(_tempData)
  }

  const handleRemoveItemConfirm = (index) => {
    setRemoveConfirmShow(true)
    setEquipmentSelectedIndex(index)
  }

  const handleRemoveData = () => {
    let _tempData = data
    _tempData.splice(equipmentSelectedIndex, 1)

    onChange(_tempData)
    setRemoveConfirmShow(false)
  }

  const handleAddData = () => {
    let _tempData = data
    _tempData.push({
      id: null,
      name: null,
      price: 0
    })

    onChange(_tempData)
  }

  return (
    <>
      <AlertPopup
        open={removeConfirmShow}
        handleClose={() => setRemoveConfirmShow(false)}
        handleContinue={() => handleRemoveData()}
        subtitle="Apakah anda yakin menghapus data ini?"
      />
      <div className="w-full border rounded-2xl p-4">
        <div className="w-full flex justify-between items-center">
          <div className="font-semibold">
            Perlengkapan Tambahan
          </div>
          <Buttons
            text="Tambah Perlengkapan"
            customClass="btn-primary btn-small"
            onClick={handleAddData}
            />
        </div>

        <div className="mt-4 flex flex-col gap-4">

          {data?.map((item, index) => (
            <>
              <div className="flex items-center w-full gap-4" key={index}>

                <div className="w-full flex gap-4 items-center">
                  <div className="w-full">
                    <Inputs 
                      label="Nama Perlengkapan"
                      placeholder="Masukan nama perlengkapan"
                      value={item.name}
                      onChange={(e) => {
                        handleUpdateData('name', e.target.value, index)
                      }}
                      />
                  </div>
                  <div className="w-full">
                    <InputsCurrency
                        label={"Harga"}
                        value={item.price}
                        placeholder="Masukan harga"
                        onChange={(val) => {
                          handleUpdateData('price', val, index)
                        }}
                      />
                  </div>
                </div>

                {index !== 0 && (
                  <>
                    <div 
                      onClick={() => handleRemoveItemConfirm(index)}
                      className="cursor-pointer mt-[25px]"
                      >
                      <img className="w-6 h-6" src={IconTrash} alt="trash"/>
                    </div>
                  </>
                )}

              </div>
            </>
          ))}

        </div>

      </div>
    </>
  )
}

export default OperationalEquipmentAdditionalForm