import Scroll from "components/scroll"
import BadgeComponent from "components/badge"

import IconChecklistListed from 'assets/icons/Checklisted.svg';
import { useEffect, useState } from "react";

const Badge = ({
  option,
  data,
  onChange,
  values
}) => {

  const [dataValues, setDataValues] = useState([])

  const handleSelect = (value) => {
    // Buat salinan dari values untuk menghindari mutasi langsung
    let _tempValues = [...dataValues];
    
    if (_tempValues.includes(value)) {
      _tempValues = _tempValues.filter((item) => item !== value);
    } else {
      _tempValues.push(value);
    }
  
    setDataValues(_tempValues);
    onChange(_tempValues);
  };

  useEffect(() => {
    if (values) {
      setDataValues(values)
    }
  }, [values])

  return (
    <>
      <Scroll
        height="220px"
        >

        {data.map((item, index) => (
          <div
            key={index}
            onClick={() => handleSelect(item[option.customKey?.value] || item.value)}
            className={`flex items-center ${index !== data.length - 1 && "mb-4"} gap-2 cursor-pointer`}>
            <BadgeComponent
              label={item[option.customKey?.label] || item.label}
              backgroundColor={item[option.customKey?.badgeBgColor] || item.badgeTextColor}
              color={item[option.customKey?.badgeTextColor] || item.badgeTextColor}
              />
          
          {dataValues.includes(item[option.customKey?.value] || item.value) && <img src={IconChecklistListed} className="w-6 h-6" alt="checklist"/>}

          </div>
        ))}

      </Scroll>
    </>
  )
}

export default Badge