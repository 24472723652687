import React, { useEffect, useState } from "react";

import DateRangePicker from "components/datepicker/DateRangePicker";
import MoslemeTable from "components/Table";

import { useDispatch } from "react-redux";

import IconBack from "assets/icons/arrow-left-dark-icon.svg";
import IconEye from "assets/icons/eye.svg";
import Badge from "components/badge";

import { getFeeTotalDetailService } from "utils/api/fee";
import { handleError } from "stores/actions/errorGeneral";
import Router from "router";
import moment from "moment";
import { IDRFormater } from "utils/helpers";

const PendapatanCabangHistory = ({ setHistory, branch }) => {
  const dispatch = useDispatch()
  const [filterStatus, setFilterStatus] = useState("all");
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(true);

  const [feeTotalDetail, setFeeTotalDetail] = useState([])

  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  const handleGetFeeTotalDetail = async () => {
    setIsLoading(true)
    const params = {
      recipient: 'branch',
      recipient_id: branch?.id_cabang,
      per_page: size,
      page: currentpage,
      status: filterStatus,
      start_date: filterStartDate,
      end_date: filterEndDate
    }
    const res = await getFeeTotalDetailService(params)
    setIsLoading(false)

    if(res.status === 200) {
      setFeeTotalDetail(res.data.data)
      setTotal(res.data.total)
    } else {
      dispatch(handleError(res.data))
    }

  }

  const columns = [
    {
      title: "Nama Paket",
      dataIndex: "package",
      render: (record) => (
        <div className="flex flex-row items-center gap-2">
          <div>{record.judul_paket}</div>
          {/* <img 
            src={IconEye} 
            className="cursor-pointer h-4 w-4" 
            onClick={() => Router.history.push(`/paket/detail/${record.id_paket}`)} 
            alt="eye" /> */}
        </div>
      ),
    },
    {
      title: "Tanggal Pendapatan",
      dataIndex: "received_at",
      render: (record) => (
        <div className="flex flex-row items-center gap-2">
          <div>{moment(record).format("dddd, DD MMMM YYYY H:mm")} WIB</div>
        </div>
      ),
    },
    {
      title: "Jumlah Pax",
      dataIndex: "count_pax",
      align: "center",
    },
    {
      title: "Nominal Pendapatan",
      dataIndex: "fee_total",
      render: (income) => (
        <>
          <div className="font-semibold">{IDRFormater(income)}</div>
        </>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Umroh Bersama Ustadz Muhammad Abduh Tuasikal 12 September 2022",
      date: "21/08/2022 11:27:29",
      total_pax: "24",
      income: "10.500.000",
    },
    {
      key: "2",
      name: "Umroh Bersama Ustadz Muhammad Abduh Tuasikal 12 September 2022",
      date: "21/08/2022 11:27:29",
      total_pax: "24",
      income: "10.500.000",
    },
  ];

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };
  // manipulated data with action
  const sourceData = data;

  const onDatePickerChange = (val) => {
    // console.log(val);
    let _tempStartDate = val[0];
    let _tempEndDate = val[1];

    setFilterStartDate(_tempStartDate);
    setFilterEndDate(_tempEndDate);
  };

  useEffect(() => {
    handleGetFeeTotalDetail()
  }, [dispatch, currentpage, size, branch, filterStatus, filterEndDate, filterStartDate]);

  return (
    <>
      <div className="flex flex-row justify-between items-center py-3">
        <div className="flex flex-row gap-4">
          <img
            className="w-[24px] h-[24px] cursor-pointer"
            src={IconBack}
            alt="back"
            onClick={() => setHistory(false)}
          />
          <div className="text-[16px] font-semibold">
            History Pendapatan Cabang
          </div>
          <Badge label={branch?.name.toUpperCase()} color={branch?.color_text} backgroundColor={branch?.color} />
        </div>
        <div className="flex flex-row gap-4">
          <DateRangePicker
            customClass="!px-4 !py-2"
            placeholder="Tanggal Awal"
            placeholder2={"Tangal Akhir"}
            onCalendarChange={onDatePickerChange}
          />
        </div>
      </div>
      <div className="mt-4 mx-[-1rem]">
        <MoslemeTable
          loading={isLoading}
          columns={columns}
          data={feeTotalDetail}
          withSelection={withSelection}
          selectionType={selectionType}
          pagination={pagination}
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      </div>
    </>
  );
};

export default React.memo(PendapatanCabangHistory);
