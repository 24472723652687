import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateRekening,
  getMasterRekening,
} from "stores/actions/datamaster/adminRekening";
import * as Yup from "yup";
import Buttons from "../../../../../components/buttons";
import Card from "../../../../../components/cards";
import Inputs from "../../../../../components/form/inputs";
import Textareas from "../../../../../components/form/textarea";
import ImageField from "../../../../../components/image_field";
import CheckboxMaster from "../../checkbox";
import { getMasterId } from "stores/actions/datamaster";
import { resetDataUpload } from "stores/actions/uploadFile";
import Badge from "components/badge";
import RichTextEditor from "components/richTextEditor";
import UploadArea from "components/Uploadarea";

export const dataRekening = [
  {
    label: "Haji",
    value: "is_haji",
    color: "#FE9A00",
    bgColor: "#ffdeab",
  },
  {
    label: "Umroh",
    value: "is_umroh",
    color: "#564592",
    bgColor: "#56459233",
  },
  {
    label: "Tour",
    value: "is_tour",
    color: "#F20089",
    bgColor: "#F851A333",
  },
  {
    label: "Jasa Badal",
    value: "is_jasa_badal",
    color: "#333D29",
    bgColor: "#00FF0133",
  },
  {
    label: "Tabungan",
    value: "is_tabungan",
    color: "#C08F01",
    bgColor: "#BF900133",
  },
];

function RekeningComponentModal() {
  const dispatch = useDispatch();

  const { masterId } = useSelector((state) => state?.datamaster);
  const { global } = useSelector((state) => state.general);
  const { isLoading } = useSelector((state) => state.adminRekening);

  const [input, setInput] = useState({
    is_haji: masterId?.data?.is_haji === "1" ? true : false,
    is_umroh: masterId?.data?.is_umroh === "1" ? true : false,
    is_tour: masterId?.data?.is_tour === "1" ? true : false,
    is_jasa_badal: masterId?.data?.is_jasa_badal === "1" ? true : false,
    is_tabungan: masterId?.data?.is_tabungan === "1" ? true : false,
  });

  const rekening = useFormik({
    initialValues: {
      bank_logo: masterId?.data?.bank_logo,
      bank_name: masterId?.data?.bank_name || "",
      account_number: masterId?.data?.account_number || "",
      account_owner: masterId?.data?.account_owner || "",
      currency: masterId?.data?.currency || "",
      description: masterId?.data?.description || "",
    },
    validationSchema: Yup.object().shape({
      bank_name: Yup.string().required("Nama wajib diisi"),
      account_number: Yup.string()
        .required("Nomor rekening wajib diisi"),
      account_owner: Yup.string().required("Pemilik rekening wajib diisi"),

      currency: Yup.string().required("Mata uang wajib diisi"),
      description: Yup.string().required("Deskripsi wajib diisi"),
    }),
    onSubmit: (val) => {
      val.is_haji = input.is_haji ? "1" : "0";
      val.is_umroh = input.is_umroh ? "1" : "0";
      val.is_tour = input.is_tour ? "1" : "0";
      val.is_jasa_badal = input.is_jasa_badal ? "1" : "0";
      val.is_tabungan = input.is_tabungan ? "1" : "0";
      // return console.log("ini value", val);

      if (masterId?.data) {
        val.id_rekening = masterId?.data?.id_rekening;
        dispatch(createOrUpdateRekening(val));
      } else {
        dispatch(createOrUpdateRekening(val));
      }

      setTimeout(() => {
        rekening.resetForm();
        dispatch(getMasterId({}));
        dispatch(resetDataUpload());
        dispatch(
          getMasterRekening({
            page_number: global?.currentpage,
            per_page: global?.size,
            keyword: "",
            sort_column: "",
            sort_order: "DESC",
          })
        );
      }, 1000);
    },
  });

  return (
    <Card containerClass="!px-0 !pb-4 !pt-4 !rounded-2xl !shadow-none !bg-transparent !border !border-gray-200">
      <div className="w-full px-4">
        <div className="text-lg font-semibold text-gray-600 mb-2">
          Jenis Rekening
        </div>
        <div className="flex mb-4">
          <CheckboxMaster
            customClass="mt-4"
            title={
              <Badge label="Haji" color="#FE9A00" backgroundColor="#ffdeab" />
            }
            value={input.is_haji}
            checked={input.is_haji}
            onChange={(e) =>
              setInput({
                ...input,
                is_haji: e.target.checked,
              })
            }
          />
          <CheckboxMaster
            customClass="mt-4"
            title={
              <Badge label="Umroh" color="#564592" backgroundColor="#DDDAE9" />
            }
            value={input.is_umroh}
            checked={input.is_umroh}
            onChange={(e) =>
              setInput({
                ...input,
                is_umroh: e.target.checked,
              })
            }
          />
          <CheckboxMaster
            customClass="mt-4"
            title={
              <Badge label="Tour" color="#F20089" backgroundColor="#F9BCC2" />
            }
            value={input.is_tour}
            checked={input.is_tour}
            onChange={(e) =>
              setInput({
                ...input,
                is_tour: e.target.checked,
              })
            }
          />
          <CheckboxMaster
            customClass="mt-4"
            title={
              <Badge
                label="Jasa Badal"
                color="#333D29"
                backgroundColor="#CDFFCB"
              />
            }
            value={input.is_jasa_badal}
            checked={input.is_jasa_badal}
            onChange={(e) =>
              setInput({
                ...input,
                is_jasa_badal: e.target.checked,
              })
            }
          />
          <CheckboxMaster
            customClass="mt-4"
            title={
              <Badge
                label="Tabungan"
                color="#C08F01"
                backgroundColor="#F2EACC"
              />
            }
            value={input.is_tabungan}
            checked={input.is_tabungan}
            onChange={(e) =>
              setInput({
                ...input,
                is_tabungan: e.target.checked,
              })
            }
          />
        </div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6}>
            <Inputs
              id="bank_name"
              label="Nama Bank"
              placeholder="Masukan Nama Bank"
              name="bank_name"
              onChange={rekening.handleChange}
              onBlur={rekening.handleBlur}
              value={rekening.values.bank_name}
              error={
                rekening.touched.bank_name &&
                rekening.errors.bank_name &&
                rekening.errors.bank_name
              }
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Inputs
              id="currency"
              label="Mata Uang"
              placeholder="Masukan Mata Uang"
              name="currency"
              onChange={rekening.handleChange}
              onBlur={rekening.handleBlur}
              value={rekening.values.currency}
              error={
                rekening.touched.currency &&
                rekening.errors.currency &&
                rekening.errors.currency
              }
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Inputs
              id="account_owner"
              label="Pemilik Rekening"
              placeholder="Pemilik Rekening"
              name="account_owner"
              onChange={rekening.handleChange}
              onBlur={rekening.handleBlur}
              value={rekening.values.account_owner}
              error={
                rekening.touched.account_owner &&
                rekening.errors.account_owner &&
                rekening.errors.account_owner
              }
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Inputs
              id="account_number"
              label="Nomor Rekening"
              placeholder="Masukan Nomor Rekening"
              type="number"
              name="account_number"
              onChange={rekening.handleChange}
              onBlur={rekening.handleBlur}
              value={rekening.values.account_number}
              error={
                rekening.touched.account_number &&
                rekening.errors.account_number &&
                rekening.errors.account_number
              }
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <RichTextEditor
              placeholder="Masukkan Deskripsi Rekening"
              data={{ value: rekening.values.description }}
              onChange={(value) => {
                rekening.setFieldValue("description", value)
              }}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <UploadArea
              type='image'
              label="Logo Bank"
              customClass="w-full"
              defaultUrl={rekening.values?.bank_logo}
              onChange={(url) => rekening.setFieldValue('bank_logo', url)}
              handleRemove={() => rekening.setFieldValue('bank_logo', null)}
              />
            {/* <ImageField
              label="Logo Bank"
              customContainer="!w-auto h-[13.5rem] !rounded-2xl"
              customWrapper="w-full !h-full flex justify-center items-center"
              customImageContainer="!max-w-[12rem] !min-w-[12rem] !max-h-full !min-w-full"
              name="bank_logo"
              setFile={(file) =>
                rekening.setFieldValue("bank_logo", file?.path)
              }
            /> */}
          </Grid>
        </Grid>
      </div>
      <div className="w-full flex justify-end border-t border-gray-200 pt-4 mt-4">
        <div className="w-64 px-4 h-auto">
          <Buttons
            disabled={!(rekening.isValid && rekening.dirty && rekening.values.description !== '<p><br></p>')}
            text="Selanjutnya"
            onClick={() => rekening.handleSubmit()}
            customClass="btn-primary  btn-large"
            loading={isLoading}
          />
        </div>
      </div>
    </Card>
  );
}

export default RekeningComponentModal;
