import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import ChartStyle from "./Chart.style";
import { IDRFormater, IDRInitialSting } from "utils/helpers";
const moment = require('moment');
require('moment/locale/id'); // Mengimpor lokal Bahasa Indonesia

const Chart = ({ containerClass, data, categories, type, colors, formatData, categoryFo }) => {
  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
        autoSelected: "pan",
      },
      locales: [{
        "name": "en",
        "options": {
          "months": ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
        }
      }],
      defaultLocale: "en"
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      axisBorder: {
        show: true,
        color: "#4F4F4F",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        datetimeFormatter: {
          year: 'yy',
          month: 'MMMM',
          day: 'dd MMM',
          hour: 'HH:mm'
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: true,
        width: 1,
        color: "#4F4F4F",
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        formatter: function (value) {
          if(formatData == "IDR") {
            return IDRInitialSting(value)
          }

          return value
        }
      }
    },
    colors: colors,
    tooltip: {
      x: {
        position: 'top',
        formatter: function (value) {
          const date = moment(value);
          const month = date.format('MMMM');
          const year = date.format('YYYY');
          return `${month} ${year}`;
        }
      },
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex }) {
          if(formatData == 'IDR') {
            return IDRFormater(value)
          } else {
            const chartName = data[seriesIndex].name;
            return value + ' ' + chartName.replace(chartName + ':', '');
          }
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
  };

  options.xaxis.categories = categories;

  return (
    <ChartStyle className={`custom-chart ${containerClass}`}>
      <ReactApexChart
        options={options}
        series={data}
        type={type}
        height={434}
      />
    </ChartStyle>
  );
};

Chart.propTypes = {
  containerClass: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.string,
  colors: PropTypes.array,
  formatData: PropTypes.string,
  categoryFo: PropTypes.string
};

Chart.defaultProps = {
  containerClass: "",
  data: [],
  type: "area",
  colors: ["#DA4646", "#9ED952", "#4F4F4F"],
  formatData: 'normal',
  categoryFo: 'MMM yy'
};

export default Chart;
