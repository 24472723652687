import {
    getSaldoFeeService
} from 'utils/api/fee'
import { handleError } from '../errorGeneral';

export const SET_LOADING = "fee/SET_LOADING";
export const SET_FETCH_SALDO = "fee/SET_FETCH_SALDO"

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload,
});

export const setSaldoFee = (payload) => ({
    type: SET_FETCH_SALDO,
    payload,
});


export const handleGetSaldoFee = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const res = await getSaldoFeeService();
            dispatch(setLoading(false));
            
            if (res.status === 200) {
                dispatch(setSaldoFee(res.data.data.saldo_fee));
            } else {
                dispatch(handleError(res.data));
            }
        } catch (error) {
            dispatch(handleError(error));
        }
    }
}