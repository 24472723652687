import React from "react";
import PropTypes from "prop-types";
import { TimePicker as AntTimePicker } from "antd";
import "./datepicker.scss";
import Close from "assets/icons/Close.svg";
import moment from "moment";
import TimePickerIcon from "./icons/TimePickerIcon";

const CalendarIcon = <TimePickerIcon />;

const TimePicker = ({
  containerClass,
  customClass,
  placeholder,
  value,
  formatManual,
  onChange,
  onBlur,
  CalendarIcon = <TimePickerIcon />,
  error,
}) => {
  const formatDateValue = (val) => {
    console.log("val:", val);
    return moment(val?.$d).format("DD-MM-YYYY");
  };

  const onChangeHandler = (val) => {
    if (formatManual) {
      return onChange(val);
    } else {
      onChange(formatDateValue(val));
    }
  };

  return (
    <div className={`${containerClass}`}>
      <AntTimePicker
        className={`${customClass} single-date-picker`}
        placeholder={[placeholder]}
        popupClassName="popup-date-picker"
        onChange={onChangeHandler}
        onBlur={onBlur}
        suffixIcon={CalendarIcon}
        format="HH:mm"
        value={value}
        getPopupContainer={() => document.getElementById("area-datepicker")}
      />
      <div id="area-datepicker"></div>
      {error && (
        <div className="flex flex-row items-center mt-1 gap-x-2">
          <img src={Close} alt="close" />
          <div className="text-red-500">{error}</div>
        </div>
      )}
    </div>
  );
};

TimePicker.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  formatManual: PropTypes.bool,
};

TimePicker.defaultProps = {
  containerClass: "",
  customClass: "",
  placeholder: "",
  formatManual: false,
  onChange: () => {},
  onBlur: () => {},
};

export default TimePicker;
