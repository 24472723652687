import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Buttons from "components/buttons";
import Badge from "components/badge";
import EditIcon from "assets/icons/edit.svg";
import TrashIcon from "assets/icons/trash.svg";
import LocationIcon from "assets/icons/location-black.svg";
import CalendarIcon from "assets/icons/colored/calendar-icon.svg";
import ClockIcon from "assets/icons/colored/clock-icon.svg";
import Places from "components/map";
import moment from "moment";
// import RefreshIcon from "assets/icons/refresh.svg";
import Swap from "assets/icons/Swap.svg";

import { sendManasikInvitationAPI } from 'utils/api/paket'
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { getMessageInvitationHistoryAPI } from "utils/api/paket/manasik";

import { resendMessageAPI } from "utils/api/message-histories";

import MoslemeTable from "components/Table";
import Avatar from "components/avatar";
import { handleName } from "utils/helpers";
import { ReloadOutlined } from "@ant-design/icons";

const ViewItem = ({
  data,
  setIsEdit,
  removeItem,
  generalInfo,
  onClickEditGeneralInfo,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  
  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  
  const [address, setAddress] = useState("");

  const [sendingInvitationManasik, setSendingInvitationManasik] = useState(false)
  const [messageInvitationHistory, setMessageInvitationHistory] = useState([])
  const [messageInvitationHistoryLoading, setMessageInvitationHistoryLoading] = useState(false)

  const [messageInvitationHistoryReload, setMessageInvitationHistoryReload] = useState(false)

  const [resendSelected, setResendSelected] = useState(null)
  const [resendLoading, setResendLoading] = useState(false)

  const handleSendInvitation = async (manasik) => {
    setSendingInvitationManasik(true)
    const response = await sendManasikInvitationAPI(params.id, manasik.id_manasik)
    setSendingInvitationManasik(false)

    if(response.status === 200 || response.status === 201){
      dispatch(handleSuccess(response))
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleGetMessageInvitationHistory = async (manasik, reload = false ) => {
    if(reload){
      setMessageInvitationHistoryReload(true)
    }
    setMessageInvitationHistoryLoading(true)
    const response = await getMessageInvitationHistoryAPI(params.id, manasik.id_manasik)
    setMessageInvitationHistoryLoading(false)

    if(reload){
      setMessageInvitationHistoryReload(false)
    }

    if(response.status === 200 || response.status === 201){
      setMessageInvitationHistory(response.data.data)
      // dispatch(handleSuccess(response))
    } else {
      dispatch(handleError(response.data))
    }
  }

  const handleResendInvitation = async (message) => {
    setResendSelected(message.id)
    setResendLoading(true)
    const response = await resendMessageAPI(message.id)
    setResendLoading(false)
    setResendSelected(null)

    if(response.status === 200 || response.status === 201){
      handleGetMessageInvitationHistory(data)
      dispatch(handleSuccess(response))
    } else {
      dispatch(handleError(response.data))
    }
  }

  const columns = [
    {
      title: "Nama",
      render: (record) => (
        <div className="flex items-center gap-2">
          <Avatar value={record.recipient ?? record.recipient_data} />
          <div>
            { record.recipient 
              ? handleName(record.recipient.jamaah_title, record.recipient.jamaah_name) 
              : handleName(record.recipient_data.jamaah_title, record.recipient_data.jamaah_name)
            }
          </div>
        </div>
      )
    },
    {
      title: "Waktu",
      render: (record) => (
        <div>
          {moment(record.created_at).format("dddd, DD MMMM YYYY H:mm")} WIB
        </div>
      )
    },
    {
      title: "Nomor Penerima",
      render: (record) => (
        <div>
          {record.destination}
        </div>
      )
    },
    {
      title: "Status",
      render: (record) => (
        <div className="flex items-center gap-2">
          <Badge 
            label={
              record.status == 'pending'
                ? "Menunggu" 
                : record.status == 'success'
                  ? "Berhasil" 
                  : record.status == 'failed'
                    ? "Gagal" : ""
            } 
            color={
              record.status == 'success' 
                ? '#359B05' 
                : record.status == 'pending' 
                  ? '#E2A246' 
                  : record.status == 'failed' 
                    ? '#E82320' : '#000000'
            }
            backgroundColor={
              record.status == 'success' 
                ? '#E8FBE7' : record.status == 'pending' 
                  ? '#FBF8E7' 
                  : record.status == 'failed' 
                    ? '#FAE7E6' : '#000000'
            }
            />
          {record.status == 'failed' && (
            <>
              <Buttons
                text="Kirim Ulang"
                loading={record.id == resendSelected && resendLoading}
                customClass="btn-primary btn-small"
                onClick={() => handleResendInvitation(record)}
              />
            </>
          )}
        </div>
      )
    },
  ];

  useEffect(() => {
    handleGetMessageInvitationHistory(data)
  },[])

  return (
    <>
      <div className="w-full flex justify-between">
        <Badge
          label={data.id}
          backgroundColor="#E0E0E0"
          color="#141414"
          customClass="capitalize w-[34px]"
        />

        {/* {auth.user?.access_data === 'all_branches' && ( */}
          {permissions?.includes("update_manasik") && (
            <>
                <div className="flex gap-4">
                  {permissions?.includes("update_manasik") && (
                    <>
                      <button
                        onClick={() => {
                          if (generalInfo) {
                            onClickEditGeneralInfo(data);
                          } else {
                            setIsEdit(true);
                          }
                        }}
                      >
                        <img src={EditIcon} alt="edit icon" width={22} />
                      </button>
                    </>
                  )}
                  {permissions?.includes("delete_manasik") && (
                    <>
                      <button onClick={() => removeItem(data.id)}>
                        <img src={TrashIcon} alt="trash icon" width={22} />
                      </button>
                    </>
                  )}
                </div>
              </>
          )}
        {/* )} */}
      </div>
      <div className="w-full flex justify-between  text-sm">
        <div className="mt-4">
          <h4 className="font-bold mb-1">Koordinat Lokasi</h4>
          <p className="flex gap-2">
            <img src={LocationIcon} alt="location icon" />
            {data.koordinat.lat ? data.lokasi : "-"}
          </p>
          <h4 className="mt-4 mb-1 font-bold">Pelaksanaan</h4>
          <div className="flex gap-3">
            <div className="flex gap-2 items-center">
              <img src={CalendarIcon} className="w-6 h-6" alt="calendar icon" />
              {data?.tanggal_pelaksanaan
                ? moment(data?.tanggal_pelaksanaan)
                    ?.locale("id")
                    ?.format("dddd, D MMMM YYYY")
                : "-"}
            </div>
            <div className="flex gap-2 items-center">
              <img src={ClockIcon} className="w-6 h-6" alt="clock icon" />
              {data?.waktu_pelaksanaan ? (
                <div dangerouslySetInnerHTML={{ __html: data?.waktu_pelaksanaan }} />
              ) : (
                "-"
              )}
            </div>
          </div>
          <Buttons
            disabled={!data.id_manasik}
            text="Kirim Undangan"
            customClass="btn-outline btn-small !w-fit mt-3"
            onClick={() => handleSendInvitation(data)}
            loading={sendingInvitationManasik}
          />

        </div>
        <div className="w-1/2">
          <div className="shadow-soft p-[8px] w-full  mt-5 rounded-2xl">
            {data.koordinat.lat ? (
              <Places
                style={{
                  borderRadius: "10px",
                  width: "100%",
                  height: "120px",
                }}
                withSearch={false}
                selected={data.koordinat}
                address={address}
                setAddress={(e) => setAddress(e)}
              />
            ) : (
              <div className="bg-gray-100 w-full h-auto rounded-md"></div>
            )}
          </div>
        </div>
      </div>

      <div className="!w-full mt-4 rounded-2xl shadow-soft relative">
        <div className="absolute bottom-4 right-4 z-10">
          <button
            onClick={() => handleGetMessageInvitationHistory(data, true)}
            className="w-10 h-10 flex items-center justify-center bg-white rounded-full shadow-lg hover:shadow-xl transition-all"
          >
            <ReloadOutlined 
              spin={messageInvitationHistoryReload}
              className="text-lg"q1x4r5ew4rd
              />
          </button>
        </div>
        <MoslemeTable 
          customClass="!w-full" 
          loading={messageInvitationHistoryLoading}
          columns={columns}
          data={messageInvitationHistory}
          withPagination={false}
          scroll={{
            y: 'calc(100vh - 550px)',
          }}
          />
      </div>
    </>
  );
};

export default ViewItem;
