import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import AntdSelect from "components/form/antdSelect"
import Inputs from "components/form/inputs"
import Choice from "components/Choice"

import IconJamPasir from "assets/icons/Jam-Pasir.svg";

import { getTourCountry, getTourCountryByMonthYear } from "utils/api/requestPaket"

import { handleError } from "stores/actions/errorGeneral"
import { Skeleton } from "antd"

const Step1 = ({
  values,
  setFieldValues,
  setTourCountrySelected,
  tourCountrySelected,
  hotelTourNights,
  setHotelTourNights,
  hotelTourIds,
  setHotelTourIds,
  countryTourName,
  setCountryTourName
}) => {

  const dispatch = useDispatch()

  const [totalPaxOptions, setTotalPaxOptions] = useState([])
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const [isLoadingGetTourCountry, setIsLoadingGetTourCountry] = useState(false)
  const [tourCountryOptions, setTourCountryOptions] = useState([])

  const airplaneClassOptions = [
    {
      value: "economy",
      label: "Economy Class"
    },
    {
      value: "business",
      label: "Business Class"
    }
  ]

  const handleSetTotalPaxOptions = () => {
    let _tempOptions = []

    for(let i = 1;i <= 500; i++) {
      _tempOptions.push({
        value: i,
        label: i
      })
    }

    setTotalPaxOptions(_tempOptions)
  }

  const handleSetMonths = () => {
    setMonths([
      { value: "01", label: "Januari" },
      { value: "02", label: "Februari" },
      { value: "03", label: "Maret" },
      { value: "04", label: "April" },
      { value: "05", label: "Mei" },
      { value: "06", label: "Juni" },
      { value: "07", label: "Juli" },
      { value: "08", label: "Agustus" },
      { value: "09", label: "September" },
      { value: "10", label: "Oktober" },
      { value: "11", label: "November" },
      { value: "12", label: "Desember" },
    ]);
  }

  const handleSetYears = () => {
    const yearNow = new Date().getFullYear();

    // Membuat daftar tahun
    const yearList = [];
    for (let i = 0; i <= 20; i++) {
      const yearSelected = yearNow + i;
      yearList.push({ value: String(yearSelected), label: yearSelected });
    }

    yearList.sort((a, b) => a - b);

    setYears(yearList);
  };

  const getTourCountryData = async () => {
      setIsLoadingGetTourCountry(true)
      const response = await getTourCountryByMonthYear(values.month_departure, values.year_departure)
      setIsLoadingGetTourCountry(false)

      if (response.status === 200) {
        setTourCountryOptions(response.data.data);
      } else {
        dispatch(handleError(response.data))
      }
  };

  const handleChangeTourCountry = (countrySelected) => {
    let _tempTourCountryIds = [...values.tour_country_ids]

    if(_tempTourCountryIds.includes(countrySelected.id)) {
      _tempTourCountryIds = _tempTourCountryIds.filter((item) => item !== countrySelected.id)
    } else {
      _tempTourCountryIds.push(countrySelected.id)
    }

    const findIndexTourCountry = _tempTourCountryIds.findIndex((item) => item === countrySelected.id)
    const findLastIndexTourCountry = values.tour_country_ids.findIndex((item) => item === countrySelected.id)

    handleSetTourCountryNights(findIndexTourCountry, 3, findLastIndexTourCountry)
    handleSetHotelTour(findIndexTourCountry, findLastIndexTourCountry)
    handleSetTourCountrySelected(countrySelected)
    setFieldValues('tour_country_ids', _tempTourCountryIds)
  }

  const handleSetTourCountryNights = (index, value, lastIndex = null) => {
    let _tempTourHotelNightsSelected = [...hotelTourNights]
    
    if(index >= 0) {
      _tempTourHotelNightsSelected[index] = value
    } else {
      _tempTourHotelNightsSelected.splice(lastIndex, 1)
    }

    setHotelTourNights(_tempTourHotelNightsSelected)
  }

  const handleSetHotelTour = (index, lastIndex = null) => {
    let _tempTourHotel = [...hotelTourIds]
    
    if(index >= 0) {
      _tempTourHotel[index] = null
    } else {
      _tempTourHotel.splice(lastIndex, 1)
    }

    setHotelTourIds(_tempTourHotel)
  }

  const handleSetTourCountrySelected = (countrySelected) => {
    let _tempTourCountrySelected = [...tourCountrySelected]

    if(_tempTourCountrySelected.some((item) => item.id === countrySelected.id)) {
      _tempTourCountrySelected = _tempTourCountrySelected.filter((item) => item.id !== countrySelected.id)
    } else {
      _tempTourCountrySelected.push(countrySelected)
    }

    setTourCountrySelected(_tempTourCountrySelected)
  }

  useEffect(() => {
    getTourCountryData()
  }, [values.month_departure, values.year_departure])

  useEffect(() => {
    handleSetTotalPaxOptions()
    handleSetMonths()
    handleSetYears()
  }, [])

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">

          <div className="w-full">
            <AntdSelect 
              label={"Pilih Jumlah Pax"}
              placeholder={"Pilih jumlah pax"}
              showSearch={true}
              options={totalPaxOptions}
              value={values.total_pax}
              onChange={(dataSelected) => {
                setFieldValues('total_pax', dataSelected.value)
              }}
              />
          </div>
          <div className="w-full">
            <AntdSelect 
              label={"Kelas Maskapai"}
              placeholder={"Pilih kelas maskapai"}
              options={airplaneClassOptions}
              value={values.airplane_class}
              onChange={(dataSelected) => {
                setFieldValues('airplane_class', dataSelected.value)
              }}
              />
          </div>

        </div>

        <div>
          <div className="font-semibold font-base mb-4">Jumlah Hari</div>
          <div className="w-full">
            <Inputs 
              label="Total Hari"
              type="number"
              placeholder="Masukkan total hari"
              value={values.total_days}
              onChange={(e) => {
                setFieldValues('total_days', e.target.value)
              }}
              />
          </div>
        </div>

        <div>
          <div className="font-semibold font-base mb-4">Waktu Keberangkatan</div>
          <div className="w-full flex items-center gap-4">
            <div className="w-full">
              <AntdSelect 
                label={"Bulan"}
                placeholder={"Pilih bulan"}
                showSearch={true}
                options={months}
                value={values.month_departure}
                onChange={(dataSelected) => {
                  setFieldValues('month_departure', dataSelected.value)
                }}
                />
            </div>
            <div className="w-full">
              <AntdSelect 
                showSearch={true}
                label={"Tahun"}
                placeholder={"Pilih tahun"}
                options={years}
                value={values.year_departure}
                onChange={(dataSelected) => {
                  setFieldValues('year_departure', dataSelected.value)
                }}
                />
            </div>
          </div>
        </div>

        <div>
          <div className="flex item gap-2 font-base items-center">
            <span className="font-semibold">Pilih Negara Tour</span>
            <span className="text-red-3">(opsional)</span>
            {values.tour_country_id > 0 && (
              <>
                <span 
                  onClick={() => {
                    setCountryTourName(null)
                    setFieldValues('tour_country_id', null)
                  }}
                  className="text-red-3 cursor-pointer underline">reset</span>
              </>
            )}
          </div>
          <div className="w-full grid grid-cols-3 gap-4 mt-4">
            {isLoadingGetTourCountry ? (
              <>
                {[...Array(6)].map((_, index) => (
                  <Skeleton.Input key={index} active={true} size="large" block={true} />
                ))}
              </>
            ) : (
              <>
                {tourCountryOptions.length > 0 && (
                  <>
                    {tourCountryOptions.map((item, index) => (
                      <div key={index}>
                        <Choice 
                          key={index}
                          type="radio"
                          value={item.name}
                          customClass={"!items-start"}
                          name="country-tour"
                          id={`country-tour-${index}`}
                          checked={countryTourName === item.name}
                          onChange={(e) => {
                            setCountryTourName(item.name)
                            setFieldValues('tour_country_id', null)
                          }}
                        >
                          <>
                            <div>
                              <div className="font-semibold font-base">{item.name}</div>
                            </div>
                            {item.name == countryTourName && (
                              <>
                                <div className="w-full flex flex-col gap-4 mt-4">
                                  {item.data.map((time, index) => (
                                    <>
                                      <Choice 
                                        key={index}
                                        type="radio"
                                        name={`country-tour-id`}
                                        label={`${time.total_days} Hari ${time.total_nights} Malam`}
                                        customClass={"!border-0 !p-0 !items-start"}
                                        // onClick={() => e.stopPropagation()}
                                        // value={6}
                                        checked={time.id == values.tour_country_id}
                                        id={`country-tour-id-${index}`}
                                        onChange={() => {
                                          setFieldValues('tour_country_id', time.id)
                                        }}
                                      />
                                    </>
                                  ))}
                                </div>
                              </>
                            )}
                          </>
                          {/* <Choice 
                            type="radio"
                            // name={`airplane_domestic_id`}
                            // customClass={"!border-0 !p-0 !items-start"}
                            // // onClick={() => e.stopPropagation()}
                            // // value={6}
                            // checked={values.airplane_domestic_id == item.airplane_id}
                            // id={`airplane_domestic_value-${index}`}
                            // onChange={() => {
                            //   setFieldValues('airplane_domestic_id', item.airplane_id)
                            //   setFieldValues('airplane_domestic_starting_id', null)
                            // }}
                          /> */}
                        </Choice>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}

          </div>
          {(tourCountryOptions.length === 0 && !isLoadingGetTourCountry) && (
            <div className="flex flex-col justify-center items-center w-full h-[220px]">
              <img src={IconJamPasir} alt="jam_pasir" width="150px" />
              <div className="text-[#141414] text-[14px] font-normal text-center font-sans mt-2 whitespace-pre-line w-64">
                Belum ada negara tour yang tersedia dibulan dan tahun yang dipilih
              </div>
            </div>
          )}
        </div>

      </div>
    </>
  )

}

export default Step1