import Switch from "../../../../../components/form/switch/";
import Radio from "@material-ui/core/Radio";
import Badge from "components/badge";
import "../Fee/styles/Fee.scss";

const StartingCard = ({
  data,
  item,
  isSwitch = false,
  choiceMasterStarting = null,
  setChoiceMasterStarting = (data) => {},
  handleChecked = (data) => {},
}) => {
  return (
    <div className="flex flex-row justify-start w-full flex-wrap gap-[16px]">
      <div className="shadow-soft overflow-hidden rounded-lg w-full">
        <div
          className={`p-4 bg-neutral-50 border-b flex justify-between w-full ${
            choiceMasterStarting?.id_starting === item?.id_starting
              ? "!bg-[#FFDCDC]"
              : ""
          } ${item.checked && isSwitch ? "!bg-[#FFDCDC]" : ""}`}
        >
          <Badge
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            customClass={
              "!py-[4px] !px-[16px] !text-[12px] !font-semibold rounded-lg"
            }
            label={`${item?.name}`}
            color={item?.color}
            backgroundColor={item?.color_bg}
          />

          {isSwitch ? (
            <Switch
              checked={item?.checked}
              onChange={(e) => {
                handleChecked(item?.id_starting);
              }}
            />
          ) : (
            <Radio
              checked={
                choiceMasterStarting?.id_starting === item?.id_starting
                  ? true
                  : false
              }
              onChange={(e) => {
                setChoiceMasterStarting(item);
              }}
              color="red"
              style={{
                color: "red",
              }}
            />
          )}
        </div>
        <div className="flex gap-8 rounded-br-lg rounded-bl-lg p-[16px]">
          <div>
            <div className="text-[#828282] text-[14px] font-normal">
              Berangkat
            </div>
            <div className="text-[14px] font-semibold">
              {item?._bandara_departure?.city}
            </div>
          </div>
          <div>
            <div className="text-[#828282] text-[14px] font-normal">Pulang</div>
            <div className="text-[14px] font-semibold">
              {item?._bandara_return?.city}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartingCard;
