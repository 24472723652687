import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import {
  exportLaporanManasikAbsensiService,
  exportLaporanManasikLokasiService,
  getLaporanManasikAbsensiService,
  getLaporanManasikLokasiService,
} from "utils/api/paket/laporan";

export const getLaporanManasikLokasi = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING", payload: true });
    const res = await getLaporanManasikLokasiService(payload);
    if (res.status === 200) {
      dispatch({ type: "SET_LOADING", payload: false });
      dispatch({
        type: "SET_DATA_LAPORAN_MANASIK_LOKASI",
        payload: res.data.data,
      });
    } else {
      dispatch({ type: "SET_LOADING", payload: false });
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch({ type: "SET_LOADING", payload: false });
  }
};

export const exportLaporanManasikLokasi =
  (id, callbackAction) => async (dispatch) => {
    try {
      dispatch({ type: "SET_LOADING_EXPORT", payload: true });
      const res = await exportLaporanManasikLokasiService(id);
      if (res.status === 200) {
        dispatch({ type: "SET_LOADING_EXPORT", payload: false });
        callbackAction(res.data.data);
        dispatch({
          type: "SET_DATA_EXPORT_MANASIK_LOKASI",
          payload: res.data.data,
        });
      } else {
        dispatch({ type: "SET_LOADING_EXPORT", payload: false });
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch({ type: "SET_LOADING_EXPORT", payload: false });
    }
  };

export const getLaporanManasikAbsensi = (id, payload, actionSuccess) => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING", payload: true });
    const res = await getLaporanManasikAbsensiService(id, payload);
    if (res.status === 200) {
      dispatch({ type: "SET_LOADING", payload: false });
      dispatch({
        type: "SET_DATA_LAPORAN_MANASIK_ABSENSI",
        payload: res.data.data ,
      });
      actionSuccess(res.data)
    } else {
      dispatch({ type: "SET_LOADING", payload: false });
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch({ type: "SET_LOADING", payload: false });
  }
};

export const exportLaporanManasikAbsensi =
  (id, callbackAction) => async (dispatch) => {
    try {
      dispatch({ type: "SET_LOADING_EXPORT", payload: true });
      const res = await exportLaporanManasikAbsensiService(id);
      if (res.status === 200) {
        dispatch({ type: "SET_LOADING_EXPORT", payload: false });
        callbackAction(res.data.data);
        dispatch({
          type: "SET_DATA_EXPORT_MANASIK_LOKASI",
          payload: res.data.data,
        });
      } else {
        dispatch({ type: "SET_LOADING_EXPORT", payload: false });
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch({ type: "SET_LOADING_EXPORT", payload: false });
    }
  };
