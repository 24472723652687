import React, { useEffect, useState } from "react";
import MoslemeTable from "../../../../../components/Table";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import AdditionalAction from "stores/actions/paket/additional";
import { useDispatch, useSelector } from "react-redux";
import AlertPopup from "../../../../../components/popup/alert";
import { useParams } from "react-router-dom";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";

function TableAdditional({
  isUpdate,
  setIsUpdate,
  setType,
  setDetailEdit,
  setIsCreate,
}) {
  // Pagination state
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [detail, setDetail] = useState({
    id: "",
    name: "",
  });
  const dispatch = useDispatch();
  const params = useParams();
  const store = useSelector(({ additional }) => additional);
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: store.total,
    current: store.page_number,
    pageSize: store.per_page,
    defaultPageSize: store.per_page,
    pageSizeOptions: ["10", "20", 50, 100],
  };

  // useEffect(() => {
  //   if (isUpdate) {
  //     dispatch(AdditionalAction.fetchData(params.id));
  //   }
  //   setIsUpdate(false);
  // }, [isUpdate]);

  // useEffect(() => {
  //   console.log("Hallo");
  //   dispatch(AdditionalAction.fetchData(params.id));
  // }, [
  //   store.keyword,
  //   store.per_page,
  //   store.page_number,
  //   store.id_paket,
  //   params.id,
  // ]);

  const sourceData = store.data.map((item, i) => {
    return {
      ...item,
      key: `${i + 1}`,
      nama: item.name,
      kategori: item.kategori || "-",
      jumlah_jamaah: item.jumlah_jamaah || "-",
      harga: [item.price, item.id],
      desc: [item.description, i],
    };
  });

  const handleEdit = (data) => {
    setDetailEdit(data);
    setType("edit");
    setIsCreate(true);
  };

  const columns = [
    {
      title: "Nama",
      dataIndex: "nama",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Kategori",
      dataIndex: "kategori",
      render: (text, data) => {
        return (
          <div className="flex flex-row items-center justify-start w-[100px] px-[8px] py-[4px] rounded-lg border-[0.5px] border-[#F2F2F2] ">
            {data?.is_wajib === "1" ? "Wajib" : "Tidak Wajib"}
          </div>
        );
      },
    },
    {
      title: "Jumlah Jamaah",
      dataIndex: "jumlah_jamaah",
      render: (text, data) => {
        return (
          <div className="flex flex-row justify-start text-[12px] text-[#141414] font-normal">
            {data?.total_order} jamaah
          </div>
        );
      },
    },
    {
      title: "Harga",
      dataIndex: "harga",
      className: "whitespace-nowrap",
      render: (s, a) => {
        return (
          <div>
            <div className="w-full overflow-hidden">
              <p className="whitespace-nowrap text-ellipsis">
                Rp {s[0].toLocaleString("id-ID")}
              </p>
            </div>
            <div className="action">
              <div className="action-content">
                <div className="action-content-item">
                  <Trash
                    onClick={() => {
                      setIsShowDelete(true);
                      setDetail({
                        id: s[1],
                        name: a.nama,
                      });
                    }}
                    size="15"
                    className="cursor-pointer"
                    color="#141414"
                  />
                </div>
                <div className="action-content-item">
                  <Edit
                    onClick={() => handleEdit(a)}
                    size="15"
                    color="#141414"
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <AlertPopup
        subtitle={`Apakah kamu yakin ingin menghapus “${detail?.name}”?`}
        open={isShowDelete}
        handleClose={() => setIsShowDelete(false)}
        handleContinue={() => {
          setIsLoadingAction(true);
          dispatch(
            AdditionalAction.deleteData(
              detail?.id,
              (message) => {
                setIsLoadingAction(false);

                setIsShowDelete(false);
                setIsUpdate(true);

                dispatch(handleSuccess(null, { message: "Berhasil menghapus additional", code: 200 }));
              },
              (error) => {
                setIsLoadingAction(false);
                setIsShowDelete(false);

                dispatch(handleErrorBE(error));
              }
            )
          );
        }}
        loading={isLoadingAction}
      />
      <MoslemeTable
        withSelection={true}
        selectionType={false}
        rowKey={(record) => record.id}
        columns={columns}
        data={sourceData}
        pagination={pagination}
        // onPageChange={(page) => dispatch(AdditionalAction.setPage(page))}
        // onSizeChange={(currentpage, pageSize) => {
        //   dispatch(AdditionalAction.setPage(currentpage));
        //   dispatch(AdditionalAction.setSize(pageSize));
        // }}
      />
    </div>
  );
}

export default TableAdditional;
