import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Spinner = ({ customClass, width, height }) => {
  return <SpinnerStyle className={`${customClass}`}>
    <svg className="spinner" viewBox="0 0 50 50" width={width} height={height}>
      <circle className="path2" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg>
  </SpinnerStyle>;
};

const SpinnerStyle = styled.div`
  /* Spinner */


  .spinner {
    animation: rotate 2s linear infinite;
    
    & .path {
      stroke: #e82320;
      background: #fff;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
    & .path2 {
      stroke: #fff;
      background: #fff;
      stroke-linecap: round;
    }
    
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

Spinner.propTypes = {
  customClass: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

Spinner.defaultProps = {
  containerClass: "",
  width: "24px",
  height: "24px"
}

export default React.memo(Spinner);
