import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Badge from "../../../../../../../../components/badge";
import MoslemeTable from "../../../../../../../../components/Table";

import { getDetailListJamaah } from "utils/api/paket/bills";
import { handleError } from "stores/actions/errorGeneral";
import Avatar from "components/avatar";
import { IDRFormater, toCapital } from "utils/helpers";

const TabTagihan = ({ 
  orderID,
  setTotalJamaah 
}) => {
  const params = useParams()
  const dispatch = useDispatch()
  const [jamaahOrder, setJamaahOrder] = useState()

  useEffect(() => {
    handleGetJamaah() 
  }, [])

  const handleGetJamaah = async () => {
    const payload = {
      order_id: orderID
    }
    const response = await getDetailListJamaah(params.id, payload)
    const { status, data } = response
    if(status === 200) {
      setJamaahOrder(data.data)
      setTotalJamaah(data.data.length)
    } else {
      dispatch(handleError(data))
    }
  }

  const columnsAdditional = [
    {
      title: "Nama",
      dataIndex: "name",
    },
    {
      title: "Jumlah",
      dataIndex: "quantity",
    },
    {
      title: "Harga",
      dataIndex: "price",
      render: (record) => (
        <>
          {IDRFormater(record)}
        </>
      )
    },
    {
      title: "Catatan",
      dataIndex: "note",
    },
  ]

  const handleRoomType = (roomType) => {
    roomType = roomType.replace('_bussiness', ' (Busines Class)')
    roomType = roomType.replace('tripple', 'triple')

    return roomType.toUpperCase()
  }

  const CardTagihanJamaah = (jamaah) => {
    jamaah = jamaah?.jamaah
    return (
      <div className="bg-white soft-shadow rounded-2xl">
        <div className="flex flex-row items-center p-4 border-b-[0.5px] border-b-[#E0E0E0]">
          <Avatar 
              value={jamaah}
              containerClass="items-center justify-center  w-[32px] h-[32px] rounded-[100%]  bg-slate-100 border-white border"
              customClass="object-cover w-[32px] h-[32px] rounded-[100%]"
              />
          <div className="text-[16px] font-semibold ml-4">
            {jamaah?.title ? `${toCapital(jamaah?.title)}.` : ''} {jamaah?.name}
          </div>
        </div>
        <div>
          <div className="flex flex-row justify-between p-4">
            <div>
              <div className="text-[16px]">Tipe Kamar</div>
              <div className="text-[20px] font-semibold txt-green">
                {handleRoomType(jamaah?.room_type)}
              </div>
            </div>
            <div>
              <div className="text-[16px]">Harga Kamar</div>
              <div className="text-[20px] font-semibold txt-green">
                {IDRFormater(jamaah?.room_price)}
              </div>
            </div>
            <div>
              <Badge label={jamaah?.name_package_starting}type="old brown" />
            </div>
          </div>

          <div className="px-4 mt-1 mb-4">
            <div className="text-[16px]">Additional</div>
            <MoslemeTable
              columns={columnsAdditional}
              data={jamaah?.additional_order}
              customClass="tbl-jamaah mt-2"
              withSelection={false}
              selectionType=""
              withPagination={false}
              customPaginationClass="pr-4"
            />
          </div>
        </div>
        <div className="border-t-[0.5px] border-[#E0E0E0] p-4">
          <div className="orange-bg rounded-xl px-6 py-3">
            <div className="text-white text-[16px] font-semibold">
              Total Tagihan:
            </div>
            <div className="text-[20px] font-semibold txt-yellow-soft mt-2">
              {IDRFormater(jamaah?.total_price)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <>
        <div className="grid grid-cols-2 gap-2 ">
          {jamaahOrder?.map((jamaah) => (
            <>
              <CardTagihanJamaah jamaah={jamaah} />
            </>
          ))}
        </div>
      </>
    </>
  );
};

export default React.memo(TabTagihan);
