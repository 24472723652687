// tagihan Actions
// --------------------------------------------------------

import {
  getAllPembayaran,
  getAllUmroh,
  getDetailUmroh,
  addPembayaran,
  getRekening,
} from "utils/api/tagihan";
import { handleError, handleErrorBE, handleSuccess } from "../errorGeneral";
import { clearData, setDataUpload, setDataUploadMulti } from "../uploadFile";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "tagihan/SET_LOADING";
export const CLEAR_ERROR = "tagihan/CLEAR_ERROR";
export const SET_ERROR = "tagihan/SET_ERROR";
export const INIT_DATA = "tagihan/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "tagihan/SET_DOUBLE_SUBMIT";

// type status
export const SET_STATUSTAGIHAN = "tagihan/SET_STATUSTAGIHAN";

// detail data
export const SET_DETAILDATA = "tagihan/SET_DETAILDATA";

// umroh
export const SET_DATAUMROH = "tagihan/SET_DATA_UMROH";

// pembayaran
export const SET_DATAPEMBAYARAN = "tagihan/SET_DATA_PEMBAYARAN";

// rekening
export const SET_REKENING = "tagihan/SET_REKENING";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setStatusData = (payload) => ({
  type: SET_STATUSTAGIHAN,
  payload,
});

export const setDetailData = (payload) => ({
  type: SET_DETAILDATA,
  payload,
});

// umroh
export const setDataUmroh = (payload) => ({
  type: SET_DATAUMROH,
  payload,
});

// pembayaran
export const setDataPembayaran = (payload) => ({
  type: SET_DATAPEMBAYARAN,
  payload,
});

// rekening
export const setDataRekening = (payload) => ({
  type: SET_REKENING,
  payload,
});

// cek status
export const statusChecking = (payload) => async (dispatch) => {
  dispatch(setStatusData(payload));
};

// action umroh
export const getAllDataUmroh = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));
    dispatch(setLoading(true));
    const response = await getAllUmroh(payload);
    dispatch(setLoading(false));

    if (response.status === 200) {
      dispatch(setDataUmroh(response.data));
      dispatch(statusChecking("idle"));
    } else {
      dispatch(handleErrorBE(response));
      dispatch(statusChecking("idle"));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDetailDataUmroh = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getDetailUmroh(payload);
    dispatch(setLoading(false));

    if (response.status === 200) {
      dispatch(setDetailData(response.data));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

// pembayaran
export const getAllDataPembayaran = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));
    dispatch(setLoading(true));
    const response = await getAllPembayaran(payload);
    dispatch(setLoading(false));

    if (response.status === 200) {
      dispatch(setDataPembayaran(response.data));
      dispatch(statusChecking("idle"));
    } else {
      dispatch(handleErrorBE(response));
      dispatch(statusChecking("idle"));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const addDataPembayaran = (payload, id) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await addPembayaran(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(handleSuccess(response.data));
      dispatch(clearData());
      dispatch(
        getAllDataPembayaran({
          page_number: 1,
          per_page: 10,
          sort_column: "date",
          sort_order: "ASC",
          id_order: id,
        })
      );
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDataRekening = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getRekening(payload);
    dispatch(setLoading(false));

    if (response.status === 200) {
      dispatch(setDataRekening(response.data.data));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};
