import React, { useState, useEffect } from "react";
import MoslemeTable from "components/Table";

import Badge from "components/badge";
import PerlengkapanDokumen from "./PerlengkapanDokumen";
import { useDispatch, useSelector } from "react-redux";
import {
  handleGetAllDokument,
  handleUploadAllDokument,
  handleUploadPassportDokument,
} from "stores/actions/paket/dokumen";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import { useParams } from "react-router-dom";
import Avatar from "components/avatar";
import { resetDataUpload } from "stores/actions/uploadFile";
import { toCapital } from "utils/helpers";
import Tab from "components/tab";
import Pembimbing from "./Pembimbing";
import Inputs from "components/form/inputs";
import IconSearch from "assets/icons/search-normal.svg";
import useDebounce from "utils/helpers/useDebounce";

const Dokumen = () => {
  const dispatch = useDispatch();

  let { id } = useParams();
  const auth = useSelector((state) => state.auth);

  const [isLoadingAction, setLoadingAction] = useState(false);
  const [dataDocumentJamaah, setDataDocumentJamaah] = useState({});
  const [currentpage, setCurrentpage] = useState(0);
  const [size, setSize] = useState(10);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [activeTab, setActiveTab] = useState("1")

  const [keyword, setKeyword] = useState("");
  const searchDebounce = useDebounce(keyword, 1000); 
  

  useEffect(() => {
    handleGetDocument();
  }, [currentpage, size]);

  useEffect(() => {
    setCurrentpage(1);
    handleGetDocument();
  }, [searchDebounce]);

  const handleGetDocument = () => {
    setLoadingAction(true);
    dispatch(
      handleGetAllDokument(
        id,
        {
          data: "document",
        },
        {
          keyword: keyword,
          page_number: currentpage,
          per_page: size,
          sort_column: "id",
          sort_order: "ASC",
        },
        (message) => {
          setLoadingAction(false);
          setDataDocumentJamaah(message?.data);
        },
        (error) => {
          setLoadingAction(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleUploadDocument = (data, handleClosepoup = (data) => {}) => {
    setIsLoadingSave(true);
    dispatch(
      handleUploadAllDokument(
        id,
        data,
        (message) => {
          setIsLoadingSave(false);
          handleClosepoup();

          setLoadingAction(true);
          dispatch(resetDataUpload());
          dispatch(
            handleGetAllDokument(
              id,
              {
                data: "document",
              },
              {
                keyword: keyword,
                page_number: currentpage,
                per_page: size,
                sort_column: "id",
                sort_order: "ASC",
              },
              (message) => {
                setLoadingAction(false);
                setDataDocumentJamaah(message?.data);
                dispatch(handleSuccess(message));
              },
              (error) => {
                setLoadingAction(false);
                dispatch(handleErrorBE(error));
              }
            )
          );
        },
        (error) => {
          setIsLoadingSave(false);

          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleUploadDocumentPassport = (
    data,
    handleClosepoup = (data) => {}
  ) => {
    setIsLoadingSave(true);
    dispatch(
      handleUploadPassportDokument(
        id,
        data,
        (message) => {
          setIsLoadingSave(false);
          handleClosepoup();

          setLoadingAction(true);
          dispatch(resetDataUpload());
          dispatch(
            handleGetAllDokument(
              id,
              {
                data: "document",
              },
              {
                keyword: keyword,
                page_number: currentpage,
                per_page: size,
                sort_column: "id",
                sort_order: "ASC",
              },
              (message) => {
                setLoadingAction(false);
                setDataDocumentJamaah(message?.data);
                dispatch(handleSuccess(message));
              },
              (error) => {
                setLoadingAction(false);
                dispatch(handleErrorBE(error));
              }
            )
          );
        },
        (error) => {
          setIsLoadingSave(false);

          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const columns = [
    {
      title: "Foto",
      dataIndex: "foto",
      width: "32px",
      render: (image, data) => {
        return <Avatar value={data?.jamaah} />;
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      render: (text, data) => (
        <span>
          {toCapital(data?.jamaah?.title)}. {data?.jamaah?.name.toUpperCase()}
        </span>
      ),
    },
    {
      title: "Jenis Kelamin",
      dataIndex: "jenisKelamin",
      render: (text, data) => <span>{data?.jamaah?.gender}</span>,
    },
    {
      title: "Starting",
      dataIndex: "starting",
      render: (val, data) => (
        <Badge
          label={data?.starting?.name}
          backgroundColor={data?.starting?.bg_color}
          color={data?.starting?.text_color}
        />
      ),
    },
    {
      title: "Cabang",
      dataIndex: "cabang",
      render: (val, data) => (
        <Badge
          label={data?.branch?.name?.toUpperCase()}
          backgroundColor={data?.branch?.bg_color}
          color={data?.branch?.text_color}
        />
      ),
    },
    {
      title: "Document",
      dataIndex: "document",
      className: "whitespace-nowrap relative",
      render: (value, data) => (
        <PerlengkapanDokumen
          value={data?.document}
          jamaahOrderID={data?.id_kamar_mapping}
          jamaahName={data?.jamaah?.name}
          orderNumber={data?.order_number}
          handleUploadDocument={handleUploadDocument}
          onUpdate={() => {
            handleGetDocument()
          }}
          handleUploadDocumentPassport={handleUploadDocumentPassport}
          isLoadingSave={isLoadingSave}
        />
      ),
    },
  ];

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: dataDocumentJamaah?.total_data,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const renderTab = () => {
    let tabs = [
      {
        label: (
          <>
            <div className="px-5 py-3">
              Jamaah
            </div>
          </>
        ),
        key: 1,
        children: (
          <>
            <MoslemeTable
              columns={columns}
              loading={isLoadingAction}
              data={dataDocumentJamaah?.data}
              withSelection={true}
              rowKey={(record) => record.id_kamar_mapping}
              selectionType="checkbox"
              withPagination={true}
              pagination={pagination}
              customClass="overflow-auto z-0 !min-h-[600px]"
              onPageChange={(page) => setCurrentpage(page)}
              onSizeChange={(currentpage, pageSize) =>
                onSizeChange(currentpage, pageSize)
              }
            />
          </>
        )
      }
    ]

    if(auth.user.access_data === 'all_branches') {
      tabs.push({
        label: (
          <>
            <div className="px-5 py-3">
              Pembimbing
            </div>
          </>
        ),
        key: 2,
        children: (
          <>
            <Pembimbing />
          </>
        )
      })
    }
    return tabs
  }

  return (
    <>
      <div className="relative">
        <Tab 
          items={renderTab()}
          onChange={(e) => setActiveTab(e)}
          defaultActiveKey="1"
          />
        
          <div className="flex gap-4 items-center absolute top-2 right-4">
            {activeTab == '1' && (
              <Inputs
                id="test"
                placeholder="Search"
                customClass="!w-[240px]"
                iconPosition="left"
                icon={IconSearch}
                onChange={e => setKeyword(e.target.value)}
              />
            )}
          </div>
      </div>
    </>
  );
};

export default Dokumen;
