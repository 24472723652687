import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import Buttons from "../../../../../components/buttons";
import * as Yup from "yup";
import Inputs from "../../../../../components/form/inputs";
import EditorToolbar, {
  modules,
  formats,
} from "../../../../../components/richTextEditor/EditorToolbar";
import { useDispatch, useSelector } from "react-redux";
import { saveDataFAQ, saveEditDataFAQ } from "stores/actions/faq";
import { useFormik } from "formik";
import { convertBase64 } from "utils/helpers/convertBase64";

const AddFAQ = ({ setOpenPopupAddFAQ, editData, category }) => {
  const dispatch = useDispatch();
  // const { isLoading } = useSelector((state) => state.faq);
  const [isLoading, setIsLoading] = useState(false);

  const saveData = async () => {
    const payload = {
      pertanyaan: formikValidation.values.pertanyaan,
      jawaban: formikValidation.values.jawaban,
      category: formikValidation.values.category,
    };
    setIsLoading(true);
    if (editData?.id_faq) {
      await dispatch(saveDataFAQ({
        ...payload,
        id_faq: editData.id_faq,
      }));
    } else {
      await dispatch(saveDataFAQ(payload));
    }
    setOpenPopupAddFAQ(false);
    setIsLoading(false);
  };

  const formikValidation = useFormik({
    initialValues: {
      pertanyaan: "",
      jawaban: "",
      category: category
    },
    validationSchema: Yup.object().shape({
      pertanyaan: Yup.string().required("Pertanyaan Wajib diisi"),
      jawaban: Yup.string().required("Jawaban Wajib Diisi"),
      category: Yup.string().required("Kategori Wajib Diisi"),
    }),
    onSubmit: saveData,
  });

  useEffect(() => {
    if (editData) {
      formikValidation.setFieldValue("pertanyaan", editData?.pertanyaan);
      formikValidation.setFieldValue(
        "jawaban",
        editData?.jawaban
      );
    }
  }, [editData]);

  return (
    <div>
      <div className="border-[1px] border-[#4048521a] rounded-[12px]">
        <div className="p-4">
          <Inputs
            label="Pertanyaan"
            placeholder="Masukkan pertanyaan"
            id="pertanyaan"
            name="pertanyaan"
            value={formikValidation.values.pertanyaan}
            error={
              formikValidation.touched.pertanyaan &&
              formikValidation.errors.pertanyaan &&
              formikValidation.errors.pertanyaan
            }
            onChange={formikValidation.handleChange}
          />
          <div className="text-[#4F4F4F] font-semibold mt-4 mb-2">Jawaban</div>
          <div className="text-editor">
            <EditorToolbar />
            <ReactQuill
              theme="snow"
              id="jawaban"
              name="jawaban"
              value={formikValidation.values.jawaban}
              onChange={(value) => {
                if (value === "<p><br></p>") {
                  formikValidation.setFieldValue("jawaban", "");
                } else {
                  formikValidation.setFieldValue("jawaban", value);
                }
              }}
              error={
                formikValidation.touched.jawaban &&
                formikValidation.errors.jawaban &&
                formikValidation.errors.jawaban
              }
              modules={modules}
              formats={formats}
              className="bg-[#EDF2F4] w-full h-[300px]"
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end p-4">
          <Buttons
            text={"Simpan"}
            customClass="btn-primary btn-medium !w-fit"
            disabled={!(formikValidation.isValid && formikValidation.dirty)}
            loading={isLoading}
            onClick={() => formikValidation.handleSubmit()}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddFAQ);
