// perlengkapan Actions
// --------------------------------------------------------

import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import {
  getAllPerlengkapanService,
  getListCabangService,
  getListPaketPerlengkapanV2,
  getListStartingPaketService,
  savePerlengkapanService,
  updatedPerlengkapan,
} from "utils/api/paket/perlengkapan";
import { getListStartingItenaryServiceV2 } from "../itinenary";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "perlengkapan/SET_LOADING";
export const CLEAR_ERROR = "perlengkapan/CLEAR_ERROR";
export const SET_ERROR = "perlengkapan/SET_ERROR";
export const INIT_DATA = "perlengkapan/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "perlengkapan/SET_DOUBLE_SUBMIT";
export const SET_DATA = "perlengkapan/SET_DATA";
export const SET_DATA_PERLENGKAPAN = "perlengkapan/SET_DATA_PERLENGKAPAN";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setDataPerlengkapan = (payload) => ({
  type: SET_DATA,
  payload,
});

export const setDataPerlengkapanV2 = (payload) => ({
  type: SET_DATA_PERLENGKAPAN,
  payload,
});

export const handleGetAllPerlengkapan =
  (payload, actionSuccess = (message) => {}, actionError = (error) => {}) =>
  async (dispatch) => {
    try {
      const response = await getAllPerlengkapanService(payload);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const saveDataPerlengkapan =
  (payload, actionSuccess = (message) => {}, actionError = (error) => {}) =>
  async (dispatch) => {
    try {
      const res = await savePerlengkapanService(payload);

      if (res.status === 200) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getListStartingPaketPerlengkapan =
  (id, actionSuccess = (message) => {}, actionError = (error) => {}) =>
  async (dispatch) => {
    try {
      const response = await getListStartingPaketService(id);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getListCabangPaketPerlengkapan =
  (payload, actionSuccess = (message) => {}, actionError = (error) => {}) =>
  async (dispatch) => {
    try {
      const response = await getListCabangService(payload);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleGetPerlengkapanJamaah =
  (
    id,
    payload,
    body,
    running = true,
    actionSuccess,
    actionError = (error) => {}
  ) =>
  async (dispatch) => {
    try {
      running && dispatch(setLoading(true));
      const response = await getListPaketPerlengkapanV2(id, payload, body);
      running && dispatch(setLoading(false));

      if (response.status === 200) {
        if (running) {
          dispatch(setDataPerlengkapanV2(response?.data));
        } else {
          actionSuccess(response);
        }
      } else {
        if (running) {
          dispatch(handleErrorBE(response));
        } else {
          actionError(response);
        }
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleUpdatedPerlengkapan =
  (id, payload, actionSuccess = (message) => {}, actionError = (error) => {}) =>
  async (dispatch) => {
    try {
      const response = await updatedPerlengkapan(id, payload);

      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
