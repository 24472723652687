// guide Actions
// --------------------------------------------------------

import {
  handleError,
  handleErrorBE,
  handleErrorCustom,
  handleSuccess,
} from "stores/actions/errorGeneral";
import { setValidation, set_after_validation } from "stores/actions/general";
import { guideApi } from "utils/api/apps/guide";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "guide/SET_LOADING";
export const CLEAR_ERROR = "guide/CLEAR_ERROR";
export const SET_ERROR = "guide/SET_ERROR";
export const INIT_DATA = "guide/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "guide/SET_DOUBLE_SUBMIT";
export const SET_ALL_GUIDE = "guide/SET_ALL_GUIDE";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setAllData = (payload) => ({
  type: SET_ALL_GUIDE,
  payload,
});

const getAppGuide = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await guideApi.list(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setAllData(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

const deleteIdAppGuide = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await guideApi.delete(id);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(handleSuccess(null, { message: "Berhasil menghapus pembimbing/guide", code: 200 }));
    } else {
      dispatch(handleErrorCustom("Gagal menghapus pembimbing/guide"));
    }
    return res.status
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

const createOrUpdateAppGuide = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await guideApi.post(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      set_after_validation(res.data);
      if(payload.id) {
        dispatch(handleSuccess(null, { message: "Berhasil mengupdate pembimbing/guide", code: 200 }));
      } else {
        dispatch(handleSuccess(null, { message: "Berhasil menambahkan pembimbing/guide", code: 200 }));
      }
    } else {
      // dispatch(handleErrorBE(res));
      if(payload.id) {
        dispatch(handleErrorCustom("Gagal mengupdate pembimbing/guide"));
      } else {
        dispatch(handleErrorCustom("Gagal menambahkan pembimbing/guide"));
      }
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

const duplicateAppGuide = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await guideApi.duplicate(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(set_after_validation(res.data));
      dispatch(handleSuccess(null, { message: "Berhasil menduplikat pembimbing/guide", code: 200 }));
    } else if (res.status === 400) {
      // dispatch(handleSuccess(res.data));
      dispatch(setValidation(res.data));
      dispatch(handleError(res.data));
    } else {
      dispatch(handleError(res.data));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

const GuideAction = {
  list: getAppGuide,
  delete: deleteIdAppGuide,
  createOrUpdate: createOrUpdateAppGuide,
  duplicate: duplicateAppGuide,
};

export default GuideAction;
