import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTransportasiPaket } from "stores/actions/paket/transportasi";
import Buttons from "components/buttons";
import { useParams } from "react-router-dom";
import Selects from "components/form/selects";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";

const Form = ({ allPaketTransportasi, setIsOpen }) => {
  let { id } = useParams();
  const { dataTransport } = useSelector((state) => state.datamaster);
  const dispatch = useDispatch();

  const [listDataTransport, setListDataTransport] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const [selectTransport, setSelectTransport] = useState([
    {
      label: "",
      value: "",
    },
  ]);

  useEffect(() => {
    if (dataTransport) {
      let result_map = dataTransport?.data?.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id_transportation,
        };
      });
      setListDataTransport(result_map);
    }
  }, [dataTransport]);

  const handleSaveTransportasiPaket = () => {
    setIsloading(true);

    const payload = {
      id_paket: id,
      id_transportation: selectTransport[0]?.value,
    };

    dispatch(
      addTransportasiPaket(
        payload,
        (message) => {
          setIsOpen(false);
          setIsloading(false);
          dispatch(handleSuccess(null, {message: "Berhasil menambahkan transportasi", code: 200}));
          allPaketTransportasi();
          setSelectTransport([
            {
              label: "",
              value: "",
            },
          ]);
        },
        (error) => {
          setIsOpen(false);
          setIsloading(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  return (
    <div className="border-[1px] border-[#E0E0E0] rounded-2xl">
      <div className="p-4">
        <Selects
          value={selectTransport}
          label="Pilih Transportasi"
          placeholder="Pilih Transportasi"
          options={listDataTransport}
          searchable
          onChange={(e) => {
            setSelectTransport(e);
          }}
          itemRenderer={({ state, item, methods }) => {
            return (
              <div
                className={`react-dropdown-select-item flex z-50 h-auto flex-row items-center gap-4 !p-4  ${
                  state.values.length !== 0 &&
                  state.values[0].value === item.value &&
                  "custom-item-selected"
                }`}
                onClick={() => {
                  methods.addItem(item);
                  setSelectTransport([item]);
                }}
              >
                <div className="flex flex-row justify-start items-center gap-[8px] w-full">
                  <div className="flex flex-row justify-start items-center gap-4">
                    <img
                      src={item?.logo}
                      alt="maskapai"
                      className="rounded-full w-8 h-8 object-cover"
                    />
                    <div className="flex flex-col justify-center items-start w-[160px] ml-[8px] mr-[8px]">
                      <div className="text-[#141414] text-[14px] font-medium">
                        {item?.name}
                      </div>
                      <div className="text-[#4F4F4F] text-[14px] font-normal">
                        {item?.category}
                      </div>
                    </div>
                    <div className="text-[12px] text-[#141414] font-normal">
                      {item?.description !== null &&
                      item?.description?.length > 150
                        ? `${item?.description?.substr(0, 150)}...`
                        : item?.description}
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />
        <div className="grid grid-cols-2 gap-4 mt-8">
          <Buttons
            text="Batal"
            customClass="btn-outline btn-medium !w-full"
            onClick={() => setIsOpen(false)}
          />
          <Buttons
            loading={isLoading}
            text="Simpan"
            customClass="btn-primary btn-medium !w-full"
            disabled={selectTransport[0]?.value === "" ? true : false}
            onClick={handleSaveTransportasiPaket}
          />
        </div>
      </div>
    </div>
  );
};

export default Form;
