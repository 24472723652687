import ReactQuill from "react-quill";
import EditorToolbar, {
  formats,
  modules,
} from "components/richTextEditor/EditorToolbar";

const AboutAppForm = ({
  setFielValue,
  values
}) => {
    return (
      <>
        <div className="mt-4">
          <EditorToolbar />
          <ReactQuill
            value={values}
            onChange={(val) => {
              setFielValue(val);
            }}
            modules={modules}
            formats={formats}
            className="bg-[#EDF2F4] w-full h-[68vh]"
          />
        </div>
      </>
    )
}

export default AboutAppForm