import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MoslemeTable from "components/Table"
import { getLaporanManasikAbsensi } from "stores/actions/paket/laporan";
import Avatar from "components/avatar";
import { toCapital } from "utils/helpers";
import RoomBadge from "../../Roomlist/components/RoomBadge";
import Badge from "components/badge";
import DropdownCustom from "components/DropdownCustom";

import TaskIcon from "assets/icons/task-table.svg";
import IconChecklist from "assets/icons/Checklist-Round.svg";
import IconCloseRed from "assets/icons/icon-close-maroon.svg";

import Spinner from "components/spinner";

import { onHandleInputAbsensiAPI } from "utils/api/paket/laporan";
import { handleError } from "stores/actions/errorGeneral";
import { Edit } from "iconsax-react";
import useDebounce from "utils/helpers/useDebounce";
import Popup from "components/popup";
import ChangeLocationForm from "./change-location-form";

const Attendance = ({
  packageId,
  keyword,
  dataFilter
}) => {

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const [present, setPresent] = useState(false);
  const [manasikSelected, setManasikSelected] = useState(null);

  const dispatch = useDispatch();
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  const [activeFilter, setActiveFilter] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const searchDebounce = useDebounce(keyword, 1000); 

  const [manasikJamaahList, setManasikJamaahList] = useState([]);

  const [openChangeLocation, setOpenChangeLocation] = useState(false);

  const columns = [
    {
      title: "Foto",
      dataIndex: "jamaah_photo",
      render: (jamaah_photo, data) => {
        return <Avatar value={data} />;
      },
    },
    {
      title: "Nama",
      dataIndex: "jamaah_name",
      render: (name, data) => {
        return <div>{`${toCapital(data?.jamaah_title)}. ${name.toUpperCase()}`}</div>;
      },
    },
    {
      title: "Jenis Kelamin",
      dataIndex: "jamaah_gender",
    },
    {
      title: "Nomor HP",
      dataIndex: "jamaah_phone",
    },
    {
      title: "Starting",
      dataIndex: "starting_name",
      render: (starting, data) => {
        return (
          <Badge
            label={starting}
            backgroundColor={data?.starting_bg_color}
            color={data?.starting_text_color}
          />
        );
      },
    },
    {
      title: "Cabang",
      dataIndex: "branch_name",
      render: (branch, data) => (
        <div>
          <Badge
            label={branch.toUpperCase()}
            backgroundColor={data?.branch_bg_color}
            color={data?.branch_text_color}
          />
        </div>
      ),
    },
    {
      title: "Tempat Manasik ",
      dataIndex: "manasik_branch",
      render: (data, manasik) => (
        <>
          <div className="flex gap-2 items-center">
            <Badge
              label={data?.name ?? '-'}
              backgroundColor={data?.color}
              color={data?.color_text}
            />
            {auth?.user?.access_data == 'all_branches' && (permissions?.includes('update_manasik')) && (
              <>
                <Edit 
                  size="16"
                  color="#333"
                  className="cursor-pointer"
                  onClick={() => handleEditManasik(manasik)}
                  />
              </>
            )}
            {(auth?.user?.access_data == 'self_branch') && ((auth?.user?.cabang_id == manasik.branch_id) && (permissions?.includes('update_manasik'))) && (
              <>
                <Edit 
                  size="16"
                  color="#333"
                  className="cursor-pointer"
                  onClick={() => handleEditManasik(manasik)}
                  />
              </>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Absensi Manasik",
      dataIndex: "manasik",
      render: (manasik, data) => (
        <div>
          {permissions?.includes('update_manasik') ? (
            <>
              <DropdownCustom
                placement="centerLeft"
                dropdownRender={() => (
                  <>
                    <div 
                      className={`w-[295px] shadow-custom-1 rounded-2xl overflow-hidden`}>
                      <div className="p-4 bg-red-3 text-white text-base font-semibold">
                        Absensi Manasik ({manasik.total_manasik_present}/{manasik.total_manasik})
                      </div>
                      <div className="bg-white p-4x">
                        <div className="flex flex-wrap w-full gap-3 p-4">
                          {manasik.data.map((item, index) => (
                            <div 
                              onClick={() => {
                                if(permissions?.includes('update_package')) {
                                  handleInputAbsensi(data.order_kamar_mapping_id, item.manasik_id, !item.is_present)
                                }
                              }}
                              className={`bg-red-5 text-xs rounded-lg py-1 px-2 flex gap-1 items-center ${permissions?.includes('update_package') ? 'cursor-pointer' : ''}`}>
                              {(present && manasikSelected === item.manasik_id) ? <Spinner height="12px" width="12px" /> : <img src={item.is_present ? IconChecklist : IconCloseRed} />}
                              <span className="text-red-3 font-semibold">{index + 1}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                >
                  <div className={`
                      inline-flex gap-2 border rounded-lg py-[6px] px-2 !text-xs cursor-pointer
                      ${manasik.total_manasik_present && '!border-green-3 !bg-green-5'}
                      `
                    }>
                    <img src={TaskIcon} />
                    <span className="">Absensi {manasik.total_manasik_present}/{manasik.total_manasik}</span>
                  </div>
              </DropdownCustom>
            </>
          ) : (
            <>
              <div className={`
                  inline-flex gap-2 border rounded-lg py-[6px] px-2 !text-xs 
                  ${manasik.total_manasik_present && '!border-green-3 !bg-green-5'}
                  `
                }>
                <img src={TaskIcon} />
                <span className="">Absensi {manasik.total_manasik_present}/{manasik.total_manasik}</span>
              </div>
            </>
          )}
        </div>
      ),
    },
  ];

  const handleInputAbsensi = async (jamaahOrderID, manasikID, isePresent) => {
    setPresent(true)
    setManasikSelected(manasikID)
    const payload = {
      "manasik_id": manasikID,
      "order_kamar_mapping_id": jamaahOrderID,
      "is_present": isePresent,
    }
    const response = await onHandleInputAbsensiAPI(packageId, payload)

    if (response.status === 200) {
      await handleGetManasikReport()
      setPresent(false)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleEditManasik = (manasik) => {
    setOpenChangeLocation(true)
    setManasikSelected(manasik)
  }

  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setPageSize(pageSize);
  }

  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: pageSize,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  const handleGetManasikReport =  async () => {
    setIsLoading(true)

    const payload = {
      keyword: keyword,
      sort_column: "created_at",
      sort_order: "ASC",
      per_page: pageSize,
      page: currentpage,
      branch_id: dataFilter["branch_id"],
    };

    await dispatch(getLaporanManasikAbsensi(packageId, payload, (data) => {
      setTotal(data.total)
      setManasikJamaahList(data.data)
    }));
    setIsLoading(false)
  }

  useEffect(() => {
    handleGetManasikReport();
  }, []);

  useEffect(() => {
    handleGetManasikReport();
  }, [currentpage, pageSize, searchDebounce, dataFilter]);

  useEffect(() => {
    console.log("Data filter", dataFilter);
  }, [dataFilter]);

  return (
    <>
    <Popup
      open={openChangeLocation}
      handleClose={() => setOpenChangeLocation(false)}
      title={"Atur Tempat Manasik"}
      width="744px" 
      children={
        <ChangeLocationForm manasik={manasikSelected} onChange={() => {
          setOpenChangeLocation(false)
          handleGetManasikReport()
        }} />
        }
      />
      <MoslemeTable
        loading={isLoading}
        columns={columns}
        data={manasikJamaahList}
        pagination={pagination}
        customClass="overflow-auto z-0 !min-h-[600px]"
        onPageChange={(page) => setCurrentpage(page)}
        withSelection={true}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
      />
    </>
  )
}

export default Attendance