export const SET_USER = "actions/SET_USER"
export const SET_IS_AUTH = "actions/SET_IS_AUTH"

export const setUser = (payload) => ({
    type: SET_USER,
    payload,
});

export const setIsAuth = (payload) => ({
    type: SET_IS_AUTH,
    payload,
});