// landing Actions
// --------------------------------------------------------

import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import { getAllPaketService, getPackagePublishList } from "utils/api/paket";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "landing/SET_LOADING";

export const CLEAR_ERROR = "landing/CLEAR_ERROR";
export const SET_ERROR = "landing/SET_ERROR";
export const INIT_DATA = "landing/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "landing/SET_DOUBLE_SUBMIT";
export const SET_ALL_PAKET = "landing/SET_ALL_PAKET";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setAllPaket = (payload) => ({
  type: SET_ALL_PAKET,
  payload,
});

export const getAllPaket =
  (payload, callBackAction = (message) => {}) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await getAllPaketService(payload);
      dispatch(setLoading(false));

      const { status, data } = res;
      if (status == 200) {
        callBackAction(data);

        dispatch(setAllPaket(data));
      } else {
        handleErrorBE(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
};

export const handlePackagePublishList = (payload, successCallback) => async (dispatch) => {
  dispatch(setLoading(true));
  const response = await getPackagePublishList(payload);
  const { status, data } = response;
  dispatch(setLoading(false));

  if (status == 200) {
    successCallback(data);
  } else {
    dispatch(handleError(response.data));
  }

}
