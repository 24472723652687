import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dropdowns, { closeDropdown } from "components/dropdowns";

import { Eye, EyeSlash } from "iconsax-react";
import TrashIcon from "assets/icons/trash.svg"

import { PaketCouponAPI } from "utils/api/paket/coupon";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { IDRFormater, handleName } from "utils/helpers";
import Radio from "components/form/radio";
import Scroll from "components/scroll"
import Inputs from "components/form/inputs";
import Buttons from "components/buttons";
import { useFormik } from "formik";

import { addAddCouponService } from "utils/api/booking/"

import * as Yup from 'yup';
import Avatar from "components/avatar";
import Badge from "components/badge";
import CouponForm from "pages/tagihan/components/CouponForm";

const AddCoupon = ({
  packageID,
  onSuccess
}) => {

  const dispatch = useDispatch()
  const { bookingDataJamaah, bookingDetail } = useSelector((state) => state.booking);

  const [isLoading, setIsLoading] = useState(false)

  const form = useFormik({
    initialValues: {
      id_coupon: null,
      jamaah_order_id: []
    },
    validationSchema: Yup.object().shape({
      "id_coupon": Yup.number().required("Kupon harus diisi"),
      jamaah_order_id: Yup.array()
        .of(Yup.number().integer("Id Jamaah harus berupa angka").required("Jamaah harus dipilih"))
        .min(1, "Setidaknya ada satu Jamaah")
        .required("Jamaah harus diisi")
    })
  })

  const handleResetCoupon = () => {
    form.setFieldValue('id_coupon', null)
    form.setFieldValue('jamaah_order_id', [])
  }

  const handleSave = async () => {
    setIsLoading(true)
    const response = await addAddCouponService(bookingDetail?.order_number, form.values)
    
    setIsLoading(false)
    if(response.status == 200) {
      dispatch(handleSuccess(response))
      onSuccess(response.data)
    } else {
      dispatch(handleError(response.data))
    }
  }

  return (
    <>
      <div className="border rounded-2xl">
        <div className="p-4">
          <CouponForm 
            packageID={packageID}
            jamaahOrders={bookingDataJamaah}
            branch={bookingDetail?.cabang}
            onChange={(couponId, jamaahIdSelected) => {
              form.setFieldValue('id_coupon', couponId)
              form.setFieldValue('jamaah_order_id', jamaahIdSelected)
            }}
            onReset={handleResetCoupon}
            />
        </div>
        <div className="border-t p-4 flex justify-end">
          <Buttons
            loading={isLoading}
            disabled={!(form.isValid && form.dirty)}
            customClass="btn-primary btn-large"
            text="Simpan"
            onClick={handleSave}
            />
        </div>
      </div>
    </>
  )
}

export default AddCoupon