import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Popup from "../../../popup";
import Inputs from "../../../form/inputs";
import Buttons from "../../../buttons";
import { makeStyles } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";

import Camera from "../../../../assets/icons/camera-black.svg"

import Avatar from "components/avatar";
import { getLocalStorage } from "utils/helpers";
import { useFormik } from "formik";
import * as Yup from "yup";

import { handleUpdateProfile, handleUploadPhotoProfile } from 'stores/actions/profile'

const useStyles = makeStyles({
  card_popup_profile: {
    width: "100%",
    height: "330px",
    padding: "16px",
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "rgba(64, 72, 82, 0.1)",
    borderRadius: "16px 16px 0px 0px",
    "& .icon_profile": {
      marginBottom: "16px",
      position: "relative",
      display: "inline-block",
      "& .icon_active": {
        width: "10px",
        height: "10px",
        borderRadius: "5px",
        background: "#A7C957",
        border: "1px solid #ffff",
        position: "absolute",
        left: "2px",
        bottom: "4px",
      },
      "& .profile_upload_button": {
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        width: "24px",
        height: "24px",
        borderRadius: "100%",
        backgroundColor: "#fff",
        padding: "4px",
        cursor: "pointer",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.08)",
        "& input": {
          display: "none"
        },
        "&:active": {
          bottom: "-2px",
          boxShadow: "none"
        }
      }
    },
  },
  container_button: {
    marginTop: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px",
    borderWidth: "0px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "rgba(64, 72, 82, 0.1)",
    borderRadius: "0px 0px 16px 16px",
    gap: "8px"
  },
});

const EditProfile = ({ openProfile, handleClosePopupProfile, dataProfile }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth)
  const { isLoading } = useSelector(({ profile }) => profile);

  const [photoProfileTemp, setPhotoProfileTemp] = useState(null)

  const formik = useFormik({
    initialValues: {
      'photo_profile' : '',
      'fullname' : '',
      'nickname' : ''
    },
    validationSchema: Yup.object().shape({
      'fullname': Yup.string().max(150, "Maksimal 150 karakter").required("Nama lengkap harus diisi"),
      'nickname': Yup.string().max(50, "Maksimal 50 karakter").required("Nama panggilan harus diisi"),
    })
  })

  useEffect(() => {
    formik.setFieldValue('photo_profile', auth?.user?.image_profile)
    formik.setFieldValue('fullname', auth?.user?.name)
    formik.setFieldValue('nickname', auth?.user?.nickname)
  }, [auth, openProfile])


  const handleChangePhotoProfile = (image) => {
    let payload = {
      'directory': 'admin/profile',
      'images' : image.target.files[0]
    }

    dispatch(handleUploadPhotoProfile(payload, (res) => {
      setPhotoProfileTemp(res.data.link)
      formik.setFieldValue('photo_profile', res.data.link)
    }))
  }

  const handleSave = () => {
    dispatch(
      handleUpdateProfile(formik.values, (successData) => {
        setPhotoProfileTemp(null)
      } , (errData) => {
        const errors = errData?.data?.data

        Object.keys(errors).forEach((field) => {
          formik.setFieldError(field, errors[field][0]);
        });
      })
    )
  }
  
  return (
    <Popup
      type="secondary"
      width="1080px"
      open={openProfile}
      handleClose={handleClosePopupProfile}
      title="Edit Profil"
    > 
      <div className={classes.card_popup_profile}>
        <div className="icon_profile">
          <Avatar 
            containerClass="items-center justify-center  w-[72px] h-[72px] rounded-[100%] bg-slate-100" 
            customClass="object-cover w-[72px] h-[72px] rounded-[100%]"
            value={auth?.user}
            imageTemp={photoProfileTemp} />
          <label className="profile_upload_button">
            <img src={Camera} />
            <input name="uplod-foto-profile" onChange={handleChangePhotoProfile} type="file" accept="image/*"/>
          </label>
        </div>
        <Inputs
          id="test"
          label="Nama Lengkap"
          placeholder="Masukan nama lengkap"
          name="fullname"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          defaultValue={formik.values.fullname}
          error={
            formik.touched.fullname && formik.errors.fullname
          }
        />
        <div className="mt-4">
          <Inputs
            id="test"
            label="Nama Panggilan"
            name="nickname"
            placeholder="Masukan nama panggilan"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            defaultValue={formik.values.nickname}
            error={
              formik.touched.nickname && formik.errors.nickname
            }
          />
        </div>
      </div>
      <div className={classes.container_button}>
          <Buttons 
            text="Kembali"
            onClick={handleClosePopupProfile}
            customClass="btn-primary btn-outline btn-large" />
          <Buttons 
            text="Simpan" 
            type="submit" 
            onClick={handleSave} 
            loading={isLoading}
            disabled={!(formik.isValid && formik.dirty)}
            customClass="btn-primary btn-large" />
      </div>
    </Popup>
  );
};

EditProfile.propTypes = {
  dataProfile: PropTypes.object.isRequired,
  openProfile: PropTypes.bool.isRequired,
  handleClosePopupProfile: PropTypes.func.isRequired,
};
EditProfile.defaultProps = {
  dataProfile: {},
  openProfile: false,
  handleClosePopupProfile: () => {},
};

export default React.memo(EditProfile);
