

import { handleError, handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import { formattorbase64 } from "utils/helpers";

import { exportReportStartingService } from "utils/api/paket/laporan";

export const SET_LOADING = "exports/package-report-starting/SET_LOADING";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const handleExport = (packageID) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await exportReportStartingService(packageID);

    dispatch(handleSuccess(null, {
      title: "🕗 Proses Export Sedang Berjalan", 
      message: "File Export akan segera diunduh secara otomatis", 
      code: 200
    }));
    dispatch(setLoading(false));

    if (res.status !== 200) {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const handleDownloadExport = (res) => (dispatch) => {
  dispatch(setLoading(false));

  if(res.status === 200) {
    const { base64, type, file_name } = res.data;
    const insertPrefix = formattorbase64(type, base64);
    var a = document.createElement("a"); //Create <a>
    a.href = `${insertPrefix}`; //Image Base64 Goes here
    a.download = `${file_name}`; //File name Here
    a.click();
  } else {
    dispatch(handleError(res.data))
  }
};