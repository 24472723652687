import React, { useEffect, useState } from "react";
import Buttons from "../../../../../components/buttons";
import Create from "./Create";
import TableAdditional from "./TableAdditional";
import { IcJamPasir } from "assets/icons/dashboard";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AdditionalAction from "stores/actions/paket/additional";
import MoslemeTable from "components/Table";
import { columns } from "./columns";
import AlertPopup from "../../../../../components/popup/alert";
import Popup from "../../../../../components/popup";
import CreateEdit from "./components/CreateEdit";


import { getAdditional } from "utils/api/paket/additional";


function Additional() {

  const dispatch = useDispatch();
  
  const [isCreateEdit, setIsCreateEdit] = useState(false)
  const [isRemove, setIsRemove] = useState(false)

  const [additoinalSelected, setAdditionalSelected] = useState({})
  const [additionalOptions, setAdditionalOptions] = useState([])

  const [isloadingDelete, setIsloadingDelete] = useState(false)

  const [currentpage, setCurrentpage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalData, setTotalData] = useState(0)

  const params = useParams();
  const store = useSelector(({ additional }) => additional);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  useEffect(() => {
    handleGet()
  }, [dispatch, currentpage, pageSize]);

  const pagination = {
    showSizeChanger: true,
    total: totalData,
    current: currentpage,
    pageSize: pageSize,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getAdditional();
      const _temp = [];
      response.data.data.forEach((item) => {
        console.log("HEHEHE", item);
        _temp.push({
          value: item.id_additional,
          label: item.name,
          price: item.default_price
        });
      });
      setAdditionalOptions(_temp);
    };
    fetch();
  }, []);

  const handleGet = () => {
    const payload = {
      id_paket: params.id,
      keywoard: "",
      page_number: currentpage,
      per_page: pageSize,
      sort_column: "",
      sort_order: "Desc"
    }
    dispatch(AdditionalAction.fetchData(payload, (successData) => {
      setTotalData(successData?.total_data)
    }));
  }

  const handleEdit = (data) => {
    setAdditionalSelected(data)
    setIsCreateEdit(true)
  }

  const handleRemove = (data) => {
    setIsRemove(true)
    setAdditionalSelected(data)
  }

  const handleDeleteAdditional = () => {
    setIsloadingDelete(true)
    dispatch(AdditionalAction.deleteData(additoinalSelected?.id, (success) => {
      setIsloadingDelete(false)
      setIsRemove(false);
      handleGet()
      setTimeout(() => {
        setAdditionalSelected({})
      }, 500);
    }),
    (error) => {
      setIsloadingDelete(false)
      setIsRemove(false);

    })
    
  }

  const handleCloseCreateEdit = () => {
    setTimeout(() => {
      setAdditionalSelected({})
    }, 500);
    setIsCreateEdit(false)
  }

  const handleSuccessCreateEdit = () => {
    handleGet()
    setIsCreateEdit(false)
  }

  return (
    <>

    <Popup
      open={isCreateEdit}
      handleClose={handleCloseCreateEdit}
      width="1072px"
      title={` ${additoinalSelected.id ? "Edit Additional" : "Tambah Additional"} `}
    >
      
      <CreateEdit
        data={additoinalSelected}
        additionalOptions={additionalOptions}
        open={isCreateEdit}
        handleSuccess={handleSuccessCreateEdit}
        />

    </Popup>

    <AlertPopup
        subtitle={`Apakah kamu yakin ingin menghapus additional “${additoinalSelected?.name}” dipaket?`}
        open={isRemove}
        handleClose={() => setIsRemove(false)}
        handleContinue={handleDeleteAdditional}
        loading={isloadingDelete}
        />

        {auth.user.access_data === 'all_branches' && (
          <>
            {permissions?.includes('update_package') && (
              <>
                <div className="flex justify-end py-[16px] pr-[16px]">
                  <Buttons
                    onClick={() => setIsCreateEdit(true)}
                    text="Tambah Data"
                    customClass="btn-primary btn-small"
                  />
                </div>
              </>
            )}
          </>
        )}

      {store.data.length || store?.status === 'process' ? (
        <>
          <MoslemeTable
            columns={columns(handleEdit, handleRemove, auth)}
            loading={store?.status === 'process'}
            data={store.data}
            withSelection={true}
            selectionType={false}
            rowKey={(record) => record.id}
            pagination={pagination}
            onPageChange={(page) => setCurrentpage(page)}
            onSizeChange={(currentpage, pageSize) => {
              setCurrentpage(currentpage)
              setPageSize(pageSize)
            }}
          />
        </>
      ) : (
        <>
          <main className="flex flex-col items-center gap-y-4 justify-center h-[69vh]">
            <img alt="hourglass" src={IcJamPasir} />
            <div className="text-[#141414] text-[16px] font-normal">
              Belum ada Additonal di paket ini
            </div>
            {auth.user.access_data === 'all_branches' && (
              <>
                <Buttons
                  onClick={() => setIsCreateEdit(true)}
                  text="Tambah Data"
                  customClass="btn-primary btn-medium"
                />
              </>
            )}
          </main>
        </>
      )}
    </>
  )
}

export default Additional;
