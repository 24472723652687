import React from "react";
import { Grid } from "@material-ui/core";
import Badge from "../../../../../../components/badge";

import IconCabangPusat from "../../../../../../assets/icons/Cabang-Pusat.svg";
import IconLinkWhite from "../../../../../../assets/icons/link-white.svg";
import Buttons from "../../../../../../components/buttons";
import { Spin } from "antd";
import EditDescription from "./EditDescription";
import { convertBase64 } from "utils/helpers/convertBase64";
import parse from "html-react-parser";

const MobileCompany = ({ store }) => {
  const data = store.dataAllCabang?.data;
  const loading = store.isLoading;
  const [isEdit, setIsEdit] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [detail, setDetail] = React.useState(null);

  const openEditModal = (id, data_detail) => {
    setId(id);
    setDetail(data_detail);
    setIsEdit(true);
  };

  return (
    <>
      <EditDescription
        show={isEdit}
        close={() => setIsEdit(false)}
        id={id}
        data_detail={detail}
      />
      <Grid container spacing={2} className="!mt-4">
        {loading ? (
          <div className="w-full flex justify-center items-center py-20">
            <Spin />
          </div>
        ) : (
          data &&
          data.map((item) => {
            return (
              <Grid item md={3} key={item?.id_cabang}>
                <div className="bg-white soft-shadow rounded-2xl">
                  <div className={`!rounded-tl-2xl !rounded-tr-2xl !rounded-br-none !rounded-bl-none !p-4`}
                    style={{
                      backgroundColor: item?.color
                    }}
                    >
                    <div className="flex flex-row gap-3 items-center">
                      <Badge color={item?.color} backgroundColor="rgba(255, 255, 255, 0.7)" label={item?.name} />
                      {!!item?.is_pusat && (
                        <img src={IconCabangPusat} alt="pusat" />
                      )}
                    </div>
                    <div className="flex flex-row gap-2 items-cente mt-2">
                      <img src={IconLinkWhite} alt="link" />
                      <a
                        href={item?.url_website}
                        target="_blank"
                        className="flex text-white" rel="noreferrer"
                      >
                        {item?.url_website ? item?.url_website : "Link"}
                      </a>
                    </div>
                  </div>
                  <div className="p-4">
                    <Buttons
                      onClick={() => openEditModal(item?.id_cabang, item)}
                      text="Edit Website"
                      customClass="btn-secondary btn-medium"
                    />
                  </div>
                </div>
              </Grid>
            );
          })
        )}
      </Grid>
    </>
  );
};

export default React.memo(MobileCompany);
