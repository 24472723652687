import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Badge from "components/badge"
import Choice from "components/Choice"

import { getAirplaneDomestics, getEquipmentAdditionals, getTourCity } from "utils/api/requestPaket";
import { handleError } from "stores/actions/errorGeneral";
import { Skeleton } from "antd";

const Step4 = ({
  values,
  setFieldValues
}) => {

  const dispatch = useDispatch()

  const [airplaneDoemestics, setAirplaneDomestics] = useState([])
  const [isLoadingAirplaneDomestics, setIsLoadingAirplaneDomestics] = useState([])

  const [isLoadingEquipmentAdditionals, setIsLoadingEquipmentAdditionals] = useState([])
  const [equipmentAdditionals, setEquipmentAdditionals] = useState([])

  const [isLoadingTourCity, setIsLoadingTourCity] = useState([])
  const [tourCityOptions, setTourCityOptions] = useState([])

  const handleChangeAirplaneDomesticValue = (airplaneId) => {
    let _tempAirplaneDomesticValues = [...values.airplane_domestic_values]
    let _airplaneDomesticIndex= _tempAirplaneDomesticValues.findIndex(item => item.airplane_id == airplaneId)

    if(_airplaneDomesticIndex >= 0) {
      _tempAirplaneDomesticValues.splice(_airplaneDomesticIndex, 1)
    } else {
      _tempAirplaneDomesticValues.push({
        airplane_id: airplaneId,
        starting_ids: []
      })
    }

    setFieldValues('airplane_domestic_values', _tempAirplaneDomesticValues)
  }

  const handleChangeAirplaneDomesticStartings = (startingId, airplaneId) => {
    let _tempAirplaneDomesticValues = [...values.airplane_domestic_values]
    let _airplaneDomesticSelected = _tempAirplaneDomesticValues.find(item => item.airplane_id == airplaneId)

    if(_airplaneDomesticSelected.starting_ids.includes(startingId)) {
      _airplaneDomesticSelected.starting_ids = _airplaneDomesticSelected.starting_ids.filter(item => item != startingId)
    } else {
      _airplaneDomesticSelected.starting_ids.push(startingId)
    }
    
    setFieldValues('airplane_domestic_values', _tempAirplaneDomesticValues)
  }

  const handleGetAirplaneDomestics = async () => {
    setIsLoadingAirplaneDomestics(true)
    const response = await getAirplaneDomestics()
    setIsLoadingAirplaneDomestics(false)

    if(response.status = 200) {
      setAirplaneDomestics(response.data.data)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleGetEquipmentAdditionals = async () => {
    setIsLoadingEquipmentAdditionals(true)
    const response = await getEquipmentAdditionals()
    setIsLoadingEquipmentAdditionals(false)

    if(response.status = 200) {
      setEquipmentAdditionals(response.data.data)
    } else {
      dispatch(handleError(response.data))
    }
  }

  const handleGetTourCity = async () => {
    setIsLoadingTourCity(true)
    const response = await getTourCity()
    setIsLoadingTourCity(false)

    if(response.status = 200) {
      setTourCityOptions(response.data.data)
    } else {
      dispatch(handleError(response.data))
    }
  }

  const handleChaneEquipmentAdditionalValues = (id) => {
    let _tempEquipmentAdditionals = [...values.equipment_additional_values]

    if(_tempEquipmentAdditionals.includes(id)) {
      _tempEquipmentAdditionals = _tempEquipmentAdditionals.filter(item => item != id)
    } else {
      _tempEquipmentAdditionals.push(id)
    }

    setFieldValues('equipment_additional_values', _tempEquipmentAdditionals)
  }

  const handleChangeTourCityValues = (id) => {
    let _tempTourCityValues = [...values.tour_city_values]

    if(_tempTourCityValues.includes(id)) {
      _tempTourCityValues = _tempTourCityValues.filter(item => item != id)
    } else {
      _tempTourCityValues.push(id)
    }
    setFieldValues('tour_city_values', _tempTourCityValues)
  }

  useEffect(() => {
    handleGetAirplaneDomestics()
    handleGetEquipmentAdditionals()
    handleGetTourCity()
  }, [])

  return (
    <div className="flex gap-4 w-full">
      <div className="w-full flex flex-col gap-4">
        <Choice 
          type="checkbox"
          name={`operations`}
          checked={values.airplane_domestic}
          customClass={"!items-start"}
          id={`airplane_domestic`}
          onChange={() => {
            if(values.airplane_domestic) {
              setFieldValues('airplane_domestic_id', null)
              setFieldValues('airplane_domestic_starting_id', null)
            }
            setFieldValues('airplane_domestic', !values.airplane_domestic)
          }}
        >
          <>
            <div>
              <div className="font-semibold font-base">Pesawat Domestik</div>
              {values.airplane_domestic && (
                <div className="w-full flex flex-col gap-4 mt-4">
                  {isLoadingAirplaneDomestics && (
                    <>
                      {[...Array(6)].map((_, index) => (
                        <Skeleton.Input active={true} size="large" block={true} />
                      ))}
                    </>
                  )}
                  {airplaneDoemestics.length > 0 && (
                    <>
                      {airplaneDoemestics.map((item, index) => (
                        <>
                          <Choice 
                            key={index}
                            type="radio"
                            name={`airplane_domestic_id`}
                            customClass={"!border-0 !p-0 !items-start"}
                            // onClick={() => e.stopPropagation()}
                            // value={6}
                            checked={values.airplane_domestic_id == item.airplane_id}
                            id={`airplane_domestic_value-${index}`}
                            onChange={() => {
                              setFieldValues('airplane_domestic_id', item.airplane_id)
                              setFieldValues('airplane_domestic_starting_id', null)
                            }}
                          >
                            <div>
                              <div className="font-semibold font-base">{item.airplane.name}</div>
                              {values.airplane_domestic_id == item.airplane_id && (
                                <div className="w-full flex flex-col gap-4 mt-4">
                                  {item.airplane.calc_starting_airplane_domestics.map((starting, idx) => (
                                    <>
                                      <Choice 
                                        type="radio"
                                        name={`airplane_domestic_starting_id`}
                                        label={"Garuda Indonesia"}
                                        customClass={"!border-0 !p-0 !overflow-visible"}
                                        checked={values.airplane_domestic_id == item.airplane_id && values.airplane_domestic_starting_id == starting.id_starting}
                                        id={`airplane_domestic_value_starting-${item.airplane_id}-${idx}`}
                                        onChange={() => setFieldValues('airplane_domestic_starting_id', starting.id_starting)}
                                      >

                                        <div className="w-full">
                                          <Badge 
                                            label={starting.name} 
                                            color={starting.color}
                                            backgroundColor={starting.color_bg}
                                            />
                                        </div>

                                      </Choice>
                                    </>
                                  ))}
                                </div>
                              )}
                            </div>
                          </Choice>
                        </>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        </Choice>
        <Choice 
          type="checkbox"
          name={`operations`}
          label={"Transportasi Darat by Kereta Cepat"}
          checked={values.transportation_highspeed_train}
          id={`transportation_highspeed_train`}
          onChange={() => setFieldValues('transportation_highspeed_train', !values.transportation_highspeed_train)}
          />
        <Choice 
          type="checkbox"
          name={`operations`}
          checked={values.equipment_additional}
          customClass={"!items-start"}
          id={`equipment_additional`}
          onChange={() => {
            if(values.equipment_additional) {
              setFieldValues('equipment_additional_values', [])
            }
            setFieldValues('equipment_additional', !values.equipment_additional)
          }}
        >
          <div>
            <div className="font-semibold font-base">Perlengkapan Tambahan</div>
            {values.equipment_additional && (
              <div className="w-full flex flex-col gap-4 mt-4">
                {isLoadingEquipmentAdditionals && (
                  <>
                    {[...Array(6)].map((_, index) => (
                      <Skeleton.Input key={index} active={true} size="large" block={true} />
                    ))}
                  </>
                )}

                {equipmentAdditionals.length > 0 && (
                  <>
                    {equipmentAdditionals?.map((item, index) => (
                      <Choice 
                        type="checkbox"
                        name={`equipment_additional_values`}
                        customClass={"!border-0 !p-0 !items-start"}
                        label={item.name}
                        checked={values.equipment_additional_values.includes(item.id)}
                        onChange={() => handleChaneEquipmentAdditionalValues(item.id)}
                        id={`equipment_additional_values-${index}`}
                      />
                    ))}
                  </>
                )}

              </div>
            )}
          </div>
        </Choice>
        <Choice 
          type="checkbox"
          name={`operations`}
          label={"Layanan Jasa Guide"}
          checked={values.guide_service}
          id={`guide_service`}
          onChange={() => setFieldValues('guide_service', !values.guide_service)}
          />
        <Choice 
          type="checkbox"
          name={`operations`}
          label={"Layanan Suntik Meningitis"}
          checked={values.meningitis}
          id={`meningitis`}
          onChange={() => setFieldValues('meningitis', !values.meningitis)}
          />
      </div>
      <div className="w-full flex flex-col gap-4">
        <Choice 
          type="checkbox"
          name={`operations`}
          checked={values.tour_city === true}
          customClass={"!items-start"}
          id={`tour_city`}
          onChange={() => setFieldValues('tour_city', !values.tour_city)}
        >
          <div>
            <div className="font-semibold font-base">City Tour Tambahann</div>
            {values.tour_city && (
              <div className="w-full flex flex-col gap-4 mt-4">
                {isLoadingTourCity && (
                  <>
                    {[...Array(6)].map((_, index) => (
                      <Skeleton.Input key={index} active={true} size="large" block={true} />
                    ))}
                  </>
                )}

                {tourCityOptions.length > 0 && (
                  <>
                    {tourCityOptions?.map((item, index) => (
                      <Choice 
                        type="checkbox"
                        name={`tour_city_values`}
                        customClass={"!border-0 !p-0 !items-start"}
                        label={item.name}
                        checked={values.tour_city_values.includes(item.id)}
                        onChange={() => handleChangeTourCityValues(item.id)}
                        id={`tour_city_values-${index}`}
                      />
                    ))}
                  </>
                )}

              </div>
            )}
          </div>
        </Choice>
        <Choice 
          type="checkbox"
          name={`operations`}
          label={"Transportasi Darat by Bus"}
          checked={values.transportation_bus}
          id={`transportation_bus`}
          onChange={() => setFieldValues('transportation_bus', !values.transportation_bus)}
          />
        <Choice 
          type="checkbox"
          name={`operations`}
          label={"Perlengkapan Lengkap"}
          checked={values.equipment_completed}
          id={`equipment_completed`}
          onChange={() => setFieldValues('equipment_completed', !values.equipment_completed)}
          />
        <Choice 
          type="checkbox"
          name={`operations`}
          label={"Layanan Manasik"}
          checked={values.manasik}
          id={`manasik`}
          onChange={() => setFieldValues('manasik', !values.manasik)}
          />
        <Choice 
          type="checkbox"
          name={`operations`}
          label={"Layanan Passport"}
          checked={values.passport}
          id={`passport`}
          onChange={() => setFieldValues('passport', !values.passport)}
          />
      </div>
    </div>
  )
}

export default Step4