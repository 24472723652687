import Rating from "components/rating"


const HotelLabel = ({
  name,
  thumbnail,
  stars
}) => {
  return (
    <>
      <div className="w-full flex gap-4">
        <div className="w-16 h-16 rounded-2xl overflow-hidden flex-shrink-0">
          <img 
            src={thumbnail}
            className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col justify-center gap-1">
          <div className="text-base font-semibold">{name}</div>
          <Rating 
            value={stars}
            />
        </div>
      </div>
    </>
  )
}

export default HotelLabel

