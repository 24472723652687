const SkeletonView = () => {
  return (
    <div className="space-y-4">
      {[1, 2, 3, 4, 5].map((item) => (
        <div
          key={item}
          className="flex items-start border border-gray-4 rounded-2xl p-4 justify-between animate-pulse"
        >
          <div className="flex items-center gap-6">
            {/* Image skeleton */}
            <div className="w-[88px] h-[88px] bg-gray-200 rounded-2xl" />
            
            <div>
              {/* Transportation name skeleton */}
              <div className="h-6 bg-gray-200 rounded-md w-48" />
              
              {/* Category skeleton */}
              <div className="h-4 bg-gray-200 rounded w-24 mt-1" />
            </div>
          </div>

          {/* Action button skeleton */}
          <div className="flex gap-4">
            <div className="w-[22px] h-[22px] bg-gray-200 rounded" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonView;
