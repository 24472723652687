import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Dropdowns, { closeDropdown } from "components/dropdowns"

import {
  handleGetFileRelasi,
  handleGetJamaahByRoomList,
  handleGetListRelasi,
  handleGetRoomlistByPackage,
  handleGetSetRoomList,
  handleUpdatedRelasi,
  handleUpdatedRoomList,
} from "stores/actions/paket/roomlist";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import Scroll from "components/scroll";
import Buttons from "components/buttons";
import { useFormik } from "formik";

import * as Yup from 'yup';
import UploadArea from "components/Uploadarea";
import Spinner from "components/spinner";

const ChoseRelation = ({
  choiceRelasi,
  onUpdated,
  roomList
}) => {
    const params = useParams()
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [relationOptions, setRelationOptions] = useState([])
    const [updatingRelation, setUpdatingRelation] = useState(false)

    const formik = useFormik({
      initialValues: {
        room_list_id: roomList?.id,
        relation_id: null,
        file: null
      },
      validationSchema: Yup.object({
        room_list_id: Yup.number().required('Room list ID harus diisi').typeError('Harus berupa angka'),
        relation_id: Yup.number().required('Relation ID harus diisi').typeError('Harus berupa angka'),
        file: Yup.string()
      })
    })

    const handleGetRelationOptions = () => {
        setIsLoading(true)
        dispatch(handleGetListRelasi(
          params.id,
          (response) => {
            setIsLoading(false)
            setRelationOptions(response.data.data)
          },
          (err) => {
            setIsLoading(false)
            dispatch(handleError(err.data))
          }
        ))
    }

    const handleChoseRelation = (relationID) => {
      formik.setFieldValue('relation_id', relationID)
      setTimeout(() => {
        formik.setFieldTouched('relation_id', true)
      })
      closeDropdown()
    }

    const handleSave = () => {
      setUpdatingRelation(true)
      dispatch(handleUpdatedRelasi(
        params.id,
        formik.values,
        (response) => {
          setUpdatingRelation(false)
          dispatch(handleSuccess(response))
          onUpdated()
        },
        (err) => {
          dispatch(handleError(err.data))
          setUpdatingRelation(false)
        }
      ))
    }

    useEffect(() => {
        handleGetRelationOptions()
    }, [])

    useEffect(() => {
      const relationSelected = relationOptions.find(relation => relation.name === choiceRelasi)
      formik.setFieldValue('relation_id', relationSelected?.id)
      // formik.setFieldValue('room_list_id', roomListID)
      setTimeout(() => {
        formik.setFieldTouched('relation_id', true)
        // formik.setFieldTouched('room_list_id', true)
      })
    }, [relationOptions])

    return (
      <div>
          <div className="border rounded-tl-2xl rounded-tr-2xl p-4">
            {isLoading ? (
              <>
                <div className="flex justify-center items-center h-[350px]"> 
                  <Spinner 
                    width="32px"
                    height="32px"
                    />
                </div>
              </>
            ) : (
              <>
                <div className="mb-4">
                  <div className="mb-2 font-semibold text-sm">Relasi</div>
                  <Dropdowns 
                    dropdownArrow={true}
                    customClass="bg-gray-5 rounded-xl py-[14px] px-4 font-sm flex justify-between items-center"
                    labelDropdown={
                      <>
                        <div>
                          {formik.values.relation_id ? relationOptions.find(relation => relation.id === formik.values.relation_id).name : 'Pilih relasi'}
                        </div>
                      </>
                    }
                    children={
                      <>
                        <div className="bg-white shadow-custom-1 rounded-2xl overflow-hidden">
                          <Scroll height="300px">
                            {relationOptions.map((relation, index) => (
                              <>
                                <div
                                  onClick={() => handleChoseRelation(relation.id)} 
                                  className={
                                    `py-2 px-4 text-sm hover:bg-red-5 focus:bg-red-4 cursor-pointer ${
                                      formik.values.relation_id === relation.id
                                        ? "bg-red-4"
                                        : ""
                                    }`
                                  }>
                                  {relation.name}
                                </div>
                              </>
                            ))}
                          </Scroll>
                        </div>
                      </>
                    }
                    />
                </div>
                {(formik.values.relation_id === 2 || formik.values.relation_id === 3)  && (
                  <>
                    <div className="mb-4">
                      <UploadArea
                        type="chose"
                        label="Upload Surat Pernikahan"
                        onChange={(url) => formik.setFieldValue('file', url)}
                        defaultUrl={roomList.jamaah.surat_nikah ? roomList.jamaah.surat_nikah : null}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="border rounded-bl-2xl rounded-br-2xl p-4 broder-t-0 flex justify-end">
            <Buttons 
              text="Simpan"
              loading={updatingRelation}
              customClass="btn-primary btn-large"
              onClick={handleSave}
              />
          </div>
      </div>
    )
}

export default ChoseRelation