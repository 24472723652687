import React, { useCallback, useState } from "react";

import Buttons from "../../../../components/buttons";
import Cards from "../../../../components/cards";
import Popup from "../../../../components/popup";
import Notifcation from "./components/Notifcation";
import AddNotification from "./components/Notifcation/components/AddNotification";
import Reminder from "./components/Reminder";
import AddReminder from "./components/Reminder/components/AddReminder";

import ILCoomingSoon from "assets/images/cooming-soon.svg";

const SettingNotification = () => {
  const listTab = ["Notification", "Pengingat"];
  const [activeTab, setActiveTab] = useState("Notification");
  const [openPopupAddNotification, setOpenPopupAddNotification] =
    useState(false);
  const [openPopupAddReminder, setOpenPopupAddReminder] = useState(false);

  const getContent = useCallback(() => {
    if (activeTab === "Pengingat") {
      return <Reminder />;
    } else {
      return <Notifcation />;
    }
  }, [activeTab]);

  return (
    <>
      {/* <Popup
        open={openPopupAddNotification}
        handleClose={() => setOpenPopupAddNotification(false)}
        title="Tambah Template Notifikasi"
        containerClass=""
        width="1000px"
        children={<AddNotification />}
      />
      <Popup
        open={openPopupAddReminder}
        handleClose={() => setOpenPopupAddReminder(false)}
        title="Tambah Template Pengingat"
        containerClass=""
        width="1000px"
        children={<AddReminder />}
      />
      <Cards containerClass="!p-0">
        <div className="flex flex-row justify-between items-center p-4">
          <div className="text-[20px] font-semibold">
            Nofitikasi & Pengingat
          </div>
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-4">
              {listTab.map((val, idx) => {
                return (
                  <div
                    key={idx}
                    className={`cursor-pointer ${
                      activeTab === val
                        ? "green-bg rounded-lg px-3 py-2 text-white"
                        : "border-[0.5px] border-[#E0E0E0] rounded-lg px-3 py-2"
                    }`}
                    onClick={() => {
                      setActiveTab(val);
                    }}
                  >
                    {val}
                  </div>
                );
              })}
            </div>
            {activeTab === "Notification" ? (
              <Buttons
                text="Tambah Template Notifikasi"
                customClass="btn-primary btn-small"
                onClick={() => setOpenPopupAddNotification(true)}
              />
            ) : (
              <Buttons
                text="Tambah Template Pengingat"
                customClass="btn-primary btn-small"
                onClick={() => setOpenPopupAddReminder(true)}
              />
            )}
          </div>
          {getContent()}
        </div>
      </Cards>
      <div id="area-modal"></div> */}

      <div className="flex flex-row justify-center items-center w-full !h-[calc(100vh-135px)] bg-white rounded-2xl">
        <div className="flex flex-col items-center">
          <img src={ILCoomingSoon} alt="comming soon" />
          <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
            Notifikasi & Pengingat dalam pengerjaan
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SettingNotification);
