import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import {
  CLEAR_ERROR,
  ERROR_FETCHING_PAKET_DETAIL_GENERAL_INFO,
  SET_COU_DATA,
  SET_DATA_PEMBIMBING,
  SET_LIST_BUS_BY_ID,
  SET_LOADING,
  SET_UPDATE_TGL,
  START_FETCHING_PAKET_DETAIL_GENERAL_INFO,
  SUCCESS_FETCHING_PAKET_DETAIL_GENERAL_INFO,
  SET_CHANGE_STATUS_LOADING,
  SET_LOADING_DETAIL
} from "./actionTypes";
import {
  deleteAllPaketService,
  deleteManasikCabangService,
  getListManasikService,
  getManasikCabangService,
  getPaketDetailGeneralInfo,
  savePaketManasikService,
  syaratKetentuanApi,
  changeStatusPackage
} from "utils/api/paket";
import { getListBusById, saveBus } from "utils/api/paket/bus";
// import generalInfoStore from 'stores/reducers/paket/generalInfo'

const startFetchingPaketDetailGeneralInfo = () => ({
  type: START_FETCHING_PAKET_DETAIL_GENERAL_INFO,
});

const errorFetchingPaketDetailGeneralInfo = () => ({
  type: ERROR_FETCHING_PAKET_DETAIL_GENERAL_INFO,
});

const successFetchingPaketDetailGeneralInfo = (payload) => ({
  type: SUCCESS_FETCHING_PAKET_DETAIL_GENERAL_INFO,
  payload,
});

const setUpdateTgl = (payload) => ({
  type: SET_UPDATE_TGL,
  payload,
});

const setCouData = (payload) => ({
  type: SET_COU_DATA,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setLoadingDetail = (payload) => ({
  type: SET_LOADING_DETAIL,
  payload,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const setListBusById = (payload) => ({
  type: SET_LIST_BUS_BY_ID,
  payload,
});
export const setDataPembimbing = (payload) => ({
  type: SET_DATA_PEMBIMBING,
  payload,
});

export const setChangeStatusLoading = (payload) => ({
  type: SET_CHANGE_STATUS_LOADING,
  payload
})

export const handleGetBusById = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await getListBusById(payload);
    if (res.status === 200) {
      dispatch(setLoading(false));
      dispatch(setListBusById(res.data));
      return res.status;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const handleSaveOrEditBus =
  (payload, callBackAction, errorCallback) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await saveBus(payload);
      if (res.status === 200) {
        callBackAction(res.data);
      } else {
        dispatch(handleErrorBE(res));
        errorCallback(res.data)
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

const handleFetchListPaketDetailGeneralInfo = (payload, actionNotFound) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoadingDetail(true));
      dispatch(startFetchingPaketDetailGeneralInfo());
      const response = await getPaketDetailGeneralInfo(payload);
      dispatch(setLoadingDetail(false));
      if (response.status === 200) {
        
        dispatch(successFetchingPaketDetailGeneralInfo(response.data));
        return response.status;
      } else {
        dispatch(handleError(response.data));
        if(response.status === 404){
          actionNotFound()
        }
      }
    } catch (error) {
      dispatch(errorFetchingPaketDetailGeneralInfo());
      dispatch(handleError(error));
    }
  };
};

const PaketDetailGeneralInfoAction = {
  fetchData: handleFetchListPaketDetailGeneralInfo,
};

const updateSKT = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await syaratKetentuanApi.main(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setUpdateTgl(response.data));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(errorFetchingPaketDetailGeneralInfo());
      dispatch(handleError(error));
    }
  };
};

const couData = (
  payload,
  actionCallback = (message) => {},
  errorCallback = (message) => {}
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await syaratKetentuanApi.saveOrUpdate(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        actionCallback(response.data);
        dispatch(setCouData(response.data));
        // console.log(response);
        return response.status;
      } else {
        errorCallback(response.data);
        dispatch(handleError(response.data));
      }
    } catch (error) {
      dispatch(errorFetchingPaketDetailGeneralInfo());
      dispatch(handleError(error));
    }
  };
};

const deleteSKT = (
  payload,
  callbackAction = (message) => {},
  calbackError = (message) => {}
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await syaratKetentuanApi.delete(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        callbackAction(response.data);
      } else {
        calbackError(response.data);
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(errorFetchingPaketDetailGeneralInfo());
      dispatch(handleError(error));
    }
  };
};

export const deleteAllPaket = (id, callbackAction) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await deleteAllPaketService(id);
      dispatch(setLoading(false));
      if (response.status === 200) {
        callbackAction(response.data);
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const saveManasikPaket = (payload, callbackAction) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await savePaketManasikService(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        callbackAction(response.data);
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const getListManasikPaket = (id, callbackAction) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await getListManasikService(id);
      dispatch(setLoading(false));
      if (response.status === 200) {
        callbackAction(response.data);
        dispatch({type: 'SET_DATA_MANASIK', payload: response.data.data })
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const getManasikCabangPaket = (payload, callbackAction) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await getManasikCabangService(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        callbackAction(response.data);
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const deleteManasikCabangPaket = (payload, callbackAction) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await deleteManasikCabangService(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        callbackAction(response.data);
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const changeStatusValuePaket = (payload) => {
  return async (dispatch, getState) => {
    try {
      // console.log(payload);
      dispatch(setChangeStatusLoading(true))
      const response = await changeStatusPackage(payload)
      const { status, data } = response

      dispatch(setChangeStatusLoading(false))
      
      if(status === 200) {
        const genralInfoData = getState().generalInfo.data
        const newData = {
          ...genralInfoData,
          status: payload.status 
        }
        dispatch(handleSuccess(null, { message: `Berhasil ${payload.status ? 'mempublish' : 'membatalkan publish'} paket` }))
        dispatch(successFetchingPaketDetailGeneralInfo({...data, data: newData}))
      } else {
        dispatch(handleError(response.data))
      }
    } catch(error) {
      dispatch(handleError(error))
    }
  }
  
}

export const syaratKetentuan = {
  updateTgl: updateSKT,
  createOrUpdateData: couData,
  delete: deleteSKT,
};

export default PaketDetailGeneralInfoAction;
