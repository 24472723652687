import React from "react";
import MaleAvatar from "../../assets/icons/male.svg";
import FemaleAvatar from "../../assets/icons/female.svg";
import PropTypes from "prop-types";

const Avatar = ({ value, containerClass, customClass, imageTemp, customGender }) => {
  
  let image = null;
  let gender = null;

  if(value) {
    gender = value?.title?.toLowerCase() == "tn" || value?.jamaah_title?.toLowerCase() == "tn" ? "ikhwan" : "akhwat"
    if(value?.image_profile || value?.profile_photo || value?.photo || value?.jamaah_photo || value?.photo_profile) {
      image = value?.image_profile || value?.profile_photo || value?.photo || value?.jamaah_photo || value?.photo_profile
    } else {
      image = gender == "akhwat" ? FemaleAvatar : MaleAvatar
    }
  }

  if(customGender) {
    gender = customGender
  }

  if(imageTemp) {
    image = imageTemp ? imageTemp : gender == "ikhwan" ? MaleAvatar : FemaleAvatar
  }

  image= image ? image : gender == "ikhwan" ? MaleAvatar : FemaleAvatar

  return (
    <div className={`items-center justify-center  rounded-[100%] w-[33px] h-[33px] bg-slate-100 ${containerClass}`}>
      <img 
        src={image} 
        alt="avatar" 
        className={`object-cover w-[33px] h-[33px] rounded-[100%] ${customClass}`} 
        onError={event => {
          event.target.src = gender == "ikhwan" ? MaleAvatar : FemaleAvatar
          event.onerror = null
        }}
        />
    </div>
  );
};

Avatar.propTypes = {
  value: PropTypes.object,
  imageTemp: PropTypes.string,
  customGender: PropTypes.string
};
Avatar.defaultProps = {
  containerClass: "",
  customClass: "",
  imageTemp: null,
  customGender: 'ikhwan'
};

export default Avatar;
