/* eslint-disable array-callback-return */
import { CheckOutlined, FilterOutlined } from "@ant-design/icons";
import { Collapse, Input } from "antd";
import CustomSelect from "components/antd/CustomSelect";
import Sliders from "components/slider";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MemberOnlineAPI } from "utils/api/member";

import { Grid, makeStyles } from "@material-ui/core";
import moment from "moment";
import { rupiah } from "pages/master/components/subpage/additional/columns";
import { useHistory } from "react-router-dom";
import { getAllCabang } from "stores/actions/cabang";
import {
  getDataJamaah,
  getDataMemberOnline,
  getDataPaket,
  getDataPaketAktif,
  getStatistik,
  getdataKelengkapanJamaah,
  resetDataDashboard,
} from "stores/actions/dashboard";
import { detailById } from "stores/actions/general";
import { getDataRole } from "stores/actions/role";
import styled from "styled-components";
import {
  IcArrowRsM,
  IcBelumBerangkat,
  IcCeklisBlue,
  IcSearch,
  IcSedangBerangkat,
  IcSudahBerangkat,
  IcTotalPaket,
} from "../../assets/icons/dashboard";
import { ImAvatar } from "../../assets/images";
import MoslemeTable from "../../components/Table";
import Badge from "../../components/badge";
import Buttons from "../../components/buttons";
import Chart from "../../components/chart";
import Inputs from "../../components/form/inputs";
import Popup from "../../components/popup";
import { columns } from "./columns";
import CardJamaah from "./components/CardJamaah";
import CardProgress from "./components/CardProgress";
import ListJamaah from "./components/ListJamaah";
import PaketAktif from "./components/PaketAktif";
import UserDesc from "./components/UserDesc";

import Card from "../../components/cards";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import useDebounce from "utils/helpers/useDebounce";

import Accordions from "../../components/accordions";
import { IDRFormater } from "utils/helpers";

import { getBelumDp, getBelumLunas, getKelengkapanData } from "stores/actions/dashboard";

import { handleSendFollowup } from "utils/api/booking";

const CustomSlider = styled.div`
  .slick-slider {
    position: inherit;
  }
  .custom-slick-arrow-prev {
    position: absolute;
    top: 27px;
    left: 92% !important;
  }

  .slick-disabled {
    opacity: 1 !important;
  }

  .custom-slick-arrow-next {
    top: 27px;
    position: absolute;
    right: 8px !important;
  }
`;

const CustomDropdown = styled.div`
  .ant-collapse-content-active {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .ant-collapse-header {
    align-items: center !important;
  }
`;

const useStyle = makeStyles({
  contMemberOnline: {
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      width: "4px",
      borderRight: "10px solid #fff",
      borderLeft: "10px solid #fff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E0E0E0",
      height: "30px",
      width: "4px",
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-track-piece:start": {
      background: "#fff",
    },
    "&::-webkit-scrollbar-track-piece:end": {
      background: "#fff",
    },
    scrollBehavior: 'smooth'
  }
})

const Dashboard = () => {
  const classes = useStyle();
  const navigate = useHistory();
  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);

  const [keywordDataTransaction, setKeywordDataTransaction] = useState("");
  const searchTransactionDebounce = useDebounce(keywordDataTransaction, 1000)

  const [openTransactionActivePackage, setOpenTransactionActivePackage] = useState({
    type: null,
    statu: false
  });

  const { byDetail, global } = useSelector((state) => state?.general);
  const { dataRole } = useSelector((state) => state.role);
  const { dataAllCabang } = useSelector((state) => state.cabang);
  const {
    totalJamaah,
    totalPaket,
    totalPaketAktif,
    totalMemberOnline,
    statistics,
    kelengkapanDataJamaah,
    belumDp,
    belumLunas,
  } = useSelector((state) => state.dashboard);

  const [memberOnlineCount, setMemberOnlineCount] = useState(0);
  const [memberOnline, setMemberOnline] = useState([]);
  const [memberOnlineLoading, setMemberOnlineLoading] = useState(false);
  const memberOnlineRoleOptions = [
    {
      value: null,
      label: 'Semua Role',
    },
    {
      value: 'admin',
      label: 'Admin',
    },
    {
      value: 'agent',
      label: 'Agen',
    },
    {
      value: 'jamaah',
      label: 'Jamaah',
    },
  ];
  const [memberOnlineRoleFilter, setMemberOnlineRoleFilter] = useState(null);
  const [memberOnlineKeyword, setMemberOnlineKeyword] = useState(null);
  const memberOnlineKeywordDebounce = useDebounce(memberOnlineKeyword, 800);
  const [memberOnlineBranchIDFilter, setMemberOnlineBranchIDFilter] = useState(null);

  const [statisticCategories, setStatisticCategories] = useState([]);
  const [statisticData, setStatisticData] = useState([]);
  

  const [userOnline, setUserOnline] = useState({
    search: "",
    role: 1,
    cabang: 1,
  });

  const [searchModal, setSearchModal] = useState({
    dp: "",
    lunas: "",
    perlengkapan: "",
    kelengkapan: "",
  });

  const [sendingFollowup, setSendingFollowup] = useState({
    loading: false,
    orderNumber: null,
    key: null
  })

  const [yearOptions, setYearOptions] = useState([
    {
      value: moment().year(),
      label: `Tahun ${moment().year()}`
    }
  ]);
  const [yearFilter, setYearFilter] = useState(moment().year());

  const handleClickFollowup = async (orderNumber, key) => {
    setSendingFollowup({
      loading: true,
      orderNumber: orderNumber,
      key: key
    })
    const res = await handleSendFollowup(orderNumber, key)
    setSendingFollowup({
      loading: false,
      orderNumber: null,
      key: null
    })

    if (res.status === 200) {
      dispatch(handleSuccess(res))
    } else {
      dispatch(handleError(res.data))
    }

  }

  const filterBelumDp = byDetail?.data?.data?.filter((item) =>
    item?.order_number?.toLowerCase()?.includes(searchModal?.dp?.toLowerCase())
  );

  const filterPerlengkapan = byDetail?.data?.data?.filter((item) =>
    item?.jamaah_name
      ?.toLowerCase()
      ?.includes(searchModal?.perlengkapan?.toLowerCase())
  );

  const dataJamaah = useCallback(() => {
    dispatch(getDataJamaah());
  }, [dispatch]);

  useEffect(() => {
    dataJamaah();
  }, [dataJamaah]);

  const dataPaket = useCallback(() => {
    dispatch(getDataPaket());
  }, [dispatch]);

  useEffect(() => {
    dataPaket();
  }, [dataPaket]);

  const dataPaketAktif = useCallback(
    (per_page, page_number) => {
      dispatch(
        getDataPaketAktif({
          per_page: per_page,
          page_number: page_number,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dataPaketAktif(10, 1);
  }, [dataPaketAktif]);

  const dataMemberOnline = useCallback(
    (currentpage) => {
      dispatch(getDataMemberOnline());
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(
      getDataMemberOnline({
        cabang_id: userOnline?.cabang,
        role_id: userOnline?.role,
        per_page: "5",
        keyword: userOnline?.search,
      })
    );
  }, [dispatch, userOnline?.cabang, userOnline?.role, userOnline?.search]);

  const allCabang = useCallback(() => {
    if(auth.user.access_data === 'all_branches') {
      dispatch(
        getAllCabang({
          page_number: global?.currentpage,
          per_page: global?.size,
          sort_column: "",
          sort_order: "Desc",
          keyword: "",
        })
      );
    }
  }, [dispatch, global?.currentpage, global?.size]);

  useEffect(() => {
    allCabang();
  }, [allCabang]);

  useEffect(() => {
    dispatch(getDataRole());
  }, [dispatch]);

  const cabangs = dataAllCabang?.data?.map((item) => ({
    ...item,
    value: item?.id_cabang,
    label: item?.name,
  }));

  cabangs?.unshift({
    value: null,
    label: 'Semua Cabang',
  });

  const roles = dataRole?.data?.map((item) => ({
    ...item,
    value: item?.key,
    label: item?.role?.tags,
  }));

  // const categories = [];
  // const dataSeries = [];

  // statistics?.data?.map((item) => {
  //   categories.push(`${item?.year}-${item?.month}`);
  //   dataSeries.push(item.jumlah_jamaah);
  // });

  // const series = [
  //   {
  //     name: "Orang",
  //     data: dataSeries,
  //   },
  //   {
  //     name: "Uang",
  //     data: [13,43,53,53,2,3,50,0,12,0,3, 200],
  //   },
  // ];

  const onDetailModal = (data) => {
    // return console.log("mantap", data);
    dispatch(
      getdataKelengkapanJamaah({
        paket_id: data?.id_paket,
        order_kamar_id: data.id,
        keyword: "",
        per_page: 10,
        page_number: 1,
        sort_column: "ordered_at",
        sort_order: "ASC",
      })
    );

    if (byDetail?.status === "perlengkapan") {
      dispatch(
        detailById({
          detailPerlengkapan: true,
          data: byDetail?.data,
          detail: kelengkapanDataJamaah,
        })
      );
    } else {
      dispatch(
        detailById({
          detailKelengkapanData: true,
          data: byDetail?.data,
          detail: kelengkapanDataJamaah,
        })
      );
    }
  };

  if (
    kelengkapanDataJamaah?.total_data !== 0 &&
    kelengkapanDataJamaah !== null
  ) {
    byDetail.detail = kelengkapanDataJamaah;
  }

  const handleCloseJamaah = () => {
    if (byDetail?.detailPerlengkapan) {
      dispatch(detailById({ status: "perlengkapan", data: byDetail?.data }));
      dispatch(resetDataDashboard());
    } else if (byDetail?.detailKelengkapanData) {
      dispatch(detailById({ status: "kelengkapan", data: byDetail?.data }));
      dispatch(resetDataDashboard());
    } else {
      dispatch(detailById({}));
    }
  };
  

  const handleGetMemberOnlineCount = async () => {
    const response = await MemberOnlineAPI.getMemberOnlineCount();

    if(response.status === 200) {
      setMemberOnlineCount(response.data.data.online)
    } else {
      dispatch(handleError(response.data))
    }

  }

  const handleGetBelumBill = async () => {
    // if openTransactionActivePackage.type belum_dp
    if (openTransactionActivePackage?.type === "belum_dp") {
      // get belum dp
      dispatch(
        getBelumDp({
          paket_id: openTransactionActivePackage?.packageID,
          // paket_id: 1,
          keyword: "",
          per_page: 1000,
          page_number: 1,
          sort_column: "ordered_at",
          sort_order: "ASC",
        })
      )
    }
  }

  const handleGetMemberOnline = async () => {
    setMemberOnlineLoading(true)
    const params = {
      branch_id: memberOnlineBranchIDFilter,
      role: memberOnlineRoleFilter,
      keyword: memberOnlineKeyword,
    }

    const response = await MemberOnlineAPI.getMemberOnlineList(params);
    setMemberOnlineLoading(false)
    if(response.status === 200) {
      setMemberOnline(response.data.data)
    } else {
      dispatch(handleError(response.data))
    }
  }

  const handleTransactionActive = (data) => {
    setOpenTransactionActivePackage(data)
  }

  useEffect(() => {
    handleGetMemberOnlineCount()
    handleGetMemberOnline()
  }, [])

  useEffect(() => {
    handleGetMemberOnlineCount()
    handleGetMemberOnline()
  }, [memberOnlineKeywordDebounce, memberOnlineBranchIDFilter, memberOnlineRoleFilter])

  useEffect(() => {

    if(openTransactionActivePackage?.type === "belum_dp") {
      dispatch(getBelumDp({
        paket_id: openTransactionActivePackage?.paket_id,
        // paket_id: 1,
        keyword: keywordDataTransaction,
        per_page: 1000,
        page_number: 1,
        sort_column: "ordered_at",
        sort_order: "ASC",
      }))
    }

    if(openTransactionActivePackage?.type === "belum_lunas") {
      dispatch(getBelumLunas({
        paket_id: openTransactionActivePackage?.paket_id,
        // paket_id: 1,
        keyword: keywordDataTransaction,
        per_page: 1000,
        page_number: 1,
        sort_column: "ordered_at",
        sort_order: "ASC",
      }))
    }
  }, [searchTransactionDebounce])

  useEffect(() => {
    const currentYear = yearFilter;
    let months = [];

    for (let i = 1; i <= 12; i++) {
      months.push(`${currentYear}-${String(i).padStart(2, '0')}`);
    }
  
    const _tempStatisticsData = [
      {
        name: "Pesanan",
        data: statistics.data?.orders?.map(data => data.total_order)
      },
      {
        name: "Pax",
        data: statistics.data?.order_jamaahs?.map(data => data.total_jamaah)
      },
      {
        name: "Berangkat",
        data: statistics.data?.order_jamaahs_departs?.map(data => data.total_jamaah)
      }
    ];
  
    setStatisticCategories(months);
    setStatisticData(_tempStatisticsData);

    setYearOptions(statistics?.data?.year_options?.map(year => {
      return {
        value: year,
        label: `Tahun ${year}`
      }
    }))

  }, [statistics]);
  
  useEffect(() => {
    dispatch(getStatistik({
      year: yearFilter
    }));
  }, [dispatch, yearFilter]);

  return (
    <Card containerClass="!p-4">
      <Grid className="">
        <div className="text-[20px] font-semibold pt-[2px] pb-[18px]">
          Dashboard
        </div>
        <div className="text-[20px] font-semibold my-4">Total Jamaah</div>
        <section className="grid w-full grid-cols-3 items-center gap-6">
          <CardJamaah
            img={IcBelumBerangkat}
            title={"Belum Berangkat"}
            paket={totalJamaah?.data?.belum_berangkat?.total + " Jamaah"}
            data={totalJamaah?.data?.belum_berangkat}
          />
          <CardJamaah
            img={IcSedangBerangkat}
            title={"Sedang Berangkat"}
            paket={totalJamaah?.data?.sedang_berangkat?.total + " Jamaah"}
            data={totalJamaah?.data?.sedang_berangkat}
          />
          <CardJamaah
            img={IcSudahBerangkat}
            title={"Sudah Berangkat"}
            paket={totalJamaah?.data?.sudah_berangkat?.total + " Jamaah"}
            data={totalJamaah?.data?.sudah_berangkat}
          />
        </section>
        <section className="grid w-full grid-cols-5 gap-x-6">
          <div className="col-span-2">
            <div className="text-[20px] font-semibold my-4">Total Paket</div>
            <div className="grid grid-cols-2 items-center gap-2">
              <CardJamaah
                bg="bg-white"
                styleItem="grid-cols-3"
                styleTitlePaket="text-[#828282] !text-[16px]"
                styleTotalPaket="text-[#141414] font-semibold !text-[16px]"
                styleTitle="text-[#828282] !text-[14px]"
                styleTotal="text-[#141414] font-semibold !text-[14px]"
                img={IcTotalPaket}
                title="Total Paket"
                paket={`${totalPaket?.data?.total_paket} Paket`}
                data={totalPaket?.data?.detail_total_paket}
                customClass="shadow-soft"
                customStyle={{ border: "0.5px solid #F2F2F2" }}
              />
              <CardJamaah
                bg="bg-white"
                styleItem="grid-cols-3"
                styleTitlePaket="text-[#828282] !text-[16px]"
                styleTotalPaket="text-[#141414] font-semibold !text-[16px]"
                styleTitle="text-[#828282] !text-[14px]"
                styleTotal="text-[#141414] font-semibold !text-[14px]"
                img={IcBelumBerangkat}
                title="Belum Berangkat"
                paket={`${totalPaket?.data?.belum_berangkat} Paket`}
                data={totalPaket?.data?.detail_belum_berangkat}
                customClass="shadow-soft"
                customStyle={{ border: "0.5px solid #F2F2F2" }}
              />
              <CardJamaah
                bg="bg-white"
                styleItem="grid-cols-3"
                styleTitlePaket="text-[#828282] !text-[16px]"
                styleTotalPaket="text-[#141414] font-semibold !text-[16px]"
                styleTitle="text-[#828282] !text-[14px]"
                styleTotal="text-[#141414] font-semibold !text-[14px]"
                img={IcSedangBerangkat}
                title="Sedang Berangkat"
                paket={`${totalPaket?.data?.sedang_berangkat} Paket`}
                data={totalPaket?.data?.detail_sedang_berangkat}
                customClass="shadow-soft"
                customStyle={{ border: "0.5px solid #F2F2F2" }}
              />
              <CardJamaah
                bg="bg-white"
                styleItem="grid-cols-3"
                styleTitlePaket="text-[#828282] !text-[16px]"
                styleTotalPaket="text-[#141414] font-semibold !text-[16px]"
                styleTitle="text-[#828282] !text-[14px]"
                styleTotal="text-[#141414] font-semibold !text-[14px]"
                img={IcSudahBerangkat}
                title="Sudah Berangkat"
                paket={`${totalPaket?.data?.sudah_berangkat} Paket`}
                data={totalPaket?.data?.detail_sudah_berangkat}
                customClass="shadow-soft"
                customStyle={{ border: "0.5px solid #F2F2F2" }}
              />
            </div>
            <div className="flex items-center gap-x-2">
              <div className="text-[20px] font-semibold my-4">Member</div>
              <div className="flex items-center text-xs gap-x-1 font-semibold">
                <div className="bg-green-old w-2 h-2 rounded-full" />
                Online ({ memberOnlineCount })
              </div>
            </div>
            <div className="rounded-2xl bg-white shadow-soft p-4 border border-[#e0e0e0]">
              <div className="grid grid-cols-10 items-start gap-x-2 mb-3">
                <div className="col-span-4">
                  <Input
                    size="large"
                    placeholder="Search"
                    prefix={<img src={IcSearch} alt="search" />}
                    onChange={(e) =>
                      setMemberOnlineKeyword(e.target.value)
                    }
                  />
                </div>
                {auth.user.access_data !== 'all_branches' && (
                  <div className="col-span-3"></div>
                )}
                <div className="col-span-3 ml-4">
                  <CustomSelect
                    prefixIconLeft={<FilterOutlined />}
                    defaultValue={memberOnlineRoleFilter}
                    className="w-full"
                    onChange={(value) =>
                      setMemberOnlineRoleFilter(value)
                    }
                    menuItemSelectedIcon={<CheckOutlined />}
                    size="large"
                    options={memberOnlineRoleOptions}
                  />
                </div>
                  {auth.user.access_data === 'all_branches' && (
                    <div className="col-span-3">
                        <CustomSelect
                          prefixIconLeft={<FilterOutlined />}
                          defaultValue="Cabang"
                          className="w-full"
                          onChange={(value) =>
                            setMemberOnlineBranchIDFilter(value)
                          }
                          menuItemSelectedIcon={<CheckOutlined />}
                          size="large"
                          options={cabangs}
                        />
                    </div>
                  )}
              </div>
              <div className={ "mx-[-1rem] h-[375px] overflow-y-auto " + classes.contMemberOnline }>
                <MoslemeTable
                  // bordered
                  columns={columns}
                  data={memberOnline}
                  customClass="tbl-jamaah"
                  withSelection={false}
                  loading={memberOnlineLoading}
                  selectionType=""
                  withPagination={false}
                  customPaginationClass="pr-4"
                  onRowClick={(record, rowIndex) => ({
                    onClick: (e) => {
                      // console.log("RECORD", record);
                      // router.push("/tagihan/detail");
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className="col-span-3 relative">
            <CustomSlider>
              <div className="text-[20px] font-semibold my-4 pl-2">
                Paket Aktif
              </div>
              <Sliders
                customClass="grid grid-cols-2 gap-x-2 !w-full slider-paket-aktif"
                slidesToShow={2}
              >
                {totalPaketAktif.data &&
                  totalPaketAktif.data.length > 0 &&
                  totalPaketAktif?.data?.map((item, idx) => (
                    <PaketAktif
                      key={idx}
                      judul_paket={item?.judul_paket}
                      image={item?.image}
                      tipe_paket={item?.tipe_paket}
                      tanggal_keberangkatan={item?.tanggal_keberangkatan}
                      total_kuota_jamaah={item?.total_kuota_jamaah}
                      seat_terisi={item?.seat_terisi}
                      total_jamaah={item?.total_jamaah}
                      sisa_seat={item?.sisa_seat}
                      total_seat={item?.total_seat}
                      pakcageID={item?.paketid}
                      data={item}
                      handleClickBelumDP={handleTransactionActive}
                      handleClickBelumLunas={handleTransactionActive}
                    />
                  ))}
              </Sliders>
            </CustomSlider>
        </div>
        </section>
        <section className="mt-4 w-full">
          <div className="flex justify-between items-center">
            <div className="text-[20px] font-semibold mb-4">Statistik</div>
            <div className="drop-shadow">
              <CustomSelect
                prefixIconLeft={<FilterOutlined />}
                defaultValue={yearFilter}
                className="w-full"
                onChange={(value) => setYearFilter(value)}
                menuItemSelectedIcon={<CheckOutlined />}
                size="large"
                options={yearOptions}
              />
            </div>
          </div>
        </section>
        <section
          className="grid grid-cols-1 bg-white p-4 rounded-2xl mt-2 !max-w-full"
          style={{ border: "0.5px solid #E0E0E0" }}
        >
          <Chart 
            data={statisticData} 
            categories={statisticCategories} 
            colors={["#DA4646", "#9ED952", "#0082d4"]}
            />
        </section>


        <Popup
          containerClass="!z-[9999]"
          open={openTransactionActivePackage.status}
          handleClose={() => {
            setOpenTransactionActivePackage({
              type: "",
              status: false
            });
          }}
          customPadding="0px"
          title={openTransactionActivePackage.type === "belum_dp" ? "Belum DP" : "Belum Lunas"}
        >
          <Inputs
            id="test"
            placeholder="Cari"
            iconPosition="left"
            icon={IcSearch}
            customClass="!py-3"
            value={keywordDataTransaction}
            onChange={(e) =>
              setKeywordDataTransaction(e.target.value)
            }
          />

          {openTransactionActivePackage.type === "belum_dp" && (
            <>
              <section className="bg-white shadow-soft rounded-2xl p-4 mt-4">
                <>
                  {belumDp?.data?.length > 0 && (
                    <>
                      <div className="flex justify-between items-start ">
                        <div className="flex items-start gap-4">
                          <div>
                            <img
                              src={belumDp?.data[0]?.paket?.image_thumbnail}
                              alt="image"
                              className="w-[80px] h-[80px] object-cover rounded-lg"
                            />
                          </div>
                          <div className="flex flex-col gap-[6px]">
                            <div className="font-semibold">
                              {belumDp?.data[0]?.paket?.judul_paket}
                            </div>
                            <div className="text-sm">
                              {belumDp?.data?.length} Tagihan
                            </div>
                          </div>
                        </div>
                        <div>
                          {moment(belumDp?.data[0]?.paket?.jadwal_keberangkatan).format("DD MMMM YYYY")}
                        </div>
                      </div>
                    </>
                  )}
                  {belumDp?.data?.map((item, idx) => {
                    return (
                      <div key={idx}>
                        <CustomDropdown>
                          <Accordions 
                            title={
                              <div>
                                <div className="font-semibold">
                                  #{item?.order_number}
                                </div>
                                <div className="mt-1">{item?.jml_pax} Pax</div>
                              </div>
                            }
                            customClass="!rounded-2xl shadow-soft !mt-4"
                            defaultExpanded={idx === 0 && true}
                            // defaultExpanded={true}
                            content={
                              <div>
                                {item?.list_jamah?.map((item, idx) => {
                                  return (
                                    <ListJamaah
                                      key={idx}
                                      id={idx + 1}
                                      name={item?.jamaah_name}
                                      kamar_name={item?.kamar_name}
                                      starting={item?.paket_kamar?.nama_starting}
                                    />
                                  );
                                })}
                                <div className="font-semibold border-t mt-[-16px] pt-4">
                                  Rincian Tagihan
                                </div>
                                <div className="flex items-center justify-between text-sm mt-4">
                                  <div>Batas Waktu DP</div>
                                  <div className="font-semibold">
                                    {moment(item?.ordered_at).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </div>
                                </div>
                                <div className="flex items-center justify-between text-sm mt-4">
                                  <div>Jumlah</div>
                                  <div className="font-semibold">
                                    {item?.jml_pax} Pax
                                  </div>
                                </div>
                                <div className="flex items-center justify-between text-sm mt-4">
                                  <div>Total DP</div>
                                  <div className="font-semibold text-[#5C9D0A]">
                                    {IDRFormater(item?.down_payment)}
                                  </div>
                                </div>
                                <section className="grid grid-cols-2 gap-x-4 mt-6 items-center">
                                  <Buttons
                                    text="Follow Up"
                                    customClass="btn-secondary w-full btn-medium"
                                    onClick={() => handleClickFollowup(item.order_number, 'dp')}
                                    loading={sendingFollowup.loading && sendingFollowup.key === 'dp' && sendingFollowup.orderNumber === item.order_number}
                                  />
                                  <Buttons
                                    text="Bayar"
                                    onClick={() => navigate.push(`/tagihan/detail/${item?.order_number}`)}
                                    customClass="btn-primary w-full btn-medium"
                                  />
                                </section>
                              </div>
                            }  
                          />
                        </CustomDropdown>
                      </div>
                    );
                  })}
                </>
              </section>
            </>
          )}

          {openTransactionActivePackage.type === "belum_lunas" && (
            <>
              <section className="bg-white shadow-soft rounded-2xl p-4 mt-4">
                <>
                  {belumLunas?.data?.length > 0 && (
                    <>
                      <div className="flex justify-between items-start ">
                        <div className="flex items-start gap-4">
                          <div>
                            <img
                              src={belumLunas?.data[0]?.paket?.image_thumbnail}
                              alt="image"
                              className="w-[80px] h-[80px] object-cover rounded-lg"
                            />
                          </div>
                          <div className="flex flex-col gap-[6px]">
                            <div className="font-semibold">
                              {belumLunas?.data[0]?.paket?.judul_paket}
                            </div>
                            <div className="text-sm">
                              {belumLunas?.data?.length} Tagihan
                            </div>
                          </div>
                        </div>
                        <div>
                          {moment(belumLunas?.data[0]?.paket?.jadwal_keberangkatan).format("DD MMMM YYYY")}
                        </div>
                      </div>
                    </>
                  )}
                  {belumLunas?.data?.map((item, idx) => {
                    return (
                      <div key={idx}>
                        <CustomDropdown>
                          <Accordions 
                            title={
                              <div>
                                <div className="font-semibold">
                                  #{item?.order_number}
                                </div>
                                <div className="mt-1">{item?.jml_pax} Pax</div>
                              </div>
                            }
                            customClass="!rounded-2xl shadow-soft !mt-4"
                            defaultExpanded={idx === 0 && true}
                            // defaultExpanded={true}
                            content={
                              <div>
                                {item?.list_jamah?.map((item, idx) => {
                                  return (
                                    <ListJamaah
                                      key={idx}
                                      id={idx + 1}
                                      name={item?.jamaah_name}
                                      kamar_name={item?.kamar_name}
                                      starting={item?.paket_kamar?.nama_starting}
                                    />
                                  );
                                })}
                                <div className="font-semibold border-t mt-[-16px] pt-4">
                                  Rincian Tagihan
                                </div>
                                <div className="flex items-center justify-between text-sm mt-4">
                                  <div>Batas Waktu DP</div>
                                  <div className="font-semibold">
                                    {moment(item?.ordered_at).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </div>
                                </div>
                                <div className="flex items-center justify-between text-sm mt-4">
                                  <div>Jumlah</div>
                                  <div className="font-semibold">
                                    {item?.jml_pax} Pax
                                  </div>
                                </div>
                                <div className="flex items-center justify-between text-sm mt-4">
                                  <div>Kekurangan Tagihan</div>
                                  <div className="font-semibold">
                                    {IDRFormater(item?.remaining_payment_amount)}
                                  </div>
                                </div>
                                <div className="flex items-center justify-between text-sm mt-4">
                                  <div>Total Tagihan</div>
                                  <div className="font-semibold text-[#5C9D0A]">
                                    {IDRFormater(item?.total_price)}
                                  </div>
                                </div>
                                <section className="grid grid-cols-2 gap-x-4 mt-6 items-center">
                                  <Buttons
                                    text="Follow Up"
                                    customClass="btn-secondary w-full btn-medium"
                                    onClick={() => handleClickFollowup(item.order_number, 'payment')}
                                    loading={sendingFollowup.loading && sendingFollowup.key === 'payment' && sendingFollowup.orderNumber === item.order_number}
                                  />
                                  <Buttons
                                    text="Bayar"
                                    onClick={() => navigate.push(`/tagihan/detail/${item?.order_number}`)}
                                    customClass="btn-primary w-full btn-medium"
                                  />
                                </section>
                              </div>
                            }  
                          />
                        </CustomDropdown>
                      </div>
                    );
                  })}
                </>
              </section>
            </>
          )}

        </Popup>

        <Popup
          containerClass="!z-[9999]"
          open={
            byDetail?.status === "perlengkapan" ||
            byDetail?.detailPerlengkapan ||
            byDetail?.status === "kelengkapan" ||
            byDetail?.detailKelengkapanData
          }
          type={
            byDetail?.detailPerlengkapan || byDetail?.detailKelengkapanData
              ? "secondary"
              : "primary"
          }
          handleClose={handleCloseJamaah}
          title={
            byDetail?.status === "perlengkapan" || byDetail.detailPerlengkapan
              ? "Perlengkapan"
              : "Kelengkapan Data"
          }
        >
          {byDetail?.status === "perlengkapan" ||
          byDetail?.status === "kelengkapan" ? (
            <div>
              <Inputs
                placeholder="Search member"
                iconPosition="left"
                icon={IcSearch}
                customClass="!py-3"
                value={searchModal.perlengkapan}
                onChange={(e) =>
                  setSearchModal({
                    ...searchModal,
                    perlengkapan: e.target.value,
                  })
                }
              />
              <div className="h-[550px] overflow-auto scrollbar-thin scrollbar-track-transparent pb-4">
                {byDetail?.data?.total_data !== 0 &&
                  filterPerlengkapan?.map((data, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`p-4 drop-shadow bg-white rounded-2xl mt-4 ${
                          byDetail?.status === "perlengkapan" && "pb-0"
                        }`}
                      >
                        <CardProgress
                          className={"drop-shadow-none"}
                          icon={ImAvatar}
                          title={`${data?.jamaah_title} ${data?.jamaah_name}`}
                          percent={
                            byDetail?.status === "perlengkapan"
                              ? data?.jml_perlengkapan_jamaah_sudah_lengkap_persen
                              : data?.kelengkapan_data_terisi_persen
                          }
                          info={
                            byDetail?.status === "perlengkapan"
                              ? data?.jml_perlengkapan_jamaah_sudah_lengkap
                              : data?.kelengkapan_data_terisi
                          }
                          kode={`#${data?.order_number}`}
                          onPress={() => onDetailModal(data)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <main className="mt-4">
              <section className="flex justify-between items-center mb-4">
                <div>
                  <div>#{byDetail?.detail?.data?.order_number}</div>
                  <div>
                    {moment(byDetail?.detail?.data?.order_date).format(
                      "DD MMMM YYYY"
                    )}
                  </div>
                </div>
                <Badge
                  label={byDetail?.detail?.data?.tipe_paket}
                  backgroundColor="#EAF7E6"
                  color="#01F91A"
                />
              </section>
              <Buttons
                text="Follow Up"
                customClass="btn-secondary w-full btn-medium"
                onClick={() =>
                  byDetail?.status === "perlengkapan"
                    ? dispatch(detailById({ detailPerlengkapan: true }))
                    : dispatch(detailById({ detailKelengkapanData: true }))
                }
              />
              <div className="text-xl font-semibold mt-4">
                {byDetail?.detail?.data?.name_jamaah}
              </div>
              <div className="h-[400px] overflow-auto scrollbar-thin scrollbar-track-transparent pb-4">
                {byDetail?.detailPerlengkapan
                  ? byDetail?.detail?.data?.perlengkapan.map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          className="flex justify-between items-center bg-white drop-shadow rounded-2xl p-4 mt-4"
                        >
                          <div className="flex gap-x-4 items-center justify-start">
                            <img
                              src={item.thumbnail}
                              alt="search"
                              height={80}
                              width={80}
                              className="rounded-xl"
                            />
                            <div>
                              <div
                                className={`${
                                  item.status !== "0" && "text-green-400"
                                } flex items-center gap-x-2`}
                              >
                                {idx + 1}.
                                <span className="font-semibold">
                                  {item.name}
                                </span>
                                {item.status !== "0" && (
                                  <img src={IcCeklisBlue} alt="ceklis" />
                                )}
                              </div>
                              <div className="mt-2">
                                {/* {item.status !== "0" ? item.description : "-"} */}
                                {item.description ? item.description : "-"}
                              </div>
                            </div>
                          </div>
                          <div>
                            <img
                              alt="left arrow"
                              src={IcArrowRsM}
                              className="object-cover"
                              // onClick={onPress}
                            />
                          </div>
                        </div>
                      );
                    })
                  : byDetail?.detail?.data?.perlengkapan.map((item, idx) => {
                      return (
                        <div
                          className="flex items-center justify-between mt-4 bg-white drop-shadow rounded-2xl p-4"
                          key={idx}
                        >
                          <div
                            className={`${
                              item.status !== "0" && "text-green-400"
                            } flex items-center gap-x-2 font-semibold`}
                          >
                            {item.name}
                            {item.status !== "0" && (
                              <img src={IcCeklisBlue} alt="ceklis" />
                            )}
                          </div>
                          <div>
                            <img
                              alt="left arrow"
                              src={IcArrowRsM}
                              className="object-cover cursor-pointer"
                              onClick={() => navigate.push("/booking/detail")}
                            />
                          </div>
                        </div>
                      );
                    })}
              </div>
            </main>
          )}
        </Popup>
      </Grid>
    </Card>
  );
};

export default Dashboard;
