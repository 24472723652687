import Buttons from "components/buttons"
import InputsCurrency from "components/form/inputsCurrency"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import OperationalOtherForms from "../../components/OperationalOtherForms"

import * as Yup from "yup";

const OperationalSaudi = ({
  data,
  onChange,
  isLoadingSave,
  savingCategory
}) => {
  
  const [saved, setSaved] = useState(false)

  const formik = useFormik({
    initialValues: {
      handling_saudi: 0,
      handling_saudi_fee: 0,
      zamzam_water: 0,
      others: [
        {
            "item_id": 1,
            "range_pax": '1-15',
            "price": 0
        },
        {
          "item_id": 2,
          "range_pax": '1-15',
          "price": 0
        },
        {
            "item_id": 3,
            "range_pax": '1-15',
            "price": 0
        }
      ]
    },
    validationSchema: Yup.object().shape({
      handling_saudi: Yup.number().required("Harga handling saudi wajib diisi").min(0),
      handling_saudi_fee: Yup.number().required("Harga handling saudi fee wajib diisi").min(0),
      zamzam_water: Yup.number().required("Harga Air Zam-zam wajib diisi").min(0),
      others: Yup.array().of(Yup.object().shape({
        item_id: Yup.number().required("Item wajib diisi"),
        range_pax: Yup.string().required("Jumlah Pax wajib diisi"),
        price: Yup.number().required("Harga wajib diisi").min(0),
      }))
    })
  })

  const handleUpdateData = (key, value) => {
    setSaved(false)
    formik.setFieldValue(key, value)
  }

  const handleChangeOperationalOthers = (itemId, newData) => {
    setSaved(false)
    let _newTempData = newData.map(item => {
      if (!('item_id' in item)) {
        item.item_id = itemId;
      }
      return item;
    });

    let _tempData = formik.values.others
    _tempData = _tempData.filter(item => item.item_id !== itemId)
    _tempData = _tempData.concat(_newTempData)

    formik.setFieldValue('others', _tempData)
  }

  const handleSave = () => {
    setSaved(true)
    onChange(formik.values)
  }

  useEffect(() => {
    formik.setValues({ ...formik.values, ...data })
  }, [data])
  

  return (
    <>
      <div className="flex justify-end items-center">
        <Buttons
          text="Simpan"
          customClass="btn-primary btn-small"
          disabled={!(formik.isValid && formik.dirty) || saved}
          onClick={handleSave}
          loading={(isLoadingSave) && (savingCategory == "saudies")}
          />
      </div>
      
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 w-full">
          <div className="w-1/2">
            <InputsCurrency
                label="Handling Saudi"
                value={formik.values.handling_saudi}
                placeholder="Masukan harga"
                onChange={(val) => handleUpdateData('handling_saudi', val)}
              />
          </div>
          <div className="w-1/2">
            <InputsCurrency
                label="Fee Handling Saudi"
                value={formik.values.handling_saudi_fee}
                placeholder="Masukan harga"
                onChange={(val) => handleUpdateData('handling_saudi_fee', val)}
              />
          </div>
        </div>
        <div className="w-full">
          <InputsCurrency
              label="Air Zam-zam"
              value={formik.values.zamzam_water}
              placeholder="Masukan harga"
              onChange={(val) => handleUpdateData('zamzam_water', val)}
            />
        </div>
        <div className="w-full flex flex-col gap-4">
          <OperationalOtherForms 
            label="Jasa Guide"
            data={formik?.values?.others?.filter(item => item.item_id == 1)}
            onChange={(newData) => handleChangeOperationalOthers(1, newData)}
            />
          <OperationalOtherForms 
            label="Transportasi Bus"
            data={formik?.values?.others?.filter(item => item.item_id == 2)}
            onChange={(newData) => handleChangeOperationalOthers(2, newData)}
            />
          <OperationalOtherForms 
            label="Transportasi Kereta Cepat"
            data={formik?.values?.others?.filter(item => item.item_id == 3)}
            onChange={(newData) => handleChangeOperationalOthers(3, newData)}
            />
        </div>
      </div>

    </>
  )

}

export default OperationalSaudi