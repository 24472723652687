// itinenary Actions
// --------------------------------------------------------

import { setValidation, set_after_validation } from "stores/actions/general";
import {
  createOrUpdateItinenaryApi,
  deleteGaleriItenaryApi,
  deleteIdItinenaryApi,
  detailItinenaryApi,
  duplicateItenaryApi,
  getListItinenaryApi,
} from "../../../../utils/api/paket";
import { setDeleteIdCabang } from "../../cabang";
import { handleError, handleErrorBE, handleSuccess } from "../../errorGeneral";
import { paketItenaryV2 } from "utils/api/paket/itenary";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "itinenary/SET_LOADING";
export const CLEAR_ERROR = "itinenary/CLEAR_ERROR";
export const SET_ERROR = "itinenary/SET_ERROR";
export const INIT_DATA = "itinenary/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "itinenary/SET_DOUBLE_SUBMIT";
export const SET_LIST_ITINENARY = "itinenary/SET_LIST_ITINENARY";
export const SET_DETAIL_ITINENARY = "itinenary/SET_DETAIL_ITINENARY";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setListItinenary = (payload) => ({
  type: SET_LIST_ITINENARY,
  payload,
});

export const setDetailItinenary = (payload) => ({
  type: SET_DETAIL_ITINENARY,
  payload,
});

export const getListItinenary = (id, payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await paketItenaryV2.getListItenaryV2(id, payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setListItinenary(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDetailItenary =
  (
    id_paket,
    id_itenary,
    actionSuccess = (message) => {},
    actionError = (error) => {},
    running = true
  ) =>
  async (dispatch) => {
    try {
      running && dispatch(setLoading(true));
      const res = await paketItenaryV2.getDetailItenaryV2(id_paket, id_itenary);
      running && dispatch(setLoading(false));
      if (res.status === 200) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteItenaryServiceV2 =
  (
    id_paket,
    payload,
    actionSuccess = (message) => {},
    actionError = (error) => {}
  ) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await paketItenaryV2.deleteItenaryV2(id_paket, payload);
      dispatch(setLoading(false));
      if (res.status === 200) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const insertItenaryServiceV2 =
  (
    id_paket,
    payload,
    actionSuccess = (message) => {},
    actionError = (error) => {}
  ) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await paketItenaryV2.insertItenaryV2(id_paket, payload);
      dispatch(setLoading(false));
      if (res.status === 200 || res.status === 201) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getListStartingItenaryServiceV2 =
  (
    id_paket,
    actionSuccess = (message) => {},
    actionError = (error) => {},
    runingLoading = true
  ) =>
  async (dispatch) => {
    try {
      runingLoading && dispatch(setLoading(true));
      const res = await paketItenaryV2.getAllStartingPaketV2(id_paket);
      runingLoading && dispatch(setLoading(false));
      if (res.status === 200 || res.status === 201) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const duplicatedItenaryServiceV2 =
  (
    id_paket,
    payload,
    actionSuccess = (message) => {},
    actionError = (error) => {}
  ) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await paketItenaryV2.duplicatedItenaryV2(id_paket, payload);
      dispatch(setLoading(false));
      if (res.status === 200 || res.status === 201) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteItinenary = (id, payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await deleteIdItinenaryApi(id, payload);
    dispatch(setLoading(false));

    if (res.status === 200) {
      dispatch(handleSuccess(res.data));

      dispatch(setDeleteIdCabang(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const deleteGaleryItenary = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await deleteGaleriItenaryApi(id);
    dispatch(setLoading(false));

    if (res.status === 200) {
      dispatch(handleSuccess(res.data));
      // dispatch(setDeleteIdCabang(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const createOrUpdateItenary = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await createOrUpdateItinenaryApi(payload);

    dispatch(setLoading(false));
    if (res.status === 200) {
      // dispatch(setListItinenary(res.data));
      dispatch(set_after_validation(res.data));
      dispatch(handleSuccess(res.data));
    } else if (res.status === 400) {
      dispatch(setValidation(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicateItenary =
  (id_itinerarie, id_starting) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await duplicateItenaryApi({
        id_itinerarie: id_itinerarie,
        id_starting: id_starting,
      });
      dispatch(setLoading(false));
      if (res.status === 200) {
        dispatch(set_after_validation(res.data));
        dispatch(handleSuccess(res.data));
      } else if (res.status === 400) {
        dispatch(setValidation(res.data));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const detailItinenary = (id, payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await detailItinenaryApi(id, payload);
    dispatch(setLoading(false));

    if (res.status === 200) {
      // dispatch(handleSuccess(res.data));

      dispatch(setDetailItinenary(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};
