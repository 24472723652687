import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Popup from "../../../popup";
import Inputs from "../../../form/inputs";
import Buttons from "../../../buttons";
import { makeStyles } from "@material-ui/core";

import IconChecklist from "../../../../assets/icons/ic_checklist.svg";
import ScrollCustom from "../../../scroll";
import InputPhone from "components/form/inputPhone";

import { useSelector, useDispatch } from "react-redux"
import { useFormik } from "formik";

import { handleUpdateContact } from "stores/actions/profile";

import * as Yup from "yup";

const useStyles = makeStyles({
  card_popup_profile: {
    width: "100%",
    height: "100%",
    padding: "16px",
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "rgba(64, 72, 82, 0.1)",
    borderRadius: "16px 16px 0px 0px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& .card_inside": {
      width: "100%",
      "& .container_button_outline": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        marginTop: "10px",
      },
      "& .container_column": {
        display: "flex",
        flexDirection: "column",
        "& .title": {
          // fontFamily: "GeneralSans",
          fontSize: "14px",
          fontWeight: 600,
          color: "#000",
        },
        "& .subtitle": {
          fontSize: "14px",
          // fontFamily: "GenaralSans",
          color: "#4F4F4F",
        },
        "& .row_container": {
          marginTop: "16px",
          marginBottom: "8px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "& .icon": {
            marginRight: "8px",
          },
          "& .title": {
            fontFamily: "GeneralSansSemiBold",
            fontSize: "16px",
            color: "#000",
          },
        },
      },
    },
  },
  container_button: {
    // marginTop: "75px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px",
    gap: "8px",
    borderWidth: "0px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "rgba(64, 72, 82, 0.1)",
    borderRadius: "0px 0px 16px 16px",
  },
});

const PerbaruiKontak = ({
  openProfileKontak,
  handleClosePopupProfileKontak,
  dataProfileKontak,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles();
  
  const auth = useSelector(({ auth }) => auth)
  const { isLoading } = useSelector(({ profile }) => profile);

  const formik = useFormik({
    initialValues: {
      'email' : '',
      'phone_number': '',
    },
    validationSchema: Yup.object().shape({
      'email': Yup.string().max(150, "Maksimal 150 karakter").required("Email harus diisi").email("Email tidak valid"),
      'phone_number': Yup.string().max(20, "Maksimal 20 karakter").required("Nomor Telepon harus diisi")
    })
  })

  useEffect(() => {
    formik.setFieldValue('email', auth?.user?.email)
    formik.setFieldValue('phone_number', auth?.user?.phone_number)
  }, [auth, openProfileKontak])

  const handleSave= () => {
    dispatch(handleUpdateContact(formik.values, (errData) => {
      const errors = errData?.data?.data

      Object.keys(errors).forEach((field) => {
        formik.setFieldError(field, errors[field][0]);
      });
    }))
  }

  return (
    <Popup
      type="secondary"
      width="1000px"
      height="467px"
      open={openProfileKontak}
      handleClose={handleClosePopupProfileKontak}
      title="Perbarui Kontak"
    >
      <div className={classes.card_popup_profile}>
        {/* <ScrollCustom height={200}> */}
          <div className="card_inside">
            <div className="container_column">
              <div className="title">Ganti Email</div>
              {/* <div className="row_container">
                <div className="icon">
                  <img src={IconChecklist} alt="checklist" />
                </div>
                <div className="title">{auth?.user?.email}</div>
              </div>
              <div className="subtitle">Alamat email telah diverifikasi</div> */}
            </div>
            <Inputs
              id="email"
              name="email"
              placeholder="Masukan masukan email"
              customClass="mt-2"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              defaultValue={formik.values.email}
              error={
                formik.touched.email && formik.errors.email
              }
            />
          </div>
          <div className="card_inside">
            <div className="container_column">
              <div className="title">Ganti Nomor Telepon</div>
              {/* <div className="row_container">
                <div className="icon">
                  <img src={IconChecklist} alt="checklist" />
                </div>
                <div className="title">{auth?.user?.phone_number}</div>
              </div>
              <div className="subtitle">Nomor telepon telah diverifikasi</div> */}
            </div>
            <InputPhone 
              customClass="mt-2"
              name="phone_number"
              value={auth?.user?.phone_number}
              onBlur={formik.handleBlur}
              onChange={(val) => {
                formik.setFieldValue('phone_number', val)
                formik.setFieldTouched('phone_number', 1, 1)
              }}
              enableSearch
              error={formik.touched.phone_number && formik.errors.phone_number}
              />
          </div>
      </div>
      <div className={classes.container_button}>
        <Buttons onClick={handleClosePopupProfileKontak} text="Kembali" customClass="btn-primary btn-outline btn-large" />
        <Buttons 
          onClick={handleSave} 
          text="Simpan" 
          customClass="btn-primary btn-large" 
          loading={isLoading}
          disabled={!(formik.isValid && formik.dirty)}
          />
      </div>
    </Popup>
  );
};

PerbaruiKontak.propTypes = {
  dataProfileKontak: PropTypes.object.isRequired,
  openProfileKontak: PropTypes.bool.isRequired,
  handleClosePopupProfileKontak: PropTypes.func.isRequired,
};
PerbaruiKontak.defaultProps = {
  dataProfileKontak: {},
  openProfileKontak: false,
  handleClosePopupProfileKontak: () => {},
};

export default React.memo(PerbaruiKontak);
