import React, { useCallback, useState } from "react";

import FeeStyle from "./Fee.Style";
import { feeMenuList } from "configuration/feeMenuList";
import Inputs from "components/form/inputs";
import FeePenarikan from "./components/Penarikan";
import FeePendapatan from "./components/Pendapatan";

import IconSearch from "assets/icons/search-normal.svg";

const FeePage = () => {
  const [activeTab, setActiveTab] = useState("penarikan");

  const getSidebar = useCallback(() => {
    return feeMenuList.map((val, idx) => {
      return (
        <div
          key={idx}
          className={`flex flex-row justify-between gap-4 items-center cursor-pointer ${
            idx !== 0 && "mt-4"
          }`}
          onClick={() => setActiveTab(val.key)}
        >
          <div className="flex flex-row items-center gap-2">
            {activeTab === val.key && (
              <img src={val.iconActive} alt="sidebar-icon" />
            )}
            {activeTab !== val.key && (
              <img src={val.icon} alt="sidebar-icon" />
            )}
            <div
              className={`sidebar-child-title ${
                activeTab === val.key && "sidebar-tab-active"
              }`}
            >
              {val.title}
            </div>
          </div>
          {/* <div className="flex justify-center items-center w-[16px] h-[16px] rounded-full gold-dark-bg">
            <div className="text-[6px] font-semibold">99+</div>
          </div> */}
        </div>
      );
    });
  }, [activeTab]);

  const getContent = useCallback(() => {
    switch (activeTab) {
      case "penarikan":
        return <FeePenarikan />;
      case "pendapatan":
        return <FeePendapatan />;
      default:
        return <FeePenarikan />;
    }
  }, [activeTab]);

  return (
    <FeeStyle className="bg-white soft-shadow rounded-[20px]">
      <div className="px-4 py-4">
        <div className="text-[20px] font-semibold">
          Managemen Fee
        </div>
      </div>
      <div className="divider"></div>
      <div className="flex flex-row">
        <div className="p-4 border-r border-r-[#E0E0E0] h-screen">
          {getSidebar()}
        </div>
        <div className="ml-4 border-l border-l-[#E0E0E0] w-full">
          {getContent()}
        </div>
      </div>
    </FeeStyle>
  );
};

export default FeePage;
