import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import uuid from "react-uuid";

import AppsStaffAction from "stores/actions/apps/staff";
import { resetDataUpload, setDataUpload } from "stores/actions/uploadFile";
import { getLocalStorage } from "utils/helpers";

import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import ImageField from "components/image_field";
import Textareas from "components/form/textarea";
import { handleErrorCustom, handleSuccess } from "stores/actions/errorGeneral";
import Upload from "pages/apps/components/Galeri/components/AddGaleri/components/Upload";
import UploadArea from "components/Uploadarea";

const AppsAddStaff = ({ setOpenPopup, edit, cabang }) => {
  const dispatch = useDispatch();
  const [userLogin, setUserLogin] = useState(
    getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"))
  );
  const [staffPhoto, setStaffPhoto] = useState({
    value: `${edit ? edit?.image : ""}`,
    error: false,
  });
  const [options, setOptions] = useState(cabang);
  const [selectedCabang, setSelectedCabang] = useState(
    edit && {
      id: edit.cabang_id,
      label: edit.cabang_name,
      value: edit.cabang_id,
    }
  );

  const formikAddOrEditStaff = useFormik({
    initialValues: {
      name: `${edit ? edit?.nama : ""}`,
      jabatan: `${edit ? edit?.jabatan : ""}`,
      cabang: `${edit ? edit?.cabang_id : ""}`,
      deskripsi: `${edit ? edit?.deskripsi : ""}`,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama Staff Wajib diisi."),
      jabatan: Yup.string().required("Jabatan Wajib diisi."),
      deskripsi: Yup.string().required("Deskripsi Wajib diisi."),
    }),
    onSubmit: async (values, actions) => {
      if (staffPhoto.value === "" || staffPhoto.value === undefined) {
        setStaffPhoto({
          value: staffPhoto.value,
          error:
            staffPhoto.value === "" || staffPhoto.value === undefined
              ? true
              : false,
        });
      } else {
        if (edit) {
          let form;
          if (
            userLogin?.data?.role_id === 1 ||
            userLogin?.data?.role_id === 2 ||
            userLogin?.data?.role_id === 3
          ) {
            form = {
              id: edit.id,
              cabang_id: values.cabang,
              nama: values.name,
              jabatan: values.jabatan,
              image: staffPhoto.value,
              deskripsi: values.deskripsi,
              edit: true,
            };
          } else {
            form = {
              id: edit.id,
              nama: values.name,
              jabatan: values.jabatan,
              image: staffPhoto.value,
              deskripsi: values.deskripsi,
              edit: true,
              cabang_id: values.cabang
            };
          }
          const status = await dispatch(AppsStaffAction.postData(form));
          if (status === 200) {
            setOpenPopup({
              show: false,
              edit: null,
            });
            // actions.resetForm();
            // dispatch(resetDataUpload());
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate staff", code: 200 }));
          } else {
            dispatch(handleErrorCustom("Gagal mengupdate staff"));
          }
        } else {
          if (Object.keys(values)?.length !== 0) {
            let form;
            if (
              userLogin?.data?.role_id === 1 ||
              userLogin?.data?.role_id === 2 ||
              userLogin?.data?.role_id === 3
            ) {
              form = {
                nama: values.name,
                jabatan: values.jabatan,
                image: staffPhoto.value,
                deskripsi: values.deskripsi,
                cabang_id: values.cabang
              };
            } else {
              form = {
                nama: values.name,
                jabatan: values.jabatan,
                image: staffPhoto.value,
                deskripsi: values.deskripsi,
                cabang_id: values.cabang
              };
            }
            const status = await dispatch(AppsStaffAction.postData(form));
            if (status === 200) {
              setOpenPopup({
                show: false,
                edit: null,
              });
              // actions.resetForm();
              // dispatch(resetDataUpload());
              dispatch(handleSuccess(null, { message: "Berhasil menambahkan staff", code: 200 }));
            } else {
              dispatch(handleErrorCustom("Gagal menambahkan staff"));
            }
          } else {
            actions.setSubmitting(false);
            setStaffPhoto({
              value: "",
              error: true,
            });
          }
        }
      }
    },
  });

  useEffect(() => {
    if (edit && edit?.image) {
      dispatch(
        setDataUpload([
          {
            id: uuid(), // di import dari library 'uuid'
            fileName: "Staff Photo",
            path: edit?.image,
            name: "staff_photo", // harus unique
          },
        ])
      );
    } else {
      // dispatch(resetDataUpload());
    }
  }, [edit]);

  return (
    <>
      <div className="bg-white border-[1px] border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <Inputs
            id="name"
            label="Nama Staff"
            placeholder="Masukkan Nama Staff"
            containerClass="mb-4"
            value={formikAddOrEditStaff.values.name}
            error={formikAddOrEditStaff.errors.name}
            customClass={formikAddOrEditStaff.errors.name ? "input-error" : ""}
            onChange={formikAddOrEditStaff.handleChange}
          />
          <div className="grid grid-cols-2 gap-4">
            <Inputs
              id="jabatan"
              label="Jabatan"
              placeholder="Masukkan Jabatan"
              value={formikAddOrEditStaff.values.jabatan}
              error={formikAddOrEditStaff.errors.jabatan}
              customClass={
                formikAddOrEditStaff.errors.jabatan ? "input-error" : ""
              }
              onChange={formikAddOrEditStaff.handleChange}
            />
            <Selects
              id="cabang"
              label="Cabang"
              placeholder="Pilih Cabang"
              options={options}
              values={
                edit ? options.filter((item) => item.value === edit.cabang_id) : []
              }
              error={formikAddOrEditStaff.errors.cabang}
              customClass={
                formikAddOrEditStaff.errors.cabang ? "select-error" : ""
              }
              onChange={(e) => {
                if (e?.length !== 0) {
                  // console.log(e[0]);
                  formikAddOrEditStaff.setFieldValue("cabang", e[0].value);
                  setSelectedCabang(e[0]);
                }
              }}
              itemRenderer={({ state, item, methods }) => {
                return (
                  <div
                    className={`react-dropdown-select-item ${
                      state.values?.length !== 0 &&
                      state.values[0].value === item.value &&
                      "react-dropdown-select-item-selected"
                    }`}
                    onClick={() => methods.addItem(item)}
                  >
                    {item.label}
                  </div>
                );
              }}
            />
            <div>
              {/* <ImageField
                name="staff_photo"
                label="Foto Staff"
                customContainer="h-[300px]"
                error={staffPhoto.error}
                errorMesssage={
                  staffPhoto.error ? "Foto Staff Wajib dipilih" : ""
                }
                onRemove={() =>
                  setStaffPhoto({
                    value: "",
                    error: false,
                  })
                }
                setFile={(file) =>
                  setStaffPhoto({
                    value: file?.path,
                    error: false,
                  })
                }
              /> */}
              <UploadArea
                label="Foto Staff"
                id="staff_photo"
                name="staff_photo"
                defaultUrl={staffPhoto.value}
                onChange={(e) => setStaffPhoto({ value: e, error: false })}
                handleRemove={() => setStaffPhoto({ value: "", error: false })}
                customClass="!h-[300px] !w-[300px]"
                />
            </div>
            <Textareas
              id="deskripsi"
              label="Deskripsi"
              placeholder="Masukan Deskripsi"
              rows={12}
              value={formikAddOrEditStaff.values.deskripsi}
              error={formikAddOrEditStaff.errors.deskripsi}
              customClass={
                formikAddOrEditStaff.errors.deskripsi ? "textarea-error" : ""
              }
              onChange={formikAddOrEditStaff.handleChange}
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end gap-2 p-4">
          <Buttons
            text="Kembali"
            customClass="btn-outline btn-medium !w-fit"
            onClick={() =>
              setOpenPopup({
                show: false,
                edit: null,
              })
            }
          />
          <Buttons
            text={`${edit ? "Edit" : "Tambah"}`}
            loading={formikAddOrEditStaff.isSubmitting}
            customClass={`btn-primary btn-medium !w-fit ${
              formikAddOrEditStaff.isSubmitting && "loading"
            }`}
            onClick={() => {
              setStaffPhoto({
                value: staffPhoto.value,
                error:
                  staffPhoto.value === "" || staffPhoto.value === undefined
                    ? true
                    : false,
              });
              formikAddOrEditStaff.handleSubmit();
            }}
          />
        </div>
      </div>
    </>
  );
};

AppsAddStaff.propTypes = {
  setOpenPopup: PropTypes.func,
  edit: PropTypes.object,
  cabang: PropTypes.array,
};

export default React.memo(AppsAddStaff);
