import axios from "axios";
import { BASE_URL_ENDPOINT } from "../../../constant/constanta";

export const uploadService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/upload-image`, payload);

export const uploadFileAPI = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/files/upload`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
