import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'


import Progressbars from "../../../../components/progressbars";

import IconChecklistCircle from "assets/icons/ChecklistCircle.svg";
import IconCheklistCircleChecked from "assets/icons/Checklist_Round.svg";
import IconCheclistSquare from "assets/icons/ChecklistSquare.svg";
import IconCheclistSquareChecked from "assets/icons/ChecklistSquareChecked.svg";

import { handleUpdateEquipmentsAPI } from "utils/api/booking"
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

const JamaahEquipment = ({ equipments, jamaahData, handleUpdateSuccess }) => {
  const params = useParams()
  const dispatch = useDispatch()

  const { bookingDetail } = useSelector((state) => state.booking);

  const [equipmentsData, setEquipmentsData] = useState([]);

  const handleChecklist = (data) => {

    if(bookingDetail?.payment_status === 4) return

    const indexSelectedEquipment = equipmentsData.findIndex(item => item.id === data.equipment_id);
    let payload = {
      equipment_id: data.equipment_id,
    }
    let newData = [...equipmentsData];

    const equipmentStatus = !newData[indexSelectedEquipment].status;
    payload = {
      ...payload,
      equipment_status: equipmentStatus
    }

    // jika yng diklik variant 
    if(data.variant_id) {
  
      newData[indexSelectedEquipment].status = true;

      payload = {
        ...payload,
        equipment_status: true,
        variant_id: data.variant_id
      }
      
      // menghapus semua checklis dulu
      newData[indexSelectedEquipment].variants.forEach(variant => {
        variant.status = false;
      })

      // cari variant yang diklik
      const indexSelectedVariant = newData[indexSelectedEquipment].variants.findIndex(item => item.id === data.variant_id);

      newData[indexSelectedEquipment].variants[indexSelectedVariant].status = true;


    } else {
      newData[indexSelectedEquipment].status = equipmentStatus;

      // jika equipment memiliki variant dan checklist perlatan false semua variantnya harus false
      if(!equipmentStatus) {
        newData[indexSelectedEquipment].variants.forEach(variant => {
          variant.status = false;
        })
      }

      // jika equipment memiliki variant dan checklist perlatan true varient pertama harus true
      if(equipmentStatus) {
        if(newData[indexSelectedEquipment].variants.length > 0) {
          newData[indexSelectedEquipment].variants[0].status = true;
          
          payload = {
            ...payload,
            variant_id: newData[indexSelectedEquipment].variants[0].id
          }

        }
      }
      

    }
    // console.log(payload);
    handleUpdateEquipment(payload)
    setEquipmentsData(newData);
  }

  const handleUpdateEquipment = async (payload) => {
    const response = await handleUpdateEquipmentsAPI({ 
      orderNumber: bookingDetail.order_number,
      jamaahOrderID: jamaahData.id,
      payload
    })


    const { status, data } = response

    if(status === 200) {
      dispatch(handleSuccess(response))
      handleUpdateSuccess()
    } else {
      dispatch(handleError(data))
    }

  }

  useEffect(() => {
    setEquipmentsData(equipments.equipments)
  }, [equipments])
  

  return (
    <div className="mt-4">
      <div>
        <div className="text-[16px] font-semibold">Checklist Perlengkapan</div>
        <Progressbars 
          percent={equipments.equipment_ready_percentage} 
          customInfoClass="absolute !top-[-24px] !right-0" 
          info={`${equipments?.equipment_ready}/${equipments?.equipment_total}`} />
      </div>

      <div className="mt-4">
        <Grid container spacing={2}>
          {!!equipmentsData && equipmentsData.map((equipment, index) => (
            <Grid item md={6}>
              <div className="p-4 rounded-2xl bg-white shadow-soft flex justify-between">
                <div className="flex items-center gap-4">
                  <img 
                    src={equipment?.thumbnail}
                    className="w-[56px] h-[56px] object-cover rounded-2xl"
                    />
                  <div className="text-[16px] font-semibold flex flex-col justify-between gap-2">
                    <div>
                      {equipment?.name}
                    </div>
                    <div className="flex gap-2 text-xs">
                      {!!equipment?.variants.length && equipment?.variants.map((variant) => (
                        <>
                          <div 
                            onClick={() => handleChecklist({ equipment_id: equipment.id, variant_id: variant.id })}
                            className={`border rounded-lg px-2 py-[6px] flex gap-2 items-center ${bookingDetail?.payment_status === 4 ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                            {!variant.status && (
                              <img
                                src={IconChecklistCircle}
                                />
                            )}
                            {!!variant.status && (
                              <img
                                className="w-4 h-4"
                                src={IconCheklistCircleChecked}
                                />
                            )}
                            <span>{variant.name}</span>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div 
                    className={`${bookingDetail?.payment_status === 4 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                    onClick={() => handleChecklist({ equipment_id: equipment?.id, equipment_status: !equipment?.status })}
                    >
                    {!equipment?.status && (
                      <img
                        src={IconCheclistSquare}
                        />
                    )}
                    {!!equipment?.status && (
                      <img 
                        src={IconCheclistSquareChecked}
                        />
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>

    </div>
  );
};

export default JamaahEquipment;
