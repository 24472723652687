import React, { useState } from "react";
import * as Yup from "yup";
import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import ImageField from "components/image_field";
import Textareas from "components/form/textarea";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import GuideAction from "stores/actions/apps/guide";
import { detailById } from "stores/actions/general";
import { resetDataUpload } from "stores/actions/uploadFile";
import UploadArea from "components/Uploadarea";

const AppsAddGuide = () => {
  const [options, setOptions] = useState([
    {
      value: "pembimbing",
      label: "Pembimbing",
    },
    {
      value: "guide",
      label: "Guide",
    },
  ]);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.guide);
  const { byDetail } = useSelector((state) => state?.general);
  const reload = () => {
    guide.resetForm();
    dispatch(detailById({}));
    dispatch(resetDataUpload());
    dispatch(
      GuideAction.list({
        per_page: 10,
        page_number: 1,
        sort_column: "id_mentor",
        sort_order: "Desc",
        keyword: "",
      })
    );
  };

  const guide = useFormik({
    initialValues: {
      name: byDetail?.data?.name || "",
      type: byDetail?.data?.type || "",
      image_photo: byDetail?.data?.photo || "",
      description: byDetail?.data?.description || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama barang wajib diisi"),
      type: Yup.string().required("Type wajib diisi"),
      image_photo: Yup.string().required("Gambar Pembimbing wajib diisi"),
    }),
    onSubmit: (val) => {
      if (byDetail?.data) {
        val.id = byDetail?.data?.id_mentor;
        dispatch(GuideAction.createOrUpdate(val)).then(() => reload());
      } else {
        dispatch(GuideAction.createOrUpdate(val)).then(() => reload());
      }
    },
  });

  return (
    <>
      <div className="bg-white border-[1px] border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <Inputs
              label="Nama Pembimbing"
              placeholder="Masukkan Nama Pembimbing"
              id="name"
              name="name"
              error={guide.touched.name && guide.errors.name}
              value={guide.values.name}
              onChange={guide.handleChange}
              onBlur={guide.handleBlur}
            />
            <Selects
              label="Tipe"
              placeholder="Pilih Jenis Slide"
              options={options}
              values={options.filter(
                (item) => item.value === guide.values.type
              )}
              onChange={(e) => {
                e.length !== 0 && guide.setFieldValue("type", e[0].value);
              }}
              searchable
              error={guide.touched.type && guide.errors.type}
              itemRenderer={({ state, item, methods }) => {
                return (
                  <div
                    className={`react-dropdown-select-item ${
                      state.values.length !== 0 &&
                      state.values[0].value === item.value &&
                      "react-dropdown-select-item-selected"
                    }`}
                    onClick={() => {
                      methods.addItem(item);
                    }}
                  >
                    {item.label}
                  </div>
                );
              }}
            />
            <div>
              {/* <ImageField
                label="Gambar Pembimbing"
                customContainer="h-[300px]"
                error={guide.touched.description && guide.errors.image_photo}
                errorMesssage={
                  guide.touched.description && guide.errors.image_photo
                }
                isMulti={false}
                setFile={(file) => {
                  guide.setFieldValue("image_photo", file?.path);
                }}
                name="guide"
              /> */}
              <UploadArea 
                label="Gambar Pembimbing"
                onChange={(e) => guide.setFieldValue("image_photo", e)}
                customClass="h-[250px] !w-[250px]"
                defaultUrl={guide.values.image_photo}
                />
            </div>
            <Textareas
              label="Deskripsi"
              placeholder="Masukan Deskripsi"
              rows={12}
              id="description"
              name="description"
              error={guide.touched.description && guide.errors.description}
              value={guide.values.description}
              onChange={guide.handleChange}
              onBlur={guide.handleBlur}
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end gap-2 p-4">
          <Buttons text="Kembali" customClass="btn-outline btn-medium !w-fit" />
          <Buttons
            text="Tambah"
            loading={isLoading}
            customClass="btn-primary btn-medium !w-fit"
            onClick={() => guide.handleSubmit()}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(AppsAddGuide);
