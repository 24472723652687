import axios from "axios";
import {
  BASE_URL_ENDPOINT,
  BASE_URL_ENDPOINT_V2,
} from "../../../../constant/constanta";

export const getAllPerlengkapanService = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/paket/get-perlengkapan/${payload}`);

export const savePerlengkapanService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-perlengkapan`, payload);

export const getListStartingPaketService = (id) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/package/${id}/startings`);
export const getListCabangService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-cabang`, payload);

export const getListPaketPerlengkapanV2 = (id, payload, body) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/jamaah-orders?data=${payload.equipment}`,
    body
  );

export const updatedPerlengkapan = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/equipment/jamaah-update`,
    payload
  );
