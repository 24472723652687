import React from "react";
import { Tabs } from "antd";
import PropTypes from "prop-types";
import TabContainer from "./Tab.style";

const Tab = (props) => {
  const {
    defaultActiveKey,
    activeKey,
    items,
    containerClass,
    tabBarExtraContent,
    customClass,
    onChange,
    destroyInactiveTabPane
  } = props;
  return (
    <TabContainer className={`${containerClass} coba`}>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        activeKey={activeKey}
        items={items}
        tabBarExtraContent={tabBarExtraContent}
        className={`${customClass} hallo`}
        onChange={onChange}
        destroyInactiveTabPane={destroyInactiveTabPane}
      />
    </TabContainer>
  );
};

Tab.propTypes = {
  defaultActiveKey: PropTypes.string,
  activeKey: PropTypes.string,
  items: PropTypes.array,
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  onChange: PropTypes.func,
  destroyInactiveTabPane: PropTypes.bool
};

Tab.defaultProps = {
  defaultActiveKey: "1",
  onChange: () => {},
  destroyInactiveTabPane: false
};

export default Tab;
