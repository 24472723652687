import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { PriceUpdateAPI } from "utils/api/calculator/price-update";

import {
    getAllDataAkomodasi,
  } from "stores/actions/datamaster";

import Buttons from "components/buttons";
import AlertPopup from "components/popup/alert";

import IconTrash from "assets/icons/trash-maroon.svg";
import IconArrowSquareDown from "assets/icons/arrow-square-down-2.svg";

import Inputs from "components/form/inputs";
import AntdSelect from "components/form/antdSelect";
import InputsCurrency from "components/form/inputsCurrency";
import moment from "moment/moment";

const AkomodasiPriceUpdate = () => {

    const dispatch = useDispatch()
    const masterDataStore = useSelector(({ datamaster }) => datamaster);

    const [actionIndexMadinah, setActionIndexMadinah] = useState(null)
    const [actionIndexMakkah, setActionIndexMakkah] = useState(null)
    
    const [removeConfirm, setRemoveConfirm] = useState({ show: false, index: null, city: null })

    const [isGetAllLoading, setGetAllLoading] = useState(false)
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [isRemoveLoading, setIsRemoveLoading] = useState(false)
    const [isGetPriceLoading, setIsGetPriceLoading] = useState(false)

    const [months, setMonths] = useState([])
    const [years, setYears] = useState([])

    const [masterAkomodasiMadinah, setMasterAkomodasiMadinah] = useState([])
    const [masterAkomodasiMakkah, setMasterAkomodasiMakkah] = useState([])

    const [akomodasiPrices, setAkomodasiPrices] = useState([])

    useEffect(() => {
        onHandleGetAllAkomodasiPrice()

        // set months
        setMonths([
            { value: "01", label: "Januari" },
            { value: "02", label: "Februari" },
            { value: "03", label: "Maret" },
            { value: "04", label: "April" },
            { value: "05", label: "Mei" },
            { value: "06", label: "Juni" },
            { value: "07", label: "Juli" },
            { value: "08", label: "Agustus" },
            { value: "09", label: "September" },
            { value: "10", label: "Oktober" },
            { value: "11", label: "November" },
            { value: "12", label: "Desember" },
        ]);

        setYears(handleSetYears())

    }, [])

    // useEffect(() => {
    //     if (masterDataStore?.dataAkomodasi?.data?.length > 0) {
    //         let masterAkomodasiTemp = [];
    //         masterDataStore?.dataAkomodasi?.data?.map((val) => {
    //           masterAkomodasiTemp.push({
    //             id: val.id_akomodasi,
    //             img: val.logo,
    //             star: val.bintang,
    //             address: val.alamat_lengkap,
    //             value: val.id_akomodasi,
    //             label: val.name,
    //             key: val.id_akomodasi,
    //             city: val.city
    //           });
    //         });

    //         // Madinah
    //         // const allAkomodasiMadinahIDSelected = akomodasiPrices.filter((item) => item.data.city === 'madinah').map(item => item.data.akomodasi_id)
    //         let masterAkomodasiMadinahTemp = masterAkomodasiTemp.filter(item => item.city == 'Madinah')

    //         // const allAkomodasiMakkahIDSelected = akomodasiPrices.filter((item) => item.data.city === 'makkah')
    //         let masterAkomodasiMakkahTemp = masterAkomodasiTemp.filter(item => item.city == 'Makkah')

    //         setMasterAkomodasiMadinah(masterAkomodasiMadinahTemp);
    //         setMasterAkomodasiMakkah(masterAkomodasiMakkahTemp);
    //     }
    
    //   }, [
    //     masterDataStore.dataAkomodasi,
    //     masterDataStore.dataAkomodasi.length,
    // ]);

    const onHandleGetAllAkomodasiPrice = async () => {
        try {

            setGetAllLoading(true)
            
            const res = await PriceUpdateAPI.akomodasiGetAll()
            if(res.status = 200) {
                handleFetchAllAkomodasiPrice(res.data.data)
                dispatch(
                    getAllDataAkomodasi({
                      page_number: 1,
                      per_page: 1000,
                      keyword: "",
                      sort_column: "",
                      sort_order: "DESC",
                    })
                );
            } else {
                dispatch(handleError(res))
            }

            setGetAllLoading(false)

        } catch(error) {
            setGetAllLoading(false)
            dispatch(handleError(error))
        }
    }
    const onHandleSaveAkomodasi = async (index, city) => {
        
        if(city === 'madinah') {
            setActionIndexMadinah(index)
        } else {
            setActionIndexMakkah(index)
        }

        try {
            setIsSaveLoading(true)

            let akomodasiPricesTemp = [...akomodasiPrices]
            let akomodasiPricesMadinahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'madinah')
            let akomodasiPricesMakkahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'makkah')

            let masterAkomodasiMadinahTemp = [...masterAkomodasiMadinah]
            let masterAkomodasiMakkahTemp = [...masterAkomodasiMakkah]

            let akomodasi = {}
            if(city == 'madinah') {
                akomodasi = akomodasiPricesMadinahTemp[index]
            } else {
                akomodasi = akomodasiPricesMakkahTemp[index]
            }

            const res = await PriceUpdateAPI.akomodasiSave(akomodasi.data)

            if(res.status === 200) {
                dispatch(handleSuccess(res))

                if(city == 'madinah') {
                    akomodasiPricesMadinahTemp[index].props.active = true
                    akomodasiPricesMadinahTemp[index].props.updated = false
                } else {
                    akomodasiPricesMakkahTemp[index].props.active = true
                    akomodasiPricesMakkahTemp[index].props.updated = false
                }

                akomodasiPricesTemp = akomodasiPricesMadinahTemp.concat(akomodasiPricesMakkahTemp)

                // Madinah
                const allAkomodasiMadinahIDSelected = akomodasiPricesMadinahTemp.map(item => item.data.akomodasi_id)
                masterAkomodasiMadinahTemp = masterAkomodasiMadinahTemp.filter(item => !allAkomodasiMadinahIDSelected.includes(item.id))

                // Makkah
                const allAkomodasiMakkahIDSelected = akomodasiPricesMakkahTemp.map(item => item.data.akomodasi_id)
                masterAkomodasiMakkahTemp = masterAkomodasiMakkahTemp.filter(item => !allAkomodasiMakkahIDSelected.includes(item.id))
                
                setAkomodasiPrices(akomodasiPricesTemp)
                setMasterAkomodasiMadinah(masterAkomodasiMadinahTemp);
                setMasterAkomodasiMakkah(masterAkomodasiMakkahTemp);
            } else {
                dispatch(handleError(res))
            }

            setIsSaveLoading(false)

        } catch(error) {
            setIsSaveLoading(false)
            dispatch(handleError(error))
        }
        
    };
    const onHandleRemove = async (index, city) => {
        try {

            setIsRemoveLoading(true)

            let akomodasiPricesTemp = [...akomodasiPrices]
            let akomodasiPricesMadinahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'madinah')
            let akomodasiPricesMakkahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'makkah')
            
            let akomodasi = {}
            if(city == 'madinah') {
                akomodasi = akomodasiPricesMadinahTemp[index]
            } else {
                akomodasi = akomodasiPricesMakkahTemp[index]
            }

            const res = await PriceUpdateAPI.akomodasiRemove(akomodasi.data.akomodasi_id)

            if(res.status === 200) {
                
                if(city === 'madinah') {
                    akomodasiPricesMadinahTemp.splice(index, 1)
                } else {
                    akomodasiPricesMakkahTemp.splice(index, 1)
                }
        
                akomodasiPricesTemp = akomodasiPricesMadinahTemp.concat(akomodasiPricesMakkahTemp)
                
                dispatch(
                    getAllDataAkomodasi({
                      page_number: 1,
                      per_page: 100,
                      keyword: "",
                      sort_column: "",
                      sort_order: "DESC",
                    })
                );

                dispatch(handleSuccess(res))
                setAkomodasiPrices(akomodasiPricesTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsRemoveLoading(false)
            setRemoveConfirm({ show: false, index: 0 })
        } catch(error) {
            setIsRemoveLoading(false)
            dispatch(handleError(error))
        }
    }
    const onHandleGetPrice = async (index, city) => {

        if(city === 'madinah') {
            setActionIndexMadinah(index)
        } else {
            setActionIndexMakkah(index)
        }
        try {
            setIsGetPriceLoading(true)

            let akomodasiPricesTemp = [...akomodasiPrices]
            let akomodasiPricesMadinahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'madinah')
            let akomodasiPricesMakkahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'makkah')
            
            let akomodasi = {}
            if(city == 'madinah') {
                akomodasi = akomodasiPricesMadinahTemp[index]
            } else {
                akomodasi = akomodasiPricesMakkahTemp[index]
            }

            const res = await PriceUpdateAPI.akomodasiGetPrice(akomodasi.data)

            if(res.status === 200) {
                const price = res.data.data

                if(city == 'madinah') {
                    akomodasiPricesMadinahTemp[index].data.quad_price = price.quad_price
                    akomodasiPricesMadinahTemp[index].data.triple_price = price.triple_price
                    akomodasiPricesMadinahTemp[index].data.double_price = price.double_price
                    akomodasiPricesMadinahTemp[index].data.single_price = price.single_price
                } else {
                    akomodasiPricesMakkahTemp[index].data.quad_price = price.quad_price
                    akomodasiPricesMakkahTemp[index].data.triple_price = price.triple_price
                    akomodasiPricesMakkahTemp[index].data.double_price = price.double_price
                    akomodasiPricesMakkahTemp[index].data.single_price = price.single_price
                }

                akomodasiPricesTemp = akomodasiPricesMadinahTemp.concat(akomodasiPricesMakkahTemp)

                setAkomodasiPrices(akomodasiPricesTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsGetPriceLoading(false)
        } catch (error) {
            setIsGetPriceLoading(false)
            dispatch(handleError(error))
        }
    }

    const handleSetYears = () => {
        const yearNow = new Date().getFullYear();

        // Membuat daftar tahun
        const yearList = [];
        
        for (let i = 0; i <= 20; i++) {
            const yearSelected = yearNow + i;
            yearList.push({ value: String(yearSelected), label: yearSelected });
        }

        yearList.sort((a, b) => a - b);

        return yearList;
    };

    const handleFetchAllAkomodasiPrice = (akomodasiPricesData) => {
        let akomodasiPricesTemp = []

        akomodasiPricesData.map((item) => {
            let dataItem = {...item, akomodasi_id: item.id}
            delete dataItem.id

            akomodasiPricesTemp.push({
                props: {
                active: true,
                showPrice: false,
                updated: false,
                },
                data: dataItem,
            });
        })

        setAkomodasiPrices(akomodasiPricesTemp)
    }
    const handleShowPrice = (index, city) => {
        let akomodasiPricesTemp = [...akomodasiPrices]
        let akomodasiPricesMadinahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'madinah')
        let akomodasiPricesMakkahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'makkah')
                    
        if(city == 'madinah') {
            akomodasiPricesMadinahTemp[index].props.showPrice = !akomodasiPricesMadinahTemp[index].props.showPrice
        } else {
            akomodasiPricesMakkahTemp[index].props.showPrice = !akomodasiPricesMakkahTemp[index].props.showPrice
        }

        akomodasiPricesTemp = akomodasiPricesMadinahTemp.concat(akomodasiPricesMakkahTemp)
        setAkomodasiPrices(akomodasiPricesTemp)
    }
    const handleAddAkomodasi = (city) => {
        const newMaskapai = {
          props: {
            active: false,
            showPrice: false,
            updated: false,
          },
          data: {
            akomodasi_id: null,
            akomodasi_name: "",
            month: moment().format("MM"),
            quad_price: 0,
            triple_price: 0,
            double_price: 0,
            single_price: 0,
            year: moment().format("YYYY"),
            city
          },
        }
    
        let akomodasiPricesTemp = [...akomodasiPrices]
        akomodasiPricesTemp.push(newMaskapai)
    
        setAkomodasiPrices(akomodasiPricesTemp)
    }
    const handleCancelAddMaskapai = (index, city) => {
        let akomodasiPricesTemp = [...akomodasiPrices];

        let akomodasiPricesMadinahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'madinah')
        let akomodasiPricesMakkahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'makkah')
        
        if(city === 'madinah') {
            akomodasiPricesMadinahTemp.splice(index, 1)
        } else {
            akomodasiPricesMakkahTemp.splice(index, 1)
        }

        akomodasiPricesTemp = akomodasiPricesMadinahTemp.concat(akomodasiPricesMakkahTemp)
    
        setAkomodasiPrices(akomodasiPricesTemp)
    }

    const handleSelectAkomodasi = (index, item, city) => {
        let akomodasiPricesTemp = [...akomodasiPrices]
        let akomodasiPricesMadinahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'madinah')
        let akomodasiPricesMakkahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'makkah')
                    
        if(city == 'madinah') {
            akomodasiPricesMadinahTemp[index].data.akomodasi_id = item.value
            akomodasiPricesMadinahTemp[index].data.akomodasi_name = item.label.props.option.label
        } else {
            akomodasiPricesMakkahTemp[index].data.akomodasi_id = item.value
            akomodasiPricesMakkahTemp[index].data.akomodasi_name = item.label.props.option.label
        }

        akomodasiPricesTemp = akomodasiPricesMadinahTemp.concat(akomodasiPricesMakkahTemp)
        setAkomodasiPrices(akomodasiPricesTemp)
    }

    const handleSelectMonth = (index, item, city) => {
        let akomodasiPricesTemp = [...akomodasiPrices]
        let akomodasiPricesMadinahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'madinah')
        let akomodasiPricesMakkahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'makkah')
                    
        if(city == 'madinah') {
            akomodasiPricesMadinahTemp[index].data.month = item.value

            if(akomodasiPricesMadinahTemp[index].props.active) {
                onHandleGetPrice(index, city)
            }
        } else {
            akomodasiPricesMakkahTemp[index].data.month = item.value

            if(akomodasiPricesMakkahTemp[index].props.active) {
                onHandleGetPrice(index, city)
            }
        }


        akomodasiPricesTemp = akomodasiPricesMadinahTemp.concat(akomodasiPricesMakkahTemp)
        setAkomodasiPrices(akomodasiPricesTemp)
        
        
    }

    const handleSelectYear = (index, item, city) => {
        let akomodasiPricesTemp = [...akomodasiPrices]
        let akomodasiPricesMadinahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'madinah')
        let akomodasiPricesMakkahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'makkah')
                    
        if(city == 'madinah') {
            akomodasiPricesMadinahTemp[index].data.year = item.value

            if(akomodasiPricesMadinahTemp[index].props.active) {
                onHandleGetPrice(index, city)
            }
        } else {
            akomodasiPricesMakkahTemp[index].data.year = item.value

            if(akomodasiPricesMakkahTemp[index].props.active) {
                onHandleGetPrice(index, city)
            }
        }

        akomodasiPricesTemp = akomodasiPricesMadinahTemp.concat(akomodasiPricesMakkahTemp)
        setAkomodasiPrices(akomodasiPricesTemp)

        onHandleGetPrice(index, city)
    }

    const handleChangePrice = (index, val, roomType, city) => {
        let akomodasiPricesTemp = [...akomodasiPrices]
        let akomodasiPricesMadinahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'madinah')
        let akomodasiPricesMakkahTemp = akomodasiPricesTemp.filter((item) => item.data.city === 'makkah')
                    
        if(city == 'madinah') {
            akomodasiPricesMadinahTemp[index].props.updated = true
            akomodasiPricesMadinahTemp[index].data[`${roomType}_price`] = val
        } else {
            akomodasiPricesMakkahTemp[index].props.updated = true
            akomodasiPricesMakkahTemp[index].data[`${roomType}_price`] = val
        }

        akomodasiPricesTemp = akomodasiPricesMadinahTemp.concat(akomodasiPricesMakkahTemp)
        setAkomodasiPrices(akomodasiPricesTemp)
    }

    const akomodasiPricesMadinahActive = akomodasiPrices.filter((item) => item.data.city === 'madinah').filter(function (akomodasi) {
        return akomodasi.props.active === true;
    });
    const akomodasiPricesMakkahActive = akomodasiPrices.filter((item) => item.data.city === 'makkah').filter(function (akomodasi) {
        return akomodasi.props.active === true;
    });
    
    const akomodasiPricesMadinahDeactive = akomodasiPrices.filter((item) => item.data.city === 'madinah').filter(function (akomodasi) {
        return akomodasi.props.active === false;
    });
    const akomodasiPricesMakkahDeactive = akomodasiPrices.filter((item) => item.data.city === 'makkah').filter(function (akomodasi) {
        return akomodasi.props.active === false;
    });

    const handleAkomodasiOptions = () => {
        
        let masterAkomodasiMadinahTemp = [];
        let masterAkomodasiMakkahTemp = [];

        masterDataStore?.dataAkomodasi?.data?.filter(item => item.city == 'Madinah').map((val) => {
            if(!akomodasiPrices.filter(item => item.data.city === 'madinah').some(item => item.data.akomodasi_id === val.id_akomodasi)) {
                masterAkomodasiMadinahTemp.push({
                    id: val.id_akomodasi,
                    img: val.logo,
                    star: val.bintang,
                    address: val.alamat_lengkap,
                    value: val.id_akomodasi,
                    label: val.name,
                    key: val.id_akomodasi,
                    city: val.city
                });
            }
        })

        masterDataStore?.dataAkomodasi?.data?.filter(item => item.city == 'Makkah').map((val) => {
            if(!akomodasiPrices.filter(item => item.data.city === 'makkah').some(item => item.data.akomodasi_id === val.id_akomodasi)) {
                masterAkomodasiMakkahTemp.push({
                    id: val.id_akomodasi,
                    img: val.logo,
                    star: val.bintang,
                    address: val.alamat_lurus,
                    value: val.id_akomodasi,
                    label: val.name,
                    key: val.id_akomodasi,
                    city: val.city
                });
            }
        })
        

        setMasterAkomodasiMadinah(masterAkomodasiMadinahTemp);
        setMasterAkomodasiMakkah(masterAkomodasiMakkahTemp);
    }

    useEffect(() => {
        handleAkomodasiOptions()
    }, [akomodasiPrices, masterDataStore.dataAkomodasi,
        masterDataStore.dataAkomodasi.length])

    return (
        <>

            <AlertPopup
                open={removeConfirm.show}
                handleClose={() => setRemoveConfirm({ show: false, index: 0, city: null })}
                handleContinue={() => onHandleRemove(removeConfirm.index, removeConfirm.city)}
                loading={isRemoveLoading}
                subtitle="Apakah anda yakin menghapus hotel ini?"
            />

            <div className="flex flex-row">
                
                <div className="w-[25%]">
                <div className="text-sm font-semibold">Hotel</div>
                </div>
                <div className="w-[75%]">

                <div className="text-sm font-bold mb-4">Madinah</div>
                <div className="flex flex-col gap-4">
                    {akomodasiPrices
                        .filter((item) => item.data.city === "madinah")
                        .map((akomodasi, index) => {
                        return (
                            <div
                                className="border border-[#E0E0E0] p-4 rounded-2xl"
                                key={index}
                                >
                                    {/* <!----------- HEADER -----------> */}
                                    <div className="flex justify-between items-center">
                                        <div className="font-bold mb-4">
                                            {!akomodasi.props.active && "Tambah "}Madinah{" "}
                                            {index + 1}
                                        </div>
                                        <div className="flex justify-end gap-4 items-center">
                                            {!akomodasi.props.active ? (
                                                <>
                                                    <Buttons
                                                        text="Batal"
                                                        onClick={() => handleCancelAddMaskapai(index, 'madinah')}
                                                        customClass="btn-primary btn-outline btn-small"
                                                        />
                                                    <Buttons
                                                        text="Simpan"
                                                        loading={isSaveLoading ? actionIndexMadinah === index : false}
                                                        onClick={() => onHandleSaveAkomodasi(index, 'madinah')}
                                                        customClass="btn-primary btn-small"
                                                    />
                                                </>
                                            ): null}
                                            {akomodasi.props.active ? (
                                                <>
                                                    {akomodasiPricesMadinahActive.length > 1 && (
                                                        <i
                                                            className="!cursor-pointer"
                                                            onClick={() =>
                                                                setRemoveConfirm({ show: true, index, city: 'madinah' })
                                                            }
                                                            >
                                                            <img
                                                                src={IconTrash}
                                                                className={`w-6 h-6`}
                                                                />
                                                        </i>
                                                    )}
                                                    <Buttons
                                                        text="Simpan"
                                                        loading={isSaveLoading ? actionIndexMadinah === index : false}
                                                        onClick={() => {
                                                            onHandleSaveAkomodasi(index, 'madinah');
                                                        }}
                                                        disabled={!akomodasi.props.updated}
                                                        customClass="btn-primary btn-small"
                                                    />
                                                </>
                                            ) : null}
                                        </div>
                                    </div>

                                    {/* <!----------- CONTENT ----------------> */}
                                    <div className="flex gap-4 items-center mb-4">
                                        <div className="w-[83%]">
                                            {akomodasi.props.active ? (
                                                <>
                                                    <Inputs
                                                        label="Akomodasi"
                                                        readonly
                                                        value={akomodasi.data.akomodasi_name}
                                                        disabled={isGetPriceLoading ? actionIndexMadinah === index : false}
                                                    />
                                                </>
                                            ): null}
                                            {!akomodasi.props.active ? (
                                                <AntdSelect
                                                    showSearch={true}
                                                    label="Hotel"
                                                    placeholder="Pilih hotel"
                                                    type="secondary"
                                                    options={masterAkomodasiMadinah}
                                                    onChange={(item) => (handleSelectAkomodasi(index, item, 'madinah'))}
                                                />
                                            ) : null}
                                        </div>
                                        <div className="w-[83%]">
                                            <AntdSelect
                                                label="Bulan"
                                                placeholder="Pilih bulan"
                                                options={months}
                                                defaultValue={akomodasi.data.month}
                                                onChange={(item) => (handleSelectMonth(index, item, 'madinah'))}
                                                disabled={isGetPriceLoading ? actionIndexMadinah === index : false}
                                            />
                                        </div>
                                        <div className="w-[83%]">
                                            <AntdSelect
                                                label="Tahun"
                                                placeholder="Pilih tahun"
                                                options={years}
                                                defaultValue={akomodasi.data.year}
                                                onChange={(item) => (handleSelectYear(index, item, 'madinah'))}
                                                disabled={isGetPriceLoading ? actionIndexMadinah === index : false}
                                                />
                                        </div>
                                        <div className="w-[10%] relative">
                                            <div className="cursor-pointer absolute bottom--4">
                                            <img
                                                onClick={() => {
                                                    handleShowPrice(index, 'madinah');
                                                }}
                                                src={IconArrowSquareDown}
                                                className={`w-8 h-8 ${
                                                    akomodasi.props.showPrice && `rotate-180`
                                                }`}
                                            />
                                            </div>
                                        </div>

                                    </div>
                                    <div
                                        className={`transition-all duration-500 flex flex-col gap-4 ${
                                            akomodasi.props.showPrice
                                            ? `block`
                                            : `hidden`
                                        }`}
                                        >

                                        <InputsCurrency
                                            label="Harga Permalam Quad"
                                            value={akomodasi.data.quad_price}
                                            placeholder="0"
                                            disabled={isGetPriceLoading ? actionIndexMadinah === index : false}
                                            onChange={(val) => (handleChangePrice(index, val, 'quad', 'madinah'))}
                                        />

                                        <InputsCurrency
                                            label="Harga Permalam Triple"
                                            value={akomodasi.data.triple_price}
                                            placeholder="0"
                                            disabled={isGetPriceLoading ? actionIndexMadinah === index : false}
                                            onChange={(val) => (handleChangePrice(index, val, 'triple', 'madinah'))}
                                        />

                                        <InputsCurrency
                                            label="Harga Permalam Double"
                                            value={akomodasi.data.double_price}
                                            placeholder="0"
                                            disabled={isGetPriceLoading ? actionIndexMadinah === index : false}
                                            onChange={(val) => (handleChangePrice(index, val, 'double', 'madinah'))}
                                        />

                                    </div>
                            </div>
                        )
                    })}
                </div>
                <Buttons
                    text="+ Tambah Hotel Madinah"
                    customClass="btn-outline btn-medium mt-4 !py-4"
                    disabled={akomodasiPricesMadinahDeactive.length}
                    onClick={() => handleAddAkomodasi('madinah')}
                />

                <div className="text-sm font-bold mb-4 mt-6">Makkah</div>
                <div className="flex flex-col gap-4">
                    {akomodasiPrices
                        .filter((item) => item.data.city === "makkah")
                        .map((akomodasi, index) => {
                        return (
                            <div
                                className="border border-[#E0E0E0] p-4 rounded-2xl"
                                key={index}
                                >
                                    {/* <!----------- HEADER -----------> */}
                                    <div className="flex justify-between items-center">
                                        <div className="font-bold mb-4">
                                            {!akomodasi.props.active && "Tambah "}Makkah{" "}
                                            {index + 1}
                                        </div>
                                        <div className="flex justify-end gap-4 items-center">
                                            {!akomodasi.props.active ? (
                                                <>
                                                    <Buttons
                                                        text="Batal"
                                                        onClick={() => handleCancelAddMaskapai(index, 'makkah')}
                                                        customClass="btn-primary btn-outline btn-small"
                                                        />
                                                    <Buttons
                                                        text="Simpan"
                                                        loading={isSaveLoading ? actionIndexMakkah === index : false}
                                                        onClick={() => onHandleSaveAkomodasi(index, 'makkah')}
                                                        customClass="btn-primary btn-small"
                                                    />
                                                </>
                                            ): null}
                                            {akomodasi.props.active ? (
                                                <>
                                                    {akomodasiPricesMakkahActive.length > 1 && (
                                                        <i
                                                            className="!cursor-pointer"
                                                            onClick={() =>
                                                                setRemoveConfirm({ show: true, index, city: 'makkah' })
                                                            }
                                                            >
                                                            <img
                                                                src={IconTrash}
                                                                className={`w-6 h-6`}
                                                                />
                                                        </i>
                                                    )}
                                                    <Buttons
                                                        text="Simpan"
                                                        loading={isSaveLoading ? actionIndexMakkah === index : false}
                                                        onClick={() => {
                                                            onHandleSaveAkomodasi(index, "makkah");
                                                        }}
                                                        disabled={!akomodasi.props.updated}
                                                        customClass="btn-primary btn-small"
                                                    />
                                                </>
                                            ) : null}
                                        </div>
                                    </div>

                                    {/* <!----------- CONTENT ----------------> */}
                                    <div className="flex gap-4 items-center mb-4">
                                        <div className="w-[83%]">
                                            {akomodasi.props.active ? (
                                                <>
                                                    <Inputs
                                                        label="Akomodasi"
                                                        readonly
                                                        value={akomodasi.data.akomodasi_name}
                                                        disabled={isGetPriceLoading ? actionIndexMakkah === index : false}
                                                    />
                                                </>
                                            ): null}
                                            {!akomodasi.props.active ? (
                                                <AntdSelect
                                                    showSearch={true}
                                                    label="Hotel"
                                                    placeholder="Pilih hotel"
                                                    type="secondary"
                                                    options={masterAkomodasiMakkah}
                                                    onChange={(item) => (handleSelectAkomodasi(index, item, 'makkah'))}
                                                />
                                            ) : null}
                                        </div>
                                        <div className="w-[83%]">
                                            <AntdSelect
                                                label="Bulan"
                                                placeholder="Pilih bulan"
                                                options={months}
                                                defaultValue={akomodasi.data.month}
                                                onChange={(item) => (handleSelectMonth(index, item, 'makkah'))}
                                                disabled={isGetPriceLoading ? actionIndexMakkah === index : false}
                                            />
                                        </div>
                                        <div className="w-[83%]">
                                            <AntdSelect
                                                label="Tahun"
                                                placeholder="Pilih tahun"
                                                options={years}
                                                defaultValue={akomodasi.data.year}
                                                onChange={(item) => (handleSelectYear(index, item, 'makkah'))}
                                                disabled={isGetPriceLoading ? actionIndexMakkah === index : false}
                                                />
                                        </div>
                                        <div className="w-[10%] relative">
                                            <div className="cursor-pointer absolute bottom--4">
                                            <img
                                                onClick={() => {
                                                    handleShowPrice(index, 'makkah');
                                                }}
                                                src={IconArrowSquareDown}
                                                className={`w-8 h-8 ${
                                                    akomodasi.props.showPrice && `rotate-180`
                                                }`}
                                            />
                                            </div>
                                        </div>

                                    </div>
                                    <div
                                        className={`transition-all duration-500 flex flex-col gap-4 ${
                                            akomodasi.props.showPrice
                                            ? `block`
                                            : `hidden`
                                        }`}
                                        >

                                        <InputsCurrency
                                            label="Harga Permalam Quad"
                                            value={akomodasi.data.quad_price}
                                            placeholder="0"
                                            disabled={isGetPriceLoading ? actionIndexMadinah === index : false}
                                            onChange={(val) => (handleChangePrice(index, val, 'quad', 'makkah'))}
                                        />

                                        <InputsCurrency
                                            label="Harga Permalam Triple"
                                            value={akomodasi.data.triple_price}
                                            placeholder="0"
                                            disabled={isGetPriceLoading ? actionIndexMadinah === index : false}
                                            onChange={(val) => (handleChangePrice(index, val, 'triple', 'makkah'))}
                                        />

                                        <InputsCurrency
                                            label="Harga Permalam Double"
                                            value={akomodasi.data.double_price}
                                            placeholder="0"
                                            disabled={isGetPriceLoading ? actionIndexMadinah === index : false}
                                            onChange={(val) => (handleChangePrice(index, val, 'double', 'makkah'))}
                                        />
                                    </div>
                            </div>
                        )
                    })}
                </div>
                <Buttons
                    text="+ Tambah Hotel Makkah"
                    customClass="btn-outline btn-medium mt-4 !py-4"
                    disabled={akomodasiPricesMakkahDeactive.length}
                    onClick={() => handleAddAkomodasi('makkah')}
                />

                </div>

            </div>
        </>
    )

}

export default AkomodasiPriceUpdate