import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "utils/helpers/useDebounce";
import moment from "moment";

import { useParams } from "react-router-dom";

import { toCapital, getLocalStorage } from "../../../utils/helpers/index"

import { AdminMemberAPI, AgenMemberAPI, JamaahMemberAPI } from "utils/api/member";

import { getAllPaket, handlePackagePublishList } from "stores/actions/paket/landing";

import BookingAction from 'stores/actions/booking/umroh'
import AddJamaah from "./AddJamaah";

import Dropdowns, { closeDropdown } from "components/dropdowns";
import Inputs from "components/form/inputs";
import Badge from "components/badge";
import Tab from "components/tab";
import Buttons from "components/buttons";

import IconSearch from "assets/icons/search-normal.svg";
import Tanggal from "assets/icons/Tanggal.svg";
import Photo from "assets/images/Photo.png";
import IconTotalSeat from "assets/icons/total-seat.svg";
import IconSeatTerisi from "assets/icons/seat-terisi.svg";
import IconSeatTersisa from "assets/icons/seat-tersisa.svg";

import * as Yup from 'yup';

import Scroll from "components/scroll";
import Avatar from "components/avatar";
import { useFormik } from "formik";

const AddBooking = () => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) =>auth);
  const [isLoadingLoadMore, setIsLoadingLoadMore] = useState(false)
  
  const [packageID, setPackageID] = useState(null)
  
  // GET PACKAGE
  const [packagePublishListOptions, setPackagePublishListOptions] = useState([]);
  const [packagePublishListNextCursor, setPackagePublishListNextCursor] = useState(null);
  const [keywordPackageOptions, setKeywordPackageOptions] = useState("");
  const searchPackageDebounce = useDebounce(keywordPackageOptions, 1000);
  
  const [keywordMember, setKeywordMember] = useState('')
  const [searchMemberDebounce] = useDebounce(keywordMember, 1000)

  // GET ADMIN
  const [pemesanOptionsList, setPemesanOptionsList] = useState([]);
  const [pemesanOptionsListNextCursor, setPemesanOptionsListNextCursor] = useState(null)
  
  const [activeTab, setActiveTab] = useState("admin");
  
  const [formData, setFormData] = useState({
      paket_id : null,
      user_order_role: 'admin',
      user_order_id: null
  })

  const dataForm = useFormik({
    initialValues: {
      "paket" : {
        id: null
      },
      pemesan: {
        id: null,
        guard_type: 'admin',
        branch: {
          name: null,
          bg_color: null,
          text_color: null
        }
      },
      id_coupon: null,
      agent_discount_amount: 0,
      order_kamars: []
    },
    validationSchema: Yup.object({
      "paket" : Yup.object({
        id: Yup.number().required("Harus plih paket"),
      }),
      pemesan: Yup.object({
        id: Yup.number().required("Harus pilih pemesan"),
        guad_type: Yup.string().required("Harus pilih pemesan"),
      }),
      order_kamars: Yup.array().of(
        Yup.object({
          "jamaah_title": Yup.string().required("Harus pilih salah satu title"),
          "jamaah_name": Yup.string().required("Nama Jaamaah harus diisi"),
          "jamaah_identity_number": Yup.string().required("NIK harus diisi")
                                                .matches(/^[0-9]+$/, "Harus berupa angka")
                                                .max(16, "Maksimal 16 digit"),
          "paket_kamar": Yup.object({
              "id": Yup.number().required("Harus pilih starting & Tipekamar"),
              "kamar_name": Yup.string().required("Harus pilih starting & Tipekamar"),
          }),
        })
      )
    })
  })

  const [isError, setIsError] = useState(true);

  const params = useParams();

  const [selectPaketLable, setSelecPaketLable] = useState("Pilih Paket")
  const [selectPemesanLable, setSelecPemesanLable] = useState("Pilih Pemesan")

  const [selectMeStatus, setSelectMeStatus] = useState(false)

  const me = JSON.parse(getLocalStorage('userLogin')).data

  useEffect(() => {
    handleGetPackagePublishOptions(true)
    handleGetPemesan(true)
  }, [])

  useEffect(() => {
    setKeywordMember('')
    dataForm.setFieldValue("pemesan.guard_type", activeTab)
  }, [activeTab])

  useEffect(() => {
    setPemesanOptionsListNextCursor(null)
    setPemesanOptionsList([])
    switch (activeTab) {
      case "admin":
        handleGetPemesan(true);
        break;
      case "agent":
        handleGetPemesan(true, 'agent');
        break;
      case "jamaah":
        handleGetPemesan(true, 'jamaah');
        break;
      default:
        handleGetPemesan(true);
    }
  }, [activeTab, searchMemberDebounce]);


  useEffect(() => {
    handleGetPackagePublishOptions(true)
  }, [searchPackageDebounce])

  const handleSelectPackage = (paket) => {
    dataForm.setFieldValue("paket.id", paket.id_paket)
    setSelecPaketLable(paket.judul_paket.toUpperCase())
    setPackageID(paket.id_paket)
  }

  const handleSelectPemesan = (pemesan) => {
    dataForm.setFieldValue("pemesan.id", pemesan.id)
    dataForm.setFieldValue("pemesan.guard_type", activeTab)

    if(activeTab === 'jamaah'){
      dataForm.setFieldValue("pemesan.branch", {
        name: auth.user.nama_cabang,
        bg_color: auth.user.color_bg_cabang,
        text_color: auth.user.color_text_cabang
      })
    }

    if(activeTab === 'agent'){
      dataForm.setFieldValue("pemesan.branch", {
        name: pemesan.cabang_name,
        bg_color: pemesan.color_bg_cabang,
        text_color: pemesan.color_text_cabang
      })
    }

    if(activeTab === 'admin'){
      dataForm.setFieldValue("pemesan.branch", {
        name: pemesan.nama_cabang,
        bg_color: pemesan.color_bg_cabang,
        text_color: pemesan.color_text_cabang
      })
    }

    setSelecPemesanLable(`${pemesan.title ? `${pemesan.title.toUpperCase()}.` : ''} ${pemesan.name.toUpperCase()}`)
    setSelectMeStatus(false)
  }

  const handleSelectMe = () => {
    dataForm.setFieldValue("pemesan.id", auth.user.id)
    dataForm.setFieldValue("pemesan.guard_type", 'admin')

    dataForm.setFieldValue("pemesan.branch", {
      name: auth.user.nama_cabang,
      bg_color: auth.user.color_bg_cabang,
      text_color: auth.user.color_text_cabang
    })

    setSelecPemesanLable(`${auth.user.title ? `${auth.user.title.toUpperCase()}.` : ''} ${auth.user.name.toUpperCase()}`)
    setSelectMeStatus(true)
  }

  const validationSchema = Yup.object().shape({
    paket_id: Yup.number().integer().nullable(false).required("Anda belum memilih paket"),
    user_order_id: Yup.number().integer().nullable(false).required("Anda belum memilih pemesan"),
    user_order_role: Yup.string().nullable(false).required("Pemesan yang anda pilih harus admin/agent/jamaah")
  })

  const handleSave = async () => {
    console.log(dataForm.values);
  }

  const handleGetPackagePublishOptions = async (first = false) => {

    const params = {
      'paginate': 'cursor',
      'per_page': 10,
      'mode'  : 'options',
      'package_type': 2,
      'cursor': packagePublishListNextCursor,
      'keyword': keywordPackageOptions
    }

    await dispatch(handlePackagePublishList(params, (successData) => {
      if(first) {
        setPackagePublishListOptions(successData.data)
        setPackagePublishListNextCursor(successData.next_cursor)
      } else {
        let packageList = packagePublishListOptions;

        packageList.push.apply(packageList, successData.data)
        setPackagePublishListOptions(packageList)
        setPackagePublishListNextCursor(successData.next_cursor)
      }
    }))
  }


  // HANDLE GET MEMBER
  const handleGetPemesan = async (firstStep = false, type='admin') => {
    let params = {
      'paginate': 'cursor',
      'per_page': 10,
      'mode'  : 'options',
      'keyword': keywordMember
    }

    let response;

    if(!firstStep) {
      params['cursor'] = pemesanOptionsListNextCursor
    }

    if(type == 'admin') {
      response = await AdminMemberAPI.getAdminList(params)
    }

    if(type == 'agent') {
      response = await AgenMemberAPI.getAgentList(params)
    }

    if(type == 'jamaah') {
      params = {
        ...params,
        have_ordered: 1
      }
      response = await JamaahMemberAPI.getJamaahList(params)
    }
    
    const { status, data }  = response

    if(status === 200) {
      if(firstStep) {
        setPemesanOptionsList(data.data)
      } else {
        let pemesanList = pemesanOptionsList

        pemesanList.push.apply(pemesanList, data.data)
        setPemesanOptionsList(pemesanList)
      }
      setPemesanOptionsListNextCursor(data.next_cursor)
    }

  }

  const handleLoadMore = async () => {
    setIsLoadingLoadMore(true)
    await handleGetPackagePublishOptions(false)
    setIsLoadingLoadMore(false)
  }

  const handleLoadMoreMember = async (type) => {
    setIsLoadingLoadMore(true)
    if(type === 'admin') {
      await handleGetPemesan(false)
    }

    if(type === 'agent') {
      await handleGetPemesan(false, 'agent')
    }
    
    if(type === 'jamaah') {
      await handleGetPemesan(false, 'jamaah')
    }

    setIsLoadingLoadMore(false)
  }

  return (
    <>
      <div className="border border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <div className="text-sm font-semibold">Paket</div>
          <Dropdowns
            customClass={`bg-[#f2f2f2] p-4 rounded-lg flex mt-2 justify-between items-center`}
            placement="bottom"
            labelDropdown={selectPaketLable}
            dropdownArrow={true}
            error={dataForm.touched.paket?.id ? true : false}
            errorMsg={dataForm.touched.paket?.id && dataForm.errors.paket?.id}
          >
            
            <div classNupdame="bg-white w-full p-4 soft-shadow rounded-2xl">
              <Inputs
                id="searchPAckage"
                placeholder="Pencarian..."
                customClass="!px-3 !py-3.5 !pl-[42px] text-sm"
                iconPosition="left"
                onChange={(e) => setKeywordPackageOptions(e.target.value)}
                icon={IconSearch}
              />

              <Scroll 
                height="350px" 
                customClass="pr-2">
                  <>
                    {packagePublishListOptions ? (
                      packagePublishListOptions.map((val, idx) => {
                        return (
                          <>
                            <div
                                key={idx}
                                className="flex flex-row bg-white rounded-2xl border-[0.5px] border-[#e0e0e0] py-2 px-4 mt-4 cursor-pointer"
                                onClick={() => {
                                  handleSelectPackage(val);
                                  closeDropdown();
                                }}
                              >
                                <div
                                  className="card-detail-booking-image w-[104px] h-[104px] rounded-xl overflow-hidden"
  
                                >
                                  <img src={val.image_thumbnail} className="object-cover h-full w-full" />
                                </div>
                                <div className="ml-4 flex flex-col gap-2 justify-center">
                                  <div className="font-bold text-xl">
                                    {val.judul_paket}
                                  </div>
                                  <div className="flex flex-row items-center gap-2">
                                    <img src={Tanggal} className="w-6 h-6" alt="tanggal" />
                                    <div className="text-sm">
                                      {moment(val.jadwal_keberangkatan).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex flex-row gap-4 w-fit">
                                    <div className="flex flex-row items-center gap-2">
                                      <img className="w-6 h-6" src={IconTotalSeat} alt="" />
                                      <div>{val.kuota}</div>
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <img className="w-6 h-6" src={IconSeatTerisi} alt="" />
                                      <div>{val.jumlah_jamaah}</div>
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                      <img className="w-6 h-6" src={IconSeatTersisa} alt="" />
                                      <div>{val.kuota_tersedia}</div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            
                          </>
                        );
                      })
                    )
                    : (
                      <div className="mt-4">Paket Tidak ditemukan</div>
                    )}
                    {packagePublishListNextCursor && (
                      <>
                        <div className="flex justify-center items-center w-full m-4">
                          <Buttons
                            onClick={handleLoadMore}
                            loading={isLoadingLoadMore}
                            text="Lihat selengkapnya"
                            customClass="btn-outline btn-secondary btn-sm"
                            />
                        </div>
                      </>
                    )}
                  </>
              </Scroll>
              
            </div>
          </Dropdowns>

          <div className="text-sm font-semibold mt-4">
            Pemesan { !selectMeStatus &&  <a className="inline-block text-red-1 cursor-pointer" onClick={handleSelectMe}>pilih diri saya</a> }
          </div>
          <Dropdowns
            customClass={`bg-[#f2f2f2] p-4 rounded-lg flex mt-2 justify-between  items-center`}
            placement="bottom"
            labelDropdown={selectPemesanLable}
            dropdownArrow={true}
          >
            <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
              <Tab
                defaultActiveKey="admin"
                onChange={(e) => {
                  setActiveTab(e);
                }}
                items={[
                  {
                    label: "Admin",
                    key: "admin",
                    children: (
                      <>
                        <Inputs
                          id="searchAdmin"
                          placeholder="Pencarian.."
                          customClass="!px-3 !py-3.5 !pl-[42px] text-sm my-2"
                          iconPosition="left"
                          value={keywordMember}
                          onChange={(e) =>
                            setKeywordMember(e.target.value)
                          }
                          icon={IconSearch}
                        />
                        <Scroll
                          height="350px"
                          customClass="pr-2"
                          >
                          {pemesanOptionsList && pemesanOptionsList.length !== 0 ? (
                            pemesanOptionsList.map((val, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                                  onClick={() => {
                                    handleSelectPemesan(val);
                                    closeDropdown();
                                  }}
                                >
                                    <Avatar
                                      value={val}
                                      containerClass={`items-center justify-center w-[56px] h-[56px] rounded-[100%]  bg-slate-100`}
                                      customClass={`object-cover w-[56px] h-[56px] rounded-[100%]`}
                                    />
                                  <div className="ml-4">
                                    <div className="font-semibold text-[20px]">
                                      {val.title ? toCapital(val.title) + `.` : "" } {toCapital(val.name)}
                                    </div>
                                    <div className="flex gap-2 mt-2">
                                      <Badge backgroundColor={val.color_bg_role} color={val.color_text_role} label={val.nama_role} />
                                      <Badge backgroundColor={val.color_bg_cabang} color={val.color_text_cabang} label={val.nama_cabang} />
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="mt-4">Admin tidak ditemukan.</div>
                          )}
                          {pemesanOptionsListNextCursor && (
                            <>
                              <div className="flex justify-center items-center w-full m-4">
                                <Buttons
                                  onClick={() => handleLoadMoreMember('admin')}
                                  loading={isLoadingLoadMore}
                                  text="Lihat selengkapnya"
                                  customClass="btn-outline btn-secondary btn-sm"
                                  />
                              </div>
                            </>
                          )}
                        </Scroll>
                      </>
                    ),
                  },
                  {
                    label: "Agen",
                    key: "agent",
                    children: (
                      <>
                        <Inputs
                          id="searchAgent"
                          placeholder="Pencarian.."
                          customClass="!px-3 !py-3.5 !pl-[42px] text-sm my-2"
                          iconPosition="left"
                          value={keywordMember}
                          onChange={(e) =>
                            setKeywordMember(e.target.value)
                          }
                          icon={IconSearch}
                        />
                        <Scroll
                          height="350px"
                          customClass="pr-2"
                          >
                            {pemesanOptionsList && pemesanOptionsList.length !== 0 ? (
                              pemesanOptionsList.map((val, idx) => {
                                return (
                                  <div
                                    key={idx}
                                    className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                                    onClick={() => {
                                      handleSelectPemesan(val);
                                      closeDropdown();
                                    }}
                                  >
                                    <Avatar
                                      value={val}
                                      containerClass={`items-center justify-center w-[56px] h-[56px] rounded-[100%]  bg-slate-100`}
                                      customClass={`object-cover w-[56px] h-[56px] rounded-[100%]`}
                                    />
                                    <div className="ml-3">
                                      <div className="font-semibold text-[20px]">
                                        {val.title ? toCapital(val.title) + `.` : "" } {toCapital(val.name)}
                                      </div>
                                      <div className="flex gap-2 mt-2">
                                        <Badge backgroundColor={val.color_bg_role} color={val.color_text_role} label={val.role_name} />
                                        <Badge backgroundColor={val.color_bg_cabang} color={val.color_text_cabang} label={val.cabang_name} />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="mt-4">Agen tidak ditemukan.</div>
                            )}
                            {pemesanOptionsListNextCursor && (
                                <>
                                  <div className="flex justify-center items-center w-full m-4">
                                    <Buttons
                                      onClick={() => handleLoadMoreMember('agent')}
                                      loading={isLoadingLoadMore}
                                      text="Lihat selengkapnya"
                                      customClass="btn-outline btn-secondary btn-sm"
                                      />
                                  </div>
                                </>
                              )}
                          </Scroll>
                      </>
                    ),
                  },
                  {
                    label: "Jamaah",
                    key: "jamaah",
                    children: (
                      <>
                        <Inputs
                          id="searhJamaah"
                          placeholder="Search"
                          customClass="!px-3 !py-4 !pl-[42px] my-2"
                          iconPosition="left"
                          value={keywordMember}
                          onChange={(e) =>
                            setKeywordMember(e.target.value)
                          }
                          icon={IconSearch}
                        />
                        <Scroll
                          height="350px"
                          customClass="pr-2"
                          >
                        {pemesanOptionsList && pemesanOptionsList.length !== 0 ? (
                          pemesanOptionsList.map((val, idx) => {
                            return (
                              <div
                                key={idx}
                                className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                                onClick={() => {
                                  handleSelectPemesan(val);
                                  closeDropdown();
                                }}
                              >
                                <Avatar
                                  value={val}
                                  containerClass={`items-center justify-center w-[56px] h-[56px] rounded-[100%]  bg-slate-100`}
                                  customClass={`object-cover w-[56px] h-[56px] rounded-[100%]`}
                                />
                                <div className="ml-3">
                                  <div className="font-semibold text-[20px]">
                                  {val.title ? toCapital(val.title) + `.` : "" } {toCapital(val.name)}
                                  </div>
                                  <div className="flex gap-2 mt-2">
                                    <Badge backgroundColor={val.color_bg_role} color={val.color_text_role} label={val.role_name} />
                                    {/* <Badge label={val.city} /> */}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="mt-4">Jamaah tidak ditemukan.</div>
                        )}
                        {pemesanOptionsListNextCursor && (
                            <>
                              <div className="flex justify-center items-center w-full m-4">
                                <Buttons
                                  onClick={() => handleLoadMoreMember('jamaah')}
                                  loading={isLoadingLoadMore}
                                  text="Lihat selengkapnya"
                                  customClass="btn-outline btn-secondary btn-sm"
                                  />
                              </div>
                            </>
                          )}
                        </Scroll>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </Dropdowns>

          <AddJamaah 
            packageID={packageID}
            dataForm={dataForm}
            />

        </div>

        <div className="divider">
        </div>
        <div className="flex flex-row justify-end p-4">
          <Buttons 
            text="Simpan" 
            customClass={`btn-primary btn-large`}
            onClick={handleSave}
            />
        </div>
      </div>
    </>
  );
};

export default React.memo(AddBooking);
