import React, { useEffect, useState, useCallback } from "react";
import EditTravel from "./components/EditTravel";
import ViewTravel from "./components/ViewTravel";
import { useDispatch, useSelector } from "react-redux";
import { getTravelAction } from "stores/actions/setting/travel";
import { getAllCabang } from "stores/actions/cabang";

const SettingTravel = () => {
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();

    const { global } = useSelector((state) => state.general);

    const allCabang = useCallback(() => {
        dispatch(
            getAllCabang({
                page_number: global?.currentpage,
                per_page: 2000,
                sort_column: "",
                sort_order: "Desc",
                keyword: "",
            })
        );
    }, [dispatch, global?.currentpage, global?.size]);

    useEffect(() => {
        allCabang();
    }, [allCabang]);

    useEffect(() => {
        dispatch(getTravelAction());
    }, []);

    return (
        <>
            {isEdit ? (
                <EditTravel setIsEdit={setIsEdit} />
            ) : (
                <ViewTravel 
                    setIsEdit={setIsEdit} />
            )}
        </>
    );
};

export default React.memo(SettingTravel);
