import Echo from "laravel-echo";
import { getLocalStorage } from "utils/helpers";

window.Pusher = require('pusher-js');

let accessToken = getLocalStorage("accessToken");
accessToken = accessToken.replace(/"/g, '');

const options = {
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_APP_KEY,
  wsHost: process.env.REACT_APP_PUSHER_HOST,
  wsPort: process.env.REACT_APP_PUSHER_PORT,
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  forceTLS: false,
  disableStats: true,
  encrypted: true,
  enabledTransports: ["ws", "wss"],
  authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
    },
  },
};

const echo = new Echo(options);

// Debug jika terjadi kesalahan koneksi WebSocket
echo.connector.pusher.connection.bind("error", (error) => {
  console.error("WebSocket Error:", error);
});

export default echo;
