import axios from "axios";
import { BASE_URL_ENDPOINT, BASE_URL_ENDPOINT_V2 } from "constant/constanta";

const getListItenaryV2 = (id, payload) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/itinerary?per_page=${payload.per_page}&keyword=${payload.keyword}&sort_column=${payload.sort_column}&sort_order=${payload.sort_order}&page_number=${payload.page_number}&package_id=${payload.package_id}`
  );

const getDetailItenaryV2 = (id_paket, id_itenary) =>
  axios.get(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_paket}/itinerary/detail/${id_itenary}`
  );

const deleteItenaryV2 = (id_paket, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_paket}/itinerary/delete`,
    payload
  );

const insertItenaryV2 = (id_paket, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_paket}/itinerary/save`,
    payload
  );

const getAllStartingPaketV2 = (id_paket) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/package/${id_paket}/startings`);

const duplicatedItenaryV2 = (id_paket, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id_paket}/itinerary/duplicate`,
    payload
  );

const generateItinenaryService = (id_paket, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/package/${id_paket}/itinerary/generate`,
    payload
  );

export const paketItenaryV2 = {
  getListItenaryV2: getListItenaryV2,
  getDetailItenaryV2: getDetailItenaryV2,
  deleteItenaryV2: deleteItenaryV2,
  insertItenaryV2: insertItenaryV2,
  getAllStartingPaketV2: getAllStartingPaketV2,
  duplicatedItenaryV2,
  generateItinenaryService,
};
