import React from "react";
import PropTypes from "prop-types";

import ImageField from "components/image_field";
import UploadArea from "components/Uploadarea";

const AddGaleriUpload = ({ setFieldValue, value, errors }) => {
  return (
    <>
      <div className="mt-4">
        {/* <ImageField
          id="image"
          name="image"
          label="Upload Gambar"
          error={errors.image}
          errorMesssage={errors.image}
          setFile={(file) => setFieldValue("image", file?.path)}
        /> */}
        <UploadArea 
            id="image"
            label="Upload Gambar"
            onChange={(e) => setFieldValue("image", e)}
            customClass="h-[400px]"
          />
      </div>
    </>
  );
};

AddGaleriUpload.propTypes = {
  setFieldValue: PropTypes.func,
  value: PropTypes.object,
  errors: PropTypes.object,
};

export default React.memo(AddGaleriUpload);
