const initialState = {
  isLoading: false,
  isLoadingExport: false,
  dataLaporanManasikLokasi: [],
  dataExportManasikLokasi: [],
  dataLaporanManasikAbsensi: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_LOADING_EXPORT":
      return {
        ...state,
        isLoadingExport: action.payload,
      };
    case "SET_DATA_LAPORAN_MANASIK_LOKASI":
      return {
        ...state,
        dataLaporanManasikLokasi: action.payload,
      };
    case "SET_DATA_EXPORT_MANASIK_LOKASI":
      return {
        ...state,
        dataExportManasikLokasi: action.payload,
      };
    case "SET_DATA_LAPORAN_MANASIK_ABSENSI":
      return {
        ...state,
        dataLaporanManasikAbsensi: action.payload,
      };
    default:
      return state;
  }
}
