
import CouponIcon from "assets/icons/colored/Coupon.svg";
import TrashIcon from "assets/icons/trash.svg";
import { IDRFormater, handleName } from "utils/helpers";

const AddCouponCard = ({
  coupon,
  onRemove = () => {},
}) => {

  return (
    <>
      <div className="bg-white p-4 rounded-xl shadow-soft">

        <div className="flex justify-between font-semibold text-sm items-center mb-4">
          
          <div className="flex items-center gap-4">
            <img src={CouponIcon} className="!w-6 !h-6" />
            <div className="font-semibold text-sm">
              Kupon {coupon?.code}
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div>
              {IDRFormater(coupon?.total_amount)}
            </div>
            <img onClick={() => {
              onRemove(coupon)
            }} src={TrashIcon} className="!w-5 !h-5 cursor-pointer" />
          </div>
          
        </div>

        <div className="text-xs font-semibold flex flex-col gap-4">
          {coupon?.jamaah_orders.map((jamaah, index) => {
            return (
              <>
                <div key={index}>
                  <span className="mr-1">•</span> {handleName(jamaah.title, jamaah.name)}
                </div>
              </>
            ) 
          })}
        </div>

      </div>
    </>
  );
};

export default AddCouponCard;