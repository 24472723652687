import axios from "axios";
import {
  BASE_URL_ENDPOINT,
  BASE_URL_ENDPOINT_V2,
} from "../../../constant/constanta";

export const getListService = (params) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/notifications`, {params});

export const getUnreadCountService = (params) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/notifications/unread-count`, {params});

export const readService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/notifications/read`, payload);
