import { makeStyles } from '@material-ui/core';
// import { theme } from '../../utils/mui_theme';

const useStyles = makeStyles(() => {
  return {
    rowLight: {
      backgroundColor: '#ffffff',
      '&.ant-table-row-selected': {
        backgroundColor: '#FF9C9A!important',
        '& .action .action-content': {
          backgroundColor: '#FF9C9A!important',
          opacity: '1',
        },
        '& .ant-table-cell': {
          backgroundColor: '#FF9C9A!important',
        },
        boxShadow: "0px 6px 64px -4px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.04), 0px 6px 32px rgba(0, 0, 0, 0.04)"
      },
      '&:hover': {
        '& .ant-table-cell': {
          backgroundColor: '#ffffff!important',
        },
        '& .action .action-content': {
          backgroundColor: '#ffffff!important',
          opacity: '1!important',
        },
      }
    },
    rowStripped: {
      backgroundColor: '#FBF3F2',
      '&:hover .ant-table-cell': {
        backgroundColor: '#FBF3F2!important',
      },
      '&:hover .action .action-content': {
        backgroundColor: '#FBF3F2!important',
      },
      '&.ant-table-row-selected': {
        boxShadow: "0px 6px 64px -4px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.04), 0px 6px 32px rgba(0, 0, 0, 0.04)"
      },
      '&.ant-table-row-selected .ant-table-cell': {
        backgroundColor: '#FF9C9A!important'
      },
      '&.ant-table-row-selected .action .action-content': {
        backgroundColor: '#FF9C9A!important',
        opacity: '1!important',
      }
    },
    action: {
      display: 'none',
      '&hover': {
        display: 'inline-block',
      },
    },
    rowHover: {
      position: "relative",
      width: "100%",
      '&:hover' : {
        boxShadow: 'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)',
        zIndex: '2!important',
      }
    }
  };
});

export default useStyles;
