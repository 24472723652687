import React, { useCallback, useEffect, useState } from "react";
import { Radio, Checkbox } from "antd";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import MoslemeTable from "components/Table";
import Badge from "components/badge";
import { useDispatch, useSelector } from "react-redux";
import { deleteDoaAction } from "stores/actions/layananIslami/doa";
import PopupAddTambahDoa from "./PopupAddTambahDoa";
import Popup from "components/popup";

const TableDoa = ({
    data,
    getDoa,
    size,
    setSize,
    currentPage,
    setCurrentPage,
}) => {
    const columns = [
        {
            title: "Judul",
            dataIndex: "judul",
            render: (text) => <span>{text}</span>,
        },
        {
            title: "Kategori",
            dataIndex: "kategori",
            render: ({ tags, color, backgroundColor }) => (
                <Badge
                    label={tags}
                    color={color}
                    backgroundColor={backgroundColor}
                    customClass="ml-1 font-bold"
                />
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            render: ({ tags, color, backgroundColor }) => (
                <Badge
                    label={tags}
                    color={color}
                    backgroundColor={backgroundColor}
                    customClass="ml-1 font-bold"
                />
            ),
        },
        {
            title: "",
            dataIndex: "action",
            render: (s) => (
                <div className="action">
                    <div className="action-content">
                        <div className="action-content-item">
                            <Eye
                                onClick={() => alert(`show id:`)}
                                size="15"
                                color="#141414"
                                className="cursor-pointer"
                            />
                        </div>
                        <div className="action-content-item">
                            <Trash
                                onClick={() => handleDelete(s.id_doa)}
                                size="15"
                                color="#141414"
                                className="cursor-pointer"
                            />
                        </div>
                        <div className="action-content-item">
                            <DocumentCopy
                                onClick={() => alert(`copy id:`)}
                                size="15"
                                color="#141414"
                                className="cursor-pointer"
                            />
                        </div>
                        <div className="action-content-item">
                            <Edit
                                onClick={() => handleEdit(s)}
                                size="15"
                                color="#141414"
                                className="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    const dispatch = useDispatch();
    const { doa, isLoading } = useSelector((state) => state.doa);

    // Loading
    // Expaneble
    // Selection & type state
    const [selectionType, setSelectionType] = useState("checkbox");
    const [withSelection, setWithSelection] = useState(false);
    const [selectedDoa, setSelectedDoa] = useState();
    const [openPopup, setOpenPopup] = useState(false);

    // Pagination state

    // Pagination config
    const pagination = {
        showSizeChanger: true,
        total: doa?.total_data || 1,
        current: currentPage,
        pageSize: size,
        defaultPageSize: 10,
        pageSizeOptions: ["10", "20"],
    };

    // func to set size pagination
    const onSizeChange = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        setSize(pageSize);
    };

    const handleClosePopup = useCallback(() => {
        setOpenPopup(false);
    }, []);

    const handleEdit = (data) => {
        setSelectedDoa(data);
        setOpenPopup(true);
    };

    const handleDelete = async (id) => {
        const status = dispatch(deleteDoaAction(id));
        if (status == 200) {
            getDoa();
        }
    };

    // if have expaneble, this is the props example..
    // manipulated data with action
    const sourceData = data;

    return (
        <>
            <MoslemeTable
                loading={isLoading}
                columns={columns}
                data={data}
                withSelection
                selectionType="checkbox"
                pagination={pagination}
                customPaginationClass="pr-4"
                customClass="mt-4"
                onPageChange={(page) => setCurrentPage(page)}
                onSizeChange={(currentPage, pageSize) =>
                    onSizeChange(currentPage, pageSize)
                }
            />
            <Popup
                open={openPopup}
                handleClose={handleClosePopup}
                title="Edit Doa"
                width="982px"
                height="648px"
            >
                <PopupAddTambahDoa
                    handleClosePopup={handleClosePopup}
                    getDoa={getDoa}
                    data={selectedDoa}
                />
            </Popup>
        </>
    );
};

export default TableDoa;
