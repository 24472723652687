import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropType from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import uuid from "react-uuid";
import dayjs from "dayjs";

import AppsSlideAction from "stores/actions/apps/slide";
import { setDataUpload } from "stores/actions/uploadFile";

import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import ImageField from "components/image_field";
import TextAreas from "components/form/textarea";
import DateRangePicker from "components/datepicker/DateRangePicker";

import Close from "assets/icons/Close.svg";
import UploadArea from "components/Uploadarea";
import { handleError, handleErrorCustom, handleSuccess } from "stores/actions/errorGeneral";

const AddSlide = ({ setOpenPopup, edit, handleSuccess }) => {
  const dispatch = useDispatch();
  const [imageSlide, setImageSlide] = useState({
    value: `${edit ? edit.image : ""}`,
    error: false,
  });

  const formikAddOrEditSlide = useFormik({
    initialValues: {
      title: "",
      tipe: "",
      link: "",
      date: [],
      deskripsi: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Judul Wajib diisi."),
      tipe: Yup.string().required("Tipe Wajib dipilih."),
      link: Yup.string().required("Link Wajib diisi."),
      date: Yup.array()
        .of(Yup.string())
        .min(1, "Tanggal Wajib dipilih.")
        .required("Tanggal Wajib dipilih."),
      deskripsi: Yup.string().required("Deskripsi Wajib diisi."),
    }),
    onSubmit: async (values, actions) => {
      if (imageSlide.value === "" || imageSlide.value === undefined) {
        setImageSlide({
          value: imageSlide.value,
          error:
            imageSlide.value === "" || imageSlide.value === undefined
              ? true
              : false,
        });
      } else {
        if (edit) {
          // console.log("HIIIII")
          const status = await dispatch(
            AppsSlideAction.postData({
              id: edit.id,
              judul: values.title,
              jenis_slide: values.tipe,
              image: imageSlide.value,
              link_image: values.link,
              start_date: values.date[0],
              end_date: values.date[1],
              deskripsi: values.deskripsi,
              edit: true,
            })
          );
          // console.log(status)
          if (status === 200) {
            actions.resetForm();
            // dispatch(resetDataUpload());
            setOpenPopup({
              show: false,
              edit: null,
            });
            // console.log("HALO");
            handleSuccess();
            // dispatch(handleSuccess(null, {"message": "Slide Berhasil diupdate", "code": 200}));
          } else {
            // dispatch(handleErrorCustom("Terjadi kesalahan", 400));
          }
        } else {
          if (Object.keys(values).length !== 0) {
            const status = await dispatch(
              AppsSlideAction.postData({
                judul: values.title,
                jenis_slide: values.tipe,
                image: imageSlide.value,
                link_image: values.link,
                start_date: values.date[0],
                end_date: values.date[1],
                deskripsi: values.deskripsi,
              })
            );
            if (status === 200) {
              actions.resetForm();
              // dispatch(resetDataUpload());
              setOpenPopup({
                show: false,
                edit: null,
              });
              handleSuccess();
              // dispatch(handleSuccess(null, {"message": "Slide Berhasil ditambahkan", "code": 200}));
            } else {
              // dispatch(handleErrorCustom("Terjadi kesalahan", 400));
            }
          } else {
            actions.setSubmitting(false);
            setImageSlide({
              value: "",
              error: true,
            });
          }
        }
      }
    },
  });

  const slideTypeOptions = [
    {
      value: "web",
      label: "Web",
    },
    {
      value: "mobile",
      label: "Mobile",
    },
  ];

  useEffect(() => {
    if (edit !== null && edit?.image) {
      // dispatch(
      //   setDataUpload([
      //     {
      //       id: uuid(), // di import dari library 'uuid'
      //       fileName: "Slide Image",
      //       path: edit?.image,
      //       name: "slide_image", // harus unique
      //     },
      //   ])
      // );
      formikAddOrEditSlide.setValues({
        title: edit?.judul,
        tipe: edit?.jenis_slide,
        link: edit?.link_image,
        date: [edit?.start_date, edit?.end_date],
        deskripsi: edit?.deskripsi,
      });
    } else {
      // dispatch(resetDataUpload());
    }
  }, [edit]);

  useEffect(() => {
    if (
      formikAddOrEditSlide.touched.tipe &&
      formikAddOrEditSlide.values.tipe === ""
    ) {
      formikAddOrEditSlide.setFieldError("tipe", "Tipe Wajib dipilih.", true);
    }
  }, [formikAddOrEditSlide.touched.tipe]);

  return (
    <>
      <div className="bg-white border-[1px] border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <Inputs
              id="title"
              label="Judul"
              placeholder="Masukkan Judul"
              value={formikAddOrEditSlide.values.title}
              onBlur={formikAddOrEditSlide.handleBlur}
              error={
                formikAddOrEditSlide.touched.title &&
                formikAddOrEditSlide.errors.title &&
                formikAddOrEditSlide.errors.title
              }
              customClass={
                formikAddOrEditSlide.touched.title &&
                formikAddOrEditSlide.errors.title &&
                "input-error"
              }
              onChange={formikAddOrEditSlide.handleChange}
            />
            <Selects
              id="tipe"
              label="Jenis Slide"
              placeholder="Pilih Jenis Slide"
              values={
                slideTypeOptions.filter(
                  (item) => item.value === formikAddOrEditSlide.values.tipe
                )
              }
              options={slideTypeOptions}
              value={
                edit !== null && edit.jenis_slide
                  ? [
                      {
                        label: edit.jenis_slide,
                        value: edit.jenis_slide,
                      },
                    ]
                  : []
              }
              error={
                formikAddOrEditSlide.touched.tipe &&
                formikAddOrEditSlide.errors.tipe &&
                formikAddOrEditSlide.errors.tipe
              }
              customClass={
                formikAddOrEditSlide.touched.tipe &&
                formikAddOrEditSlide.errors.tipe &&
                "select-error"
              }
              onDropdownClose={() => {
                formikAddOrEditSlide.setFieldTouched("tipe", true, false);
              }}
              onChange={(e) => {
                formikAddOrEditSlide.setFieldValue("tipe", e[0]?.value);
                formikAddOrEditSlide.setFieldError("tipe", "", true);
              }}
              itemRenderer={({ state, item, methods }) => {
                return (
                  <div
                    className={`react-dropdown-select-item ${
                      state.values.length !== 0 &&
                      state.values[0].value === item.value &&
                      "react-dropdown-select-item-selected"
                    }`}
                    onClick={() => methods.addItem(item)}
                  >
                    {item.label}
                  </div>
                );
              }}
            />
            <div>
              <UploadArea
                label="Tambahkan Gambar"
                id="slide_image"
                name="slide_image"
                defaultUrl={imageSlide.value}
                onChange={(e) => setImageSlide({ value: e, error: false })}
                handleRemove={() => setImageSlide({ value: "", error: false })}
                customClass="!h-[200px]"
                />
              {/* <ImageField
                name="slide_image"
                label="Tambahkan Gambar"
                customContainer="h-[200px]"
                error={imageSlide.error}
                errorMesssage={imageSlide.error ? "Gambar Wajib dipilih" : ""}
                onRemove={() =>
                  setImageSlide({
                    value: "",
                    error: false,
                  })
                }
                setFile={(file) =>
                  setImageSlide({
                    value: file?.path,
                    error: false,
                  })
                }
              /> */}
            </div>
            <div>
              <Inputs
                id="link"
                label="Link"
                placeholder="Masukkan Link"
                value={formikAddOrEditSlide.values.link}
                onBlur={formikAddOrEditSlide.handleBlur}
                error={
                  formikAddOrEditSlide.touched.link &&
                  formikAddOrEditSlide.errors.link &&
                  formikAddOrEditSlide.errors.link
                }
                customClass={
                  formikAddOrEditSlide.touched.link &&
                  formikAddOrEditSlide.errors.link &&
                  "input-error"
                }
                onChange={formikAddOrEditSlide.handleChange}
              />
              <DateRangePicker
                name="date"
                id="date"
                containerClass="mt-4"
                placeholder={`Tanggal Awal`}
                placeholder2={`Tanggal Akhir`}
                value={formikAddOrEditSlide.values.date}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    formikAddOrEditSlide.setFieldTouched({
                      ...formikAddOrEditSlide.touched,
                      date: true,
                    });
                  } else {
                    formikAddOrEditSlide.setFieldTouched({
                      ...formikAddOrEditSlide.touched,
                      date: false,
                    });
                  }
                }}
                onChange={(e) => {
                  formikAddOrEditSlide.setFieldValue("date", e);
                }}
              />
              {formikAddOrEditSlide.touched.date &&
                formikAddOrEditSlide.errors.date && (
                  <div className="flex flex-row items-center gap-2 mt-1">
                    <img src={Close} alt="close" />
                    <div className="text-[#e82320]">
                      {formikAddOrEditSlide.errors.date}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <TextAreas
            id="deskripsi"
            containerClass="mt-4"
            label="Deskripsi"
            placeholder="Masukkan Deskripsi"
            value={formikAddOrEditSlide.values.deskripsi}
            onBlur={formikAddOrEditSlide.handleBlur}
            error={
              formikAddOrEditSlide.touched.deskripsi &&
              formikAddOrEditSlide.errors.deskripsi &&
              formikAddOrEditSlide.errors.deskripsi
            }
            customClass={
              formikAddOrEditSlide.touched.deskripsi &&
              formikAddOrEditSlide.errors.deskripsi &&
              "textarea-error"
            }
            onChange={formikAddOrEditSlide.handleChange}
          />
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end gap-2 p-4">
          <Buttons
            text="Kembali"
            customClass="btn-outline btn-medium !w-fit"
            onClick={() =>
              setOpenPopup({
                show: false,
                edit: null,
              })
            }
          />
          <Buttons
            text={`${edit ? "Edit" : "Tambah"}`}
            loading={formikAddOrEditSlide.isSubmitting}
            customClass={`btn-primary btn-medium !w-fit ${
              formikAddOrEditSlide.isSubmitting && "loading"
            }`}
            onClick={() => {
              setImageSlide({
                value: imageSlide.value,
                error:
                  imageSlide.value === "" || imageSlide.value === undefined
                    ? true
                    : false,
              });
              formikAddOrEditSlide.handleSubmit();
            }}
          />
        </div>
      </div>
    </>
  );
};

AddSlide.propTypes = {
  setOpenPopup: PropType.func,
  dispatch: PropType.func,
  edit: PropType.object,
};

export default React.memo(AddSlide);
