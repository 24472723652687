import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { handleGetPembimbingManifests } from 'stores/actions/paket/manifest'
import Accordions from 'components/accordions'
import DetailManifest from './DetailManifest'
import Popup from 'components/popup'
import ManifestForm from './ManifestForm'

const Pembimbing = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { pembimbingManifests } = useSelector((state) => state.packageManifest)

  const [isUpdateManifest, setIsUpdateManifest] = useState(false)
  const [updateManifestKey, setUpdateManifestKey] = useState('pas_photo_4x6')
  const [updateManifestTitle, setUpdateManifestTitle] = useState("Pas Photo 4x6")
  const [dataManifestSelected, setDataManifestSelected] = useState({})

  useEffect(() => {
    dispatch(handleGetPembimbingManifests(params.id))
  }, [])

  useEffect(() => {
    console.log("Pembimbing Manifest", pembimbingManifests);
  }, [pembimbingManifests])

  return (
    <>
    <Popup
      open={isUpdateManifest}
      handleClose={() => setIsUpdateManifest(false)}
      title={updateManifestTitle}
      width="900px" 
      children={
        <ManifestForm 
          manifestKey={updateManifestKey}
          dataManifest={dataManifestSelected}
          onUpdate={() => {
            dispatch(handleGetPembimbingManifests(params.id))
          }}
          />
        }
      />
      <div className='pt-4 px-4 border-t'>
        {!!pembimbingManifests && pembimbingManifests.map((manifest, index) => (
          <Accordions 
            avatar={manifest?.photo}
            customClass="!rounded-2xl !mb-4 border"
            title={manifest?.name}
            defaultExpanded={index === 0}
            content={
              <>
                <DetailManifest 
                  manifest={manifest}
                  setIsUpdateManifest={setIsUpdateManifest}
                  setUpdateManifestKey={setUpdateManifestKey}
                  setUpdateManifestTitle={setUpdateManifestTitle}
                  setDataManifestSelected={setDataManifestSelected}
                  />
              </>
            }
            />
        ))}
      </div>
    </>
  )
}

export default Pembimbing