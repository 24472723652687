import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GalleryAction from "stores/actions/apps/gallery";

import Buttons from "components/buttons";
import Selects from "components/form/selects";
import Popup from "components/popup";
import AddGaleri from "./components/AddGaleri";

const AppGaleri = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ gallery }) => gallery);
  const [listGallery, setListGallery] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  
  const [options, setOptions] = useState([
    {
      id: 1,
      value: "haji",
      label: "Haji",
    },
    {
      id: 2,
      value: "umroh",
      label: "Umroh",
    },
    {
      id: 3,
      value: "tabungan",
      label: "Tabungan",
    },
    {
      id: 4,
      value: "tour",
      label: "Tour",
    },
    {
      id: 5,
      value: "haji_badal",
      label: "Haji Badal",
    },
  ]);

  useEffect(() => {
    dispatch(GalleryAction.fetchData());
  }, []);

  useEffect(() => {
    setListGallery(store.data);
  }, [store, store.data.length]);

  const getContent = useCallback(() => {
    if (listGallery.length > 0) {
      return listGallery.map((val, idx) => {
        return (
          <div
            key={idx}
            className="h-[197px] rounded-2xl"
            style={{
              backgroundImage: `url(${val.file})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          ></div>
        );
      });
    }
  }, [listGallery]);

  return (
    <>
      <Popup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        title="Tambah Galeri"
        width="982px"
        children={<AddGaleri setOpenPopup={setOpenPopup} />}
      />
      <div className="bg-white soft-shadow rounded-[20px]">
        <div className="flex flex-row justify-between p-4">
          <div className="text-[20px] font-semibold">Galery</div>
          {permissions?.includes('create_apps') && (
            <>
              <Buttons
                text="Tambah"
                customClass="btn-primary btn-small"
                onClick={() => setOpenPopup(true)}
              />
            </>
          )}
        </div>
        <div className="divider"></div>
        <div className="p-4">
          {/* <Selects
            label="Jenis Paket"
            placeholder="Semua Jenis"
            customClass="!w-[200px]"
            options={options}
            searchable
            itemRenderer={({ state, item, methods }) => {
              return (
                <div
                  className={`react-dropdown-select-item ${
                    state.values.length !== 0 &&
                    state.values[0].value === item.value &&
                    "react-dropdown-select-item-selected"
                  }`}
                  onClick={() => methods.addItem(item)}
                >
                  {item.label}
                </div>
              );
            }}
          /> */}
          <div className="grid grid-cols-3 gap-4 mt-4">{getContent()}</div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AppGaleri);
