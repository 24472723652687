import { Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { getPaketAkomodasi } from "stores/actions/paket/akomodasi";
import { getAllDataAkomodasi } from "stores/actions/datamaster";
import { deletePaketAkomodasi } from "stores/actions/paket/akomodasi";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";

import LocationIcon from "assets/icons/location-black.svg";
import CalendarIcon from "assets/icons/colored/calendar-icon.svg";
import EditIcon from "assets/icons/edit.svg";
import TrashIcon from "assets/icons/trash.svg";

import AlertPopup from "components/popup/alert";
import moment from "moment";
import SkeletonView from "./skeleton-view";
import { useState } from "react";
import Form from "./form";
import Rating from "components/rating";


const ListView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { global } = useSelector((state) => state.general);
  const { paketAkomodasi, isLoading } = useSelector((state) => state?.akomodasi);
  const [editForm, setEditForm] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const allPaketAkomodasi = useCallback(() => {
    dispatch(
      getPaketAkomodasi({
        id_paket: id,
        page_number: 1,
        per_page: 1000,
        keyword: "",
        sort_column: "",
        sort_order: "DESC",
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      getAllDataAkomodasi({
        page_number: 1,
        per_page: 1000,
        keyword: "",
        sort_column: "",
        sort_order: "DESC",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    allPaketAkomodasi();
  }, [allPaketAkomodasi]);

  const onConfirmDelete = () => {
    setIsLoadingDelete(true);

    dispatch(
      deletePaketAkomodasi(
        deleteData.id,
        (message) => {
          setIsLoadingDelete(false);
          setIsDelete(false);
          allPaketAkomodasi();
          dispatch(handleSuccess(null, {message: "Berhasil menghapus akomodasi", code: 200}));
        },
        (error) => {
          setIsLoadingDelete(false);
          setIsDelete(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleDelete = (item) => {
    setIsDelete(true);
    setDeleteData(item);
  };

  if (isLoading) {
    return <SkeletonView />;
  }

  return (
    <>
      <AlertPopup
        open={isDelete}
        handleClose={() => {
          setIsDelete(false);
        }}
        handleContinue={onConfirmDelete}
        loading={isLoadingDelete}
        title="Hapus Data"
        subtitle="Apakah Anda sudah yakin ingin menghapus akomodasi? "
      />
      <div className="space-y-4 w-full">
        {paketAkomodasi?.map((item) => (
          <>
            {editForm?.id !== item.id && (
              <>
                <div
                  key={item.id}
                  className="flex items-start border border-gray-4 rounded-2xl p-4 justify-between"
                >
                  <div className="flex items-start gap-6">
                    <Image
                      width={88}
                      height={88}
                      src={item.thumbnail}
                      alt={item.name}
                      className="rounded-2xl"
                      preview={false}
                    />
                    <div>
                      <div className="font-semibold">{item.name}</div>
                      <div className="flex items-center gap-3 mt-[10px]">
                        <div className="flex items-center gap-2">
                          <img src={LocationIcon} alt="location icon" />
                          <span className="text-[#141414] text-sm">
                            {item.city}
                          </span>
                        </div>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="12"
                            viewBox="0 0 2 12"
                            fill="none"
                          >
                            <path d="M1 0V12" stroke="#E0E0E0" />
                          </svg>
                        </div>
                        <div>
                          <Rating value={item?.bintang} />
                        </div>
                      </div>

                      <div className="mt-4 flex items-center gap-4 text-sm">
                        <div className="flex flex-col gap-2">
                          <div className="font-semibold">Check In</div>
                          <div className="flex items-center gap-2 mt-[10px]">
                            <img
                              src={CalendarIcon}
                              className="w-6 h-6"
                              alt="calendar icon"
                            />
                            <div>
                              {item?.checkin_date
                                ? moment(item?.checkin_date)
                                    ?.locale("id")
                                    ?.format("dddd, D MMMM YYYY")
                                : "-"}
                            </div>
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2"
                                height="12"
                                viewBox="0 0 2 12"
                                fill="none"
                              >
                                <path d="M1 0V12" stroke="#E0E0E0" />
                              </svg>
                            </div>
                            <div>{item?.checkin_time || "-"}</div>
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className="font-semibold">Check Out</div>
                          <div className="flex items-center gap-2 mt-[10px]">
                            <img
                              src={CalendarIcon}
                              className="w-6 h-6"
                              alt="calendar icon"
                            />
                            <div>
                              {item?.checkout_date
                                ? moment(item?.checkout_date)
                                    ?.locale("id")
                                    ?.format("dddd, D MMMM YYYY")
                                : "-"}
                            </div>
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2"
                                height="12"
                                viewBox="0 0 2 12"
                                fill="none"
                              >
                                <path d="M1 0V12" stroke="#E0E0E0" />
                              </svg>
                            </div>
                            <div>{item?.checkout_time || "-"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                  {auth?.user?.access_data === 'all_branches' && permissions?.includes('update_package') && (
                    <div className="flex gap-4">
                      <button
                        onClick={() => {
                          setEditForm(item);
                        }}
                      >
                        <img src={EditIcon} alt="edit icon" width={22} />
                      </button>
                      <button onClick={() => handleDelete(item)}>
                        <img src={TrashIcon} alt="trash icon" width={22} />
                      </button>
                    </div>
                  )}

                </div>
              </>
            )}
            {editForm && item.id == editForm.id && (
              <>
                <div key={item.id}>
                  <Form
                    setIsOpen={() => setEditForm(null)}
                    choice={editForm}
                    setChoice={setEditForm}
                    allPaketAkomodasi={allPaketAkomodasi}
                  />
                </div>
              </>
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default ListView;