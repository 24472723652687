import styled from "styled-components";

const SegmentStyle = styled.div`
  .custom-segment-label {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 4px;
    background: linear-gradient(
      34.99deg,
      #151515 17.46%,
      #3d3d3d 58.62%,
      #595959 87.32%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .custom-segment {
    background: #e0e0e0;
    border-radius: 16px;
    padding: 4px;
  }

  .ant-segmented-thumb {
    border-radius:12px!important;
  }

  .ant-segmented .ant-segmented-item {
    background: transparent;

    &:hover {
      &:after {
        background: transparent !important;
      }
    }

    .ant-segmented-item-label {
      color: #bdbdbd;
      font-weight: 400;
      font-size: 16px;
      padding: 6px 16px;
    }
  }

  .ant-segmented .ant-segmented-item-selected {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(64, 72, 82, 0.1);
    border-radius: 12px;
    box-shadow: 0px 6px 64px -4px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.04), 0px 6px 32px rgba(0, 0, 0, 0.04);


    .ant-segmented-item-label {
      font-weight: 400;
      background: linear-gradient(204.44deg, #01f91a -18.49%, #359b05 84.72%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;

      .segment-label-error {
        background: linear-gradient(204.44deg, #f90601 -18.49%, #8a1212 84.72%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      .segment-label-yellow {
        background: #faab36;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      .segment-label-blue {
        background: #1271ce;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }
`;

export default SegmentStyle;
