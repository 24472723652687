import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import BookingDetailStyle from "./BookingDetail.Style";
import Cards from "components/cards";
import Buttons from "components/buttons";
import BookingDetailHeader from "./components/BookingDetailHeader";
import BookingDetailContent from "./components/BookingDetailContent";

import { handleCancelBooking, handleGenerateEBuktiBooking, repatOrderAPI } from "utils/api/booking";

import IconPrint from "assets/icons/Print.svg";
import IconArrowLeft from "assets/icons/arrow-left.svg";
import Router from "router";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import { handleGetDetailOrder } from "stores/actions/booking";

import Alert from "components/popup/alert";
import Page404 from "components/404";
import moment from "moment";
import ReasonCancelBooking from "../components/ReasonCancelBooking";
import Popup from "components/popup";
import Badge from "components/badge";

import IconSistemMaroon from 'assets/icons/icon-sistem-maroon.svg'
import IconAdminMaroon from 'assets/icons/icon-admin-maroon.svg'
import IconChecklistRound from 'assets/icons/Checklist-Round.svg'

import { InfoCircle } from "iconsax-react";

const BookingDetailPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory()
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  const { bookingDetail } = useSelector((state) => state.booking);

  const [reasonCancel, setReasonCancel] = useState(null)

  const [downloading, setDownloading] = useState(false)
  const [isNotFound, setIsNotFound] = useState(false)

  const [isCancelOrder, setIsCancelOrder] = useState(false)
  const [isLoadingCancel, setIsLoadingCancel] = useState(false)

  const [openCancelReason, setOpenCancelReason] = useState(false)
  const [cancelReasonInfo, setCancelReasonInfo] = useState("")

  const [packageType, setPackageType] = useState("")

  const [confirmRepeatOrderOpen, setConfirmRepeatOrderOpen] = useState(false)
  const [isLoadingRepeatOrder, setIsLoadingRepeatOrder] = useState(false)
  
  const handleDownload = async () => {
    setDownloading(true)
    const response = await handleGenerateEBuktiBooking(params.orderNumber)
    
    if(response.status === 200) {
      var a = document.createElement("a")
      a.href = `${response.data.data.url}`; //Image Base64 Goes here
      a.download = `${response.data.data.url}`; //File name Here
      a.target = "_blank"
      a.click();
    } else {
      dispatch(handleError(response.data))
    }

    setDownloading(false)

  };

  const [order, setOrder] = useState(null)


  const handleSelectedOrder = (order) => {
    setOrder(order)
  }

  const handleCancelOrderConfirm =  async () => {
    setIsLoadingCancel(true)
    const payload = {
      cancel_reason: reasonCancel
    }
    const response = await handleCancelBooking(params.orderNumber, payload)
    const { status, data } = response
    setIsLoadingCancel(false)

    if(status === 200 || status === 201) {
      dispatch(handleSuccess(response))
      setIsCancelOrder(false)
      history.push(`/booking/${packageType}`)
    } else {
      dispatch(handleError(data))
    }

  }

  const handleConfirmRepeatOrder = () => {
    setConfirmRepeatOrderOpen(true)
  }

  const handleRepeatOrder = async () => {
    setIsLoadingRepeatOrder(true)
    const res = await repatOrderAPI(params.orderNumber)
    setIsLoadingRepeatOrder(false)

    if(res.status === 200) {
      dispatch(handleSuccess(res))
      dispatch(handleGetDetailOrder(params.orderNumber, () => {
        handleNotFound()
      }));
      setConfirmRepeatOrderOpen(false)
    } else {
      dispatch(handleError(res.data))
    }

  }

  useEffect(() => {
    switch(bookingDetail?.paket?.tipe_paket) {
      case 1:
        setPackageType("haji")
        break;
      case 2:
        setPackageType("umroh")
        break;
      case 3:
        setPackageType("tour")
        break;
      case 4:
        setPackageType("jasabadal")
        break;
      case 5:
        setPackageType("tabungan")
    }
  }, [bookingDetail])

  return (
    <>
      {!isNotFound && (
        <>
          <Alert 
            open={isCancelOrder}
            title="Yakin akan membatalkan pesanan"
            subtitle="Pesanan yang dibatalkan tidak dapat dikembalikan"
            handleClose={() => setIsCancelOrder(false)}
            handleContinue={() => handleCancelOrderConfirm()}
            text_accept="Yakin"
            text_cancel="Tidak"
            loading={isLoadingCancel}
            />

          <Popup
            open={isCancelOrder}
            handleClose={() => setIsCancelOrder(false)}
            title={`Yakin Membatalkan Bookingan #${params.orderNumber}?`}
            width="550px"
            containerClass=""
            children={
              <ReasonCancelBooking 
                handleContinue={() => handleCancelOrderConfirm()}
                loading={isLoadingCancel}
                handleCancel={() => setIsCancelOrder(false)}
                onChange={(value) => setReasonCancel(value)}
              />
            }
            
          />

        <Popup
          open={openCancelReason}
          handleClose={() => {
            setCancelReasonInfo('')
            setOpenCancelReason(false)
          }}
          title={`Alasan dibatalkan`}
          width="550px"
          containerClass=""
          children={
            <>
              <div className="p-4 border rounded-2xl">
                {cancelReasonInfo}
              </div>
            </>
          }
          
        />

      <Alert
        open={confirmRepeatOrderOpen}
        title="Booking Ulang"
        subtitle={`Apakah anda yakin ingin booking paket "${bookingDetail?.paket?.judul_paket}?" "${bookingDetail?.jumlah_pax}" pax yang dipesan oleh "${bookingDetail?.pemesan?.name}"`}
        handleClose={() => {
          setConfirmRepeatOrderOpen(false)
        }}
        handleContinue={() => handleRepeatOrder()}
        text_accept="Yakin"
        text_cancel="Tidak"
        loading={isLoadingRepeatOrder}
        />
            
          <BookingDetailStyle>
            <Cards containerClass="!p-0">
              <div className="p-4 flex justify-between items-center">
                <div className="flex flex-row gap-2 items-center">
                  <img src={IconArrowLeft} alt="arrow" className="cursor-pointer" onClick={() => window.history.back()} />
                  <div className="text-[28px] font-semibold">Booking {params.orderNumber}</div>
                  <>
                    {bookingDetail.name_payment_status === 'Belum DP' && (
                      <Badge 
                        backgroundColor={'#F7A399'}
                        color={"#FE0000"}
                        label={bookingDetail.name_payment_status.toUpperCase()} />
                    )}
                    {bookingDetail.name_payment_status === 'Sudah DP' && (
                      <Badge 
                        backgroundColor={'#BBDEFB'}
                        color={"#0000FE"}
                        label={`SUDAH DP`} />
                    )}
                    {bookingDetail.name_payment_status === 'Belum Lunas' && (
                      <Badge 
                        backgroundColor={'#FEDCED'}
                        color={"#F20089"}
                        label={`BELUM LUNAS`} />
                    )}
                    {bookingDetail.name_payment_status === 'Lunas' && (
                      <Badge 
                        backgroundColor={'#92E6A7'}
                        color={"#1A7431"}
                        label={bookingDetail.name_payment_status.toUpperCase()} />
                    )}
                    {bookingDetail.name_payment_status === 'Di Batalkan' && (
                      <>
                        <Badge 
                          backgroundColor={'#CED4DA'}
                          color={"#6C757D"}
                          label={<>
                            <div className="flex justify-center items-center gap-2">
                              <span>
                                {`DIBATALKAN`}
                              </span>
                              <div>
                                {bookingDetail.cancel_by > 0 ? <img src={IconAdminMaroon} /> : <img src={IconSistemMaroon} />}
                              </div>
                            </div>
                          </>} />
                          
                          <Buttons 
                            text={
                              <>
                                <InfoCircle size={16} />
                              </>
                            } 
                            onClick={() => {
                              setOpenCancelReason(true)
                              setCancelReasonInfo(bookingDetail.cancel_reason)
                            }}
                            customClass="btn-secondary btn-small !py-[6px] !px-[10px]" />
                      </>
                    )}
                  </>
                </div>
                <div className="flex items-center gap-4">

                {(bookingDetail?.payment_status === 4 && bookingDetail?.cancel_by === 0) && (
                    <>
                      {bookingDetail?.repeated_order ? (
                        <>
                          <Badge 
                            backgroundColor={'#D8E1BE'}
                            customClass=""
                            label={<>
                              <div className="flex gap-1 items-center">
                                <div className="flex-shrink-0">
                                  <img src={IconChecklistRound} alt="checklist"/>
                                </div>
                                <div className="flex-shrink-0 green-linear-text font-semibold pr-3">
                                  Booking Ulang
                                </div>
                              </div>
                            </>}

                            />
                        </>
                      ) : (
                        <>
                          <div>
                            <Buttons 
                              customClass="btn btn-outline btn-primary btn-small" 
                              text="Booking Ulang"
                              onClick={() => handleConfirmRepeatOrder()}
                              />
                          </div>
                        </>
                      )}
                    </>
                  )}

                  <Buttons
                    text="Download E-Bukti Booking"
                    customClass="btn-secondary btn-small py-2"
                    loading={downloading}
                    onClick={() => handleDownload()}
                    />

                  {(bookingDetail?.payment_status != 4 && moment().isBefore(bookingDetail?.paket?.deadline_registration_date)) && (
                    <>
                      {!!permissions?.includes("delete_booking-data") && (
                        <>
                          <Buttons 
                            text="Cancel"
                            customClass="btn-primary btn-small"
                            onClick={() => setIsCancelOrder(true)}
                            />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="divider"></div>
              <div className="p-4">
                <BookingDetailHeader 
                    handleSetOrder={handleSelectedOrder}
                    handleNotFound={() => setIsNotFound(true)}
                  />
                <div className="mt-4">
                  <BookingDetailContent 
                    order={order}
                    />
                </div>
              </div>
            </Cards>
          </BookingDetailStyle>
        </>
      )}
      {isNotFound && <Page404 />}
    </>
  );
};

export default React.memo(BookingDetailPage);
