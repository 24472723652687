import React, { useEffect, useState } from "react";

import IconSingle from "assets/icons/Single.svg";
import IconDouble from "assets/icons/Double.svg";
import IconTriple from "assets/icons/Triple.svg";
import IconQuad from "assets/icons/Quad.svg";
import { toCapital } from "utils/helpers";

import PropTypes from "prop-types";

const RoomBadge = ({
    roomType,
    roomNumber
}) => {
    const [roomTypeName, setRoomTypeName] = useState('')
    const handleRoomTypeIcon = () => {

        if (roomTypeName === 'single') {
            return IconSingle
        }

        if (roomTypeName === 'double') {
            return IconDouble
        }

        if (roomTypeName === 'triple') {
            return IconTriple
        }

        if (roomTypeName === 'quad') {
            return IconQuad
        }
    }

    useEffect(() => {
        let __tempRoomType = roomType
        __tempRoomType = __tempRoomType?.toLowerCase()
        __tempRoomType = __tempRoomType?.replace("_bussiness", "")
        __tempRoomType = __tempRoomType?.replace("tripple", "triple")

        setRoomTypeName(__tempRoomType)
    }, [roomType])

    return (
        <div className="py-1 px-[10px] bg-green-5 rounded-lg inline-flex gap-2 items-center">
            <img src={handleRoomTypeIcon()} alt={roomType} />
            <span className="font-semibold">
                {toCapital(roomTypeName)}
            </span>
            <div className="text-xs font-semibold flex justify-center items-center z-10 !bg-white h-4 w-4 rounded-full border border-red-2">
                <span className="red-linear-text">{roomNumber}</span>
            </div>
        </div>
    )
}

RoomBadge.propTypes = {
    roomType: PropTypes.string,
    roomNumber: PropTypes.number
}

RoomBadge.defaultProps = {
    roomType: 'quad',
    roomNumber: 1
}

export default RoomBadge