import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import Popup from "components/popup";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteIdMasterRekening,
  duplicateRekening,
  getMasterRekening,
} from "stores/actions/datamaster/adminRekening";
import MoslemeTable from "../../../../../components/Table";
import AlertPopup from "../../../../../components/popup/alert";
import { getMasterId } from "../../../../../stores/actions/datamaster";
import useDebounce from "../../../../../utils/helpers/useDebounce";
import CheckboxMaster from "../../checkbox";
import { columns } from "./columns";
function Rekening({ filter }) {
  // redux
  const dispatch = useDispatch();
  const { allRekening, isLoading } = useSelector(
    (state) => state.adminRekening
  );
  const { masterId } = useSelector((state) => state.datamaster);
  const { dataUpload } = useSelector((state) => state.uploadFile);
  const auth = useSelector(({ auth }) => auth)
  // end redux

  const [selectionType, setSelectionType] = useState("checkbox");

  const [input, setInput] = useState({
    bank_name: "",
    logo: true,
    account_number: true,
    account_owner: true,
    description: true,
    currency: true,
  });
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    masterId?.data &&
      setInput({
        bank_name: `${masterId?.data?.bank_name} - CLONE`,
        logo: true,
        account_number: true,
        account_owner: true,
        description: true,
        currency: true,
      });
  }, [masterId?.data, masterId?.data?.bank_name]);

  // Pagination state
  const total = allRekening?.data?.total_data;

  // data table
  let dataTable = allRekening?.data?.data;

  // console.log(allRekening?.data?.data);

  const filterRekening = useDebounce(filter, 1000);

  const masterRekening = useCallback(() => {
    dispatch(
      getMasterRekening({
        page_number: currentpage,
        per_page: size,
        keyword: filterRekening,
        sort_column: "",
        sort_order: "DESC",
      })
    );
  }, [dispatch, filterRekening, currentpage, size]);

  useEffect(() => {
    dispatch(
      getMasterRekening({
        page_number: currentpage,
        per_page: size,
        keyword: filterRekening,
        sort_column: "",
        sort_order: "DESC",
      })
    );
  }, [masterRekening, filterRekening, currentpage, size, dispatch]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const onHandleDelete = () => {
    dispatch(deleteIdMasterRekening(masterId?.data?.id_rekening)).then(
      (item) => {
        dispatch(getMasterId({}));
        masterRekening();
      }
    );
  };

  const onHandleDuplicate = () => {
    input.account_number = input.account_number ? 1 : 0;
    input.account_owner = input.account_owner ? 1 : 0;
    input.description = input.description ? 1 : 0;
    input.currency = input.currency ? 1 : 0;
    input.logo = input.logo ? 1 : 0;
    input.account_number = input.account_number ? 1 : 0;
    input.id = masterId?.data?.id_rekening;
    input.jenis_rekening = 1;
    // console.log("input aku", input);
    dispatch(duplicateRekening(input)).then(() => {
      dispatch(getMasterId({}));
      masterRekening();
    });
  };

  return (
    <div>
      <AlertPopup
        open={masterId?.modal}
        handleClose={() => dispatch(getMasterId({}))}
        handleContinue={onHandleDelete}
        loading={isLoading ? true : false}
      />

      <Popup
        open={masterId?.popup}
        handleClose={() => dispatch(getMasterId({}))}
        title="Duplicate Rekening"
      >
        <Inputs
          id="test"
          placeholder="Masukan nama bank"
          // customClass="input-disabled"
          label="Nama Bank"
          value={input.bank_name}
          onChange={(e) => setInput({ ...input, bank_name: e.target.value })}
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Logo"
          value={input.logo}
          checked={input.logo}
          onChange={(e) =>
            setInput({
              ...input,
              logo: e.target.checked,
            })
          }
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Nomor Rekening"
          value={input.account_number}
          checked={input.account_number}
          onChange={(e) =>
            setInput({
              ...input,
              account_number: e.target.checked,
            })
          }
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Pemilik Rekening"
          value={input.account_owner}
          checked={input.account_owner}
          onChange={(e) =>
            setInput({
              ...input,
              account_owner: e.target.checked,
            })
          }
        />

        <CheckboxMaster
          customClass="mt-4"
          title="Mata Uang"
          value={input.currency}
          checked={input.currency}
          onChange={(e) =>
            setInput({
              ...input,
              currency: e.target.checked,
            })
          }
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Description"
          value={input.description}
          checked={input.description}
          onChange={(e) =>
            setInput({
              ...input,
              description: e.target.checked,
            })
          }
        />
        <div className="grid grid-cols-2 mt-4 items-center gap-x-4">
          <Buttons
            onClick={() => dispatch(getMasterId({}))}
            text="Batal"
            customClass="btn-secondary btn-large"
          />

          <Buttons
            onClick={onHandleDuplicate}
            text="Simpan"
            loading={isLoading ? true : false}
            disabled={isLoading ? true : false}
            customClass="btn-primary btn-large"
          />
        </div>
      </Popup>
      <MoslemeTable
        columns={columns(dispatch, dataUpload, auth?.user?.permissions)}
        loading={isLoading}
        data={dataTable}
        rowId={(record) => record.id_rekening}
        withSelection={true}
        selectionType={selectionType}
        customPaginationClass="pr-4"
        rowKey={(record) => record.id_rekening}
        pagination={pagination}
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) => {
          onSizeChange(currentpage, pageSize);
        }}
      />
    </div>
  );
}

export default Rekening;
