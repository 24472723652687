import React from "react";
import Badge from "components/badge";
import Buttons from "components/buttons";
import { Switch } from "antd";
import "./Perlengkapan.scss";

const PerlengkapanPopup = ({
  setOpen,
  masterPerlengkapan,
  setMasterPerlengkapan,
  loadingAction,
  handleInserPerlengkapan,
}) => {
  const handleChange = (index) => {
    setMasterPerlengkapan(prevState => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        checked: !newState[index].checked
      };
      return newState;
    });
  };

  return (
    <div className="border-[1px] border-solid rounded-2xl border-[#E0E0E0] h-[504px] flex flex-col justify-between content-center">
      <div className="flex flex-col justify-start m-[16px]  ">
        <div className="grid grid-cols-3 gap-4">
          {masterPerlengkapan?.map((item, index) => (
            <div
              className="flex justify-between items-center p-[16px] gap-[16px] w-full h-[58px] perlengkapan-card !border-0 !shadow-soft"
              key={index}
            >
              <div className="flex flex-row justify-between p-4 gap-[8px] w-[320px] h-[58px] items-center">
                <Badge
                  label={item?.name}
                  customClass="uppercase !text-green-700 font-semibold  !bg-[#92E20080]"
                  backgroundColor="#92E20080 "
                  color=""
                />

                <div className="container-switch-perlengkapan">
                  <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={item?.checked}
                    className="!bg-slate-400"
                    onChange={(e) => {
                      handleChange(index);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end items-center p-[16px]">
          <Buttons
            text="Simpan"
            disabled={
              masterPerlengkapan?.filter((item) => item.checked === true)
                ?.length === 0
            }
            customClass="btn-primary btn-medium !w-[186px] float-right"
            onClick={handleInserPerlengkapan}
            loading={loadingAction}
          />
        </div>
      </div>
    </div>
  );
};

export default PerlengkapanPopup;
