import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import UploadArea from "components/Uploadarea"
import Buttons from "components/buttons"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

import { handleUpdateJamaahOrderAPI } from 'utils/api/booking'
import { handleError, handleSuccess } from 'stores/actions/errorGeneral'
import Inputs from 'components/form/inputs'
import Selects from 'components/form/selects'
import DatePicker from 'components/datepicker/DatePicker'

import moment from 'moment'
import dayjs from "dayjs";
import Textarea from 'components/form/textarea'
import InputPhone from 'components/form/inputPhone'
import { handleGetDataJamaah } from 'stores/actions/booking'

const Contact = ({
    jamaahData,
    onSuccess,
    permissions,
    data
}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const formik = useFormik({
        initialValues: {
            jamaah_title: null,
            jamaah_phone: null,
            jamaah_email: null,
            jamaah_identity_number: null,
            user_jamaah_id: null,
            room_type: {
                id: null,
                name: null,
                description: null
            }
        }
    })
    const [isLoading, setIsLoading] = useState(false)

    const { bookingDetail } = useSelector(state => state.booking)

    const handleSave = async () => {
    //     // console.log(formik.values);
        setIsLoading(true)
        const response = await handleUpdateJamaahOrderAPI(params.orderNumber, jamaahData.id, formik.values)
        setIsLoading(false)
        if(response.status === 200) {
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate data manifest", code: 200 }))
            dispatch(handleGetDataJamaah(params.orderNumber))
            onSuccess()
        } else {
            dispatch(handleError(response.data))
        }

    }
    
    useEffect(() => {
        
        formik.setFieldValue('jamaah_name', jamaahData.jamaah_name)
        formik.setFieldValue('jamaah_phone', data.contact_phone)
        formik.setFieldValue('jamaah_email', data.contact_email)
        formik.setFieldValue('jamaah_title', jamaahData.jamaah_title)
        formik.setFieldValue('jamaah_identity_number', jamaahData.jamaah_identity_number)
        formik.setFieldValue('user_jamaah_id', jamaahData.user_jamaah_id)
        formik.setFieldValue('room_type.id', jamaahData.paket_kamar_id)
        formik.setFieldValue('room_type.name', jamaahData.room_type)
        formik.setFieldValue('room_type.description', jamaahData.kamar_description)
        

    }, [])

    return (
        <>
            <div className="border p-4 rounded-t-2xl">
                <div className='w-full'>
                    <InputPhone
                        disabled={!permissions.includes('update_manifest')}
                        name='jamaah_name'
                        id='jamaah_name'
                        label='Nomor Telepon'
                        placeholder='Masukan Nomor Telepon'
                        containerClass='my-4'
                        value={formik.values?.jamaah_phone}
                        onChange={(e) => formik.setFieldValue('jamaah_phone', e)}
                        />
                    <Inputs 
                        disabled={!permissions.includes('update_manifest')}
                        name='jamaah_email'
                        id='jamaah_email'
                        label='Email'
                        placeholder='Masukan Email'
                        containerClass='my-4'
                        value={formik.values?.jamaah_email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        />
                </div>
            </div>
            {permissions.includes('update_manifest') && bookingDetail?.payment_status !== 4 && (
                <div className="border rounded-b-2xl flex justify-between border-t-0 p-4">
                    <div>
                    </div>
                    <div>
                        <Buttons
                            customClass="btn-primary btn-large"
                            text="Simpan"
                            loading={isLoading}
                            onClick={handleSave}
                            />
                    </div>
                </div>
            )}
        </>
    )
}

export default Contact