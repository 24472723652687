import Buttons from "components/buttons"
import AntdSelect from "components/form/antdSelect"
import InputsCurrency from "components/form/inputsCurrency"
import { useEffect, useState } from "react"

import AlertPopup from "components/popup/alert";
import IconTrash from "assets/icons/trash-maroon.svg";

const OperationalOtherForms = ({ 
  label,
  data,
  onChange
 }) => {

  const [isAddingItemPax, setIsAddingItemPax] = useState(false)
  const [removeConfirmShow, setRemoveConfirmShow] = useState(false)

  const [itemPaxIndexSelected, setItemPaxIndexSelected] = useState(null)
  const [paxLableOptions, setPaxLableOptions] = useState([])

  const [rangePaxOptions, setRangePaxOptions] = useState([])


  const handleChangePrice = (rangePax, price) => {
    const checkData = data?.some(item => item.range_pax === rangePax)
    let _tempData = data
    
    if(checkData) {
      _tempData = _tempData.map(item => {
        if(item.range_pax === rangePax) {
          item.price = price
        }
        return item
      })
      
    } else {

      _tempData.push({
        "range_pax": rangePax,
        "price": price
      })

    }

    onChange(_tempData)
  }

  const handleAddingItemPax = () => {
    setIsAddingItemPax(true)
  }
  
  const handleRemoveItem = (index) => {
    setRemoveConfirmShow(true)
    setItemPaxIndexSelected(index)
  }

  const handleItemsOptions = () => {
    // console.log("Data in handleItemsOptions", data, data.some(item => item.range_pax === 1))
    const _dataOptions = Object.entries(rangePaxOptions).map(([key, label]) => ({
      value: label,
      disabled: data?.some(item => item.range_pax == label),
      label: label
    }));

    setPaxLableOptions(_dataOptions);
  };
  

  const handleRangePaxOptions = () => {
    const values = [];
    for (let i = 1; i <= 500; i += 15) {
        const end = Math.min(i + 14, 500); // Menghitung nilai akhir dari rentang
        values.push(`${i}-${end}`);
    }

    setRangePaxOptions(values);
  }

  const handleRemoveItemConfirm = () => {
    const _tempData = data.filter((item, i) => i !== itemPaxIndexSelected)
    onChange(_tempData)
    setRemoveConfirmShow(false)
  }

  useEffect(() => {
    if(data.length > 0 && rangePaxOptions.length > 0) {
      handleItemsOptions()
    }
  }, [data, rangePaxOptions])

  useEffect(() => {
    handleRangePaxOptions()
  }, [])

  return (
    <>
      <AlertPopup
          open={removeConfirmShow}
          handleClose={() => setRemoveConfirmShow(false)}
          handleContinue={() => handleRemoveItemConfirm()}
          subtitle="Apakah anda yakin menghapus isian pax ini?"
        />
       <div className="border rounded-2xl p-4">

        <div className="flex justify-between items-center">
          <div className="font-semibold">
            {label}
          </div>
          <Buttons
            text="Tambah Isian Pax"
            customClass="btn-primary btn-small"
            onClick={handleAddingItemPax}
            disabled={isAddingItemPax}
            />
        </div>

        <div className="flex flex-col gap-4 mt-4">
          {data?.map((item, index) => {
            return (
              <>
                <div className="w-full flex items-center gap-4" key={index}>
                  <div className="w-full">
                    <InputsCurrency
                      label={
                        <>
                          Isi <span className="text-red-3">{item.range_pax}</span> Pax
                        </>
                      }
                      value={item.price}
                      placeholder="Masukan harga"
                      onChange={(val) => (handleChangePrice(item.range_pax, val))}
                    />
                  </div>
                  {index != 0 && (
                    <>
                      <div 
                        className="cursor-pointer mt-[25px]"
                        onClick={() => handleRemoveItem(index)}
                        >
                        <img src={IconTrash} alt="trash"/>
                      </div>
                    </>
                  )}
                </div>
              </>
            )
          })}
          {isAddingItemPax && (
            <>
              <div className="w-full flex items-center gap-4">
                <div className="w-full">
                  <AntdSelect
                    label="Banyak Pax"
                    options={paxLableOptions}
                    placeholder={"Pilih Banyak Pax"}
                    onChange={(dataSelected) => {
                      handleChangePrice(dataSelected.value, 0)
                      setIsAddingItemPax(false)
                    }}
                  />
                </div>
                <div 
                  onClick={() => setIsAddingItemPax(false)}
                  className="cursor-pointer mt-[25px]"
                  >
                  <img src={IconTrash} alt="trash"/>
                </div>
              </div>
            </>
          )}
        </div>

       </div>
    </>
  )
}

export default OperationalOtherForms