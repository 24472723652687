import { useEffect, useState } from "react";

import IconPlus from "assets/icons/icon-plus.svg";

import AntdSelect from "components/form/antdSelect";
import InputsCurrency from "components/form/inputsCurrency";

import { useFormik } from "formik";
import * as Yup from 'yup';

const AddItemTourCountryForm = ({
  combinationsDayNightExists,
  onAddItem 
}) => {

  const [totalDayOptions, setTotalDayOptions] = useState([])
  const [totalNightOptions, setTotalNightOptions] = useState([])

  const formik = useFormik({
    initialValues: {
      total_days: null,
      total_nights: null,
      price: 0
    },
    validationSchema: Yup.object({
      total_nights: Yup.number().min(1).required('Jumlah malam wajib diisi'),
      total_days: Yup.number().min(1).required('Jumlah hari wajib diisi'),
      price: Yup.number().min(1).required('Harga wajib diisi')
    }),
  })

  const handleSetTotalOptions = (disableNumbers = []) => {
    let _tempOptions = []
  
    for(let i = 1; i <= 20; i++) {
      _tempOptions.push({
        value: i,
        label: i,
        disabled: disableNumbers.includes(i)
      })
    }
  
    return _tempOptions
  }

  const handleParseTotalOptions = () => {
    let _totalDayOptions = []
    let _totalNightOptions = []

    // Jika total_days sudah dipilih, disable total_nights yang kombinasinya sudah ada
    const disableNightsForSameDay = formik.values.total_days
      ? combinationsDayNightExists
          .filter(c => c.total_days === formik.values.total_days)
          .map(c => c.total_nights)
      : [];

    // Jika total_nights sudah dipilih, disable total_days yang kombinasinya sudah ada
    const disableDaysForSameNight = formik.values.total_nights
      ? combinationsDayNightExists
          .filter(c => c.total_nights === formik.values.total_nights)
          .map(c => c.total_days)
      : [];

    _totalDayOptions = handleSetTotalOptions(disableDaysForSameNight)
    _totalNightOptions = handleSetTotalOptions(disableNightsForSameDay)

    setTotalDayOptions(_totalDayOptions)
    setTotalNightOptions(_totalNightOptions)
  }

  const handleAdd = () => {
    onAddItem(formik.values)

    formik.resetForm()
  }

  useEffect(() => {
    handleParseTotalOptions()
  }, [combinationsDayNightExists, formik.values.total_days, formik.values.total_nights])

  return (
    <>
      <div className="flex gap-4">
        <div className="w-full">
          <AntdSelect 
            label={"Pilih Jumlah Hari"}
            placeholder={"Pilih jumlah hari"}
            showSearch={true}
            options={totalDayOptions}
            value={formik.values.total_days}
            onChange={(dataSelected) => {
              formik.setFieldValue("total_days", dataSelected.value)
            }}
          />
        </div>
        <div className="w-full">
          <AntdSelect 
            label={"Pilih Jumlah Malam"}
            placeholder={"Pilih jumlah malam"}
            showSearch={true}
            options={totalNightOptions}
            value={formik.values.total_nights}
            onChange={(dataSelected) => {
              formik.setFieldValue("total_nights", dataSelected.value)
            }}
          />
        </div>
        <div className="w-full">
            <InputsCurrency
                label="Harga"
                value={formik.values.price}
                placeholder="Masukan harga tourItem"
                onChange={(val) => {
                    formik.setFieldValue("price", val)
                }}
            />
        </div>
        <div 
          onClick={() => !!(formik.isValid && formik.dirty) && handleAdd()}
          className={`cursor-pointer  ${!(formik.isValid && formik.dirty) && "!cursor-not-allowed"} flex-shrink-0 flex items-center justify-center`}>
            <img src={IconPlus} className="mt-6" />
        </div>
      </div>
    </>
  )
}

export default AddItemTourCountryForm
