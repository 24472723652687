import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertPopup from "components/popup/alert";
import Popup from "components/popup";
import MoslemeTable from "components/Table";
import {
  deletePaketTransportation,
  getListPaketTransportasi,
  getListTransportasi,
} from "stores/actions/paket/transportasi";
import EditTransportasi from "../../components/transportasi/modal/EditTransportasi";
import Buttons from "components/buttons";
import { IcJamPasir } from "assets/icons/dashboard";
import { columns } from "./columns";
import { detailById } from "stores/actions/general";
import { useParams } from "react-router-dom";
import IconSearch from "assets/icons/search-normal.svg";
import IconSquare from "assets/icons/send-square.svg";
import IconActivity from "assets/icons/activity.svg";
import Inputs from "components/form/inputs";
import { getAllDataTransport } from "stores/actions/datamaster";
import useDebounce from "utils/helpers/useDebounce";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";

const Transportasi = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [currentpage, setCurrentpage] = useState(1);
  const { listPaketTransportasi, isLoading } = useSelector(
    (state) => state?.transportasi
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [search, setSearch] = useState("");
  const keyword = useDebounce(search, 1000);
  const [isLoadingDelete, setIsloadingDelete] = useState(false);
  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  const [choice, setChoice] = useState(null);

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const [size, setSize] = useState(10);
  const total = listPaketTransportasi?.total_data;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  useEffect(() => {
    handleGetAllDataTranport();
  }, []);

  useEffect(() => {
    dispatch(
      getListPaketTransportasi({
        keyword: keyword,
        page_number: currentpage,
        per_page: 10,
        sort_column: "name",
        sort_order: "ASC",
        id_paket: id,
      })
    );
  }, [keyword, currentpage]);

  useEffect(() => {
    dispatch(
      getListPaketTransportasi({
        keyword: keyword,
        page_number: 1,
        per_page: 10,
        sort_column: "name",
        sort_order: "ASC",
        id_paket: id,
      })
    );
  }, [keyword]);

  const addTransportation = () => {
    setIsOpen(true);
  };

  const handleGetAllDataTranport = () => {
    const payload = {
      page_number: 1,
      per_page: 10,
      keyword: "",
      sort_column: "",
      sort_order: "DESC",
    };

    dispatch(getAllDataTransport(payload));
  };

  const handleDeleteDataTransportasi = () => {
    setIsloadingDelete(true);

    dispatch(
      deletePaketTransportation(
        choice.id,
        {},
        (message) => {
          setIsDelete(false);
          handleAllPaketTranportasi();
          dispatch(handleSuccess(null, { message: "Berhasil menghapus transportasi", code: 200 }));
          setIsloadingDelete(false);
        },
        (error) => {
          setIsloadingDelete(false);
          setIsDelete(false);

          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleAllPaketTranportasi = () => {
    dispatch(
      getListPaketTransportasi({
        keyword: "",
        page_number: currentpage,
        per_page: 10,
        sort_column: "name",
        sort_order: "ASC",
        id_paket: id,
      })
    );
  };

  return (
    <div className="">
      <Popup
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        title={"Tambah Transportasi"}
        width={"988px"}
        containerClass="!z-[1000]"
      >
        {
          <EditTransportasi
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleGetAllDataTranport={handleAllPaketTranportasi}
          />
        }
      </Popup>
      <AlertPopup
        open={isDelete}
        handleClose={() => setIsDelete(false)}
        handleContinue={handleDeleteDataTransportasi}
        loading={isLoadingDelete}
        title="Hapus Data"
        subtitle="Apakah Anda sudah yakin ingin menghapus Transportasi? "
      />
      <div className="flex flex-row justify-between items-center  border-solid border-b-[1px] border-[#E0E0E0] p-[16px]">
        <div className="flex flex-row justify-start items-center">
          <Inputs
            id="test"
            placeholder="Search"
            customClass="!w-[245px] !h-[44px]"
            iconPosition="left"
            icon={IconSearch}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          {/* <img
            width={16}
            height={16}
            className="ml-4 cursor-pointer"
            src={IconSquare}
            alt="square"
          />
          <img
            width={16}
            height={16}
            className="ml-4 cursor-pointer"
            src={IconActivity}
            alt="activity"
          /> */}
        </div>
        {listPaketTransportasi?.data?.length > 0 && (
          <>
            {auth.user?.access_data === 'all_branches' && (
              <>
                {permissions?.includes('update_package') && (
                  <>
                    <Buttons
                      text="Tambah data"
                      customClass="btn-primary btn-small "
                      onClick={addTransportation}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      {listPaketTransportasi?.data?.length === 0 ? (
        <main className="flex flex-col items-center gap-y-4 justify-center h-[69vh]">
          <img alt="hourglass" src={IcJamPasir} />
          <div>Belum ada Transportasi di kategori ini</div>
          {auth.user?.access_data === 'all_branches' && (
              <>
                {permissions?.includes('update_package') && (
                  <>
                    <Buttons
                      text="Tambah data"
                      customClass="btn-primary btn-small "
                      onClick={addTransportation}
                    />
                  </>
                )}
              </>
            )}
        </main>
      ) : (
        <MoslemeTable
          expandable={false}
          loading={isLoading}
          columns={columns(dispatch, setIsDelete, setChoice, auth)}
          data={listPaketTransportasi?.data}
          withSelection={true}
          selectionType={false}
          rowKey={(record) => record.id}
          pagination={pagination}
          customPaginationClass="pr-4"
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      )}
    </div>
  );
};

export default Transportasi;
