import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import UploadArea from "components/Uploadarea"
import Buttons from "components/buttons"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

import { handleUpdateManifestAPI } from 'utils/api/booking'
import { handleError, handleSuccess } from 'stores/actions/errorGeneral'
import Inputs from 'components/form/inputs'
import Selects from 'components/form/selects'
import DatePicker from 'components/datepicker/DatePicker'

import moment from 'moment'
import dayjs from "dayjs";
import Textarea from 'components/form/textarea'
import Tab from 'components/tab'
import { toPermalink } from 'utils/helpers'
import { handleGetDataJamaah } from 'stores/actions/booking'

const VaccineCovid = ({
    data,
    jamaahOrderID,
    onSuccess,
    permissions,
    jamaahName,
    orderNumber
}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const formik = useFormik({
        initialValues: {
            order_kamar_mapping_id: jamaahOrderID,
            data
        }
    })
    const [isLoading, setIsLoading] = useState(false)
    const [tabActive, setTabActive] = useState(1)

    const { bookingDetail } = useSelector(state => state.booking)

    const handleSave = async () => {
        // console.log(formik.values);
        setIsLoading(true)
        const response = await handleUpdateManifestAPI(params.orderNumber, formik.values)
        setIsLoading(false)
        if(response.status === 200) {
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate data manifest", code: 200 }))
            dispatch(handleGetDataJamaah(params.orderNumber))
            onSuccess()
        } else {
            dispatch(handleError(response.data))
        }

    }
    
    const handleChangeTab = (key) => {
        setTabActive(key)
    }

    useEffect(() => {
        formik.setValues({
            order_kamar_mapping_id: jamaahOrderID,
            data
        })
    }, [data])



    return (
        <>
            <div className="border p-4 rounded-t-2xl">
                <div className='mt-4 pb-4 flex gap-4 border-b'>
                    <div className='w-1/2'>
                        <UploadArea
                            viewOnly={!permissions.includes('update_manifest') || bookingDetail?.payment_status === 4}
                            type='chose'
                            label="Dokumen Vaksin 1"
                            customClass="w-full"
                            defaultUrl={formik.values?.data?.vaccine_covid_1}
                            onChange={(url) => formik.setFieldValue('data.vaccine_covid_1', url)}
                            handleRemove={() => formik.setFieldValue('data.vaccine_covid_1', null)}
                            customName={`vaksin_covid_1-${orderNumber}-${toPermalink(jamaahName)}`}
                            />
                    </div>
                    <div className='w-1/2'>
                        <div className='mt-4'>
                            <Inputs 
                                disabled={!permissions.includes('update_manifest')}
                                label='Jenis Vaksin 1'
                                name='data.vaccine_covid_1_type'
                                id='data.vaccine_covid_1_type'
                                placeholder='Masukan Jenis Vaksin'
                                containerClass='mb-4'
                                value={formik.values?.data?.vaccine_covid_1_type}
                                onChange={(e) => formik.setFieldValue('data.vaccine_covid_1_type', e.target.value)}
                                />
                            </div>
                        <div className='mt-4'>   
                            <div className='text-sm font-semibold mb-2'>Tanggal Vaksin 1</div>
                            <DatePicker 
                                disabled={!permissions.includes('update_manifest')}
                                customClass='w-full'
                                value={formik.values?.data?.vaccine_covid_1_date ? dayjs(moment(formik.values?.data?.vaccine_covid_1_date).format("YYYY-MM-DD"), "YYYY-MM-DD") : dayjs(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
                                onChange={(val) => {
                                    formik.setFieldValue('data.vaccine_covid_1_date', moment(val, "DD-MM-YYYY").format("YYYY-MM-DD"))
                                }}
                                />
                        </div>
                    </div>
                </div>
                <div className='mt-4 pb-4 flex gap-4 border-b'>
                    <div className='w-1/2'>
                        <UploadArea
                            viewOnly={!permissions.includes('update_manifest') || bookingDetail?.payment_status === 4}
                            type='chose'
                            label="Dokumen Vaksin 2"
                            customClass="w-full"
                            defaultUrl={formik.values?.data?.vaccine_covid_2}
                            onChange={(url) => formik.setFieldValue('data.vaccine_covid_2', url)}
                            handleRemove={() => formik.setFieldValue('data.vaccine_covid_2', null)}
                            customName={`vaksin_covid_2-${orderNumber}-${toPermalink(jamaahName)}`}
                            />
                    </div>
                    <div className='w-1/2'>
                        <div className='mt-4'>
                            <Inputs 
                                disabled={!permissions.includes('update_manifest')}
                                label='Jenis Vaksin 2'
                                name='data.vaccine_covid_2_type'
                                id='data.vaccine_covid_2_type'
                                placeholder='Masukan Jenis Vaksin'
                                containerClass='mb-4'
                                value={formik.values?.data?.vaccine_covid_2_type}
                                onChange={(e) => formik.setFieldValue('data.vaccine_covid_2_type', e.target.value)}
                                />
                            </div>
                        <div className='mt-4'>   
                            <div className='text-sm font-semibold mb-2'>Tanggal Vaksin 2</div>
                            <DatePicker 
                                disabled={!permissions.includes('update_manifest')}
                                customClass='w-full'
                                value={formik.values?.data?.vaccine_covid_2_date ? dayjs(moment(formik.values?.data?.vaccine_covid_2_date).format("YYYY-MM-DD"), "YYYY-MM-DD") : dayjs(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
                                onChange={(val) => {
                                    formik.setFieldValue('data.vaccine_covid_2_date', moment(val, "DD-MM-YYYY").format("YYYY-MM-DD"))
                                }}
                                />
                        </div>
                    </div>
                </div>
                <div className='mt-4 pb-4 flex gap-4'>
                    <div className='w-1/2'>
                        <UploadArea
                            viewOnly={!permissions.includes('update_manifest') || bookingDetail?.payment_status === 4}
                            type='chose'
                            label="Dokumen Vaksin 3"
                            customClass="w-full"
                            defaultUrl={formik.values?.data?.vaccine_covid_3}
                            onChange={(url) => formik.setFieldValue('data.vaccine_covid_3', url)}
                            handleRemove={() => formik.setFieldValue('data.vaccine_covid_3', null)}
                            customName={`vaksin_covid_3-${orderNumber}-${toPermalink(jamaahName)}`}
                            />
                    </div>
                    <div className='w-1/2'>
                        <div className='mt-4'>
                            <Inputs 
                                disabled={!permissions.includes('update_manifest')}
                                label='Jenis Vaksin 3'
                                name='data.vaccine_covid_3_type'
                                id='data.vaccine_covid_3_type'
                                placeholder='Masukan Jenis Vaksin'
                                containerClass='mb-4'
                                value={formik.values?.data?.vaccine_covid_3_type}
                                onChange={(e) => formik.setFieldValue('data.vaccine_covid_3_type', e.target.value)}
                                />
                            </div>
                        <div className='mt-4'>   
                            <div className='text-sm font-semibold mb-2'>Tanggal Vaksin 3</div>
                            <DatePicker 
                                disabled={!permissions.includes('update_manifest')}
                                customClass='w-full'
                                value={formik.values?.data?.vaccine_covid_3_date ? dayjs(moment(formik.values?.data?.vaccine_covid_3_date).format("YYYY-MM-DD"), "YYYY-MM-DD") : dayjs(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
                                onChange={(val) => {
                                    formik.setFieldValue('data.vaccine_covid_3_date', moment(val, "DD-MM-YYYY").format("YYYY-MM-DD"))
                                }}
                                />
                        </div>
                    </div>
                </div>
            </div>
            {permissions.includes('update_manifest') && bookingDetail?.payment_status !== 4 && (
                <div className="border rounded-b-2xl flex justify-between border-t-0 p-4">
                    <div>
                    </div>
                    <div>
                        <Buttons
                            customClass="btn-primary btn-large"
                            text="Simpan"
                            loading={isLoading}
                            onClick={handleSave}
                            />
                    </div>
                </div>
            )}
        </>
    )
}

export default VaccineCovid