import { useState } from "react";
import ListView from "./Partials/list-view";
import Scroll from "components/scroll";
import Form from "./Partials/form";
import Buttons from "components/buttons";
import { useSelector } from "react-redux";

const Accomodations = () => {
    const [formOpen, setFormOpen] = useState(false);

    const auth = useSelector(({ auth }) => auth)
    const permissions = auth?.user?.permissions;

    const handleCloseForm = () => {
        setFormOpen(false);
    };

    return (
        <>
            <div>
                <Scroll height="calc(100vh - 311px)" customClass="p-4">
                    <ListView />
                    {formOpen && (
                        <div className="mt-4">
                            <Form setIsOpen={handleCloseForm} />
                        </div>
                    )}
                </Scroll>
                {auth?.user?.access_data === 'all_branches' && permissions?.includes('update_package') && (
                    <div className="pt-5">
                        <Buttons
                            text="Tambah Akomodasi"
                            customClass="btn-outline btn-medium !w-full"
                            onClick={() => setFormOpen(true)}
                            disabled={formOpen}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default Accomodations;