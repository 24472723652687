
import {
    SET_LIST,
    SET_COUNT_UNREAD,
    SET_LOADING_UNREAD_ALL,
    SET_NEXT
} from "../../actions/notifications";

const initialState = {
    list: [],
    countUnread: 0,
    loadingUnreadAll: false,
    next: null
};

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING_UNREAD_ALL:
            return {
                ...state,
                loadingUnreadAll: action.payload
            }
        case SET_LIST:
            return {
                ...state,
                list: action.payload,
            };
        case SET_COUNT_UNREAD:
            return {
                ...state,
                countUnread: action.payload,
            };
        case SET_NEXT:
            return {
                ...state,
                next: action.payload,
            };
        default:
            return state;
    }
};

export default notificationsReducer