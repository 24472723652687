import { Edit, Trash } from "iconsax-react";

export const columns = (dispatch, setIsDelete, setIsChoice, auth) => [

  {
    title: "Logo",
    dataIndex: "logo",
    width: '60px',
    render: (logo) => (
      <img src={logo} alt="avatar" className="w-8 h-8 rounded-full object-cover" />
    ),
  },
  {
    title: "Nama",
    dataIndex: "name",
    render: (name) => (
      <div className="flex flex-row">
        <div className="">{name}</div>
      </div>
    ),
  },
  {
    title: "Kategori",
    dataIndex: "category",
    render: (s, item) => {
      return (
        <div className="w-full relative flex flex-row justify-between">
          {s !== null && s?.length > 150 ? `${s?.substr(0, 150)}...` : s}

          {auth.user.access_data === 'all_branches' && (
            <>
              <div className="action">
                <div className="action-content">
                  <div className="action-content-item">
                    <Trash
                      className="cursor-pointer"
                      onClick={() => {
                        setIsDelete(true);
                        setIsChoice(item);
                      }}
                      size="15"
                      color="#141414"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    },
  },
];
