import Drawer from "../../../../../components/drawer";
import Input from "../../../../../components/form/inputs";
import IconCloseDrawer from "../../../../../assets/icons/Close-Drawer.svg";
import IconSearch from "../../../../../assets/icons/search-normal.svg";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";

import Badge from "components/badge";
import Avatar from "components/avatar";

const JamaahDrawer = ({
  isOpenDrawer = false,
  setIsOpenDrawer = (message) => {},
  listDataJamaah = {},
  dataHeaderDrawer = {},
  input,
  setInput,
}) => {
  return (
    <Drawer open={isOpenDrawer}>
      <div className="!w-[360px]">
        <div className="flex justify-between rounded-xl p-4 mb-5 bg-white shadow-lg items-center ">
          <div>
            <h2 className="font-bold text-lg">List Jamaah Starting</h2>
            <div className="flex items-center gap-1">
              <Badge
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                customClass={
                  "!py-[4px] !px-[16px] !text-[12px] !font-semibold rounded-lg"
                }
                label={`${dataHeaderDrawer?.starting?.name}`}
                color={dataHeaderDrawer?.starting?.color_text}
                backgroundColor={dataHeaderDrawer?.starting?.color_bg}
              />
            </div>
          </div>
          <img
            className="cursor-pointer w-6 h-6"
            src={IconCloseDrawer}
            alt="close"
            onClick={() => {
              setIsOpenDrawer(false);
              setInput("");
            }}
          />
        </div>
        {listDataJamaah?.data?.jamaah?.length ? (
          <>
            <Input
              customClass="!py-3"
              placeholder="Search"
              iconPosition="left"
              icon={IconSearch}
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            {listDataJamaah?.data?.jamaah?.map((item) => {
              return (
                <div className="flex items-start mt-6" key={item?.id}>
                  <Avatar value={item} />
                  <div className="ml-4">
                    <div className="mb-2 text-xs">{item?.name}</div>
                    <Badge
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      customClass={
                        "!py-[4px] !px-[16px] !text-[12px] !font-semibold rounded-lg"
                      }
                      label={`${item?.cabang?.name?.toUpperCase()}`}
                      color={item?.cabang?.color_text}
                      backgroundColor={item?.cabang?.color_bg}
                    />
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="flex justify-center items-start w-full">
            <div className="flex flex-col justify-center items-center">
              <img src={IconJamPasir} alt="jam_pasir" />
              <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
                Belum ada jamaah
              </div>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};
export default JamaahDrawer;
