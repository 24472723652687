import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const SettingAboutAPI = {
  getList: (params) =>
    axios.get(`${BASE_URL_ENDPOINT}/admin/settings`, {params}),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-setting`, payload),
};

export { SettingAboutAPI };
