import React, { useEffect, useState } from "react";
import Inputs from "components/form/inputs";

import useDebounce from "utils/helpers/useDebounce";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import RoomFilter from "./RoomFilter";

import IconSearch from "assets/icons/search-normal.svg";

import { Events } from 'react-scroll';


import {
  getJamaahExportByRoomlistIdV2
} from "utils/api/paket/roomlist";

import { ReactComponent as Receive } from "assets/icons/receive-square.svg";

import { handleExport } from "stores/actions/exports/package-roomlist";

import "./index.css"
import JamaahList from "./JamaaList";
import Spinner from "components/spinner";
import { formattorbase64 } from "utils/helpers";
import { handleError } from "stores/actions/errorGeneral";

const PaketRoomlist = ({
  packageID
}) => {
    const params = useParams()
    const dispatch = useDispatch()

    const [keyword, setKeyword] = useState('')
    const keywordDebounce = useDebounce(keyword, 800)
    const [roomTypeSelected, setRoomTypeSelected] = useState('all')
    const [loadRoomlist, setLoadRoomlist] = useState(false)

    const exportPackageRoomlist = useSelector(({exportPackageRoomlist}) => exportPackageRoomlist)

    const [exporting, setExporting] = useState(false)

    const [isFixed, setIsFixed] = useState(false);

    const [roomSelectedID, setRoomSelectedID] = useState(null)

    const handleExportRoomlist = async () => {
      // setExporting(true)
      
      // const response = await getJamaahExportByRoomlistIdV2(packageID)
      
      // setExporting(false)
  
      // if(response.status === 200) {
      //   const { base64, type, file_name } = response.data.data;
      //   const insertPrefix = formattorbase64(type, base64);
      //   var a = document.createElement("a"); //Create <a>
      //   a.href = `${insertPrefix}`; //Image Base64 Goes here
      //   a.download = `${file_name}`; //File name Here
      //   a.click();
      // } else {
      //   dispatch(handleError(response.data))
      // }

      dispatch(handleExport(packageID))
  
    }

    return (
        <>
          <div className={`${isFixed ? "fixed" : "relative"}`}>
            <div className="flex border-b justify-between py-4 px-4 items-center panel-roomlist">
              <div className="font-semibold text-xl">Atur Pengelompokan Kamar</div>
              <div className="flex justify-centee items-center gap-4">
                <Inputs
                  containerClass=""
                  label=""
                  placeholder="Pencarian"
                  customClass="!w-[400px]"
                  iconPosition="left"
                  icon={IconSearch}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <div onClick={handleExportRoomlist} className="cursor-pointer">
                  {!exportPackageRoomlist.loading ? (
                    <Receive />
                  ) : (
                    <div>
                      <Spinner width="16px" height="16px" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="h-[calc(100vh-210px)] flex">
              <div className="w-[20%] h-full border-r overflow-hidden rounded-bl-[20px]">
                <RoomFilter 
                  onChangeRoomType={(roomType) => setRoomTypeSelected(roomType)}
                  onSelectedRoom={(roomID) => {
                    setRoomSelectedID(roomID)
                  }}
                  loadRoomlist={loadRoomlist}
                  onLoadRoomlist={() => setLoadRoomlist(false)}
                  packageID={packageID}
                  />
              </div>
              <div className="w-[80%] h-full overflow-hidden rounded-br-[20px]">
                <JamaahList 
                  keyword={keyword}
                  keywordDebounce={keywordDebounce}
                  roomTypeSelected={roomTypeSelected}
                  roomSelectedID={roomSelectedID}
                  onUpdatedRoomlist={() => {
                  }}
                  packageID={packageID}
                  />
              </div>
            </div>
          </div>
        </>
    )
}

export default PaketRoomlist