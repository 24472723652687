import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import Selects from "../../../../../../components/form/selects";
import Timepickers from "../../../../../../components/timepickers";
import Switch from "../../../../../../components/form/switch";
import Inputs from "../../../../../../components/form/inputs";
import Buttons from "../../../../../../components/buttons";

import MaskapaiGaruda from "../../../../../../assets/images/maskapai-garuda.jpg";
import Bandara from "../../../../../../assets/images/bandara-jogja.jpg";
import DatePicker from "components/datepicker/DatePicker";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getAllDataBandara } from "stores/actions/datamaster";

import dayjs from 'dayjs'

const AddOrEditFlight = ({
  formInternational,
  onSaveHandle,
  isLoadingAction,
  handleChangeFormInternational,
}) => {
  const { dataBandara } = useSelector((state) => state.datamaster);
  const dispatch = useDispatch();
  const [masterBandara, setMasterBandara] = useState([]);

  useEffect(() => {
    dispatch(
      getAllDataBandara({
        page_number: 1,
        per_page: 1000000,
        keyword: "",
        sort_column: "",
        sort_order: "DESC",
      })
    );
  }, []);

  useEffect(() => {
    if (dataBandara) {
      let _resultMap = dataBandara?.data?.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id_bandara,
        };
      });
      setMasterBandara(_resultMap);
    }
  }, [dataBandara]);

  return (
    <>
      <div className="border-[1px] border-[#E0E0E0] rounded-2xl">
        <div className="p-[16px]">
          <Grid container spacing={2}>
            {formInternational.is_transit === 1 ? (
              <>
                <Grid item md={6}>
                  <Selects
                    label="Terbang dari"
                    placeholder="Pilih Bandara"
                    options={masterBandara}
                    values={masterBandara?.filter(
                      (val) => val.value === formInternational.terbang_dari
                    )}
                    searchable
                    onChange={(e) => {
                      handleChangeFormInternational(
                        e[0]?.value,
                        "terbang_dari"
                      );
                    }}
                    itemRenderer={({ state, item, methods }) => {
                      return (
                        <div
                          className={`react-dropdown-select-item flex flex-row items-center gap-4 !py-6 ${
                            state.values.length !== 0 &&
                            state.values[0].value === item.value &&
                            "custom-item-selected"
                          }`}
                          onClick={() => methods.addItem(item)}
                        >
                          <img src={Bandara} alt="maskapai" />
                          <div className="flex flex-col gap-2">
                            <div className="font-semibold">{item?.label}</div>
                            <div className="text-[12px] text-[#828282]">
                              {item?.domestic_code}
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Selects
                    label="Transit Di"
                    placeholder="Pilih Bandara"
                    options={masterBandara}
                    values={masterBandara?.filter(
                      (val) => val.value === formInternational.teransit_di
                    )}
                    onChange={(e) => {
                      handleChangeFormInternational(e[0]?.value, "teransit_di");
                    }}
                    searchable
                    itemRenderer={({ state, item, methods }) => {
                      return (
                        <div
                          className={`react-dropdown-select-item flex flex-row items-center gap-4 !py-6 ${
                            state.values.length !== 0 &&
                            state.values[0].value === item.value &&
                            "custom-item-selected"
                          }`}
                          onClick={() => methods.addItem(item)}
                        >
                          <img src={Bandara} alt="maskapai" />
                          <div className="flex flex-col gap-2">
                            <div className="font-semibold">{item.label}</div>
                            <div className="text-[12px] text-[#828282]">
                              {item?.domestic_code}
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={6}>
                  <Selects
                    label="Terbang dari"
                    placeholder="Pilih Bandara"
                    options={masterBandara}
                    values={masterBandara?.filter(
                      (val) => val.value === formInternational.terbang_dari
                    )}
                    searchable
                    onChange={(e) => {
                      handleChangeFormInternational(
                        e[0]?.value,
                        "terbang_dari"
                      );
                    }}
                    itemRenderer={({ state, item, methods }) => {
                      return (
                        <div
                          className={`react-dropdown-select-item flex flex-row items-center gap-4 !py-6 ${
                            state.values.length !== 0 &&
                            state.values[0].value === item.value &&
                            "custom-item-selected"
                          }`}
                          onClick={() => methods.addItem(item)}
                        >
                          <img src={Bandara} alt="maskapai" />
                          <div className="flex flex-col gap-2">
                            <div className="font-semibold">{item.label}</div>
                            <div className="text-[12px] text-[#828282]">
                              {item.domestic_code}
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Selects
                    label="Mendarat di"
                    placeholder="Pilih Bandara"
                    options={masterBandara}
                    values={masterBandara?.filter(
                      (val) => val.value === formInternational.mendarat_di
                    )}
                    searchable
                    onChange={(e) => {
                      handleChangeFormInternational(e[0]?.value, "mendarat_di");
                    }}
                    itemRenderer={({ state, item, methods }) => {
                      return (
                        <div
                          className={`react-dropdown-select-item flex flex-row items-center gap-4 !py-6 ${
                            state.values.length !== 0 &&
                            state.values[0].value === item.value &&
                            "custom-item-selected"
                          }`}
                          onClick={() => methods.addItem(item)}
                        >
                          <img src={Bandara} alt="maskapai" />
                          <div className="flex flex-col gap-2">
                            <div className="font-semibold">{item.label}</div>
                            <div className="text-[12px] text-[#828282]">
                              {item?.domestic_code}
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Grid>
              </>
            )}

            <Grid item md={12}>
              <div className="flex flex-row items-center gap-2">
                <Switch
                  label=""
                  checked={formInternational?.is_transit === 1 ? true : false}
                  onChange={() =>
                    handleChangeFormInternational(
                      formInternational?.is_transit === 1 ? 0 : 1,
                      "is_transit"
                    )
                  }
                />
                <div>Dengan Transit</div>
              </div>
            </Grid>
            <Grid item md={6}>
              <Inputs
                label="Terminal"
                placeholder="Terminal"
                value={formInternational?.terminal_terbang}
                onChange={(e) =>
                  handleChangeFormInternational(
                    e.target.value,
                    "terminal_terbang"
                  )
                }
              />
            </Grid>
            <Grid item md={6}>
              <Inputs
                placeholder="Kode penerbangan"
                label="Kode Penerbangan"
                value={formInternational?.kode_penerbangan}
                onChange={(e) =>
                  handleChangeFormInternational(
                    e.target.value,
                    "kode_penerbangan"
                  )
                }
              />
            </Grid>

            <Grid item md={6}>
              <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
                Perkiraan Tanggal Keberangkatan
              </div>
              <DatePicker
                label="Perkiraan Tanggal Keberangkatan"
                containerClass="w-full"
                customClass="w-full"
                placeholder="Tentukan Pilih Tanggal Keberangkatan"
                value={formInternational?.date_keberangkatan ? dayjs(moment(formInternational?.date_keberangkatan, "DD-MM-YYYY").format("YYYY-MM-DD"), "YYYY-MM-DD") : null}
                // placeholder={
                //   formInternational?.date_keberangkatan ??
                //   "Pilih Tanggal Keberangkatan"
                // }
                onChange={(e) =>
                  handleChangeFormInternational(e, "date_keberangkatan")
                }
              />
            </Grid>
            <Grid item md={6}>
              <Timepickers
                label="Perkiraan Waktu Keberangakatan"
                value={formInternational?.date_keberangkatan ? dayjs(formInternational?.time_keberangkatan, "HH:mm") : null}
                placeholder="Tentukan Pilih Waktu Keberangkatan"
                onSelect={(e) => {
                  let data = moment(e?.$d).format("HH:mm:ss");
                  handleChangeFormInternational(data, "time_keberangkatan");
                }}
              />
            </Grid>
            {!!formInternational.is_transit && (<div className="divider my-4 mx-2"></div>)}
            
            <Grid item md={6}>
              {formInternational.is_transit ? (
                <>
                  <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
                    Perkiraan Tanggal Kedatangan Transit
                  </div>
                  <DatePicker
                    // label="Perkiraan Tanggal Kedatangan Transit"
                    containerClass="w-full"
                    placeholder="Tentukan Perkiraan Tanggal Kedatangan di Transit"
                    customClass="w-full"
                    value={formInternational?.date_kedatangan_transit ? dayjs(moment(formInternational?.date_kedatangan_transit, "DD-MM-YYYY", "DD-MM-YYYY").format("YYYY-MM-DD"),"YYYY-MM-DD") : null}
                    onChange={(e) =>
                      handleChangeFormInternational(e, "date_kedatangan_transit")
                    }
                  />
                </>
              ) : (
                <>
                  <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
                    Perkiraan Tanggal Kedatangan di Tujuan
                  </div>
                  <DatePicker
                    // label="Perkiraan Tanggal Kedatangan"
                    containerClass="w-full"
                    placeholder="Tentukan Perkiraan Tanggal Kedatangan"
                    customClass="w-full"
                    value={formInternational?.date_kedatangan ? dayjs(moment(formInternational?.date_kedatangan, "DD-MM-YYYY", "DD-MM-YYYY").format("YYYY-MM-DD"),"YYYY-MM-DD") : null}
                    onChange={(e) =>
                      handleChangeFormInternational(e, "date_kedatangan")
                    }
                  />
                </>
              )}

            </Grid>
            
            <Grid item md={6}>
            {formInternational.is_transit ? (
              <>
                <Timepickers
                  label="Perkiraan Waktu Kedatangan di Transit"
                  value={formInternational?.time_kedatangan_transit ? dayjs(formInternational?.time_kedatangan_transit, "H:mm") : null}
                  placeholder="Tentukan Pilih Waktu Kedatangan Transit"
                  onSelect={(e) => {
                    let data = moment(e?.$d).format("HH:mm:ss");
                    handleChangeFormInternational(data, "time_kedatangan_transit");
                  }}
                />
              </>
            ) : (
              <>
                <Timepickers
                  label="Perkiraan Waktu Kedatangan di Tujuan"
                  value={formInternational?.time_kedatangan ? dayjs(formInternational?.time_kedatangan, "H:mm") : null}
                  placeholder="Tentukan Pilih Waktu Kedatangan"
                  onSelect={(e) => {
                    let data = moment(e?.$d).format("HH:mm:ss");
                    handleChangeFormInternational(data, "time_kedatangan");
                  }}
                />
              </>
            )}
              
            </Grid>
            {formInternational.is_transit === 1 && (
              <>
                {/* <div className="divider"></div> */}
                <Grid item md={6}>
                  <Selects
                    label="Terbang dari"
                    placeholder="Pilih Bandara"
                    options={masterBandara}
                    values={masterBandara?.filter(
                      (val) => val.value === formInternational.teransit_di
                    )}
                    onChange={(e) => {
                      handleChangeFormInternational(e[0]?.value, "teransit_di");
                    }}
                    searchable
                    itemRenderer={({ state, item, methods }) => {
                      return (
                        <div
                          className={`react-dropdown-select-item flex flex-row items-center gap-4 !py-6 ${
                            state.values.length !== 0 &&
                            state.values[0].value === item.value &&
                            "custom-item-selected"
                          }`}
                          onClick={() => methods.addItem(item)}
                        >
                          <img src={Bandara} alt="maskapai" />
                          <div className="flex flex-col gap-2">
                            <div className="font-semibold">{item.label}</div>
                            <div className="text-[12px] text-[#828282]">
                              {item.domestic_code}
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Selects
                    label="Mendarat di"
                    placeholder="Pilih Bandara"
                    options={masterBandara}
                    values={masterBandara?.filter(
                      (val) => val.value === formInternational.mendarat_di
                    )}
                    searchable
                    onChange={(e) => {
                      handleChangeFormInternational(e[0]?.value, "mendarat_di");
                    }}
                    itemRenderer={({ state, item, methods }) => {
                      return (
                        <div
                          className={`react-dropdown-select-item flex flex-row items-center gap-4 !py-6 ${
                            state.values.length !== 0 &&
                            state.values[0].value === item.value &&
                            "custom-item-selected"
                          }`}
                          onClick={() => methods.addItem(item)}
                        >
                          <img src={Bandara} alt="maskapai" />
                          <div className="flex flex-col gap-2">
                            <div className="font-semibold">{item.label}</div>
                            <div className="text-[12px] text-[#828282]">
                              {item?.domestic_code}
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Inputs
                    label="Terminal"
                    placeholder="Terminal"
                    value={formInternational?.terminal_transit}
                    onChange={(e) =>
                      handleChangeFormInternational(
                        e.target.value,
                        "terminal_transit"
                      )
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <Inputs
                    placeholder="Kode penerbangan"
                    label="Kode Penerbangan"
                    value={formInternational?.kode_penerbangan_transit}
                    onChange={(e) =>
                      handleChangeFormInternational(
                        e.target.value,
                        "kode_penerbangan_transit"
                      )
                    }
                  />
                </Grid>

                <Grid item md={6}>
                  <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
                    Perkiraan Tanggal Keberangkatan dari Transit
                  </div>
                  <DatePicker
                    // label="Perkiraan Tanggal Keberangkatan"
                    containerClass="w-full"
                    customClass="w-full"
                    value={formInternational?.date_keberangkatan_transit ? dayjs(moment(formInternational?.date_keberangkatan_transit, "DD-MM-YYYY").format("YYYY-MM-DD"), "YYYY-MM-DD") : null}
                    placeholder="Tentukan Tanggal Transit Keberangakatan"
                    onChange={(e) =>
                      handleChangeFormInternational(
                        e,
                        "date_keberangkatan_transit"
                      )
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <Timepickers
                    label="Perkiraan Waktu Keberangakatan dari Transit"
                    value={formInternational?.time_keberangkatan_transit ? dayjs(formInternational?.time_keberangkatan_transit, "H:mm") : null}
                    placeholder="Tentukan Transit Waktu Keberangkatan "
                    onSelect={(e) => {
                      let data = moment(e?.$d).format("HH:mm:ss");
                      handleChangeFormInternational(
                        data,
                        "time_keberangkatan_transit"
                      );
                    }}
                  />
                </Grid>

                {!!formInternational.is_transit && (<div className="divider my-4 mx-2"></div>)}

                <Grid item md={6}>
                  <div className="text-[#4F4F4F] text-[14px] font-semibold mb-2">
                    Perkiraan Tanggal Kedatangan di Tujuan
                  </div>
                  <DatePicker
                    label="Perkiraan Tanggal Kedatangan di Tujuan"
                    containerClass="w-full"
                    customClass="w-full"
                    value={formInternational?.date_kedatangan ? dayjs(moment(formInternational?.date_kedatangan, "DD-MM-YYYY").format("YYYY-MM-DD"), "YYYY-MM-DD") : null}
                    placeholder="Tentukan Pilih Tanggal Kedatangan"
                    onChange={(e) =>
                      handleChangeFormInternational(
                        e,
                        "date_kedatangan"
                      )
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <Timepickers
                    label="Perkiraan Waktu Kedatangan di Tujuan"
                    value={formInternational?.time_kedatangan ? dayjs(formInternational?.time_kedatangan, "H:mm") : null}
                    placeholder="Pilih waktu kedatangan"
                    onSelect={(e) => {
                      let data = moment(e?.$d).format("HH:mm:ss");
                      handleChangeFormInternational(
                        data,
                        "time_kedatangan"
                      );
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
        <div className="divider"></div>
        <div className="px-[16px] py-[16px] flex flex-row justify-end">
          <Buttons
            text="Simpan"
            customClass="btn-primary btn-large"
            onClick={onSaveHandle}
            loading={isLoadingAction}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(AddOrEditFlight);
