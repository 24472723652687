// make NotificationDropdown component

import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Scroll from "components/scroll";

import { getList, read, getCountUnread, setNext } from "stores/actions/notifications";

import IconBullet from "assets/icons/bullet.svg"
import Spinner from "components/spinner";

const NotificationDropdown = ({
    handleClose
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("all");

    const auth = useSelector(({ auth }) => auth)

    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [readAllLoading, setReadAllLoading] = useState(false);

    const notifications = useSelector((state) => state.notifications.list);
    const notificationsNext = useSelector((state) => state.notifications.next);

    const dropdownRef = useRef(null);

    const handleLoadMore = async () => {
        const params = {
            cursor: notificationsNext
        }

        setLoadMoreLoading(true);
        await dispatch(getList(params));
        setLoadMoreLoading(false);

    }

    const handleReadAll = async () => {
        setReadAllLoading(true);
        await dispatch(read());
        setActiveTab("all");
        setReadAllLoading(false);
    }

    const handleClickNotification = (notif) => {
        if(!notif.is_read) {
            dispatch(read({id: notif.id}));
        }

        if(notif.action_type === "push") {
            // json decode
            const actionData = JSON.parse(notif.action_data);

            if(notif.key.includes("order.")) {
                history.push(`/booking/detail/${actionData.booking_number}`);
            }

            if(notif.key.includes("payment.")) {
                history.push(`/tagihan/detail/${actionData.booking_number}`);
            }

            if(notif.key.includes("fee.withdraw")) {
                if(auth.user?.access_data === "self_branch") {
                    history.push(`/fee-penarikan`);
                } else {
                    history.push(`/fee`);
                }
            }

            if(notif.key.includes("fee.received")) {
                history.push(`/fee-penarikan`);
            }


            handleClose()
        }

    }

    const handleOutsideClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            handleClose()
        }
    };


    useEffect(() => {
        let params = {
            cursor: null
        };

        setNext(null);

        if(activeTab === "unread") {
            params = {
                unread: true,
                cursor: null
            }
        }

        dispatch(getList(params));
    }, [activeTab]);

    useEffect(() => {
        dispatch(getCountUnread());
        setNext(null);
        dispatch(getList());
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    });

    return (
        <>
            <div 
                ref={dropdownRef}
                className="bg-white absolute right-[110px] top-[110px] rounded-2xl shadow-desktop w-[330px] z-[999] overflow-hidden">
            <div className="p-4 pb-0">
                <h2 className="text-base font-semibold">Notifikasi</h2>
                <div className="flex justify-between text-xs mt-4 border-b-2 pb-[18px]">
                <div className="flex gap-2">
                    <span 
                        onClick={() => setActiveTab("all")}
                        className={`font-semibold px-4 py-1 text-gray-3 ${activeTab === "all" && "bg-[#A7C957]/30 !text-black"} rounded-lg cursor-pointer`}>
                        All
                    </span>
                    <span 
                        onClick={() => setActiveTab("unread")}
                        className={`font-semibold text-gray-3 px-4 py-1 ${activeTab === "unread" && "bg-[#A7C957]/30 !text-black"}  rounded-lg cursor-pointer`}>
                        Unread
                    </span>
                </div>
                <span 
                    onClick={handleReadAll}
                    className={`font-semibold text-gray-3 py-1 rounded-lg cursor-pointer ${readAllLoading && "!cursor-progress"}`}>
                    Read All
                </span>
                </div>
            </div>
            <div>
                <Scroll height="400px" style={notificationsNext && {paddingBottom: "37px"}}>

                    {notifications?.length > 0 ? (
                        <>
                            {notifications.map((item, index) => (
                                <>
                                    <div 
                                        onClick={() => handleClickNotification(item)}
                                        key={index}
                                        className={`py-[22px] px-4 flex gap-2 cursor-pointer items-center relative hover:bg-[#FBF3F2] ${!item.is_read && "bg-[#FBF3F2]"}`}>
                                        <div className="bg-gray-3 !rounded-full !w-[32px] !min-w-[32px] h-[32px]">
                                        <img 
                                            className="w-full h-full rounded-full"
                                            src={item.image}
                                        />
                                        </div>
                                        <div className="flex flex-col gap-1">
                                        <div className="font-semibold text-sm">{item.title}</div>
                                        <div className="text-xs text-gray-3 line-clamp-2">
                                            <div dangerouslySetInnerHTML={{__html: item.caption}} />
                                        </div>
                                        </div>
                                        {!item.is_read && <img src={IconBullet} className="absolute right-4 top-[45%]" />}
                                    </div>
                                </>   
                            ))}
                        </>
                    ): (
                        <div className="text-center h-[400px] flex justify-center items-center">Tidak Ada Notifikasi</div>
                    )}

                    {notificationsNext && (
                        <div 
                            onClick={handleLoadMore}
                            className="bg-white w-full p-2 text-red-1 flex justify-center gap-2 items-center cursor-pointer absolute bottom-0 left-0 right-0">
                            <span>Lihat lagi</span>
                            {loadMoreLoading && <Spinner width="16px" height="16px" /> }
                        </div>
                    )}

                </Scroll>
            </div>
            </div>
        </>
    )

}


export default NotificationDropdown
