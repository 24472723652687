import { useEffect } from "react"

import PasPhoto4x6 from "./pas_photo_4x6"
import KTP from "./ktp";
import Passport from "./passport";
import KK from "./kk";
import Akta from "./akta";
import SuratNikah from "./surat_nikah";
import Meningitis from "./meningitis";
import BPJSKesehatan from "./bpjs_kesehatan";
import VaccineCovid1 from "./vaccine_covid_1";
import VaccineCovid2 from "./vaccine_covid_2";
import VaccineCovid3 from "./vaccine_covid_3";

import { useSelector } from "react-redux"

const DocumentForm = ({
  documentKey,
  documentValue,
  onUpdate,
  pembimbingPaketID
}) => {
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;
  return (
    <>
      {documentKey === 'ktp' && (
        <KTP 
          data={
            {
              'ktp' : documentValue
            }
          }
          pembimbingPaketID={pembimbingPaketID}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          />
      )}
      {documentKey === 'passport' && (
        <Passport 
          data={
            {
              'passport_1' : documentValue[0].value,
              'passport_2' : documentValue[1].value,
            }
          }
          pembimbingPaketID={pembimbingPaketID}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          />
      )}
      {documentKey === 'kk' && (
        <KK 
          data={
            {
              'kk' : documentValue
            }
          }
          pembimbingPaketID={pembimbingPaketID}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          />
      )}
      {documentKey === 'pas_photo_4x6' && (
        <PasPhoto4x6 
          data={
            {
              'pas_photo_4x6' : documentValue
            }
          }
          pembimbingPaketID={pembimbingPaketID}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          />
      )}
      {documentKey === 'akta_kelahiran' && (
        <Akta 
          data={
            {
              'akta_kelahiran' : documentValue
            }
          }
          pembimbingPaketID={pembimbingPaketID}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          />
      )}
      {documentKey === 'surat_nikah' && (
        <SuratNikah 
          data={
            {
              'surat_nikah' : documentValue
            }
          }
          pembimbingPaketID={pembimbingPaketID}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          />
      )}
      {documentKey === 'meningitis_vaccine' && (
        <Meningitis 
          data={
            {
              'meningitis_vaccine' : documentValue
            }
          }
          pembimbingPaketID={pembimbingPaketID}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          />
      )}
      {documentKey === 'vaccine_covid_1' && (
        <VaccineCovid1 
          data={
            {
              'vaccine_covid_1' : documentValue
            }
          }
          pembimbingPaketID={pembimbingPaketID}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          />
      )}
      {documentKey === 'vaccine_covid_2' && (
        <VaccineCovid2 
          data={
            {
              'vaccine_covid_2' : documentValue
            }
          }
          pembimbingPaketID={pembimbingPaketID}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          />
      )}
      {documentKey === 'vaccine_covid_3' && (
        <VaccineCovid3 
          data={
            {
              'vaccine_covid_3' : documentValue
            }
          }
          pembimbingPaketID={pembimbingPaketID}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          />
      )}
      {documentKey === 'bpjs_kesehatan' && (
        <BPJSKesehatan 
          data={
            {
              'bpjs_kesehatan' : documentValue
            }
          }
          pembimbingPaketID={pembimbingPaketID}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          />
      )}
    </>
  )
}

export default DocumentForm