import React, { useEffect, useState } from "react";

import Buttons from "../../../components/buttons";
import Cards from "../../../components/cards";
import Switch from "../../../components/form/switch";

import IconCloseDrawer from "../../../assets/icons/Close-Drawer.svg";
import FotoProfile from "../../../assets/images/profile_new.png";
// import Places from "../../../../../components/map";
import { makeStyles } from "@material-ui/core";
import { Skeleton } from "antd";
import { IcLocation, IcPencil, IcWaMaaroon } from "assets/icons/dashboard";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import { detailById } from "stores/actions/general";
import { setDataUpload } from "stores/actions/uploadFile";
import IconCabangPusat from "../../../assets/icons/Cabang-Pusat.svg";
import IconMoon from "../../../assets/icons/Mode.svg";
import IconScring from "../../../assets/icons/brush.svg";
import Clock from "../../../assets/icons/clock.svg";
import IconNotif from "../../../assets/icons/notif_new.svg";
import IconScanBarcode from "../../../assets/icons/scan-barcode.svg";
import IconWhatsappBlack from "../../../assets/icons/whatsapp-black.svg";
import IconPinMap from "assets/icons/pin-map.svg";

import AvatarRed from "../../../assets/images/Avatar-ikhwan-red.svg";
import Places from "../../../components/map";
import Badge from "components/badge";
import { getLocalStorage } from "utils/helpers";
import { generateQRwaWA, getBranchDetail } from "stores/actions/cabang";

const useStyle = makeStyles({
  mapContainer: {
    "& .places-container": {
      display: "none",
    },

    "& input": {
      display: "none",
    },
    borderRadius: "16px",
  },
});

const DetailCabang = ({
  handleHistory,
  handleWACabang,
  handleLogoutWA,
  setConnectWA,
  connectWA,
  is_requestWA,
  handleQRCode,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { dataUpload } = useSelector((state) => state.uploadFile);
  const { byDetail } = useSelector((state) => state.general);
  const auth = useSelector(({auth}) => auth)
  const { detailLoading, generateQRLoading } = useSelector(
    (state) => state.cabang
  );

  const [isChecked, setChacked] = useState(false);
  const userLogin = getLocalStorage("userLogin");
  const userLoginJsonParse = JSON.parse(userLogin);
  const [scand, setScand] = useState(null);

  const permissions = auth?.user?.permissions;

  useEffect(() => {
    if (Object.keys(byDetail)) {
      setScand(byDetail?.data?.use_self_wa);
    }
    console.log(byDetail);
  }, [byDetail]);

  useEffect(() => {
    if (byDetail?.contact?.data?.wa_info?.status === "begin") {
      dispatch(getBranchDetail(byDetail?.data));
    }
  }, [byDetail?.contact?.data?.wa_info?.status, byDetail?.data, dispatch]);

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return "";
  };

  const renderActiveLicensi = () => {
    setTimeout(() => {}, 2000);
    if (userLoginJsonParse.data?.account.role_key === "super_admin_root") {
      return (
        <div className="flex flex-row w-[336px] items-center">
          <Switch
            checked={is_requestWA}
            onChange={(val) => {
              setChacked(!isChecked);
              handleWACabang(isChecked);
            }}
          />
          <div className="text-sm font-semibold text-[#000] ml-2 w-full">
            WA Cabang
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row w-[336px] items-center">
          <Switch
            checked={connectWA}
            onChange={(val) => {
              setChacked(!isChecked);
              setConnectWA(isChecked);
            }}
          />
          <div className="text-sm font-semibold text-[#000] ml-2 w-full">
            WA Cabang
          </div>
        </div>
      );
    }
  };

  return (
    <div className="relative h-full">
      <Cards containerClass="!px-4 !py-4">
        <div className="flex flex-row justify-between ">
          <div className="text-xl font-semibold">View Cabang</div>
          <div>
            <img
              className="cursor-pointer"
              src={IconCloseDrawer}
              alt="close"
              onClick={() =>
                dispatch(
                  detailById({
                    detail: false,
                    data: byDetail?.data,
                    contact: byDetail?.contact,
                  })
                )
              }
              width={24}
              height={24}
            />
          </div>
        </div>
        {/* {renderActiveLicensi()} */}
      </Cards>

      {detailLoading ? (
        <div className="mt-6">
          <div className="flex flex-col gap-3">
            <div className="flex gap-4">
              <Skeleton.Avatar active size="default" shape="circle" />
              <Skeleton.Input active size="default" block />
            </div>
            <div className="flex gap-4">
              <Skeleton.Avatar active size="default" shape="circle" />
              <Skeleton.Input active size="default" block />
            </div>
          </div>
          <div className="flex flex-row gap-3 mt-5 justify-center">
            <div className="flex gap-4">
              <Skeleton.Input active size="default" block />
            </div>
            <div className="flex gap-4">
              <Skeleton.Input active size="default" block />
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-6">
          <div className="flex flex-row items-center">
            <img
              className="w-[72px] h-[72px] rounded-full object-cover"
              src={byDetail?.data?.img || AvatarRed}
              alt="avatar"
            />
            <div className="flex flex-col gap-y-3">
              <div className="flex flex-row ml-4">
                <img
                  height={24}
                  width={24}
                  src={IconCabangPusat}
                  alt="cabang"
                />
                <div className="ml-2 font-semibold text-sm">
                  {byDetail?.data?.name}
                </div>
              </div>
              {scand ? (
                <div className="flex flex-row ml-4">
                  <img
                    height={24}
                    width={24}
                    src={IcWaMaaroon}
                    alt="wa maaroon"
                  />
                  <div className="ml-2 text-sm">
                    {byDetail?.contact?.data?.wa_info?.status !== "online" ? (
                      <div className="flex justify-start items-center gap-x-4">
                        {"(-)"}
                        {byDetail?.contact?.data?.wa_info?.status && (
                          <Badge
                            circle="#FE9A00"
                            label={`${capitalizeFirstLetter(
                              byDetail?.contact?.data?.wa_info?.status
                            )}`}
                            backgroundColor="rgba(254, 154, 0, 0.30)"
                            color="#FE9A00"
                            customClass="!text-xs !flex justify-start !items-center"
                          />
                        )}
                      </div>
                    ) : (
                      <div className="flex justify-start items-center gap-x-4">
                        {byDetail?.contact?.data?.wa_info?.number}
                        <Badge
                          circle="#71BE0E"
                          label={`${capitalizeFirstLetter(
                            byDetail?.contact?.data?.wa_info?.status
                          )}`}
                          backgroundColor="#D8E1BE"
                          color="#71BE0E"
                          customClass="!text-xs !flex justify-start !items-center"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="ml-4">{renderActiveLicensi()}</div>
              )}
            </div>
          </div>

          {scand ? (
            <div className="mt-6 flex flex-row justify-between gap-4">
              {byDetail?.contact?.data?.wa_info?.status !== "online" && (
                <Buttons
                  disabled={
                    byDetail?.contact?.data?.wa_info?.status !== "offline"
                  }
                  customClass="btn-primary btn-outline btn-medium d-block"
                  text="Scan QR"
                  onClick={() => {
                    const payload = {
                      id_cabang: byDetail?.data?.id_cabang,
                    };

                    dispatch(
                      detailById({
                        detail: true,
                        scanQR: true,
                        data: byDetail?.data,
                        contact: byDetail?.contact,
                      })
                    );

                    dispatch(
                      generateQRwaWA(payload, (message) => {
                        handleQRCode(message?.data?.qrcode);
                        dispatch(
                          detailById({
                            detail: true,
                            scanQR: true,
                            data: byDetail?.data,
                            contact: byDetail?.contact,
                          })
                        );
                      })
                    );
                  }}
                />
              )}

              <Buttons
                customClass="btn-primary btn-outline btn-medium d-block"
                text="History WA"
                onClick={handleHistory}
              />

              {byDetail?.contact?.data?.wa_info?.status === "online" && (
                <Buttons
                  onClick={handleLogoutWA}
                  customClass="btn-primary btn-medium d-block"
                  text="Logout WA"
                />
              )}
            </div>
          ) : (
            ""
          )}

          <div className="divider mt-6"></div>

          <div className="mt-4">
            <div className="font-semibold">Alamat</div>
            <div className="mt-1">
              {byDetail?.data?.address_details || "belum di isi"}
            </div>
          </div>

          <div className="mt-4">
            <div className="font-semibold">Website</div>
            <a className="flex mt-1 link" href={byDetail?.data?.url_website}>
              {byDetail?.data?.url_website?.replace(/^https?:\/\//, "")}
            </a>
          </div>

          <div className="mt-4">
            <div className="font-semibold mb-1">Warna</div>
            <Badge
              label={byDetail?.data?.color_text}
              backgroundColor={byDetail?.data?.color}
              color={byDetail?.data?.color_text}
              customClass="!text-xs !flex justify-start !items-center"
            />
          </div>

          <div className="mt-4">
            <div className="font-semibold mb-1">Koordinat Lokasi</div>
            <div className="mb-4 flex gap-x-4 items-center">
              <img alt="location" src={IconPinMap} />
              <span>{byDetail?.data?.address_lat_long ?? "-"}</span>
            </div>
            <div
              className={classes.mapContainer + ` shadow-soft mb-4 rounded-2xl`}
            >
              <Places
                containerClass="!w-full !h-[206px] rounded-2xl p-1"
                classes="rounded-2xl"
                selected={{
                  lat: Number(byDetail?.data?.latitude),
                  lng: Number(byDetail?.data?.longitude),
                }}
              />
            </div>
            <div className="h-[120px]"></div>
          </div>
        </div>
      )}

      {permissions.includes("update_branch") && (
        <Cards containerClass="fixed w-[400px] bottom-0 right-0 !p-0 z-[100]">
          <div className="p-4">
            <Buttons
              text="Edit Cabang"
              icon={IcPencil}
              customClass="btn-primary btn-large"
              disabled={detailLoading}
              onClick={() => {
                dispatch(
                  detailById({
                    update: true,
                    detail: true,
                    data: byDetail?.data,
                    contact: byDetail?.contact,
                  })
                );
                dispatch(
                  setDataUpload([
                    ...dataUpload,
                    {
                      name: "map",
                      path: byDetail?.data?.img,
                      fileName: byDetail?.data?.name,
                      id: uuid(),
                    },
                  ])
                );
              }}
            />
          </div>
        </Cards>
      )}
    </div>
  );
};

export default React.memo(DetailCabang);
