import React, { useState } from "react";
import ViewItem from "./ViewItem";
import EditItem from "./EditItem";

const Item = ({
  data,
  removeItem,
  generalInfo,
  onClickEditGeneralInfo,
  handleClickManasikCabang,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <div className="w-full border p-4 rounded-2xl">
      {isEdit ? (
        <EditItem
          data={data}
          setIsEdit={setIsEdit}
          handleClickManasikCabang={handleClickManasikCabang}
        />
      ) : (
        <ViewItem
          data={data}
          setIsEdit={setIsEdit}
          removeItem={removeItem}
          generalInfo={generalInfo}
          onClickEditGeneralInfo={onClickEditGeneralInfo}
        />
      )}
    </div>
  );
};

export default Item;
