import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import fotoProfil from "assets/images/Avatar-ikhwan-1.svg";
import fotoProfilAkhwat from "assets/images/foto-profil-ibadahku.svg";
import Switch from "components/form/switch";
import IconLocation from "assets/icons/colored/icon-location-maroon.svg";
import IconWhatsapp from "assets/icons/colored/icon-whatsapp.svg";
import Badge from "components/badge";
import { useDispatch, useSelector } from "react-redux";
import {
  getAlbumDataJamaah,
  updateHidePhotoAlbumJamaah,
} from "stores/actions/paket/ibadahku";
import { Spin } from "antd";
import { toCapital } from "utils/helpers";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";

const AlbumDataCard = ({
  name,
  location,
  phone,
  badgeLabel,
  badgeColor,
  badgeBackgroundColor,
  photo,
  defaultChecked,
  checked,
  onChange,
}) => {
  return (
    <div className="flex flex-row justify-between items-start p-[16px] mx-4 rounded-lg shadow-soft mb-4">
      <div className="flex flex-row items-center gap-[16px]">
        <div className="flex flex-row justify-center items-center gap-[8px] w-[100px] h-[100px] rounded-xl bg-[#E0E0E0]">
          <img
            src={photo}
            alt="foto profil"
            className="w-[100px] h-[100px] rounded-xl object-cover"
          />
        </div>
        <div className="flex flex-col gap-[8px]">
          <h2 className="text-xs text-black font-semibold">{name}</h2>
          <div className="flex flex-row items-center gap-[8px]">
            <img src={IconLocation} alt="lokasi" />
            <p className="">{location}</p>
          </div>
          <div className="flex flex-row items-center gap-[8px]">
            <img src={IconWhatsapp} alt="lokasi" />
            <p className="">{phone}</p>
          </div>
          <div>
            <Badge
              label={badgeLabel}
              color={badgeColor}
              backgroundColor={badgeBackgroundColor}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center gap-[8px]">
        <Switch
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={onChange}
        />
        <p className="text-xs font-normal text-slate-400">Sembunyikan Foto</p>
      </div>
    </div>
  );
};

const AlbumDataJamaah = () => {
  const dispatch = useDispatch();
  const { isLoading, dataAlbumJamaah } = useSelector((state) => state.ibadahku);

  let { id } = useParams();

  useEffect(() => {
    dispatch(getAlbumDataJamaah(id));
  }, []);

  const handleUpdateHidePhoto = (value, item) => {
    const valueHidePhoto = value ? 0 : 1;
    const valueId = item.id;
    const payload = {
      id: valueId,
      hide_photo: valueHidePhoto,
    };
    dispatch(updateHidePhotoAlbumJamaah(id, payload));
  };

  return (
    <div
      className={`flex flex-col overflow-auto ${
        isLoading && "items-center justify-center"
      }`}
    >
      {isLoading ? (
        <div className="w-full h-[530px] flex justify-center items-center">
          <Spin size="large" />
        </div>
      ) : (
        // if dataAlbumJamaah length 0
        <>
          {dataAlbumJamaah.length == 0 && (
            <>
              <div className="flex flex-col h-[530px] justify-center items-center bg-white">
                <img
                  src={IconJamPasir}
                  className="mb-2 w-[252px] h-[260px]"
                />
                <p className="text-base text-black">
                  Belum ada jamaah 
                </p>
              </div>
            </>
          )}
          {dataAlbumJamaah?.map((item) => {
              const photoValue = item?.photo
                ? item?.photo
                : item?.title == "ny"
                ? fotoProfilAkhwat
                : fotoProfil;

              const nameValue = item?.name ? item?.name : "-";

              return (
                <AlbumDataCard
                  key={item.id}
                  name={`${toCapital(item?.title)}. ${nameValue.toUpperCase()}`}
                  location={item.address}
                  phone={item.phone}
                  badgeLabel={item.branch.toUpperCase()}
                  badgeColor={item.branch_color_text}
                  badgeBackgroundColor={item.branch_color_bg}
                  photo={photoValue}
                  defaultChecked={item.hide_photo === 0 ? true : false}
                  onChange={(value) => handleUpdateHidePhoto(value, item)}
                />
              );
            })}
        </>
      )}
    </div>
  );
};

export default AlbumDataJamaah;
