import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import UploadArea from "components/Uploadarea"
import Buttons from "components/buttons"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

import { handleUpdateManifestAPI } from 'utils/api/booking'
import { handleError, handleSuccess } from 'stores/actions/errorGeneral'
import Inputs from 'components/form/inputs'
import Selects from 'components/form/selects'
import DatePicker from 'components/datepicker/DatePicker'

import moment from 'moment'
import dayjs from "dayjs";
import Textarea from 'components/form/textarea'
import { toPermalink } from 'utils/helpers'
import { handleGetDataJamaah } from 'stores/actions/booking'

const BPJS = ({
    data,
    jamaahOrderID,
    onSuccess,
    permissions,
    jamaahName,
    orderNumber
}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const formik = useFormik({
        initialValues: {
            order_kamar_mapping_id: jamaahOrderID,
            data
        }
    })
    
    const [isLoading, setIsLoading] = useState(false)
    const { bookingDetail } = useSelector(state => state.booking)

    const handleSave = async () => {
        // console.log(formik.values);
        setIsLoading(true)
        const response = await handleUpdateManifestAPI(params.orderNumber, formik.values)
        setIsLoading(false)
        if(response.status === 200) {
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate data manifest", code: 200 }))
            dispatch(handleGetDataJamaah(params.orderNumber))
            onSuccess()
        } else {
            dispatch(handleError(response.data))
        }

    }
    
    useEffect(() => {
        formik.setValues({
            order_kamar_mapping_id: jamaahOrderID,
            data
        })
    }, [data])

    return (
        <>
            <div className="border p-4 rounded-t-2xl">
                <div className='flex gap-4'>
                    <div className='w-full'>
                        <UploadArea
                            viewOnly={!permissions.includes('update_manifest') || bookingDetail?.payment_status === 4}
                            type='chose'
                            label="Dokumen BPJS Kesehatan"
                            customClass="w-full h-[350px]"
                            defaultUrl={formik.values?.data?.bpjs_kesehatan}
                            onChange={(url) => formik.setFieldValue('data.bpjs_kesehatan', url)}
                            handleRemove={() => formik.setFieldValue('data.bpjs_kesehatan', null)}
                            customName={`bpjs_kesehatan-${orderNumber}-${toPermalink(jamaahName)}`}
                            />
                        <Inputs 
                            disabled={!permissions.includes('update_manifest')}
                            name='data.bpjs_kesehatan_number'
                            id='data.bpjs_kesehatan_number'
                            label='No. BPJS Kesehatan'
                            placeholder='Masukan No. BPJS Kesehatan'
                            containerClass='my-4'
                            value={formik.values?.data?.bpjs_kesehatan_number}
                            onChange={(e) => formik.setFieldValue('data.bpjs_kesehatan_number', e.target.value)}
                            />
                    </div>
                </div>
            </div>
            {permissions.includes('update_manifest') && bookingDetail?.payment_status !== 4 && (
                <div className="border rounded-b-2xl flex justify-between border-t-0 p-4">
                    <div>
                    </div>
                    <div>
                        <Buttons
                            customClass="btn-primary btn-large"
                            text="Simpan"
                            loading={isLoading}
                            onClick={handleSave}
                            />
                    </div>
                </div>
            )}
        </>
    )
}

export default BPJS