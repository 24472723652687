import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { onHandleRemoveEvisaService } from "utils/api/paket/evisa";
import { handleDetectFile } from "utils/helpers";
import Alert from "components/popup/alert";

import CardFileUpload from "./CardFileUpload";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";

const PopupViewEvisa = ({
    jamaahEvisa,
    onRemoved
}) => {
    const [popupRemoving, setPopupRemoving] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [removeSelectedID, setRemoveSelectedID] = useState(null);
    const dispatch = useDispatch();
    const params = useParams();
    const auth = useSelector((state) => state.auth);
  
    const handleRemovingEvisa = async () => {
        setRemoving(true)
        const payload = {
          "e_visa_id" : removeSelectedID
        }
        const response = await onHandleRemoveEvisaService(params.id, payload)
        setRemoving(false)
    
        if (response.status === 200) {
          dispatch(handleSuccess(null, {message: "Berhasil menghapus Evisa", code: 200}))
          setPopupRemoving(false)
          onRemoved(removeSelectedID)
        } else {
          dispatch(handleError(response.data))
        }
    
      }
      
      const handleFileName = (url) => {
        const parts = url.split('/')
        const fileName = parts[parts?.length - 1]
    
        return fileName
      }
    
    const handleRemoveEvisa = async (id) => {
        setRemoveSelectedID(id)
        setPopupRemoving(true)
    }


    return (
        <div className="border rounded-2xl p-4">
            {auth.user.access_data === 'all_branches' && (
                 <Alert 
                    open={popupRemoving}
                    handleClose={() => setPopupRemoving(false)}
                    title="Apakah anda yakin menghapus?"
                    subtitle="Apakah kamu yakin ingin menghapus?"
                    handleContinue={() => handleRemovingEvisa()}
                    loading={removing}
                />
            )}
            <div>
                {!!jamaahEvisa._evisa?.length && (
                    <>
                        <div className="">
                            {jamaahEvisa?._evisa.map((item, index) => (
                                <CardFileUpload 
                                fileName={handleFileName(item.file)} 
                                time="2 hari yang lalu" 
                                fileType={handleDetectFile(item.file)}
                                url={item.file}
                                handleRemove={() => {
                                    handleRemoveEvisa(item.id)
                                }}
                                />
                            ))}
                        </div>
                    </>
                )}
          </div>
        </div>
    )
}

export default PopupViewEvisa