import Buttons from "components/buttons";
import { useParams } from "react-router-dom";
import Dropdowns, { closeDropdown } from "components/dropdowns";
import Inputs from "components/form/inputs";
import InputsCurrency from "components/form/inputsCurrency";
import Radio from "components/form/radio";
import Scroll from "components/scroll";
import { useFormik } from "formik";
import { Eye, EyeSlash } from "iconsax-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'

import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import { handleGetDetailOrder } from "stores/actions/booking";

import { PaketCouponAPI } from "utils/api/paket/coupon";
import { handleUpdateDiscountAPI } from "utils/api/booking";

import { IDRFormater } from "utils/helpers";

const EditDiscount = () => {
  const dispatch = useDispatch()
  const params = useParams();

  const [couponSelectorLabel, setCouponSelectorLabel] = useState("Pakai Kupon")

  const [couponList, setCouponList] = useState([])
  const [couponPrivateList, setCouponPrivateList] = useState([])
  const [isLoadingCheckCoupon, setIsloadingCheckCoupon] = useState(false)
  const [selectedCoupon, setSelectedCoupon] = useState({})

  const [isLoading, setIsLoading] = useState(false);

  const { bookingDetail } = useSelector((state) => state.booking);

  const [typeCoupon, setTypeCoupon] = useState(1)
  const [codeCoupon, setCodeCoupon] = useState(null)

  const [typeOptions, setType] = useState([
    {
      value: (
        <div className="flex items-center">
          <Eye variant="Bold" className="mr-2" /> <p>Public</p>
        </div>
      ),
      label: "Pilih Kupon",
      key: 1,
    },
    {
      value: (
        <div className="flex items-center">
          <EyeSlash variant="Bold" className="mr-2" /> <p>Private</p>
        </div>
      ),
      label: "Masukan Kupon",
      key: 2,
    },
  ]);

  const formik = useFormik({
    initialValues: {
      coupon: '',
      agent_discount: '',
    }
  })

  const handleGetCoupons = async () => {
    const params = {
      code_coupon: null,
      package_id: bookingDetail.paket.id_paket
    }
    const response = await PaketCouponAPI.getCouponForOrder(params)
    const { status, data } = response

    if(status === 200) {
      setCouponList(data.data)
      
      if(bookingDetail.id_coupon) {
        const coupon = data.data.find((item) => item.id_coupon === bookingDetail.id_coupon)

        if(coupon) {
            handleSelectCoupon(coupon)
        }
      }

    } else {
      dispatch(handleError(data))
    }

  }

  const handleGetCouponPrivate = async () => {
    setCouponPrivateList([])
    if(codeCoupon != '') {
      const params = {
        code_coupon: codeCoupon,
        package_id: bookingDetail.paket.id_paket
      }
      setIsloadingCheckCoupon(true)
      const response = await PaketCouponAPI.getCouponForOrder(params)
      const { status, data } = response
      setIsloadingCheckCoupon(false)
      if(status === 200) {
        setCouponPrivateList(data.data)
      } else {
        dispatch(handleError(data))
      }  
    }
  }

  const handleSelectCoupon = (coupon) => {
    
    setSelectedCoupon(coupon)
    // handleCountTotalCutPrice()

    formik.setFieldValue('coupon', coupon.id_coupon)
    formik.setFieldValue('coupon_detail', coupon)

    setTimeout(() => {
      formik.setFieldTouched('coupon', 1)
      formik.setFieldTouched('coupon_detail', 1)
    })

    const label = `${coupon.code_coupon} - Diskon ${coupon.jenis_diskon === 1 ? IDRFormater(coupon.nominal_diskon) : `${coupon.nominal_diskon}%`}`
    setCouponSelectorLabel(label)

    closeDropdown()
  }

  const handleResetCoupon = () => {
    formik.setFieldValue('coupon', null)
    formik.setFieldValue('coupon_detail', null)

    setTimeout(() => {
      formik.setFieldTouched('coupon', 1)
      formik.setFieldTouched('coupon_detail', 1)
    })

    const label = `Pakai Kupon`
    setCouponSelectorLabel(label)

  }

  const handleSave = async () => {
    setIsLoading(true)
    const response = await handleUpdateDiscountAPI(params.orderNumber, formik.values)
    setIsLoading(false)

    if(response.status === 200) {
      dispatch(handleSuccess(response))
      dispatch(handleGetDetailOrder(params.orderNumber))
    } else {
      dispatch(handleError(response.data))
    }

  }

  useEffect(() => {
    // setSelectedCoupon({})
    handleGetCoupons()

    formik.setFieldValue('agent_discount', bookingDetail.agent_discount_amount)

    setTimeout(() => {
      formik.setFieldTouched('agent_discount', 1)
    })

    if(formik.values.coupon) {
      const coupon = formik.values.coupon_detail
      const label = `${coupon.code_coupon} - Diskon ${coupon.jenis_diskon === 1 ? IDRFormater(coupon.nominal_diskon) : `${coupon.nominal_diskon}%`}`
      setCouponSelectorLabel(label)
    }

  }, [])

  return (
      <>  
        <div className="p-4 border rounded-tr-2xl rounded-tl-2xl">
            <div className="mb-4">
                <div className="font-semibold text-sm mb-2 flex justify-between">
                <div>Kupon</div>
                <div onClick={handleResetCoupon} className="cursor-pointer text-red-1">Reset</div>
                </div>
                <Dropdowns
                customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between  items-center`}
                placement="bottom"
                labelDropdown={couponSelectorLabel}
                dropdownArrow={true}
                >

                <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
                    <div>
                    <Radio 
                        options={typeOptions} 
                        onChange={(e) => {
                        const selectedType = typeOptions.find(
                            (option) => option.value === e.target.value
                        )

                        if(selectedType) {
                            setTypeCoupon(selectedType.key);
                        }

                        }}
                        />
                    </div>
                    {typeCoupon === 1 && (
                    <>
                        <Scroll 
                        height="350px"
                        customClass="mt-4 pr-4"
                        >
                        {couponList?.map((coupon, index) => {
                            return (
                            <>
                                <div 
                                onClick={() => handleSelectCoupon(coupon)}
                                className="border p-4 rounded-xl mb-4 cursor-pointer">
                                <div className=" text-xl font-semibold mb-2">
                                    {coupon.code_coupon}
                                </div>
                                <div className="text-base text-[#F2994A]">
                                    {coupon.jenis_diskon === 1 && (
                                    <>
                                        Diskon {IDRFormater(coupon?.nominal_diskon)}
                                    </>
                                    )}
                                    {coupon.jenis_diskon === 2 && (
                                    <>
                                        Diskon {coupon.nominal_diskon}%
                                    </>
                                    )}
                                </div>
                                </div>
                            </>
                            )
                        })}
                        </Scroll>
                    </>
                    )}
                    {typeCoupon === 2 && (
                    <>
                        <div className="flex items-center gap-2 mt-4">
                        <div className="w-4/5">
                            <Inputs 
                            placeholder="Masukan Kode Kupon"
                            onChange={(e) => setCodeCoupon(e.target.value)}
                            />
                        </div>
                        <div className="w-1/5">
                            <Buttons
                            text="Cek Kupon"
                            customClass="btn btn-primary btn-medium !py-4"
                            onClick={() => handleGetCouponPrivate()}
                            loading={isLoadingCheckCoupon}
                            />
                        </div>
                        </div>
                        <div className="mt-4">
                        {couponPrivateList?.map((coupon, index) => {
                            return (
                                <>
                                <div 
                                    onClick={() => handleSelectCoupon(coupon)}
                                    className="border p-4 rounded-xl mb-4 cursor-pointer">
                                    <div className=" text-xl font-semibold mb-2">
                                    {coupon.code_coupon}
                                    </div>
                                    <div className="text-base text-[#F2994A]">
                                    {coupon.jenis_diskon === 1 && (
                                        <>
                                        Diskon {IDRFormater(coupon?.nominal_diskon)}
                                        </>
                                    )}
                                    {coupon.jenis_diskon === 2 && (
                                        <>
                                        Diskon {coupon.nominal_diskon}%
                                        </>
                                    )}
                                    </div>
                                </div>
                                </>
                            )
                            })}
                        </div>
                    </>
                    )}
                </div>

                </Dropdowns>
            </div>
            {bookingDetail.guard_type === 'agent' && (
                <>
                <div>
                    <InputsCurrency 
                    label="Diskon Agen (akan mengurangi fee agen)"
                    //   defaultValue={formik.values.agent_discount}
                    value={formik.values.agent_discount}
                    onChange={(val) => {
                        formik.setFieldValue('agent_discount', val)

                        setTimeout(() => {
                        formik.setFieldTouched('agent_discount', 1)
                        })

                    }}
                    />
                </div>
                </>
            )}
        </div>
        <div className="border rounded-br-2xl border-t-0 rounded-bl-2xl p-4 flex justify-end">
          <Buttons 
            text="Simpan"
            onClick={handleSave}
            // disabled={!(formik.dirty && !formik.isValid)}
            // containerClass="mt-6"
            customClass="btn-primary btn-large"
            loading={isLoading}
            />
        </div>
      </>
  );
};

export default EditDiscount;