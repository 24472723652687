
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import IconSingle from "assets/icons/Single.svg";
import IconDouble from "assets/icons/Double.svg";
import IconTriple from "assets/icons/Triple.svg";
import IconQuad from "assets/icons/Quad.svg";

import Scroll from "components/scroll";
import Progressbars from "components/progressbars";

import './index.css'
import RoomBadge from "./components/RoomBadge";

import {
  handleGetFileRelasi,
  handleGetJamaahByRoomList,
  handleGetListRelasi,
  handleGetRoomlistByPackage,
  handleGetSetRoomList,
  handleUpdatedRelasi,
  handleUpdatedRoomList,
} from "stores/actions/paket/roomlist";
import { type } from "@testing-library/user-event/dist/type";
import { handleError } from "stores/actions/errorGeneral";
import Spinner from "components/spinner";
import { countPercent } from "utils/helpers";

import PropTypes from 'prop-types';

const RoomFilter = ({
  onChangeRoomType,
  onSelectedRoom, 
  loadRoomlist,
  onLoadRoomlist,
  packageID
}) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [gettingRoomlist, setGettingRoomlist] = useState(false);
  const [roomTypeSelected, setRoomTypeSelected] = useState('all');

  const [roomFilterList, setRoomFilterList] = useState([])
  const [roomSelectedID, setRoomSelectedID] = useState(null)

  const handleGetRoomFilterList = (loading = true) => {
    loading && setGettingRoomlist(true)
    dispatch(handleGetRoomlistByPackage(
      packageID,
      {room_type: roomTypeSelected}, 
      (response) => {
        setRoomFilterList(response.data.data)
        setGettingRoomlist(false)
        onLoadRoomlist()
      },
      (err) => {
        setGettingRoomlist(false)
        dispatch(handleError(err.data))
      }
    ))
  }

  const handleCountPax = (roomType) => {

    roomType = roomType.toLowerCase()
    roomType = roomType.replace('_bussiness', '')

    if (roomType === 'quad') {
      return 4
    }
    if (roomType === 'tripple') {
      return 3
    }
    if (roomType === 'double') {
      return 2
    }
    if (roomType === 'single') {
      return 1
    }
  }

  useEffect(() => {
    handleGetRoomFilterList()
  }, [])

  useEffect(() => {
    if (loadRoomlist) {
      handleGetRoomFilterList(false)
    }
  }, [loadRoomlist])

  useEffect(() => {
    handleGetRoomFilterList()
    onChangeRoomType(roomTypeSelected)
  }, [roomTypeSelected])

  return (
    <>
      <div className="p-4 border-b">
        <div className="font-semibold text-base mb-4">
          Filter Kamar
        </div>
        <div className="flex flex-row text-sm justify-between">
          <div
            onClick={() => {
              setRoomTypeSelected('all')
              handleGetRoomFilterList()
              setRoomSelectedID(null)
              onSelectedRoom(null)
            }}
            className={`flex items-center cursor-pointer px-2 py-1 border rounded-lg ${roomTypeSelected === 'all' ? 'font-semibold border border-red-500' : ''}`}>
            All
          </div>
          <div 
            onClick={() => setRoomTypeSelected('quad')}
            className={`flex items-center cursor-pointer px-2 py-1 border rounded-lg ${roomTypeSelected === 'quad' ? 'font-semibold border border-red-500' : ''}`}>
            <img src={IconQuad} alt="quad" />
          </div>
          <div 
            onClick={() => setRoomTypeSelected('tripple')}
            className={`flex items-center cursor-pointer px-2 py-1 border rounded-lg ${roomTypeSelected === 'tripple' ? 'font-semibold border border-red-500' : ''}`}>
            <img src={IconTriple} alt="triple" />
          </div>
          <div 
            onClick={() => setRoomTypeSelected('double')}
            className={`flex items-center cursor-pointer px-2 py-1 border rounded-lg ${roomTypeSelected === 'double' ? 'font-semibold border border-red-500' : ''}`}>
            <img src={IconDouble} alt="double" />
          </div>
          <div 
            onClick={() => setRoomTypeSelected('single')}
            className={`flex items-center cursor-pointer px-2 py-1 border rounded-lg ${roomTypeSelected === 'single' ? 'font-semibold border border-red-500' : ''}`}>
            <img src={IconSingle} alt="single" />
          </div>
        </div>
      </div>
      <Scroll 
        customClass="!h-[calc(100vh-313px)]"
        >
        
        {gettingRoomlist ? (
          <>
            <div className="h-full w-full flex justify-center items-center">
              <Spinner 
                width="32px"
                height="32px"
                />
            </div>
          </>
        ) : (
          <>
            {roomFilterList.map((data, index) => (
              <div className={`roomfilter-list p-4 ${data.id === roomSelectedID && '!bg-red-4'}`} onClick={() => {
                setRoomSelectedID(data?.id)
                onSelectedRoom(data.id)
              }}>
                <div className="flex gap-2 items-center">
                  <RoomBadge 
                    roomType={data?.room_type}
                    roomNumber={data?.room_number}
                    />
                  <div>
                    <span className="text-xs">({data?.total_pax_filled} Pax)</span>
                  </div>
                </div>
                <div className="mt-4">
                  <Progressbars 
                    // progress={0}
                    label="Kamar Terisi"
                    info={`${data.total_pax_filled}/${data.total_pax}`}
                    customInfoClass="!text-xs font-semibold !top-[5px]"
                    percent={countPercent(data?.total_pax_filled, data?.total_pax)}
                    />
                </div>
              </div>
            ))}
          </>
        )}


      </Scroll>
    </>   
  )
};

RoomFilter.propTypes = {
  onChangeRoomType: PropTypes.func,
  onSelectedRoom: PropTypes.func
}

RoomFilter.defaultProps = {
  onChangeRoomType: (roomType) => {},
  onSelectedRoom: (roomID) => {}
}

export default RoomFilter