import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Col, Row } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment";
import 'moment/locale/id';
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";

import UmrohImg from "../../../../../../assets/images/umrohImg.png";
import Badge from "../../../../../../components/badge/index";
import Calendar from "../../../../../../assets/icons/colored/calendar-icon.svg";
import ClockIcon from "../../../../../../assets/icons/colored/clock-icon.svg";
import PlaneIcon from "../../../../../../assets/icons/colored/airplane-icon.svg";
import GarudaIndonesia from "../../../../../../assets/images/garuda-indonesia-logo.png";
import LandingPlaneIcon from "../../../../../../assets/icons/colored/airplane-2-icon.svg";
import PaketVipIcon from "../../../../../../assets/icons/colored/paket-vip.svg";
import Quad from "../../../../../../assets/icons/colored/Quad.svg";
import TripleQuad from "../../../../../../assets/icons/colored/TripleBed.svg";
import DoubleQuad from "../../../../../../assets/icons/colored/DoubleBed.svg";
import SingleBed from "../../../../../../assets/icons/colored/SingleBed.svg";
import HotelIcon from "../../../../../../assets/icons/colored/Hotel-Akomodasi.svg";
import Address from "../../../../../../assets/icons/colored/Address.svg";
import TipeKamar from "../../../../../../assets/icons/colored/Tipe-Kamar.svg";
import Rating from "../../../../../../assets/icons/Rating.svg";
import RatingActive from "../../../../../../assets/icons/rating_active.svg";
import Buttons from "./../../../../../../components/buttons/index";
import SeatBlack from "../../../../../../assets/icons/colored/seat-black.svg";
import SeatBlue from "../../../../../../assets/icons/colored/seat-blue.svg";
import SeatGreen from "../../../../../../assets/icons/colored/seat-green.svg";
import SeatRed from 'assets/icons/seat-tersisa-maroon.svg'
import Rute from "../../../../../../assets/icons/colored/rute.svg"
import ArrowRightSquare from "../../../../../../assets/icons/right-arrow.svg"
import Tanggal from "../../../../../../assets/icons/Tanggal.svg"
import SudahBerangkat from "../../../../../../assets/icons/colored/sudah-berangkat.svg";
import IconHotelAkomodasi from "../../../../../../assets/icons/Hotel Akomodasi1.svg";
import IconCategory from "../../../../../../assets/icons/KategoryColor.svg";

import { IDRFormater, handleName } from "utils/helpers";

const useStyle = makeStyles({
  container: {
    width: "456px",
    height: "fit-content",
    maxHeight: "calc(100vh - 48px)",
    background: "#FFFFFF",
    boxShadow:
      "0px 6px 64px -4px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.04), 0px 6px 32px rgba(0, 0, 0, 0.04)",
    borderRadius: "24px",
    zIndex: "9999999999",
    position: "absolute",
    // left: "57%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    "& .button-ic": {
      "& img": {
        width: '16px'
      }
    },
    "& .image-container": {
      width: "448px",
      height: "145px",
      left: "4px",
      right: "4px",
      top: "4px",
      margin: "0 auto",
      paddingTop: "4px",
      alignItems: "center",

      "& .hover-image": {
        width: "448px",
        height: "145px",
        borderRadius: "20px 20px 0px 0px",
        objectFit: "fit",
      },
    },

    "& .content-container": {
      margin: "16px",

      "& .title": {
        marginTop: "8px",
        marginBottom: '16px',
        width: "387px",
        // height: "48px",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "24px",
        background:
          "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        // marginBottom: '16px',
      },

      "& .info-paket": {
        display: "flex",
        flexDirection: "row",
        marginBottom: "12px",

        "& .title-paket": {
          width: "126px",
          height: "18px",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "18px",
          marginLeft: "8px",
        },
      },

      "& .harga-dasar-container": {
        width: "100%",
        padding: '8px 16px',
        // height: "111px",
        background: "#f2f2f2",
        borderRadius: "16px",

        "& .quad-container": {
          width: "100%",
          // height: "44px",
          // paddingTop: "12px",
          // textAlign: "center",
          // margin: "0 auto",
          marginBottom: "8px",
          gap: '4px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        },

        "& .title-quad": {
          // width: "387px",
          // height: "48px",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "20px",
          background:
            "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          textFillColor: "transparent",
          marginLeft: "8px",
        },

        "& .title-icon": {
          width: "65px",
          height: "20px",
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          margin: "0 auto",
        },

        "& .price-quad": {
          // width: "157px",
          // height: "24px",
          display: "flex",
          flexDirection: "row",
          alignItems: 'center'
        },

        "& .price": {
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          background:
            "linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          textFillColor: "transparent",
        },
        "& .pax": {
          color: "black !important",
        },

        "& .quad-bottom": {
          display: "flex",
          flexDirection: "row",
          justifyContent: 'space-between',
          // marginLeft: "12px",

          "& .title-icon-bottom": {
            display: "flex",
            flexDirection: "row",

            "& .title-quad-triple": {
              marginLeft: "8px",
              fontSize: '12px',
              fontWeight: '700'
            },
          },
        },
      },
    },
  },

  penginapanContainer: {
    width: "100%",
    // height: "296px",
    margin: "16px 16px 16px 0px",
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    "& .paket-content": {
      width: "100%",
      // height: "68px",
      background: "#F2f2f2",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      // marginTop: "8px",

      "& .paket-title": {
        display: "flex",
        alignItems: 'center',
        gap: '8px'
      },
    },

    "& .top": {
      margin: "12px",
    },

    "& .bottom": {
      margin: "12px",
    },

    "& .paket-seat": {
      width:'100%',
      padding: '16px',
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      // alignContent: "space-between",
      alignItems: "center",
      // marginLeft: '12px',
      // gap: "100px",
      margin: "0 auto",

      "& .seat-black": {
        background:
          "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        fontWeight: "500",
      },

      "& .blue-seat": {
        background:
          "linear-gradient(204.44deg, #4590F4 -18.49%, #1361C9 84.72%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        fontWeight: "500",
      },

      "& .green-seat": {
        background:
          "linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        fontWeight: "500",
      },
    },
  },
  manyAcomodasi: {
    flexDirection: 'row!important',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
    '-webkit-user-select': 'none', /* Safari */
    '-ms-user-select': 'none', /* IE 10 and IE 11 */
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#ebebeb',
        '-webkit-border-radius': '0px',
        borderRadius: '0px'
    },
    '&::-webkit-scrollbar-thumb': {
        '-webkit-border-radius': '0px',
        borderRadius: '0px',
        background: '#6d6d6d'
    },
    '& .paket-content': {
      flexGrow: '0',
      flexShrink: '0',
      flexBasis: '85%'
    }
  },
  priceContainer: {
    overflowX: 'auto',
    '-webkit-user-select': 'none', /* Safari */
    '-ms-user-select': 'none', /* IE 10 and IE 11 */
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#ebebeb',
        '-webkit-border-radius': '0px',
        borderRadius: '0px'
    },
    '&::-webkit-scrollbar-thumb': {
        '-webkit-border-radius': '0px',
        borderRadius: '0px',
        background: '#6d6d6d'
    },
  }
});

const PackageDetailPopUp = ({ positionLeft, data }) => {
  const router = useHistory();
  const classes = useStyle();

  const acomodasiRef = useRef(null);
  const priceRef = useRef(null);

  const auth = useSelector(({ auth }) => auth)

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [publishSettingAgents, setPublishSettingAgents] = useState([]);
  const [publishSettingBranches, setPublishSettingBranches] = useState([]);

  const [publishSettingAgentsRemaining, setPublishSettingAgentsRemaining] = useState(0);
  const [publishSettingBranchesRemaining, setPublishSettingBranchesRemaining] = useState(0);

  const dateFormatter = (val) => {
    if (val) {
      return moment(val).format("DD MMMM YYYY") || "-";
    }
  };

  const handleAcomodasiMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - acomodasiRef.current.offsetLeft);
    setScrollLeft(acomodasiRef.current.scrollLeft);
  };

  const handleAcomodasiMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - acomodasiRef.current.offsetLeft;
    const walk = (x - startX) * 3; // Multiplier untuk kecepatan geser
    acomodasiRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleAcomodasiMouseUp = () => {
    setIsDragging(false);
  };

  const handlePriceMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - priceRef.current.offsetLeft);
    setScrollLeft(priceRef.current.scrollLeft);
  };

  const handlePriceMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - priceRef.current.offsetLeft;
    const walk = (x - startX) * 3; // Multiplier untuk kecepatan geser
    priceRef.current.scrollLeft = scrollLeft - walk;
  };

  const handlePriceMouseUp = () => {
    setIsDragging(false);
  };

  const makeAkomodasiBintangArry = (number) => {
    const numbers = Array.from({ length: number - 1 + 1 }, (_, index) => 1 + index);

    return numbers
  }

  useEffect(() => {
    const agentData = data?.publish_settings?.agents
    const branchData = data?.publish_settings?.branches

    setPublishSettingBranchesRemaining(branchData.length - 1)
    setPublishSettingBranches(branchData.slice(0, 1))

    setPublishSettingAgentsRemaining(agentData.length - 2)
    setPublishSettingAgents(agentData.slice(0, 2))

  }, [data])

  return (
    <AnimatePresence>
      <motion.div
        className={classes.container}
        style={{ left: `${positionLeft}px` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="image-container">
          <img
            src={data?.image_thumbnail}
            alt="detail"
            className="hover-image object-cover"
          />
        </div>
        <div className="content-container">

          <div className="flex justify-between items-center">
            <div className="flex gap-2 flex-col">
              <div className="flex gap-2 items-center">
                {data?.status_keberangkatan === 0 ? (
                  <>
                    {data.status === 0 ? (
                      <Badge
                        style={{}}
                        label={"Draft"}
                        color="#564592"
                        backgroundColor="#56459233"
                        customClass="!leading-6"
                      />
                    ) : (
                      <Badge
                        style={{}}
                        label={"Publish"}
                        color="#333D29"
                        backgroundColor="rgba(46, 178, 67, 0.2)"
                        customClass="!leading-6"
                      />
                    )}
                  </>
                ) : (
                  <>
                    {data.status_keberangkatan === 1 ? (
                      <Badge
                        style={{}}
                        label={"Sedang Berangkat"}
                        color="#e5be00"
                        backgroundColor="#FFF2B2"
                        customClass="!leading-6"
                      />
                    ) : (
                      <Badge
                        style={{}}
                        label={"Sudah Berangkat"}
                        color="#6C757D"
                        backgroundColor="#CED4DA"
                        customClass="!leading-6"
                      />
                    )}
                  </>
                )}

                {auth.user.access_data === 'all_branches' && (
                  <>
                    {publishSettingBranches.map((item, index) => (
                      <Badge
                        key={index}
                        label={item?.name.toUpperCase()}
                        color={item?.color_text}
                        backgroundColor={item?.color}
                        customClass="!max-w-[110px] !line-clamp-1 !leading-6"
                      />
                    ))}
                    {publishSettingBranchesRemaining > 0 && (
                      <Badge
                        label={`+${publishSettingBranchesRemaining}`}
                        color={"#141414"}
                        backgroundColor={"#fff"}
                        customClass="!border !leading-6"
                      />
                    )}
                  </>
                )}
              </div>
              {auth.user.access_data === 'all_branches' && (
                <>
                  <div className="flex gap-2 items-center">
                    {publishSettingAgents.map((item, index) => (
                      <Badge
                        key={index}
                        label={handleName(item.title, item.name)}
                        color={"#60308C"}
                        backgroundColor={"#CCDCFF"}
                        customClass="!max-w-[110px] !line-clamp-1 !leading-6"
                      />
                    ))}
                    {publishSettingAgentsRemaining > 0 && (
                      <Badge
                        label={`+${publishSettingAgentsRemaining}`}
                        color={"#141414"}
                        backgroundColor={"#fff"}
                        customClass="!border !leading-6"
                      />
                    )}
                  </div>
                </>
              )}
            </div>

            <Buttons
              text="Detail Paket"
              icon={ArrowRightSquare}
              iconPosition="right"
              customClass="btn-outline btn-sm text-xxs py-[6px] px-4 button-ic"
              onClick={() => router.push(`/paket/detail/${data?.id_paket}`)}
            />
            
          </div>      
      
          <h1 className="title">{data?.judul_paket}</h1>

          <div className="grid grid-cols-3 text-xs justify-between gap-4 my-4">
            <div className="flex gap-2 items-center">
              <img src={Tanggal} />
              <span>
                {moment(data?.jadwal_keberangkatan).locale('id').format('DD MMMM YYYY')}
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <img src={PlaneIcon} />
              <span className="flex gap-1 items-center">
                {data?.maskapai?.name}
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <img src={IconHotelAkomodasi} className="h-4 w-4" />
              <span className="flex">
                {data?.akomodasi?.length ? (
                  <>
                    {makeAkomodasiBintangArry(data?.akomodasi[0]?._akomodasi?.bintang).map((i) => (
                      <img key={i} src={RatingActive} className="w-4 h-4" alt="Address" />
                    ))}
                    {makeAkomodasiBintangArry(5 - data?.akomodasi[0]?._akomodasi?.bintang).map((i) => (
                      <img key={i} src={Rating} className="w-4 h-4" alt="Address" />
                    ))}
                  </>
                ) : '-'}
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <img src={ClockIcon} />
              <span>
                {data?.jumlah_hari} Hari
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <img src={SudahBerangkat} className="w-4 h-4" />
              <span className="flex gap-1 items-center">
                Landing {data?.mendarat_di ? data?.mendarat_di : '-'}
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <img src={IconCategory} className="w-4 h-4" />
              <span className="flex gap-1 items-center">
                {data?.kategori}
              </span>
            </div>
          </div>

          {!!data.harga_kamar?.is_active_price_quad && (
            <div
              ref={priceRef} 
              onMouseDown={handlePriceMouseDown}
              onMouseMove={handlePriceMouseMove}
              onMouseUp={handlePriceMouseUp}
              style={{ cursor: isDragging ? 'grabbing' : 'grab' }}  
              className={`${classes.penginapanContainer} ${classes.priceContainer} !flex-row scroll-smooth`}>
                <div className="flex items-center">
                  <div className="paket-content !flex-col gap-1 p-4 !w-auto">
                    <div className="text text-sm flex gap-2 items-center">
                      <img src={Quad} className="w-4 h-4" alt="Quad" />
                      <span className="font-semibold">Quad</span>
                    </div>
                    <div className="text-base">
                      <span className="text-green-3 font-semibold">
                        {IDRFormater(data.harga_kamar.price_quad_basic)}
                      </span>
                      /pax
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="paket-content !flex-col gap-1 p-4 !w-auto">
                    <div className="text-xs flex gap-2 items-center">
                      <img src={TripleQuad} className="w-4 h-4" alt="Quad" />
                      <span className="font-semibold">Triple</span>
                    </div>
                    <div className="text-xs">
                      <span className="text-green-3 font-semibold">
                        {IDRFormater(data.harga_kamar.price_tripple_basic)}
                      </span>
                      /pax
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="paket-content !flex-col gap-1 p-4 !w-auto">
                    <div className="text-xs flex gap-2 items-center">
                      <img src={DoubleQuad} className="w-4 h-4" alt="Quad" />
                      <span className="font-semibold">Double</span>
                    </div>
                    <div className="text-xs">
                      <span className="text-green-3 font-semibold">
                        {IDRFormater(data.harga_kamar.price_double_basic)}
                      </span>
                      /pax
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="paket-content !flex-col gap-1 p-4 !w-auto">
                    <div className="text-xs flex gap-2 items-center">
                      <img src={SingleBed} className="w-4 h-4" alt="Quad" />
                      <span className="font-semibold">Single</span>
                    </div>
                    <div className="text-xs">
                      <span className="text-green-3 font-semibold">
                        {IDRFormater(data.harga_kamar.price_single_basic)}
                      </span>
                      /pax
                    </div>
                  </div>
                </div>
            </div>
          )}

          {!!data?.akomodasi?.length && data?.akomodasi?.length < 2 && (
            <div className={classes.penginapanContainer}>
              {data?.akomodasi?.map((item) => (
                <div className="paket-content">
                  <div className="top">
                    <div className="paket-title">
                      <img src={HotelIcon} alt="hotel akomodasi" />
                      <p className="text-xs font-bold">{item?._akomodasi?.name}</p>
                    </div>
                    <div className="paket-title mt-4">
                      <img src={TipeKamar} alt="hotel akomodasi" />
                      <div className="flex flex-row">
                        {makeAkomodasiBintangArry(item?._akomodasi?.bintang).map((i) => (
                          <img src={RatingActive} className="w-4 h-4" alt="Address" />
                        ))}
                        {makeAkomodasiBintangArry((5 - item?._akomodasi?.bintang)).map((i) => (
                          <img src={Rating} className="w-4 h-4" alt="Address" />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="paket-title">
                      <img src={Address} alt="Address" />
                      <p className="text-xs font-bold">{item?._akomodasi?.city}</p>
                    </div>
                    <div className="paket-title mt-4">
                      <img src={Rute} alt="hotel akomodasi" />
                      <p className="text-xs">
                        {item?._akomodasi?.city === 'Makkah' || item?._akomodasi?.city === 'Madinah' ? (
                          `+-${item?._akomodasi?.city === 'Makkah' ? item?._akomodasi?.distance_alharam : item?._akomodasi?.distance_nabawi}m dari halaman Masjid ${item?._akomodasi?.city === 'Makkah' ? 'Al Haram' : 'Nabawi'}`
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {!!data?.akomodasi?.length && data?.akomodasi?.length >= 2 && (
            <div
              ref={acomodasiRef} 
              onMouseDown={handleAcomodasiMouseDown}
              onMouseMove={handleAcomodasiMouseMove}
              onMouseUp={handleAcomodasiMouseUp}
              style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
              className={`${classes.penginapanContainer} ${classes.manyAcomodasi}`}>
              {data?.akomodasi?.map((item) => (
                <div className="paket-content">
                  <div className="top">
                    <div className="paket-title">
                      <img src={HotelIcon} alt="hotel akomodasi" />
                      <p className="text-xs font-bold">{item?._akomodasi?.name}</p>
                    </div>
                    <div className="paket-title mt-4">
                      <img src={TipeKamar} alt="hotel akomodasi" />
                      <div className="flex flex-row">
                        {makeAkomodasiBintangArry(item?._akomodasi?.bintang).map((i) => (
                          <img src={RatingActive} className="w-4 h-4" alt="Address" />
                        ))}
                        {makeAkomodasiBintangArry((5 - item?._akomodasi?.bintang)).map((i) => (
                          <img src={Rating} className="w-4 h-4" alt="Address" />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="paket-title">
                      <img src={Address} alt="Address" />
                      <p className="text-xs font-bold">{item?._akomodasi?.city}</p>
                    </div>
                    <div className="paket-title mt-4">
                      <img src={Rute} alt="hotel akomodasi" />
                      <p className="text-xs">
                        {item?._akomodasi?.city === 'Makkah' || item?._akomodasi?.city === 'Madinah' ? (
                          `+-${item?._akomodasi?.city === 'Makkah' ? item?._akomodasi?.distance_alharam : item?._akomodasi?.distance_nabawi}m dari halaman Masjid ${item?._akomodasi?.city === 'Makkah' ? 'Al Haram' : 'Nabawi'}`
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className={classes.penginapanContainer}>
            <div className="paket-content !flex-col">
              <div className="paket-seat">
                <div className="paket-title">
                  <img
                    src={SeatBlack}
                    alt="hotel akomodasi"
                    className="align-center align-middle m-0"
                  />
                  <p className="text-[12px] !font-semibold !text-center align-middle seat-black">
                    {data?.kuota} Total Seat
                  </p>
                </div>
                <div className="paket-title">
                  <img
                    src={SeatBlue}
                    alt="hotel akomodasi"
                    className="!align-middle"
                  />
                  <p className="text-[12px] !font-semibold !text-center align-middle blue-seat">
                    {data?.jumlah_jamaah} Seat Terisi
                  </p>
                </div>
                <div className="paket-title">
                  {data?.kuota_tersedia > 5 ? (
                    <>
                      <img
                        src={SeatGreen}
                        alt="hotel akomodasi"
                        className="align-middle"
                      />
                      <p className="text-[12px] !font-semibold !text-center align-middle green-seat">
                        {data?.kuota_tersedia} Sisa Seat 
                      </p>
                    </>
                  ) : (
                    <>
                      <img
                        src={SeatRed}
                        alt="hotel akomodasi"
                        className="align-middle"
                      />
                      <p className="text-[12px] !font-semibold !text-center align-middle red-linear-text">
                        {data?.kuota_tersedia} Sisa Seat 
                      </p>
                    </>
                  )}
                </div>
              </div>
              {data?.jamaah_without_ticket > 0 && (
                <div className="bg-[#92E6A7] p-2 mx-4 mb-4 text-[#1A7431] flex justify-center items-center text-xs font-semibold rounded-xl">
                  {data?.jamaah_without_ticket} pax Tanpa Tiket
                </div>
              )}
            </div>
          </div>
          {/* <Buttons
            text="Detail Paket"
            customClass="btn-outline btn-large mt-[12px]"
            onClick={() => router.push(`/paket/detail/${data?.id_paket}`)}
          /> */}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PackageDetailPopUp;
