
import Tab from "components/tab";
import { useEffect, useState } from "react";

import useDebounce from "utils/helpers/useDebounce";
import { toCapital } from "utils/helpers"; 

import { AdminMemberAPI, AgenMemberAPI, JamaahMemberAPI } from "utils/api/member";

import IconSearch from "assets/icons/search-normal.svg";

import PropTypes from "prop-types";
import Inputs from "components/form/inputs";
import Scroll from "components/scroll";
import Avatar from "components/avatar";
import Badge from "components/badge";
import Buttons from "components/buttons";

const SelectOrderer = ({
  handleChange
}) => {
  
  const [activeTab, setActiveTab] = useState("admin");
  const [keywordPemesan, setKeywordPemesan] = useState("")
  const searchDebouncePemesan = useDebounce(keywordPemesan, 1000)

  const [isLoadingMore, setIsloadingMore] = useState(false)
  const [pemesanOptionsList, setPemesanOptionsList] = useState([])
  const [nextPemesanCursor, setNextPemesanCursor] = useState(null)

  const handleLoadMorePemesan = async (type) => {
    setIsloadingMore(true)
    if(type === 'admin') {
      await handleGetPemesan(false)
    }

    if(type === 'agent') {
      await handleGetPemesan(false, 'agent')
    }

    if(type === 'jamaah') {
      await handleGetPemesan(false, 'jamaah')
    }

    setIsloadingMore(false)
  }

  const handleGetPemesan = async (firstStep = false, type='admin') => {
    let params = {
      'paginate': 'cursor',
      'per_page': 10,
      'mode'  : 'options',
      'keyword': keywordPemesan
    }

    let response;

    if(!firstStep) {
      params['cursor'] = nextPemesanCursor
    }

    if(type == 'admin') {
      response = await AdminMemberAPI.getAdminList(params)
    }

    if(type == 'agent') {
      response = await AgenMemberAPI.getAgentList(params)
    }

    if(type == 'jamaah') {
      params = {
        ...params,
        have_ordered: 1
      }
      response = await JamaahMemberAPI.getJamaahList(params)
    }
    
    const { status, data }  = response

    if(status === 200) {
      if(firstStep) {
        setPemesanOptionsList(data.data)
      } else {
        let pemesanList = pemesanOptionsList

        pemesanList.push.apply(pemesanList, data.data)
        setPemesanOptionsList(pemesanList)
      }
      setNextPemesanCursor(data.next_cursor)
    }

  }

  useEffect(() => {
    setKeywordPemesan('')
  }, [activeTab])
  
  useEffect(() => {
    setNextPemesanCursor(null)
    setPemesanOptionsList([])
    switch (activeTab) {
      case "admin":
        handleGetPemesan(true);
        break;
      case "agent":
        handleGetPemesan(true, 'agent');
        break;
      case "jamaah":
        handleGetPemesan(true, 'jamaah');
        break;
      default:
        handleGetPemesan(true);
    }
  }, [activeTab, searchDebouncePemesan]);

  useEffect(() => {
    handleGetPemesan()
  }, [])
  

  return (
    <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
      <Tab
        defaultActiveKey="admin"
        onChange={(e) => {
          setActiveTab(e);
        }}
        items={[
          {
            label: "Admin",
            key: "admin",
            children: (
              <>
                <Inputs
                  id="searchAdmin"
                  placeholder="Pencarian.."
                  customClass="!px-3 !py-3.5 !pl-[42px] text-sm my-2"
                  iconPosition="left"
                  value={keywordPemesan}
                  onChange={(e) =>
                    setKeywordPemesan(e.target.value)
                  }
                  icon={IconSearch}
                />
                <Scroll
                  height="350px"
                  customClass="pr-2"
                  >
                  {pemesanOptionsList && pemesanOptionsList.length !== 0 ? (
                    pemesanOptionsList.map((val, idx) => {
                      return (
                        <div
                          key={idx}
                          className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                          onClick={() => {
                            handleChange(val, activeTab);
                            
                          }}
                        >
                            <Avatar
                              value={val}
                              containerClass={`items-center justify-center w-[56px] h-[56px] rounded-[100%]  bg-slate-100`}
                              customClass={`object-cover w-[56px] h-[56px] rounded-[100%]`}
                            />
                          <div className="ml-4">
                            <div className="font-semibold text-[20px]">
                              {val.title ? toCapital(val.title) + `.` : "" } {toCapital(val.name)}
                            </div>
                            <div className="flex gap-2 mt-2">
                              <Badge backgroundColor={val.color_bg_role} color={val.color_text_role} label={val.nama_role} />
                              <Badge backgroundColor={val.color_bg_cabang} color={val.color_text_cabang} label={val.nama_cabang} />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="mt-4">Admin tidak ditemukan.</div>
                  )}
                  {nextPemesanCursor && (
                    <>
                      <div className="flex justify-center items-center w-full m-4">
                        <Buttons
                          onClick={() => handleLoadMorePemesan('admin')}
                          loading={isLoadingMore}
                          text="Lihat selengkapnya"
                          customClass="btn-outline btn-secondary btn-sm"
                          />
                      </div>
                    </>
                  )}
                </Scroll>
              </>
            ),
          },
          {
            label: "Agen",
            key: "agent",
            children: (
              <>
                <Inputs
                  id="searchAgent"
                  placeholder="Pencarian.."
                  customClass="!px-3 !py-3.5 !pl-[42px] text-sm my-2"
                  iconPosition="left"
                  value={keywordPemesan}
                  onChange={(e) =>
                    setKeywordPemesan(e.target.value)
                  }
                  icon={IconSearch}
                />
                <Scroll
                  height="350px"
                  customClass="pr-2"
                  >
                    {pemesanOptionsList && pemesanOptionsList.length !== 0 ? (
                      pemesanOptionsList.map((val, idx) => {
                        return (
                          <div
                            key={idx}
                            className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                            onClick={() => {
                              handleChange(val, activeTab);
                              
                            }}
                          >
                            <Avatar
                              value={val}
                              containerClass={`items-center justify-center w-[56px] h-[56px] rounded-[100%]  bg-slate-100`}
                              customClass={`object-cover w-[56px] h-[56px] rounded-[100%]`}
                            />
                            <div className="ml-3">
                              <div className="font-semibold text-[20px]">
                                {val.title ? toCapital(val.title) + `.` : "" } {toCapital(val.name)}
                              </div>
                              <div className="flex gap-2 mt-2">
                                <Badge backgroundColor={val.color_bg_role} color={val.color_text_role} label={val.role_name} />
                                <Badge backgroundColor={val.color_bg_cabang} color={val.color_text_cabang} label={val.cabang_name} />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="mt-4">Agen tidak ditemukan.</div>
                    )}
                    {nextPemesanCursor && (
                        <>
                          <div className="flex justify-center items-center w-full m-4">
                            <Buttons
                              onClick={() => handleLoadMorePemesan('agent')}
                              loading={isLoadingMore}
                              text="Lihat selengkapnya"
                              customClass="btn-outline btn-secondary btn-sm"
                              />
                          </div>
                        </>
                      )}
                  </Scroll>
              </>
            ),
          },
          {
            label: "Jamaah",
            key: "jamaah",
            children: (
              <>
                <Inputs
                  id="searhJamaah"
                  placeholder="Search"
                  customClass="!px-3 !py-4 !pl-[42px] my-2"
                  iconPosition="left"
                  value={keywordPemesan}
                  onChange={(e) =>
                    setKeywordPemesan(e.target.value)
                  }
                  icon={IconSearch}
                />
                <Scroll
                  height="350px"
                  customClass="pr-2"
                  >
                {pemesanOptionsList && pemesanOptionsList.length !== 0 ? (
                  pemesanOptionsList.map((val, idx) => {
                    return (
                      <div
                        key={idx}
                        className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                        onClick={() => {
                          handleChange(val, activeTab);
                          
                        }}
                      >
                        <Avatar
                          value={val}
                          containerClass={`items-center justify-center w-[56px] h-[56px] rounded-[100%]  bg-slate-100`}
                          customClass={`object-cover w-[56px] h-[56px] rounded-[100%]`}
                        />
                        <div className="ml-3">
                          <div className="font-semibold text-[20px]">
                          {val.title ? toCapital(val.title) + `.` : "" } {toCapital(val.name)}
                          </div>
                          <div className="flex gap-2 mt-2">
                            <Badge backgroundColor={val.color_bg_role} color={val.color_text_role} label={val.role_name} />
                            {/* <Badge label={val.city} /> */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="mt-4">Jamaah tidak ditemukan.</div>
                )}
                {nextPemesanCursor && (
                    <>
                      <div className="flex justify-center items-center w-full m-4">
                        <Buttons
                          onClick={() => handleLoadMorePemesan('jamaah')}
                          loading={isLoadingMore}
                          text="Lihat selengkapnya"
                          customClass="btn-outline btn-secondary btn-sm"
                          />
                      </div>
                    </>
                  )}
                </Scroll>
              </>
            ),
          },
        ]}
      />
    </div>
  )
}

SelectOrderer.propTypes = {
  handleChange: PropTypes.func
};
SelectOrderer.defaultProps = {
  handleChange: () => {}
};

export default SelectOrderer