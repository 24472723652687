import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Buttons from "components/buttons"
import Scroll from "components/scroll"
import { IDRFormater } from "utils/helpers";
import Dropdowns, { closeDropdown } from "components/dropdowns";

import IconChecklisted from "assets/icons/Checklisted.svg";

import { getAdditionalByPaket } from "utils/api/paket/additional";
import { handleAddAdditionalAPI, handleUpdateAdditionalByJamaahOrderAPI } from 'utils/api/booking'

import { useFormik } from "formik"
import * as Yup from 'yup'

import { handleGetDetailOrder } from "stores/actions/booking";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import InputsCurrency from "components/form/inputsCurrency";
import Inputs from "components/form/inputs";
import Textarea from "components/form/textarea";


const EditAdditionalForm = ({
    packageID,
    order,
    onChange,
    additionalData,
    jamaahData
}) => {
    const dispatch = useDispatch()
    const params = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [additionalLabel, setAdditionalLabel] = useState('Pilih Additional') 
    
    const formik = useFormik({
        initialValues: {
            quantity: 1,
            note: ''
        },
        validationSchema: Yup.object().shape({
            quantity: Yup.number().required('Quantity is required'),
        })
    })

    const handleSave = async () => {
        setIsLoading(true)
        
        const dataParams = {
            orderNumber: params.orderNumber,
            jamaahOrderID: jamaahData.id,
            additionalID: additionalData.paket_additional_id,
            payload: formik.values
        }

        const response = await handleUpdateAdditionalByJamaahOrderAPI(dataParams)
        setIsLoading(false)

        if(response.status === 200) {
            dispatch(handleSuccess(response))
            onChange()
        } else {
            dispatch(handleError(response.data))
        }
    }

    useEffect(() => {
        console.log(additionalData);
        setAdditionalLabel(additionalData.additional_name)

        formik.setFieldValue('quantity', additionalData?.quantity)
        formik.setFieldValue('note', additionalData?.note)

        formik.setFieldTouched('quantity', 1)
        formik.setFieldTouched('note', 1)
        

    }, [additionalData])

    return (
        <>
            <div className="border rounded-t-2xl p-4">
                <Scroll 
                    height="390px"
                    >
                    
                    <div className="p-4 border rounded-2xl ">
                        <div className="items-center flex gap-2">
                            <Dropdowns
                                dropdownArrow={false}
                                customClass="flex items-center gap-2"
                                labelDropdown={
                                    <>
                                        <div className="flex items-center font-semibold">
                                            {additionalLabel}
                                        </div>
                                    </>
                                }
                                >
                            </Dropdowns>
                        </div>
                        <div className="mt-3 bg-orange-1 text-white rounded-2xl p-4 text-xs">
                            <div className="mb-2">
                                Harga
                            </div>
                            <div className="font-semibold">
                                {/* {additionalSelected ? (
                                    <>
                                        {IDRFormater(additionalSelected.price)}
                                    </>
                                ) : (
                                    <>
                                        -
                                    </>
                                )} */}
                                {IDRFormater(additionalData?.price)}
                            </div>
                        </div>
                    </div>

                    <div className="p-4 border rounded-2xl mt-4">
                    <div className="flex gap-2 items-center mt-4">
                    <Inputs 
                        id="quantity"
                        type='number'
                        containerClass="w-1/5"
                        label="Jumlah"
                        placeholder="Jumlah"
                        min={1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.quantity}
                        error={formik.touched.quantity && formik.errors.quantity}
                        />
                    <InputsCurrency
                        id="price" 
                        containerClass="w-4/5"
                        label="Harga"
                        disabled
                        value={(formik.values.quantity * (additionalData?.price ?? 0))}
                     />
                </div>
                <div className="mt-4 mb-4">
                    <Textarea
                        label="Catatan"
                        id="note"
                        placeholder="Catatan"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.note}
                        error={formik.touched.note && formik.errors.note}
                        ></Textarea>
                </div>
                    </div>

                </Scroll>
            </div>
            <div className="border border-t-0 rounded-b-2xl p-4 flex justify-end">
                <Buttons
                    text="Edit Additional"
                    customClass="btn-primary btn-large"
                    disabled={formik.dirty && !formik.isValid}
                    loading={isLoading}
                    onClick={handleSave}
                    />
            </div>
        </>
    )
}

export default EditAdditionalForm