import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import Rates from "../../../../../components/form/rate";
import {
  editDataMaster,
  getDetailDataAkomodasi,
  getDetailDataTransport,
} from "stores/actions/datamaster";
import uuid from "react-uuid";

export const columns = (
  handleOpen,
  setActionHandle,
  setIsModal,
  openModal,
  dispatch,
  formikValue,
  setCheckName,
  permissions
) => [
  {
    title: "Logo",
    dataIndex: "logo",
    render: (img) => (
      <img
        className="rounded-full w-10 h-10 object-cover"
        alt="logo maskapai"
        src={img}
      />
    ),
  },
  {
    title: "Nama",
    dataIndex: "name",
  },

  {
    title: "Kategori",
    dataIndex: "kategori",
  },
  {
    title: "Kelas Bintang",
    dataIndex: "bintang",
    width: `150px`,
    align: 'center',
    render: (s) => (
      <div>
        <Rates value={s} disabled={true} />
      </div>
    ),
  },
  {
    title: "Map",
    dataIndex: "address_lat_long",
  },
  {
    title: "Deskripsi",
    dataIndex: "deskripsi",
    className: "whitespace-nowrap relative",

    render: (s, record) => (
      <div className="w-full relative flex flex-row justify-between">
        {s !== null && s?.length > 150 ? `${s?.substr(0, 150)}...` : s}
        <div className="action">
          <div className="action-content">
            {permissions?.includes("delete_master-data") && (
              <div className="action-content-item">
                <Trash
                  onClick={() => {
                    handleOpen();
                    setActionHandle(record.id_akomodasi);
                  }}
                  size="16"
                  color="#141414"
                  className="cursor-pointer"
                />
              </div>
            )}
            {permissions?.includes("create_master-data") && (
              <div className="action-content-item">
                <DocumentCopy
                  onClick={() => {
                    formikValue("id", record.id_akomodasi);
                    formikValue("name", `${record.name} - COPY`);
                    setIsModal(true);
                  }}
                  size="16"
                  color="#141414"
                  className="cursor-pointer"
                />
              </div>
            )}
            {permissions?.includes("update_master-data") && (
              <div className="action-content-item">
                <Edit
                  className="cursor-pointer"
                  onClick={() => {
                    openModal(true);
                    dispatch(editDataMaster("edit akomodasi"));
                    dispatch(getDetailDataAkomodasi(record.id_akomodasi, uuid()));
                  }}
                  size="16"
                  color="#141414"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ),
  },
];
