import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import StarIcon from "assets/icons/Star.svg";
import AvatarIkhwan from "assets/images/Avatar-ikhwan-1.svg";
import Badge from "components/badge";
import CalendarIcon from "assets/icons/colored/calendar-icon.svg";
import ILCoomingSoon from "assets/images/cooming-soon.svg";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";
import Rating from "components/rating";
// import { Rating } from "@mui/material";

import { getRatingPackageService } from "utils/api/paket/ibadahku";
import { handleError } from "stores/actions/errorGeneral";
import Spinner from "components/spinner";
import { toCapital } from "utils/helpers";
import Avatar from "components/avatar";
import moment from "moment";

const listRating = [
  {
    key: null,
    label: "Semua"
  },
  {
    key: 1,
    label: "RATING 1",
  },
  {
    key: 2,
    label: "RATING 2",
  },
  {
    key: 3,
    label: "RATING 3",
  },
  {
    key: 4,
    label: "RATING 4",
  },
  {
    key: 5,
    label: "RATING 5",
  },
];

const RatingCardData = [
  {
    name: "Dimas Andreyan Prana Putra",
    badgeLabel: "Yogyakarta",
    badgeColor: "#FE0000",
    badgeBackgroundColor: "#F7A399",
    fotoProfilImg: AvatarIkhwan,
    date: "10/10/2023",
    textReview: `Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
        aliquam, purus sit amet luctus venenatis, lectus magna fringilla
        urna, porttitor rhoncus dolor purus non enim praesent elementum
        facilisis leo, velLorem ipsum dolor sit amet, consectetur
        adipiscing elit ut aliquam, purus sit amet luctus venenatis,
        lectus magna fringilla urna, porttitor rhoncus dolor purus non
        enim praesent elementum facilisis leo, vel`,
  },
  {
    name: "Dimas Andreyan Prana Putra",
    badgeLabel: "Yogyakarta",
    badgeColor: "#FE0000",
    badgeBackgroundColor: "#F7A399",
    fotoProfilImg: AvatarIkhwan,
    date: "10/10/2023",
    textReview: `Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
        aliquam, purus sit amet luctus venenatis, lectus magna fringilla
        urna, porttitor rhoncus dolor purus non enim praesent elementum
        facilisis leo, velLorem ipsum dolor sit amet, consectetur
        adipiscing elit ut aliquam, purus sit amet luctus venenatis,
        lectus magna fringilla urna, porttitor rhoncus dolor purus non
        enim praesent elementum facilisis leo, vel`,
  },
  {
    name: "Dimas Andreyan Prana Putra",
    badgeLabel: "Yogyakarta",
    badgeColor: "#FE0000",
    badgeBackgroundColor: "#F7A399",
    fotoProfilImg: AvatarIkhwan,
    date: "10/10/2023",
    textReview: `Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
        aliquam, purus sit amet luctus venenatis, lectus magna fringilla
        urna, porttitor rhoncus dolor purus non enim praesent elementum
        facilisis leo, velLorem ipsum dolor sit amet, consectetur
        adipiscing elit ut aliquam, purus sit amet luctus venenatis,
        lectus magna fringilla urna, porttitor rhoncus dolor purus non
        enim praesent elementum facilisis leo, vel`,
  },
];

const RatingPerjamaahCard = ({
  name,
  fotoProfilImg,
  badgeLabel,
  badgeColor,
  badgeBackgroundColor,
  date,
  textReview,
  jamaah,
  rating
}) => {
 
  return (
    <div className="flex flex-col items-start p-[16px] gap-[16px] shadow-soft rounded-xl">
      <div className="flex flex-row justify-between items-start gap-[16px] w-full">
        <div className="flex flex-row items-center gap-[16px]">
          <Avatar 
            value={jamaah} 
            containerClass="tems-center justify-center w-[40px] h-[40px] rounded-[100%]  bg-slate-100"
            customClass="object-cover w-[40px] h-[40px] rounded-[100%]"
            />
          <div className="flex flex-col items-start gap-[8px]">
            <h2>{name}</h2>
            <Badge
              color={badgeColor}
              backgroundColor={badgeBackgroundColor}
              label={badgeLabel}
            />
          </div>
        </div>
        <div className="flex flex-row items-center gap-[8px]">
          <img src={CalendarIcon} alt="calendar icon" />
          <p>{moment(date).format("dddd, DD MMMM YYYY HH:mm")} WIB</p>
        </div>
      </div>
      <div className="flex flex-col items-start gap-[8px]">
        <div>
          <Rating name="size-medium" value={rating} />
        </div>
        <div>
          <p>{textReview}</p>
        </div>
      </div>
    </div>
  );
};

const RatingPerjamaah = () => {
  const [activeFilter, setActiveFilter] = useState(null);
  
  const dispatch = useDispatch();
  const params = useParams()

  const [ratingTotal, setRatingTotal] = useState(0);
  const [ratings, setRatings] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [getting, setGetting] = useState(false);

  const handleGetRatingReviews = async () => {
    setGetting(true);
    try {

      const payload = {
          "paket_id" : params.id,
          "rating" : activeFilter, // isi null jika pilih semua
          "per_page" : 1000,
          "page_number" : 1,
          "sort_column" : "jamaah_name",
          "sort_order" : "asc"
      }

      const res = await getRatingPackageService(params.id, payload);
      setGetting(false);
      if(res.status === 200) {
        setRatingTotal(res.data.data.rating);
        setRatings(res.data.data.ratings);
        setReviews(res.data.data.reviews);
      } else {
        dispatch(handleError(res.data));
      }
    } catch (error) {
      handleError(error);
    }
  };

  const getFilterRating = () => {
    return listRating.map((rating, idx) => {
      return (
        <div
          key={idx}
          className={`border-[1px] border-[#4048521a] rounded-lg px-4 py-1 cursor-pointer uppercase font-semibold ${
            activeFilter === rating.key && "bg-[#F3CFCE]"
          }`}
          onClick={() => setActiveFilter(rating.key)}
        >
          <div className={`${activeFilter === rating.key && "txt-maroon"}`}>
            {rating.label != "Semua" ? (
              <>
                {rating.label} ({ratings[idx-1]?.count})
              </>
            ) : (
              <>
                {rating.label}
              </>
            )}  
          </div>
        </div>
      );
    });
  };


  useEffect(() => {
    handleGetRatingReviews();
  }, [dispatch, activeFilter]);

  return (
    <>
      <div className="flex flex-col gap-[16px] mt-4">
        <div className="flex flex-row items-center p-[20px] gap-4 rounded-xl bg-white soft-shadow">
          <div className="flex flex-row gap-[4px]">
            <img src={StarIcon} alt="rating" />
            <h2 className="font-semibold">{ratingTotal}/5</h2>
          </div>
          <div className="flex flex-row flex-wrap items-center gap-4">
            {getFilterRating()}
          </div>
        </div>
        {getting ? (
          <>
            <div className="flex h-[400px] justify-center items-center">
              <Spinner width="50px" height="50px"/>
            </div>
          </>
        ) : (
          <>
            {reviews.length > 0 ? (
              <>
                <div className="flex gap-[16px] flex-col">
                  {reviews.map((item) => (
                    <RatingPerjamaahCard
                      key={item.name}
                      name={`${toCapital(item.title)}. ${item.name?.toUpperCase()}`}
                      badgeLabel={item.branch_name}
                      badgeColor={item.branch_text_color}
                      badgeBackgroundColor={item.branch_bg_color}
                      fotoProfilImg={item.fotoProfilImg}
                      date={item.date}
                      textReview={item.comment}
                      rating={item.rating ?? 0}
                      jamaah={item}
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col h-[400px] justify-center items-center bg-white">
                  <img
                    src={IconJamPasir}
                    className="mb-2 w-[252px] h-[260px]"
                  />
                  <p className="text-base text-black">
                    Belum ada Review
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {/* <div className="flex flex-col h-screen justify-center items-center bg-white">
        <img
          src={ILCoomingSoon}
          alt="Cooming Soon"
          className="mb-2 w-[252px] h-[260px]"
        />
        <p className="text-base text-black">
          Maaf, fitur ini sedang dalam tahap pengembangan
        </p>
      </div> */}
    </>
  );
};

export default RatingPerjamaah;
