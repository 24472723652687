import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Buttons from "components/buttons";
import MoslemeTable from "components/Table";
import Badge from "components/badge";
import Popup from "components/popup";
import PaketLaporanPembayaranDaftarPopup from "./components/DaftarPembayaran";

import IconCSV from "assets/icons/CSV.svg";
import BCA from "assets/images/bca.png";

import { onHandleGetOrderReportAPI, exportLaporanOrderReportService } from "utils/api/paket/laporan";
import { handleError } from "stores/actions/errorGeneral";
import { IDRFormater, formattorbase64 } from "utils/helpers";

const PaketLaporanPembayaran = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  const [orderList, setOrderList] = useState([])

  // Expaneble
  const [isExpand, setIsExpand] = useState(true);
  const [tableExpand, setTableExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  const [orderSelectedID, setOrderSelectedID] = useState(null);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const handleGetOrder = async () => {
    setIsLoading(true);
    const payload = {
      "sort_column" : "id",
      "sort_order" : "asc",
      "per_page" : size,
      "page_number" : currentpage,
    }
    const response = await onHandleGetOrderReportAPI(
      params.id,
      payload
    )
    setIsLoading(false);

    if (response.status === 200) {
      setOrderList(response.data.data)
      setTotal(response.data.total_data)
    } else {
      dispatch(handleError(response.data));
    }
  }

  const handleGetPayments = (order) => {
    setOpenPopup(true)
    setOrderSelectedID(order)
  }

  const handleDownloadCSV = async () => {
    const response = await exportLaporanOrderReportService(params.id);

    if (response.status === 200) {
      const { base64, type, file_name } = response.data.data;
      const insertPrefix = formattorbase64(type, base64);
      var a = document.createElement("a"); //Create <a>
      a.href = `${insertPrefix}`; //Image Base64 Goes here
      a.download = `${file_name}`; //File name Here
      a.click();
    } else {
      dispatch(handleError(response.data));
    }
  };

  const columns = [
    {
      title: "Kode Tagihan",
      dataIndex: "order_number",
      render: (text) => <span>#{text}</span>,
    },
    // {
    //   title: "Nama Jamaah",
    //   dataIndex: "name",
    //   render: (name) => (
    //     <>
    //       <div>
    //         <div className="whitespace-nowrap mb-1">{name.title}</div>
    //       </div>
    //       <div className="flex flex-row gap-1">
    //         {tableExpand ? (
    //           <Badge customClass="uppercase" label="Dibayar Sebagian" />
    //         ) : (
    //           <>
    //             <Badge label={name.tags[0]} />
    //             <Badge label={name.tags[1]} />
    //           </>
    //         )}
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "Tanggal Booking",
      dataIndex: "order_at",
      render: (order_at) => <div>{order_at} WIB</div>,
    },
    {
      title: "Banyak Transaksi",
      dataIndex: "payment_count",
      render: (count) => <div>{count}x Transaksi</div>,
    },
    {
      title: "Total Dibayar",
      dataIndex: "paid_amount",
      className: "whitespace-nowrap",
      render: (paid) => <div className="font-semibold">{IDRFormater(paid)}</div>,
    },
    {
      title: "Total DP",
      dataIndex: "down_payment",
      className: "whitespace-nowrap",
      render: (dp) => <div className="font-semibold">{IDRFormater(dp)}</div>,
    },
    {
      title: "Total Tagihan",
      dataIndex: "total_price",
      className: "whitespace-nowrap",
      render: (bill) => <div className="font-semibold">{IDRFormater(bill)}</div>,
    },
  ];

  const data = [
    {
      key: "1",
      bill_code: "#UMR-123-1",
      name: {
        title: "Dimas Andreyan Pradana Putra",
        tags: ["Admin Cabang Daerah", "Aceh"],
      },
      booking_date: "31/12/2023 14:07 WIB",
      total_transaction: "5x Transaksi",
      total_paid: "10.000.000",
      total_dp: "10.000.000",
      total_bill: "40.000.000",
    },
  ];

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => (
      //   <p style={{ margin: 0 }}>{record.name.title}</p>
      <div className="ml-2 relative">
        <ul className="w-full flex py-3 border-l">
          <li className="w-full">
            <ul>
              <li className="border-b pl-5 pb-3 font-bold">
                Tanggal Pembayaran
              </li>
            </ul>
          </li>
          <li className="w-full">
            <ul>
              <li className="border-b pl-5 pb-3 font-bold">Nomor Pembayaran</li>
            </ul>
          </li>
          <li className="w-full">
            <ul>
              <li className="border-b pl-5 pb-3 font-bold">
                Nominal Pembayaran
              </li>
            </ul>
          </li>
          <li className="w-full">
            <ul>
              <li className="border-b pl-5 pb-3 font-bold">Akun Penerima</li>
            </ul>
          </li>
          {/* <li className="w-full">
            <ul>
              <li className="border-b pl-5 pb-3 font-bold">Akun Pengirim</li>
            </ul>
          </li> */}
        </ul>
        {record.payments.map((payment, index) => (
          <ul className="w-full flex items-center pb-3 border-l border-b">
            <li className="w-full">
              <div className="pl-5 pt-2">
                {payment.date}
              </div>
            </li>
            <li className="w-full">
              <div className="pl-5 pt-2">#{payment.payment_number}</div>
            </li>
            <li className="w-full">
              <div className="pl-5 pt-2 font-semibold">{IDRFormater(payment.amount)}</div>
            </li>
            <li className="w-full whitespace-nowrap">
              <div className="pl-5 pt-2">
                <div className="flex flex-row items-center gap-2">
                  {record._receipent_account_detail?.bank_logo && <img className="flex w-[16px] h-[16px]" src={record._receipent_account_detail?.bank_logo} alt="bank" />}
                  <div>{record._receipent_account_detail?.account_number}</div>
                </div>
                <div className="font-semibold mt-1">
                  {record._receipent_account_detail?.account_owner}
                </div>
              </div>
            </li>
          </ul>
        ))}
        <Buttons
          text="Lihat Selengkapnya"
          customClass="btn-secondary btn-small !w-fit mt-4"
          onClick={()=> {
            handleGetPayments(record.order_id)
          }}
        />
      </div>
    ),
    rowExpandable: (record) => record.payments?.length,
    onExpand: (check) => setTableExpand(check),
  };
  // manipulated data with action
  
  useEffect(() => {
    handleGetOrder()
  }, [])

  useEffect(() => {
    handleGetOrder()
  }, [currentpage, size])

  return (
    <>
      <Popup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        title="Daftar Pembayaran #ID"
        width="1080px"
        children={
          <PaketLaporanPembayaranDaftarPopup 
            orderID={orderSelectedID}
            />
        }
      />
      <div className="bg-white border-[1px] border-[#4048521a] rounded-bl-lg rounded-br-lg">
        <div className="flex flex-row justify-between items-center p-4">
          <div className="text-[20px] font-semibold">Laporan Pembayaran</div>
          {/* <Buttons
            text="Export CSV"
            customClass="btn-outline btn-small"
            icon={IconCSV}
            onClick={handleDownloadCSV}
          /> */}
        </div>
        <div className="mt-4">
          <MoslemeTable
            loading={isLoading}
            expandable={isExpand && expandable}
            columns={columns}
            data={orderList}
            withSelection={withSelection}
            selectionType={selectionType}
            rowKey={(record) => record.order_id}
            pagination={pagination}
            onPageChange={(page) => setCurrentpage(page)}
            onSizeChange={(currentpage, pageSize) =>
              onSizeChange(currentpage, pageSize)
            }
          />
        </div>
      </div>
      <div id="area-modal"></div>
    </>
  );
};

export default React.memo(PaketLaporanPembayaran);
