import React, { useEffect, useState } from "react";

import EditorToolbar, {
  formats,
  modules,
} from "components/richTextEditor/EditorToolbar";
import ReactQuill from "react-quill";
import RichTextEditor from "components/richTextEditor";
import Tab from "components/tab";

import { handleGetFollowupTemplate } from "utils/api/booking";
import { useFormik } from "formik";

const FollowUpOne = ({
  formik
}) => {
  const [input, setInput] = useState();

  const handleGetTemplate = async () => {
    const res = await handleGetFollowupTemplate('dp');
    const templateDP = res.data.data[0]

    formik.setFieldValue('message_to_orderer', templateDP.message_to_orderer)
    formik.setFieldValue('message_to_jamaah', templateDP.message_to_jamaah)
  }

  const shortcodes = [
    {
      shortcode: "[nama]",
      description: "Nama yang ditagihkan"
    },
    {
      shortcode: "[nama_pemesan]",
      description: "Nama Pemesan"
    },
    {
      shortcode: "[no_whatsapp_pemesan]",
      description: "Nomor Whatsapp Pemesan"
    },
    {
      shortcode: "[email_pemesan]",
      description: "Email Pemesan"
    },
    {
      shortcode: "[role_pemesan]",
      description: "Role Pemesan"
    },
    {
      shortcode: "[nama_travel]",
      description: "Nama Travel"
    },
    {
      shortcode: "[tgl_batas_dp]",
      description: "Tanggal Batas DP"
    },
    {
      shortcode: "[jarak_batas_dp]",
      description: "Jarak waktu dengan batas DP"
    },
    {
      shortcode: "[total_dp_unik]",
      description: "Nominal DP dengan kode unik"
    },
    {
      shortcode: "[total_dp]",
      description: "Nominal DP tanpa kode unik"
    },
    {
      shortcode: "[nama_bank]",
      description: "Nama Bank rekening penerima"
    },
    {
      shortcode: "[no_rekening]",
      description: "Nomor rekening penerima"
    },
    {
      shortcode: "[nama_rekening]",
      description: "Atas nama rekening penerima"
    },
    {
      shortcode: "[invoice_id]",
      description: "ID Invoice"
    }
  ]

  useEffect(() => {
    handleGetTemplate();
  }, [])

  return (
    <>
    <div>
      <Tab
        defaultActiveKey="1"
        destroyInactiveTabPane={true}
        items={[
          {
            label: "Pesan ke Pemesan",
            key: "1",
            children: (
              <>
                <div className="mt-4">
                    <RichTextEditor 
                      placeholder="Masukan pesan template untuk pemesan"
                      label="" 
                      shortcode={true}
                      shortcodeData={shortcodes}
                      data={{value: formik.values.message_to_orderer}}
                      onChange={(value) => formik.setFieldValue('message_to_orderer', value)}
                      />
                </div>
              </>
            ),
          },
          {
            label: "Pesan ke Jamaah",
            key: "2",
            children: (
              <>
                <div className="mt-4">
                    <RichTextEditor 
                      label="" 
                      placeholder="Masukan pesan template untuk jamaah" 
                      shortcode={true}
                      shortcodeData={shortcodes}
                      data={{value: formik.values.message_to_jamaah}}
                      onChange={(value) => formik.setFieldValue('message_to_jamaah', value)}
                      />
                </div>
              </>
            ),
          },
        ]}
      />
      </div>
    </>
  );
};

export default React.memo(FollowUpOne);
