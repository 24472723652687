import { getInfoKontakWA, getBranchDetail } from "stores/actions/cabang";
import { getInitials } from "utils/helpers";

const { IcCabangPusat } = require("assets/icons/dashboard");
const { Trash, Eye } = require("iconsax-react");
const { detailById } = require("stores/actions/general");

export const columns = (dispatch, permissions) => [
  {
    title: "Foto",
    dataIndex: "img",
    width: "5%",
    render: (img, branch) => {
      return img ? (
        <img
          src={img}
          alt="avatar"
          className="w-8 h-8 rounded-full object-fill"
        />
      ) : (
        <div
          className={`w-8 h-8 rounded-full flex justify-center items-center text-base font-[500]`}
          style={{ background: branch.color, color: branch.color_text }}
        >
          {getInitials(branch.name)}
        </div>
      );
    },
  },
  {
    title: "Nama",
    dataIndex: "name",
    width: "10%",
    render: (name, data) => {
      return (
        <div className="flex flex-row">
          {data?.is_pusat === 1 && <img src={IcCabangPusat} alt="cabang" />}
          <div className="ml-2">{name}</div>
        </div>
      );
    },
  },
  {
    title: "Alamat",
    width: "45%",
    dataIndex: "address_details",
  },
  {
    title: "Website",
    dataIndex: "url_website",
    render: (link) => (
      <>
        <a
          href={link}
          className="flex link"
          style={{ color: "#E82320", WebkitTextFillColor: "unset" }}
        >
          {link?.replace(/^https?:\/\//, "")}
        </a>
      </>
    ),
  },
  {
    title: "Warna",
    dataIndex: "color",
    width: "15%",
    render: (color, item, idx) => {
      return (
        <>
          <div
            className={`px-2 py-1.5 rounded-lg text-center inline-block uppercase`}
            style={{ backgroundColor: color, fontSize: "12px" }}
          >
            {color}
          </div>
          <div className="action">
            <div className="action-content">
              {permissions?.includes("view_branch") && (
                <div className="action-content-item">
                  <Eye
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(getBranchDetail(item));
                    }}
                    size="15"
                    color="#141414"
                  />
                </div>
              )}
              {permissions?.includes("delete_branch") && (
                <div className="action-content-item">
                  <Trash
                    className="cursor-pointer"
                    onClick={() =>
                      dispatch(detailById({ delete: true, data: item }))
                    }
                    size="15"
                    color="#141414"
                  />
                </div>
              )}
            </div>
          </div>
        </>
      );
    },
  },
];
