import { Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deletePaketTransportation, getListPaketTransportasi } from "stores/actions/paket/transportasi";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import EditIcon from "assets/icons/edit.svg";
import TrashIcon from "assets/icons/trash.svg";

import AlertPopup from "components/popup/alert";
import SkeletonView from "./skeleton-view";

const ListView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { listPaketTransportasi, isLoading } = useSelector((state) => state?.transportasi);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const getAllPaketTransportasi = useCallback(() => {
    dispatch(
      getListPaketTransportasi({
        id_paket: id,
        page_number: 1,
        per_page: 1000,
        keyword: '',
        sort_column: "name",
        sort_order: "ASC",
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    getAllPaketTransportasi();
  }, [getAllPaketTransportasi]);

  const onConfirmDelete = () => {
    setIsLoadingDelete(true);

    dispatch(
      deletePaketTransportation(
        deleteData.id,
        {},
        (message) => {
          setIsLoadingDelete(false);
          setIsDelete(false);
          getAllPaketTransportasi();
          dispatch(handleSuccess(null, {message: "Berhasil menghapus transportasi", code: 200}));
        },
        (error) => {
          setIsLoadingDelete(false);
          setIsDelete(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleDelete = (item) => {
    setIsDelete(true);
    setDeleteData(item);
  };

  if (isLoading) {
    return <SkeletonView />;
  }

  return (
    <>
      <AlertPopup
        open={isDelete}
        handleClose={() => {
          setIsDelete(false);
        }}
        handleContinue={onConfirmDelete}
        loading={isLoadingDelete}
        title="Hapus Data"
        subtitle="Apakah Anda sudah yakin ingin menghapus transportasi? "
      />
      <div className="space-y-4">
        {listPaketTransportasi?.data?.map((item) => (
          <div
            key={item.id}
            className="flex items-start border border-gray-4 rounded-2xl p-4 justify-between"
          >
            <div className="flex items-center gap-6">
              <Image
                width={88}
                height={88}
                src={item.logo}
                alt={item.name}
                className="rounded-2xl"
                preview={false}
              />
              <div>
                <div className="font-semibold">{item.name}</div>
                <div className="text-[#4F4F4F] text-[14px] font-normal mt-1">
                  {item.category}
                </div>
              </div>
            </div>
            {auth?.user?.access_data === 'all_branches' && permissions?.includes('update_package') && (
              <div className="flex gap-4">
                <button onClick={() => handleDelete(item)}>
                  <img src={TrashIcon} alt="trash icon" width={22} />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ListView;