import {
  ProfileAPI
} from "utils/api/profile";

import {
  uploadService
} from "utils/api/upload";

import { setUser } from 'stores/actions/auth'
import { handleError, handleErrorBE, handleSuccess } from "../errorGeneral";
import { setLocalStorage } from "../../../utils/helpers";

export const SET_LOADING = "profile/SET_LOADING";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload
})

export const handleUpdateProfile = (payload, succesCallback, error400Callback) => async (dispatch) => {
  dispatch(setLoading(true))

  const response = await ProfileAPI.updateProfile(payload)
  const { status, data } = response;

  dispatch(setLoading(false))

  if(status === 200) {
    dispatch(handleSuccess(response))

    succesCallback(response)
    const userData = {
      isAuth: true,
      ...data.data
    }

    dispatch(setUser(userData))
    setLocalStorage("userLogin", JSON.stringify(userData));
  } else {
    if(status === 400) {
      error400Callback(response)
    } else {
      dispatch(handleError(response))
    }
  }

}

export const handleUpdateContact = (payload, error400Callback) => async (dispatch) => {
  dispatch(setLoading(true))

  const response = await ProfileAPI.updateContact(payload)
  const { status, data } = response;

  dispatch(setLoading(false))

  if(status === 200) {
    dispatch(handleSuccess(response))
    const userData = {
      isAuth: true,
      ...data.data
    }

    dispatch(setUser(userData))
    setLocalStorage("userLogin", JSON.stringify(userData));
  } else {
    if(status === 400) {
      error400Callback(response)
    } else {
      dispatch(handleError(response))
    }
  }

}

export const handleUpdatePassword = (payload, error400Callback) => async (dispatch) => {
  dispatch(setLoading(true))

  const response = await ProfileAPI.updatePassword(payload)
  const { status, data } = response;

  dispatch(setLoading(false))

  if(status === 200) {
    dispatch(handleSuccess(response))
    const userData = {
      isAuth: true,
      ...data.data
    }

    dispatch(setUser(userData))
    setLocalStorage("userLogin", JSON.stringify(userData));
  } else {
    if(status === 400) {
      error400Callback(response)
    } else {
      dispatch(handleError(response))
    }
  }

}

export const handleUploadPhotoProfile = (payload, successCallback) => async (dispatch) => {
  const formData = new FormData()
  formData.append('images', payload.images)
  formData.append('directory', payload.directory)

  const response = await uploadService(formData)
  const {status, data} = response

  if(status === 200) {
    successCallback(data)
  } else {
    dispatch(handleError(response))
  }
}