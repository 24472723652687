// errorGeneral Reducers
// --------------------------------------------------------

import {
  SET_LOADING,
  CLEAR_ERROR,
  SET_ERROR,
  INIT_DATA,
  SET_DOUBLE_SUBMIT,
  SET_ERROR_400,
  SET_ERROR_404,
  SET_SUCCESS_200,
} from "../../actions/errorGeneral";

const initialState = {
  isLoading: false,
  isDoubleSubmit: false,
  isSubmitting: false,
  error: {
    isError: false,
    message: "",
    code: "",
  },
  error400: {
    isError400: false,
    message: "",
    code: "",
  },
  error404: {
    isError404: false,
    message: "",
    code: "",
  },
  success: {
    title: null,
    isSuccess: false,
    message: "",
    code: "",
    title: null
  },
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_DOUBLE_SUBMIT:
      return {
        ...state,
        isDoubleSubmit: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case INIT_DATA:
      return {
        ...initialState,
      };
    case SET_ERROR_400:
      return {
        ...state,
        error400: payload,
      };

    case SET_ERROR_404:
      return {
        ...state,
        error404: {
          ...payload,
        },
      };

    case SET_SUCCESS_200:
      return {
        ...state,
        success: payload,
      };

    default:
      return state;
  }
};
