import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import UploadArea from "components/Uploadarea"
import Buttons from "components/buttons"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

import { handleUpdateManifestAPI } from 'utils/api/booking'
import { handleGetDataJamaah } from 'stores/actions/booking'

import { handleError, handleSuccess } from 'stores/actions/errorGeneral'
import Inputs from 'components/form/inputs'
import Selects from 'components/form/selects'
import DatePicker from 'components/datepicker/DatePicker'

import * as Yup from 'yup'

import moment from 'moment'
import dayjs from "dayjs";
import Textarea from 'components/form/textarea'
import { toPermalink } from 'utils/helpers'

const KTP = ({
    data,
    jamaahOrderID,
    onSuccess,
    permissions,
    jamaahName,
    orderNumber,
    author
}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const formik = useFormik({
        initialValues: {
            order_kamar_mapping_id: jamaahOrderID,
            data
        },
        validationSchema: Yup.object({
            data: Yup.object({
                ktp_nik: Yup.string().required("NIK harus diisi")
                    .matches(/^[0-9]+$/, "NIK Harus berupa angka")
                    .min(16, "NIK harus 16 digit")
                    .max(16, "NIK harus 16 digit").required("Data NIK harus diisi")
            })
        })
    })
    const [isLoading, setIsLoading] = useState(false)

    const handleSave = async () => {
        // console.log(formik.values);
        setIsLoading(true)
        const response = await handleUpdateManifestAPI(orderNumber, formik.values)
        setIsLoading(false)
        if(response.status === 200) {
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate data manifest", code: 200 }))
            onSuccess()
        } else {
            dispatch(handleError(response.data))
        }

    }
    
    const genderOptions = [
        {
            label: "Laki-laki",
            value: "Laki-laki"
        },
        {
            label: "Perempuan",
            value: "Perempuan"
        }
    ]

    useEffect(() => {
        formik.setValues({
            order_kamar_mapping_id: jamaahOrderID,
            data
        })

    }, [data])



    return (
        <>
            <div className="border p-4 rounded-t-2xl">
                <div className='flex gap-4'>
                    <div className='w-1/2'>
                        <UploadArea
                            viewOnly={!permissions.includes('update_manifest')}
                            type='chose'
                            label="Foto KTP"
                            customClass="w-full"
                            defaultUrl={formik.values?.data?.ktp}
                            onChange={(url) => formik.setFieldValue('data.ktp', url)}
                            handleRemove={() => formik.setFieldValue('data.ktp', null)}
                            customName={`ktp-${orderNumber}-${toPermalink(jamaahName)}`}
                            note={
                                author?.ktp
                                    ? `Terupload ${moment(author?.ktp?.updated_at).format('dddd, DD MMMM YYYY [.] [jam] HH.mm')} oleh ${author?.ktp?.name} sebagai ${author?.ktp?.role}` 
                                    : null
                            }
                            />
                    </div>
                    <div className='w-1/2'>
                        <Inputs 
                            disabled={!permissions.includes('update_manifest')}
                            name='data.ktp_name'
                            id='data.ktp_name'
                            label='Nama sesuai KTP'
                            placeholder='Nama Sesuai KTP'
                            containerClass='mb-4'
                            value={formik.values?.data?.ktp_name?.toUpperCase()}
                            onChange={(e) => formik.setFieldValue('data.ktp_name', e.target.value.toUpperCase())}
                            />
                        <Inputs 
                            disabled={!permissions.includes('update_manifest')}
                            label='NIK'
                            name='data.ktp_nik'
                            id='data.ktp_nik'
                            placeholder='Masukan NIK'
                            containerClass='mb-4'
                            maxLength={16}
                            onBlur={formik.handleBlur}
                            error={formik.touched.ktp_nik && formik.errors.ktp_nik}
                            value={formik.values?.data?.ktp_nik}
                            onChange={(e) => formik.setFieldValue('data.ktp_nik', e.target.value)}
                            />
                        <Selects 
                            disabled={!permissions.includes('update_manifest')}
                            label='Jenis Kelamin'
                            placeholder='Pilih Jenis Kelamin'
                            containerClass='mb-4'
                            options={genderOptions}
                            values={genderOptions.filter(item => item.value == formik.values?.data?.ktp_gender)}
                            onChange={(e) => formik.setFieldValue('data.ktp_gender', e[0].value)}
                            />
                    </div>
                </div>
                <div className='mb-4 mt-4'>                
                    <div className='mb-4'>   
                        <div className='text-sm font-semibold mb-2'>Tanggal Lahir</div>
                        <DatePicker 
                            disabled={!permissions.includes('update_manifest')}
                            customClass='w-full'
                            value={formik.values?.data?.ktp_date_of_birth ? dayjs(moment(formik.values?.data?.ktp_date_of_birth).format("YYYY-MM-DD"), "YYYY-MM-DD") : dayjs(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
                            onChange={(val) => {
                                formik.setFieldValue('data.ktp_date_of_birth', moment(val, "DD-MM-YYYY").format("YYYY-MM-DD"))
                            }}
                            />
                    </div>
                    <div>
                        <Inputs 
                            disabled={!permissions.includes('update_manifest')}
                            name='data.ktp_place_of_birth'
                            id='data.ktp_place_of_birth'
                            label='Tempat lahir'
                            placeholder="Masukan Tempat Lahir"
                            value={formik.values?.data?.ktp_place_of_birth}
                            onChange={(e) => formik.setFieldValue('data.ktp_place_of_birth', e.target.value)}
                            />
                    </div>
                    <div className='mt-4'>
                        <Textarea 
                            disabled={!permissions.includes('update_manifest')}
                            name='data.ktp_address'
                            id='data.ktp_address'
                            label='Alamat sesuai KTP'
                            placeholder='Masukan alamat '
                            value={formik.values?.data?.ktp_address}
                            onChange={(e) => formik.setFieldValue('data.ktp_address', e.target.value)}
                            />
                    </div>
                </div>
            </div>
            {permissions.includes('update_manifest') && (
                <div className="border rounded-b-2xl flex justify-between border-t-0 p-4">
                    <div>
                    </div>
                    <div>
                        <Buttons
                            customClass="btn-primary btn-large"
                            text="Simpan"
                            loading={isLoading}
                            onClick={handleSave}
                            />
                    </div>
                </div>
            )}
        </>
    )
}

export default KTP