import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import IconCalender from 'assets/icons/icon-calendar-maroon.svg'
import IconClock from 'assets/icons/clock-active.svg'

import id from 'date-fns/locale/id';

import './custom.css'

const ReactDatepicker = ({
    selected,
    onChange,
    showTimeSelect,
    showTimeSelectOnly
}) => {
    registerLocale('id', id);
    const [startDate, setStartDate] = useState(new Date());

    const isValidDate = (dateString) => {
      var date = new Date(dateString);
      return !isNaN(date.getTime());
    }

    useEffect(() => {
      if(isValidDate(selected)){
        setStartDate(selected)
      } else {
        setStartDate(new Date())
      }
    }, [selected])

    return (
      <>
        {/* {!showTimeSelect && !showTimeOnly && ( */}
          <>
            <DatePicker 
              showIcon
              selected={startDate}
              onChange={(date) => {
                setStartDate(date)
                onChange(date)
              }}
              showTimeSelect={showTimeSelect}
              showTimeSelectOnly={showTimeSelectOnly}
              locale='id'
              // dateFormat={"dd/MM/yyyy"}
              // timeFormat={false}
              dateFormat={showTimeSelectOnly ? "HH:mm" : "dd/MM/yyyy"}
              timeCaption={`Waktu`}
              icon={
                <>
                  <div className="absolute right-4 top-[14px]">
                    {!showTimeSelectOnly ? (
                      <>
                        <img src={IconCalender} className="!w-6 !h-6" alt="calendar" />
                      </>
                    ) : (
                    <>
                      <img src={IconClock} className="!w-6 !h-6" alt="Waktu" />
                    </>)}
                  </div>
                </>
              }
              />
          </>  
        {/* )} */}

      </>
    )
}

ReactDatepicker.propTypes = {
  // selected: PropTypes.date,
  onChange: PropTypes.func,
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool
}

ReactDatepicker.defaultProps = {
  selected: new Date(),
  onChange: () => {},
  showTimeSelect: false,
  showTimeSelectOnly: false
}

export default React.memo(ReactDatepicker)