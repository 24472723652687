import Avatar from "components/avatar";
import uuid from "react-uuid";
import { setDataUpload } from "stores/actions/uploadFile";

const { default: Badge } = require("components/badge");
const { Trash, DocumentCopy, Edit } = require("iconsax-react");
const { detailById } = require("stores/actions/general");

const columns = (dispatch, dataUpload, permissions) => [
  {
    title: "Foto",
    dataIndex: "photo",
    render: (img) => (
      <div className="!w-8">
        {/* <img
          src={img}
          alt="img"
          className="w-8 h-8 rounded-full object-cover"
        /> */}
        <Avatar imageTemp={img} />
      </div>
    ),
  },
  {
    title: "Nama",
    dataIndex: "name",
    render: (name, item) => <div>{name}</div>,
  },
  {
    title: "Tipe",
    dataIndex: "type",
    render: (type) => (
      <div>
        <Badge
          customClass="capitalize"
          backgroundColor={type === "pembimbing" ? "#D2E6CF" : "#F9DCF3"}
          color={type === "pembimbing" ? "#333D29" : "#973AA8"}
          label={type}
        />
      </div>
    ),
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    render: (desc, item) => (
      <div className="!w-2/5">
        <div>{desc}</div>
        <div className="action">
          <div className="action-content">
            {permissions?.includes("delete_apps") && (
              <div className="action-content-item">
                <Trash
                  onClick={() => dispatch(detailById({ delete: true, data: item }))}
                  size="15"
                  color="#141414"
                  className="cursor-pointer"
                />
              </div>
            )}
            {permissions?.includes("create_apps") && (
              <div className="action-content-item">
                <DocumentCopy
                  onClick={() =>
                    dispatch(detailById({ duplicate: true, data: item }))
                  }
                  size="15"
                  color="#141414"
                  className="cursor-pointer"
                />
              </div>
            )}
            {permissions?.includes("update_apps") && (
              <div className="action-content-item">
                <Edit
                  onClick={() => {
                    dispatch(
                      detailById({ addOrUpdate: true, data: item, type: "update" })
                    );
                    dispatch(
                      setDataUpload([
                        ...dataUpload,
                        {
                          name: "guide",
                          path: item?.photo,
                          fileName: item?.name,
                          id: uuid(),
                        },
                      ])
                    );
                  }}
                  size="15"
                  color="#141414"
                  className="cursor-pointer"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ),
  },
];

export default columns;
