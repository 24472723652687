import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { menuStepSidebarListOrder } from "../../configuration/stepMenuListOrder";
import { useSelector, useDispatch } from "react-redux";

import TagihanStyle from "./Tagihan.style";
import SidebarChild from "../../components/template_layout/Siderbar/SidebarChild";
import CardBookingTable from "./components/CardBookingTable";
// import Popup from "../../components/popup";
// import AddBooking from "./components/AddBooking";
// import PopupConfirm from "../../components/popup/error-handling/index";

import BookingAction from 'stores/actions/booking/umroh'

import { clearSucces200 } from "../../stores/actions/errorGeneral";

const BookingPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showContent, setShowContent] = useState("umroh");
  const [openPopupAddBooking, setPopupAddBooking] = useState(false);

  const {success} = useSelector(
    (state) => state.errorGeneral
  ) 

  const handleClickSidebar = (key) => {
    setShowContent(key)
    history.push(`/tagihan/${key}`)
  }

  return (
    <TagihanStyle>
        <SidebarChild
          menuList={menuStepSidebarListOrder}
          handleClick={handleClickSidebar}
          showContent={showContent}
        />
        <div style={{ marginLeft: '174px', paddingLeft: '16px' }}>
          <CardBookingTable  />
          <div className="h-5"></div>
        </div>
    </TagihanStyle>
  );

};

export default BookingPage;
