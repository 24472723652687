import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import "./datepicker.scss";
import DatePickerIcon from "./icons/DatePickerIcon";
import dayjs from "dayjs";
import Close from "assets/icons/Close.svg";
import moment from "moment";

const { RangePicker } = DatePicker;

const CalendarIcon = <DatePickerIcon />;

const rangePresets = [
  { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
  { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
];


const DateRangePicker = ({
  containerClass,
  customClass,
  placeholder,
  placeholder2,
  value,
  onCalendarChange,
  onChange,
  showRangePresets,
  onBlur,
  id,
  name,
  errors
}) => {

  const [valueDate, setValueDate] = useState(undefined);

  useEffect(() => {
    if(value) {
      
      if(value.length) {

        if(value[0] && value[1]) {
          setValueDate([dayjs(value[0], "YYYY-MM-DD"), dayjs(value[1], "YYYY-MM-DD")]);
        } else {
          setValueDate(undefined);
        }

      } else {
        setValueDate(undefined);
      }

    } else {
      setValueDate(undefined);
    }
  }, [value]);

  return (
    <div className={`${containerClass}`}>
      <RangePicker
        id={id}
        allowClear={true}
        className={`${customClass} ${errors && "border border-[#E82320]"}`}
        placeholder={[placeholder, placeholder2]}
        popupClassName="popup-date-picker"
        onCalendarChange={(date) => {
          const data = date?.map((item) => {
            return item ? moment(item.$d).locale("id").format("YYYY-MM-DD") : null;
          });
          
          onCalendarChange(data);
        }}
        onChange={(date) => {
          const data = date?.map((item) => {
            return item ? moment(item.$d).locale("id").format("YYYY-MM-DD") : null;
          });
          
          onChange(data);
        }}
        presets={showRangePresets && rangePresets}
        suffixIcon={CalendarIcon}
        separator="/"
        allowEmpty={[true, true]}
        value={valueDate}
        format="DD-MM-YYYY"
        getPopupContainer={() => document.getElementById("area-datepicker")}
        name={name}
        onBlur={onBlur}
      />
      <div id="area-datepicker" className="custom-datepicker-open"></div>
      {errors && (
        <>
          <div className="flex flex-row items-center gap-2 mt-1">
            <img src={Close} alt="close" />
            <div className="text-[#e82320]">{errors}</div>
          </div>
        </>
      )}
    </div>
  );
};

DateRangePicker.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  onCalendarChange: PropTypes.func,
  onChange: PropTypes.func,
  showRangePresets: PropTypes.bool,
  onBlur: PropTypes.func,
  value: PropTypes.array,
  errors: PropTypes.string
};

DateRangePicker.defaultProps = {
  containerClass: "",
  customClass: "",
  placeholder: "",
  onCalendarChange: () => {},
  onChange: () => {},
  showRangePresets: false,
  onBlur: () => {},
  value: [],
  errors: ""
};

export default DateRangePicker;
