import { getListService, getUnreadCountService, readService } from "../../../utils/api/notifications";
import { handleError } from "../errorGeneral";

export const SET_LOADING_UNREAD_ALL = "notifications/SET_LOADING_UNREAD_ALL";
export const SET_LIST = "notifications/SET_LIST";
export const SET_COUNT_UNREAD = "notifications/SET_COUNT_UNREAD";
export const SET_NEXT = "notifications/SET_NEXT";

export const setLoadingUnreadAll = (payload) => ({
    type: SET_LOADING_UNREAD_ALL,
    payload,
});

export const setList = (payload) => ({
    type: SET_LIST,
    payload,
});

export const setCountUnread = (payload) => ({
    type: SET_COUNT_UNREAD,
    payload,
});

export const setNext = (payload) => ({
    type: SET_NEXT,
    payload,
});


export const getList = (params = null) => async (dispatch, getState) => {

    if(!params?.per_page) {
        // append params per_page
        params = {...params, per_page: 10};
    }

    if(!params?.cursor) {
        // append params cursor
        params = {...params, cursor: params.next_cursor};
    }

    const res = await getListService(params);
    if (res.status === 200) {
        if(params?.cursor) {
            dispatch(setList([...getState().notifications.list, ...res.data.data]));
        } else {
            dispatch(setList(res.data.data));
        }
        dispatch(setNext(res.data.next_cursor));
    } else {
        dispatch(handleError(res.data));
    }
}

export const getCountUnread = () => async (dispatch) => {
    const res = await getUnreadCountService();
    if (res.status === 200) {
        dispatch(setCountUnread(res.data.data.count));
    } else {
        dispatch(handleError(res.data));
    }
}

export const read = (payload) => async (dispatch) => {

    if(!payload?.id) {
        dispatch(setLoadingUnreadAll(true));
    }

    const res = await readService(payload);
    dispatch(setLoadingUnreadAll(false));

    if (res.status === 200) {
        dispatch(getList());
        dispatch(getCountUnread());
    } else {
        dispatch(handleError(res.data));
    }
}