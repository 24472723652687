import ICWarn from "../../assets/icons/warn.svg";
import React, { useEffect } from "react";
import MoslemeTable from "../../components/Table";
import MaleAvatar from "../../assets/icons/male.svg";
import Badge from "../../components/badge";
import CreateJamaah from "./components/CreateJamaah";
import AlertPopup from "../../components/popup/alert";
import JamaahAction from "../../stores/actions/member/jamaah";
import { Edit, Trash, Verify } from "iconsax-react";
import { FilterJamaah } from "./components/Filter";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "components/avatar";

function Jamaah() {
  const dispatch = useDispatch();
  const store = useSelector(({ jamaah }) => jamaah);
  const auth = useSelector(({ auth }) => auth)
  const [isEditShow, setIsEditShow] = React.useState(false);
  const [isShowDelete, setIsShowDelete] = React.useState(false);
  const [detail, setDetail] = React.useState(null);
  const [isHover, setIsHover] = React.useState(null);

  const permissions = auth?.user?.permissions;

  const callbackDelete = () => {
    dispatch(JamaahAction.fetchData());
    setIsShowDelete(false);
  };

  const onShowEdit = (id) => {
    dispatch(JamaahAction.setJamaahId(id));
    setTimeout(() => {
      setIsEditShow(true);
    }, 500);
  };

  const columns = [
    {
      title: "Foto",
      dataIndex: "foto",
      render: (img, a) => <Avatar value={a} />,
    },
    {
      title: "Nama",
      dataIndex: "name",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            alert(rowIndex);
          },
        };
      },
    },
    {
      title: "Telepon",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => (
        <p className="max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis">
          {text}
        </p>
      ),
    },
    {
      title: "Cabang",
      dataIndex: "cabang_name",
      render: (text, a) => {
        const cabang = a?.list_cabang?.length;
        const dataFilter = a?.list_cabang?.filter((item, index) => index !== 0);
        if (cabang === 0) {
          return <h1 className="text-xs text-[#141414]">-</h1>;
        }
        return (
          <div className="justify-center items-center">
            <div className={`${cabang && "flex flex-row items-center"}`}>
              <Badge
                backgroundColor={a?.list_cabang[0]?.color}
                color={a?.list_cabang[0]?.color_text}
                label={`${a?.list_cabang[0]?.name}`}
              />
              {cabang > 1 && (
                <div
                  className="p-1.5 ml-1 rounded-lg border border-[#E0E0E0] justify-center items-center cursor-pointer"
                  onMouseEnter={() => setIsHover(a?.id)}
                  onMouseLeave={() => setIsHover(null)}
                >
                  <h1 className="text-xs text-[#141414] font-semibold">
                    +{cabang - 1}
                  </h1>
                </div>
              )}
            </div>
            {isHover === a?.id && (
              <div className="absolute mt-2.5">
                <div className="p-4 bg-white rounded-2xl justify-center items-center shadow-soft">
                  {dataFilter?.map((cabang, i) => {
                    return (
                      <div
                        className={`py-[6px] px-4 ${
                          i !== 0 && "mt-2.5"
                        } rounded-lg text-xs font-semibold`}
                        style={{
                          color: cabang?.color_text,
                          backgroundColor: cabang?.color,
                        }}
                      >
                        {cabang?.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Status aktif",
      dataIndex: "status_active",
      className: "whitespace-nowrap relative",
      render: (s, a) => {
        return (
          <div>
            <div className="flex items-center">
              <div
                className={`w-3 h-3 ${
                  s === "Online" ? "bg-[#A7C957]" : "bg-slate-400"
                } rounded-full mr-2`}
              />
              <p className="font-bold">{s}</p>
            </div>
          </div>
        );
      },
    },

    {
      title: "Status email",
      dataIndex: "is_email_confirmed",
      className: "whitespace-nowrap",
      render: (s, a) => (
        <div>
          <Badge
            backgroundColor={
              s === 1 ? "rgba(167, 201, 87, 0.3);" : "rgba(141, 8, 1, 0.3)"
            }
            color="#141414"
            label={
              <div className="flex items-center">
                {s === 1 ? (
                  <Verify size="16" color="#2DC653" variant="Bold" />
                ) : (
                  <img src={ICWarn} alt="warn" />
                )}

                <p className="ml-1">{s === 1 ? "Verified" : "Unverified"}</p>
              </div>
            }
          />
          <div className="action">
            <div className="action-content">
              {permissions?.includes("delete_member") && (
                <div className="action-content-item">
                  <Trash
                    className="cursor-pointer"
                    onClickCapture={() => {
                      setIsShowDelete(true);
                      setDetail(a);
                    }}
                    size="15"
                    color="#141414"
                  />
                </div>
              )}
              {permissions?.includes("update_member") && (
                <div className="action-content-item">
                  <Edit
                    className="cursor-pointer"
                    onClick={() => onShowEdit(a.id)}
                    size="15"
                    color="#141414"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
  ];

  const sourceData = store.data.map((item, i) => {
    return {
      ...item,
      key: `${i + 1}`,
      status_active: item.is_online ? "Online" : "Offline",
      foto: MaleAvatar,
      className: "whitespace-nowrap",
    };
  });
  // co

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: store.total,
    current: store.page_number,
    pageSize: store.per_page,
    defaultPageSize: store.per_page,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  useEffect(() => {
    dispatch(JamaahAction.fetchData());
  }, [dispatch, store.keyword, store.per_page, store.page_number]);

  return (
    <div>
      <AlertPopup
        subtitle={`Apakah kamu yakin ingin menghapus “${detail?.name}”?`}
        open={isShowDelete}
        handleClose={() => setIsShowDelete(false)}
        handleContinue={() =>
          dispatch(JamaahAction.deleteData(detail?.id, callbackDelete))
        }
        loading={store.status === "process"}
      />
      <FilterJamaah />

      <CreateJamaah
        show={isEditShow}
        close={() => {
          dispatch(JamaahAction.setJamaahId("0"));
          setIsEditShow(false);
        }}
      />

      <div>
        <MoslemeTable
          loading={store.status === "process"}
          columns={columns}
          withSelection={true}
          data={sourceData}
          pagination={pagination}
          onPageChange={(page) => dispatch(JamaahAction.setPage(page))}
          onSizeChange={(currentpage, pageSize) => {
            dispatch(JamaahAction.setPage(currentpage));
            dispatch(JamaahAction.setSize(pageSize));
          }}
        />
      </div>
    </div>
  );
}

export default Jamaah;
