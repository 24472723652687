import Tab from "components/tab";
import Inputs from "components/form/inputs";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Penerbangan from "./Tabs/Penerbangan";
import Bus from "./Tabs/Bus";

import IconSearch from "assets/icons/search-normal.svg";
import IconAirplaneInactive from "assets/icons/airplane-inactive.svg";
import IconAirplane from "assets/icons/colored/airplane-3-icon.svg";
import IconBusInactive from "assets/icons/colored/bus-inactive-icon.svg";

import { handleGetTransportations } from "utils/api/paket/blockseat";
import { useEffect, useState } from "react";
import { dark } from "@material-ui/core/styles/createPalette";
import { handleError } from "stores/actions/errorGeneral";


const Blockseat = ({
  packageID
}) => {
  const params = useParams()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [transportations, setTransportations] = useState([])
  const [BlockseatTabs, setBlockseatTabs] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [transportationType, setTransportationType] = useState([])

  useEffect(() => {
    handleGetListTransportations()
  },[])

  const handleGetListTransportations = async () => {
    setIsLoading(true)
    const response = await handleGetTransportations(packageID)
    const { status, data } = response

    if (status === 200) {
      fetchTranspotations()
      setTransportationType(data.data)
    } else {
      dispatch(handleError(data))
    }

  }

  useEffect(() => {
    fetchTranspotations()
  }, [transportationType])

  const fetchTranspotations = () => {
    const newData = transportationType.map((item,index) => {
      return {
        label: (
          <>
            <span className="flex gap-1">
              {index === 0 && (
                <>
                  {(index + 1) === activeTab ? <img src={IconAirplane} alt="icon" /> : <img src={IconAirplaneInactive} alt="icon" />}
                </>
              )}
              {index !== 0 && (
                <>
                  <img src={IconBusInactive} alt="icon" />
                </>
              )}
              <span className="font-semibold">
                {index === 0 ? "Pesawat" : item.name}  
              </span> 
              <span className="txt-green font-semibold">({item.total_seat})</span>
            </span>
          </>
        ),
        key: index + 1,
        children: index === 0 ? <Penerbangan packageID={packageID} /> : <Bus packageID={packageID} busID={item.id} />,
      }
    })

    setBlockseatTabs(newData)
    setIsLoading(false)
  }

  return (
    <>
      <div className="p-5 border-b relative">
        <div>
          <h2 className="font-semibold mb-2">Tipe Transportasi</h2>

          <Tab 
            defaultActiveKey="1" 
            onChange={(key) => setActiveTab(key)}
            items={BlockseatTabs} />
        </div>
      </div>
    </>
  );
};

export default Blockseat;
