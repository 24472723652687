import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import Buttons from "../../../../components/buttons";
import Cards from "../../../../components/cards";
import Dropdowns from "../../../../components/dropdowns";
import Popup from "../../../../components/popup";

import { Edit, Trash } from "iconsax-react";
import IconMore from "../../../../assets/icons/More.svg";
import AddFAQ from "./components/AddFAQ";
import { useDispatch, useSelector } from "react-redux";
import { deleteIdFAQ, getDataFAQ, setDataFAQ } from "stores/actions/faq";
import { convertBase64 } from "utils/helpers/convertBase64";
import AlertPopup from "../../../../components/popup/alert";
import Spinner from "components/spinner";
import { Spin } from "antd";
import { removeHtmlTags } from "utils/helpers";
import Tab from "components/tab";
import ListFAQ from "./components/ListFAQ";

const parse = require("html-react-parser");

const SettingFAQ = () => {
  const [openPopupAddFAQ, setOpenPopupAddFAQ] = useState(false);
  const dispatch = useDispatch();
  const { dataFAQ, isPopUP } = useSelector((state) => state.faq);
  const { isLoading } = useSelector((state) => state.faq);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [faqToDelete, setFaqToDelete] = useState(null);

  const [faqCategory, setFaqCategory] = useState("mobile_jamaah");

  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (isPopUP) {
      setOpenPopupAddFAQ(false);
      dispatch(getDataFAQ());
    }
  }, [isPopUP]);

  // useEffect(() => {
  //   dispatch(getDataFAQ());
  // }, [getDataFAQ]);

  const handleEditClick = (id) => {
    let dataToEdit = dataFAQ.find((item) => item.id_faq === id);

    if (dataToEdit) {
      setEditData(dataToEdit);
      setIsEdit(true);
      setOpenPopupAddFAQ(true);
    }
  };

  const handleConfirmDeleteFAQ = (id) => {
    const dataToDelete = dataFAQ.find((item) => item.id_faq === id);

    if (dataToDelete) {
      setFaqToDelete(dataToDelete);
      setIsDelete(true);
      setShowAlertPopup(true);
    }
  };

  const handleDeleteFAQ = async () => {
    setIsDeleting(true);
    await dispatch(deleteIdFAQ(faqToDelete.id_faq));    
    setShowAlertPopup(false);
    setFaqToDelete(null);
    setIsDelete(true);
    setIsDeleting(false);
    dispatch(getDataFAQ({
      "page_number": 1,
      "per_page": 1000,
      "keyword": "",
      "sort_column": "",
      "sort_order": "ASC",
      "category": faqCategory
    }));
  };

  return (
    <>
      <AlertPopup
        open={showAlertPopup}
        handleClose={() => setShowAlertPopup(false)}
        handleContinue={handleDeleteFAQ}
        loading={isDeleting}
        title="Hapus FAQ"
        subtitle={`Apakah anda sudah yakin ingin menghapus FAQ ${faqToDelete?.pertanyaan} `}
      />

      <Popup
        open={openPopupAddFAQ}
        handleClose={() => {
          setOpenPopupAddFAQ(false);
          setIsEdit(false);
        }}
        title={isEdit ? "Edit FAQ" : "Tambah FAQ"}
        containerClass=""
        children={
          <AddFAQ setOpenPopupAddFAQ={setOpenPopupAddFAQ} editData={editData} category={faqCategory} />
        }
      />
      <Cards containerClass="!p-0">
        <div className="flex flex-row justify-between items-center p-4">
          <div className="text-[20px] font-semibold">Daftar FAQ</div>
        </div>
        <div className="divider"></div>
        <div className="relative">
          <Buttons
              text="Tambah FAQ"
              customClass="btn-primary btn-small !inline-block !w-auto absolute right-4 top-[22px] z-10"
              onClick={() => {
                setOpenPopupAddFAQ(true);
                setEditData(null);
              }}
            />
          <Tab 
            containerClass="p-4 mb-4"
            defaultActiveKey="mobile_jamaah"
            destroyInactiveTabPane={true}
            onChange={(category) => {
              setFaqCategory(category);
            }}
            items={[
              {
                label: "Mobile Jamaah",
                key: "mobile_jamaah",
                children: 
                  <ListFAQ 
                    handleEdit={handleEditClick} 
                    handleRemove={handleConfirmDeleteFAQ}
                    category="mobile_jamaah" />,
              },
              {
                label: "Mobile Agen",
                key: "mobile_agent",
                children: <ListFAQ 
                handleEdit={handleEditClick} 
                handleRemove={handleConfirmDeleteFAQ}
                category="mobile_agent" />,
              },
            ]}
            />
        </div>
      </Cards>
    </>
  );
};

export default React.memo(SettingFAQ);
