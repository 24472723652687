import { useEffect } from "react"

import { useSelector } from "react-redux"

import Scroll from "components/scroll"

import IconArrowLeftGray from 'assets/icons/arrow-right-gray.svg'

const Step4SelectingPaymentMethods = ({
  rekeningOptions,
  onChange
}) => {

  const auth = useSelector(({auth}) => auth)

  const handleSelected = (rekeningId, paymentMethod) => {
    onChange(rekeningId, paymentMethod)
  }

  return (
    <>
      <Scroll 
        maxHeight="calc(100vh - 350px)"
      >
        <div className="bg-white rounded-2xl border border-gray-4 p-4">
          <div className="font-semibold">
            Pembayaran Transfer (verifikasi otomatis ±5 menit)
          </div>
          <div className="flex flex-col mb-[18px]">
            {rekeningOptions?.filter((item) => item.bank_name.toLowerCase() !== 'cash')?.map((rekening, i) => (
              <>
                <div 
                  key={i} 
                  onClick={() => handleSelected(rekening?.id_rekening, 2)}
                  className={`${i === (rekeningOptions?.filter((item) => item.bank_name.toLowerCase() !== 'cash')?.length - 1) ? '' : 'border-b'} py-[18px] flex justify-between items-center cursor-pointer`}>
                  <div className="flex gap-4 items-center">
                    <img src={rekening.bank_logo} alt="Bank Logo" className="h-8" />
                    <div>
                      {rekening.bank_name}
                    </div>
                  </div>
                  <div>
                    <img src={IconArrowLeftGray} alt="Bank Arrow" />
                  </div>
                </div>
              </>
            ))}
          </div>
          {auth?.user?.access_data === 'all_branches' && (
            <>
              <div className="font-semibold">
                Pembayaran Tunai (verifikasi manual)
              </div>
              <div className="flex flex-col">
                {rekeningOptions?.filter((item) => item.bank_name.toLowerCase() === 'cash')?.map((rekening, i) => (
                  <>
                    <div 
                      key={i}
                      onClick={() => handleSelected(rekening?.id_rekening, 1)}
                      className={`${i === (rekeningOptions?.filter((item) => item.bank_name.toLowerCase() === 'cash').length - 1) ? '' : 'border-b'} py-[18px] flex justify-between items-center cursor-pointer`}>
                      <div className="flex gap-4 items-center">
                        <img src={rekening.bank_logo} alt="Bank Logo" className="h-8" />
                        <div>
                          {rekening.bank_name}
                        </div>
                      </div>
                      <div>
                        <img src={IconArrowLeftGray} alt="Bank Arrow" />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      </Scroll>
    </>
  )
}


export default Step4SelectingPaymentMethods