import Scroll from "components/scroll"
import Tab from "components/tab"
import ReactJson from "react-json-view"

const DataDetail = ({
  oldData,
  newData,
  requestData
}) => {

  return (
    <div className="shadow-hard rounded-2xl overflow-hidden">
      <div className="p-4 bg-orange-1 text-white font-semibold">
        Detail Data
      </div>
      <div className="px-4 bg-white">
        <Tab
          defaultActiveKey="1"
          destroyInactiveTabPane={true}
          items={[
            {
              label: "Data Lama",
              key: "1",
              children: <div className="py-4">
                <Scroll
                  height="300px"
                  width="700px"
                  >
                  <ReactJson
                    src={JSON.parse(oldData)} />
                </Scroll>
              </div>
              ,
            },
            {
              label: "Data Baru",
              key: "2",
              children: <div className="py-4">
                <Scroll
                  height="300px"
                  width="700px"
                  >
                    <ReactJson
                    src={JSON.parse(newData)} />
                </Scroll>
              </div>
              ,
            },
            {
              label: "Request Data",
              key: "3",
              children: <div className="py-4">
                <Scroll
                  height="300px"
                  width="700px"
                  >

                    <ReactJson
                      src={JSON.parse(requestData)} />

                  </Scroll>
                
              </div>
              ,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default DataDetail