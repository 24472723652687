import debounce from "debounce-promise";
import { PaketCouponAPI } from "utils/api/paket/coupon";
import {
  ERROR_FETCHING_PAKET_COUPON,
  SET_ADD_OR_EDIT_PAKET_COUPON,
  SET_DELETE_PAKET_COUPON,
  SET_LOADING,
  START_FETCHING_PAKET_COUPON,
  SUCCESS_FETCHING_PAKET_COUPON,
  CLEAR_ERROR,
  SET_PAGE_PAKET_COUPON,
  SET_SIZE_PAKET_COUPON,
  SET_SIZE_TOTAL_PAKET_COUPON,
  SET_KEYWORD_PAKET_COUPON,
} from "./actionTypes";
import { handleError, handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";

let debounceGetList = debounce(PaketCouponAPI.getList, 1500);

const startFetchingPaketCoupon = () => ({
  type: START_FETCHING_PAKET_COUPON,
});

const errorFetchingPaketCoupon = () => ({
  type: ERROR_FETCHING_PAKET_COUPON,
});

const successFetchingPaketCoupon = (payload) => ({
  type: SUCCESS_FETCHING_PAKET_COUPON,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

const setPage = (page) => ({
  type: SET_PAGE_PAKET_COUPON,
  page,
});

const setSize = (size) => ({
  type: SET_SIZE_PAKET_COUPON,
  size,
});

const setTotal = (total) => ({
  type: SET_SIZE_TOTAL_PAKET_COUPON,
  total,
});

const setKeyword = (keyword) => ({
  type: SET_KEYWORD_PAKET_COUPON,
  keyword,
});

export const setAddOrEditCoupon = (payload, edit) => ({
  type: SET_ADD_OR_EDIT_PAKET_COUPON,
  payload,
  edit,
});

export const setDeleteCoupon = (payload, id) => ({
  type: SET_DELETE_PAKET_COUPON,
  payload,
  id,
});

const handleFetchDataPaketCoupon = (payload) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingPaketCoupon());
    try {
      let per_page = getState().coupon.per_page;
      let page_number = getState().coupon.page_number;
      let sort_column = getState().coupon.sort_column;
      let sort_order = getState().coupon.sort_order;
      let keyword = getState().coupon.keyword;
      const container = {
        paket_id: payload.paket_id,
        per_page,
        page_number,
        sort_column,
        sort_order,
        keyword,
      };

      let res = await debounceGetList(container);
      if (res.data.code === 401) {
        window.location.href = "/login";
      }
      let data = res.data.data === "" ? [] : res.data.data;
      dispatch(successFetchingPaketCoupon(data));
      dispatch(setTotal(res.data.total_data));
      dispatch(setPage(res.data.page));
    } catch (error) {
      console.log("error: ", error.response);
      dispatch(errorFetchingPaketCoupon());
    }
  };
};

const handleAddOrEditCoupon = (
  payload,
  actionSuccess = (message) => {},
  actionError = (error) => {}
) => {
  return async (dispatch, getState) => {
    let edit = payload.edit ? true : false;
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await PaketCouponAPI.postData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const handleDeleteCoupon = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await PaketCouponAPI.deleteData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setDeleteCoupon(response.data, payload));
        dispatch(handleSuccess(response))
        return response.status;
      } else {
        dispatch(handleError(response.data));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const handleDuplicateCoupon = (payload, successAction, errorAction) => {
  return async (dispatch, getState) => {
    const response = await PaketCouponAPI.duplicateCoupon(payload)
    const {status, data} = response

    if(status === 200) {
      dispatch(handleSuccess(null, {message:"Berhasil menduplikat coupon", code: "200"}))
      successAction(data)
    } else {
      errorAction()
      dispatch(handleError(data))
    }
  }
}

const PaketCouponAction = {
  fetchData: handleFetchDataPaketCoupon,
  postData: handleAddOrEditCoupon,
  deleteData: handleDeleteCoupon,
  setPage: setPage,
  setSize: setSize,
  setKeyword: setKeyword,
  duplicate: handleDuplicateCoupon
};

export default PaketCouponAction;
