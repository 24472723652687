/* eslint-disable react/jsx-no-duplicate-props */
import { Edit, Trash } from "iconsax-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IcArrowBottom,
  IcClose,
  IcDocCopy,
  IcTambah,
  IcTanggal,
} from "assets/icons/dashboard";
import { ImCardAceh } from "assets/images";
import Badge from "components/badge";
import Buttons from "components/buttons";
import Cards from "components/cards";
import { Tooltip } from "antd";
import { resetDataUpload } from "stores/actions/uploadFile";
import moment from "moment";

import "./Itenary.scss";

const DetailItinenary = ({
  setIsDuplicated,
  setIsDrawer,
  setIsEditItenary,
  setIsDelete,
  setIsModalImage,
  detailItenary,
  setListDataGalery,
  setChoice,
  getListStartingItenaryV2,
  setItenary,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  const title = (item) => (
    <div className="text-white items-center text-center justify-between p-3 text-sm flex-col">
      <div className="text-center font-semibold capitalize">
        {item?.airport_departure_city}
      </div>
      <div class="flex z-50 items-center">
        <div class="border-b border-white flex-grow"></div>
        <div class="px-2">
          <img alt="arrow" src={IcArrowBottom} />
        </div>
        <div class="border-b border-white flex-grow"></div>
      </div>
      <div className="text-center font-semibold capitalize">
        {item?.airport_arrival_city}
      </div>
    </div>
  );

  return (
    <div className="relative flex flex-col  justify-start w-full">
      <Cards containerClass="flex flex-row justify-between !p-4 items-center @apply shadow-[0px_4px_80px_-4px_#0000000A,0px_10px_16px_-6px_#0000000A]">
        <div className="text-lg font-semibold flex flex-col">
          Daftar Itinerary
          <Tooltip placement="bottom" title={title(detailItenary)}>
            <Badge
              backgroundColor={detailItenary?.starting?.bg_color}
              color={detailItenary?.starting?.text_color}
              label={detailItenary?.starting?.name}
              customClass=" px-4 text-center py-1  rounded-lg text-xs"
            />
          </Tooltip>
        </div>
        <div>
          <img
            className="cursor-pointer h-6 w-6"
            src={IcClose}
            alt="close"
            onClick={() => {
              setIsDrawer(false);
            }}
          />
        </div>
      </Cards>
      {detailItenary?.itineraries?.length !== 0 && (
        <main className=" ">
          {auth.user.access_data === 'all_branches' && (
            <>
              {permissions?.includes('update_package') && (
                <>
                  <div className=" flex justify-end items-end mt-4">
                    <Buttons
                      text="Duplicate"
                      customClass="btn-outline btn-small"
                      icon={IcDocCopy}
                      onClick={() => {
                        getListStartingItenaryV2(false);
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
          <div className="mt-4">
            {detailItenary?.itineraries?.map((item, idx) => (
              <div className="flex w-full h-full" key={`itenary-${idx}`}>
                <div className="mr-4 flex flex-col items-center ">
                  <div>
                    <div
                      className={`flex h-10 w-10 ${
                        idx == 0 ? "bg-[#FE0000]" : "bg-[#F9F9F9]"
                      }  ${
                        idx === 0 ? "text-white" : "text-[#141414]"
                      }  border-2 border-white items-center justify-center rounded-full font-semibold @apply shadow-[0px_2px_16px_0px_rgba(0,0,0,0.1),0px_4px_4px_0px_rgba(0,0,0,0.1)]`}
                    >
                      {idx + 1}
                    </div>
                  </div>
                  <div className="h-full !border-[1px] border-spacing-y-4 !border-dashed !border-[#DEDEDE]"></div>
                </div>

                <main className=" @apply shadow-soft rounded-2xl p-[16px] !w-full flex flex-col gap-y-[16px] bg-white mb-[20px]">
                  <section className="relative  rounded-2xl  ">
                    <img
                      alt="location"
                      src={item?.thumbnail ? item?.thumbnail : ImCardAceh}
                      className="h-36 w-full bg-cover object-cover rounded-2xl"
                    />

                    <span className="rounded-tl-2xl absolute top-0 text-[12px] font-normal left-0 rounded-br-2xl px-[6px] py-[3px] bg-[#EDBABA] text-[#FE0000] border-white border-2">
                      {item?.rute?.join(",")}
                    </span>
                    <span className="p-2 absolute rounded-bl-2xl top-0 right-0  bg-white cursor-pointer rounded-tr-2xl">
                      {auth.user?.access_data === 'all_branches' && (
                        <>
                          {permissions?.includes('update_package') && (
                            <div className="flex gap-x-2 items-center">
                              <Edit
                                size={16}
                                onClick={() => {
                                  setItenary(item);
                                  setIsEditItenary(true);
                                }}
                              />
                              <Trash
                                size={16}
                                onClick={() => {
                                  setChoice(item);
                                  setIsDelete(true);
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </span>
                  </section>
                  <section className="flex flex-row w-full justify-between items-center">
                    <Badge
                      label={`Hari ke-${item?.day}`}
                      backgroundColor="#D8E1BE"
                      customClass="!w-[100px] text-center flex flex-row justify-center items-center"
                      color="#3D3D3D"
                    />
                    <div className="flex items-center gap-x-2 text-xs ml-[2px]">
                      <img alt="tanggal" src={IcTanggal} />
                      <span>
                        {" "}
                        {moment(item.date, "DD-MM-YYYY").format(
                          "dddd, D MMMM YYYY"
                        )}
                      </span>
                    </div>
                  </section>
                  <div
                    className="data-html break-words"
                    dangerouslySetInnerHTML={{
                      __html: item?.description,
                    }}
                  />

                  <div
                    className="flex items-center gap-x-3 cursor-pointer"
                    onClick={() => {
                      setListDataGalery(item);
                      setIsModalImage(true);
                    }}
                  >
                    {item?.gallery?.slice(0, 6).map((nil, idx) => {
                      return (
                        <div
                          key={`galery-${idx}`}
                          className={`${
                            idx >= 5 &&
                            "contrast-50 rounded-lg flex justify-center items-center"
                          } relative cursor-pointer`}
                        >
                          <img
                            alt="gambar"
                            src={nil ? nil?.link : ImCardAceh}
                            className="w-[60px] h-[60px] rounded-lg object-cover"
                          />
                          {idx >= 5 && (
                            <div className="text-xl absolute font-medium text-white">
                              +{item?.gallery?.length - 5}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </main>
              </div>
            ))}
          </div>
        </main>
      )}
      <div className="mt-5 grow flex justify-end flex-col">
        {auth.user.access_data === 'all_branches' && (
          <>
            {permissions?.includes("update_package") && (
              <Buttons
                text="Rute Hari"
                customClass="btn-outline btn-medium"
                onClick={() => {
                  setIsEditItenary(true);
                  setItenary(null);
                  dispatch(resetDataUpload());
                }}
                icon={IcTambah}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DetailItinenary;
