import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";

import MoslemeTable from "components/Table";
import Buttons from "components/buttons";
import Chart from "components/chart";
import Badge from "components/badge";
import RekeningCard from "components/RekeningCard";
import Popup from "components/popup";
import PopupWithdrawFee from "./components/PopupWithdrawFee";
import AddOrEditRekening from "./components/AddOrEditRekening";
import HistoryPendapatanFee from "./components/HistoryPendapatanFee";
import HistoryPenarikanFee from "./components/HistoryPenarikanFee";

import { 
  getHistoryFeeService, 
  getHistoryWithdrawFeeService, 
  getWithdrawBankAccountService,
  getHistoryFeeStatisticsService
} from "utils/api/fee";

import { handleError } from "stores/actions/errorGeneral";
import moment from "moment";
import { IDRFormater } from "utils/helpers";
import Spinner from "components/spinner";

const PenarikanFee = () => {
  const dispatch = useDispatch();

  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Expaneble
  const [isExpand, setIsExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const [feeHistories, setFeeHistories] = useState([]);
  const [loadingFeeHistories, setLoadingFeeHistories] = useState(false);

  const [feeWithdrawHistories, setFeeWithdrawHistories] = useState([]);
  const [loadingFeeWithdrawHistories, setLoadingFeeWithdrawHistories] = useState(false);

  const [withdrawAccounts, setWithdrawAccounts] = useState([])
  const [isLoadingWithdrawAccounts, setIsLoadingWithdrawAccounts] = useState(false)

  const [totalEarnings, setTotalEarnings] = useState(0)
  const [earningsData, setEarningsData] = useState([])
  const [earningsCategories, setEarningsCategories] = useState([])

  const series = [
    {
      name: "Fee",
      data: earningsData,
    }
  ];

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  const handleGetWithdrawBankAccount = async () => {
    setIsLoadingWithdrawAccounts(true)

    const res = await getWithdrawBankAccountService()
    setIsLoadingWithdrawAccounts(false)

    if (res?.status === 200) {
      setWithdrawAccounts(res?.data?.data);
    } else {
      // gettingFeeHistory(false);
      dispatch(handleError(res?.data));
    }

  }

  const handleGetPenarikanFee = async () => {
    setLoadingFeeHistories(true);
    const payload = {
      offset: 6,
    }

    const res = await getHistoryFeeService(payload);
    setLoadingFeeHistories(false);

    if (res?.status === 200) {
      setFeeHistories(res?.data?.data);
    } else {
      // gettingFeeHistory(false);
      dispatch(handleError(res?.data));
    }

  };

  const handleGetWithdrawFee = async () => {
    setLoadingFeeWithdrawHistories(true);
    const payload = {
      offset: 5,
    }

    const res = await getHistoryWithdrawFeeService(payload);
    setLoadingFeeWithdrawHistories(false);

    if (res?.status === 200) {
      setFeeWithdrawHistories(res?.data?.data);
    } else {
      // gettingFeeHistory(false);
      dispatch(handleError(res?.data));
    }

  };

  const handleGetWithdrawFeeStatistics = async () => {
    const res = await getHistoryFeeStatisticsService()
    
    if (res?.status === 200) {
      setTotalEarnings(res?.data?.data?.fee_total_received)

      let _tempEarnings = []
      let _tempEarningsCategories = []

      res.data.data.fee_histories?.forEach(earning => {
        _tempEarnings.push(earning.total_fee);
        _tempEarningsCategories.push(`${earning.year}-${earning.month}`);
      });

      console.log(_tempEarnings, _tempEarningsCategories)

      setEarningsData(_tempEarnings)
      setEarningsCategories(_tempEarningsCategories)

      // setFeeHistories(res?.data?.data);
    } else {
      // gettingFeeHistory(false);
      dispatch(handleError(res?.data));
    }

  }

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };

  const columns = [
    {
      title: "Nama Paket",
      dataIndex: "package",
      render: (record) => (
        <div>
          <div>{record?.judul_paket}</div>
          {record?.tipe_paket == 1 && <Badge backgroundColor={"#BBDEFB"} color={"#0000FE"} label="Haji" customClass="mt-[6px]" />}
          {record?.tipe_paket == 2 && <Badge backgroundColor="#92E6A7" color="#1B662E" label="Umroh" customClass="mt-[6px]" />}
          {record?.tipe_paket == 3 && <Badge backgroundColor={"#F7A399"} color="#FE0000" label="Tour" customClass="mt-[6px]" />}
          {record?.tipe_paket == 4 && <Badge label="Tabungan" customClass="mt-[6px]" />}
          {record?.tipe_paket == 5 && <Badge label="Jasa Badal" customClass="mt-[6px]" />}
        </div>
      ),
    },
    {
      title: "Tanggal Pendapatan",
      dataIndex: "received_at",
      render: (record) => (
        moment(record).format("dddd, DD MMMM YYYY")
      ),
    },
    {
      title: "Jumlah Pax",
      dataIndex: "count_pax",
      render: (record) => (
        <div>
          <div>{record} pax</div>
        </div>
      ),
    },
    {
      title: "Nominal Pendapatan",
      dataIndex: "fee_total",
      render: (record) => (
        <div>
          <div className="font-semibold">
            {IDRFormater(record)}
          </div>
        </div>
      ),
    },
  ];


  const [openPopupWithdrawFee, setOpenPopupWithdrawFee] = useState(false);
  const [openPopupAddRekening, setOpenPopupAddRekening] = useState({
    show: false,
    edit: null,
  });
  const [openPopupHistoryPendapatanFee, setOpenPopupHistoryPendapatanFee] =
    useState(false);
  const [openPopupHistoryPenarikanFee, setOpenPopupHistoryPenarikanFee] =
    useState(false);

  useEffect(() => {
    handleGetPenarikanFee()
    handleGetWithdrawFee()
    handleGetWithdrawBankAccount()
    handleGetWithdrawFeeStatistics()
  }, []);

  return (
    <>
      <Popup
        open={openPopupWithdrawFee}
        handleClose={() => setOpenPopupWithdrawFee(false)}
        title="Withdraw Fee"
        width="768px"
        children={
          <PopupWithdrawFee 
            onSuccess={() => {
              handleGetWithdrawFee()
              setOpenPopupWithdrawFee(false)
            }}
          />
        }
      />
      <Popup
        open={openPopupAddRekening.show}
        handleClose={() =>
          setOpenPopupAddRekening({
            show: false,
            edit: null,
          })
        }
        title={`${
          openPopupAddRekening.edit ? "Edit Rekening" : "Tambah Rekening"
        }`}
        width="768px"
        children={
          <AddOrEditRekening 
            data={openPopupAddRekening.edit}
            onSuccess={() => {
              handleGetWithdrawBankAccount()
              setOpenPopupAddRekening({
                show: false,
                edit: null,
              })
            }}
          />
        }
      />
      <Popup
        open={openPopupHistoryPendapatanFee}
        handleClose={() => setOpenPopupHistoryPendapatanFee(false)}
        title="History Pendapatan Fee"
        width="1080px"
        children={<HistoryPendapatanFee />}
      />
      <Popup
        open={openPopupHistoryPenarikanFee}
        handleClose={() => setOpenPopupHistoryPenarikanFee(false)}
        title="History Penarikan Fee"
        width="1080px"
        children={<HistoryPenarikanFee />}
      />
      <div className="bg-white soft-shadow rounded-[20px]">
        <div className="p-4">
          <div className="font-semibold text-[20px]">Penarikan Fee</div>
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <Grid container spacing={2}>
            <Grid item md={7}>
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col gap-1">
                  <div>Total Earning</div>
                  <div className="font-semibold text-[20px]">{IDRFormater(totalEarnings)}</div>
                </div>
                {permissions?.includes("create_withdraw-fee") && (
                  <Buttons
                    text="Withdraw Fee"
                    customClass="btn-primary btn-small"
                    onClick={() => setOpenPopupWithdrawFee(true)}
                  />
                )}
              </div>
              <div className="mt-2">
                <Chart
                  data={series}
                  categories={earningsCategories}
                  formatData={'IDR'}
                  colors={["#1F4BFF"]}
                />
              </div>
              <div className="mt-6">
                <div className="flex flex-row items-center justify-between">
                  <div className="font-semibold text-[20px]">
                    History Pendapatan Fee
                  </div>
                  <Buttons
                    text="Lihat Semua"
                    customClass="btn-primary btn-small"
                    onClick={() => setOpenPopupHistoryPendapatanFee(true)}
                  />
                </div>
                <MoslemeTable
                  loading={loadingFeeHistories}
                  expandable={false}
                  columns={columns}
                  data={feeHistories}
                  withSelection={withSelection}
                  selectionType={selectionType}
                  withPagination={false}
                  customClass="!mt-4 !overflow-hidden !rounded-2xl !shadow-soft"
                />
              </div>
            </Grid>
            <Grid item md={5}>
              <div className="bg-white soft-shadow rounded-2xl">
                <div className="flex flex-row justify-between items-center p-4">
                  <div className="font-semibold text-[20px]">Rekening Anda</div>
                  <div className="flex flex-row gap-2">
                    
                    {permissions?.includes("create_withdraw-fee") && (
                      <Buttons
                        text="Tambah Rekening"
                        customClass="btn-primary btn-small"
                        onClick={() =>
                          setOpenPopupAddRekening({
                            show: true,
                            edit: false,
                          })
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="divider"></div>
                <div className="flex flex-row gap-4 overflow-x-auto p-4 costumScrollbarHorizontal">
                  {isLoadingWithdrawAccounts && (
                    <div className="flex justify-center items-center w-full h-[281px]">
                      <Spinner 
                        width="40px"
                        height="40px"
                        />
                    </div>
                  )}
                  {!isLoadingWithdrawAccounts && (
                    <>
                      {withdrawAccounts.map((account) => (
                        <RekeningCard 
                          account={account}
                          rekening={account?.account_bank_number}
                          name={account?.account_bank_name}
                          bank={account?.bank?.name}
                          handleEdit={(dataAccount) => {
                            // console.log(dataAccount)
                            setOpenPopupAddRekening({
                              show: true,
                              edit: dataAccount,
                            })
                          }}
                          />
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div className="bg-white soft-shadow rounded-2xl mt-4 overflow-hidden">
                <div className="flex flex-row justify-between items-center p-4">
                  <div className="font-semibold text-[20px]">
                    History Penarikan Fee
                  </div>
                  <Buttons
                    text="Lihat Semua"
                    customClass="btn-primary btn-small"
                    onClick={() => setOpenPopupHistoryPenarikanFee(true)}
                  />
                </div>
                <div className="divider"></div>
                <div>
                  {loadingFeeWithdrawHistories && (
                    <>
                      <div className="flex justify-center items-center h-[416px]">
                        <Spinner
                          width="40px"
                          height="40px"
                          />
                      </div>
                    </>
                  )}
                  {!loadingFeeWithdrawHistories && (
                    <>
                      {feeWithdrawHistories.map((val, idx) => {
                        return (
                          <div
                            className={`flex flex-row items-center justify-between px-4 py-5 ${
                              idx % 2 === 0 ? "bg-white" : "bg-[#FBF3F2]"
                            }`}
                          >
                            <div className="flex flex-row items-center gap-4">
                              <img 
                                className="w-12 h-12 rounded-full object-cover shadow-highlight"
                                src={val.agent_account_bank_withdraw.bank?.logo} alt="logo" />
                              <div className="flex flex-col gap-1">
                                <div className="font-semibold">{val.agent_account_bank_withdraw?.account_bank_name}</div>
                                <div>{moment(val.created_at).format("dddd, DD MMMM YYYY . HH:mm")}</div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-1 ">
                              <div
                                className={`font-semibold ${
                                  val.status === "success"
                                    ? "green-linear-text"
                                    : val.status === "pending"
                                    ? "orange-linear-text"
                                    : "red-linear-text"
                                } text-xs`}
                              >
                                {val.status === "success"
                                  ? "BERHASIL"
                                  : val.status === "pending"
                                  ? "TERTUNDA"
                                  : "GAGAL"}
                              </div>
                              <div className="font-semibold !text-base">{ IDRFormater(val.nominal) }</div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default PenarikanFee;
