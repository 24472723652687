import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import { IDRFormater } from "utils/helpers";

export const columns = (handleEdit, handleRemove, auth) => [
    {
        title: "Nama",
        dataIndex: "name",
        render: (text) => <span>{text}</span>,
    },
    {
        title: "Kategori",
        dataIndex: "kategori",
        render: (text, data) => {
          return (
            <div className="flex flex-row items-center justify-start w-[100px] px-[8px] py-[4px] rounded-lg border-[0.5px] border-[#F2F2F2] ">
              {data?.is_wajib === "1" ? "Wajib" : "Tidak Wajib"}
            </div>
          );
        },
    },
    {
        title: "Jumlah Jamaah",
        dataIndex: "total_order",
        render: (text, data) => {
            return (
                <div className="flex flex-row justify-start text-[12px] text-[#141414] font-normal">
                    {data?.total_order} jamaah
                </div>
            );
        },
    },
    {
        title: "Harga",
        dataIndex: "price",
        className: "whitespace-nowrap",
        render: (s, a) => {
            return (
            <div>
                <div className="w-full overflow-hidden">
                <p className="whitespace-nowrap text-ellipsis">
                    {IDRFormater(s)}
                </p>
                </div>
                <div className="action">
                {auth.user?.access_data === 'all_branches' && (
                    <div className="action-content">
                        <div className="action-content-item">
                            <Trash
                                size="15"
                                className="cursor-pointer"
                                color="#141414"
                                onClick={() => handleRemove(a)}
                            />
                        </div>
                        <div className="action-content-item">
                            <Edit
                                onClick={() => handleEdit(a)}
                                size="15"
                                color="#141414"
                                className="cursor-pointer"
                            />
                        </div>
                    </div>
                )}
                </div>
            </div>
            );
        },
    },
]