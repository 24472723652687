import React, { useState, useCallback, useEffect } from "react";
import Buttons from "components/buttons";
import Switch from "components/form/switch";
import "./index.scss";
import { Grid } from "@material-ui/core";
import MarketingCards from "./MarketingCards";
import Popup from "components/popup";
import MarketingPopup from "./MarketingPopup";
import { saveDataMarketing } from "stores/actions/marketingKit";
import { useDispatch, useSelector } from "react-redux";

import ILCoomingSoon from "assets/images/cooming-soon.svg";

const Marketing = () => {
  const [open, setOpen] = useState(false);
  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  const handleClosePopup = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      {/* <div className="w-full h-[878px] bg-white rounded-[20px] mr-[16px] overflow-auto">
        <div className="marketing-title-container">
          <div className="flex flex-row items-center gap-[16px]">
            <Switch
              customClass="!w-[52px] h-[20px]"
              containerClass="!w-[52px] h-[20px]"
            />
            <h1 className="font-[#141414] text-[20px] font-semibold">
              Menampilkan Marketing Yang Sudah Tidak Aktif
            </h1>
          </div>
          <div className="mb-[20px]">
            {permissions?.includes('create_apps') && (
              <Buttons
                text="Tambah"
                customClass="btn-primary btn-medium"
                containerClass="!w-[153px] !h-[32px]"
                onClick={() => setOpen(true)}
              />
            )}
            <Popup
              open={open}
              handleClose={handleClosePopup}
              title="Tambah Marketing KIT"
              width="982px"
              height="648px"
            >
              <MarketingPopup handleClosePopup={handleClosePopup} />
            </Popup>
          </div>
        </div>
        <div className="p-[16px] overflow-auto">
          <Grid container spacing={2}>
            <Grid item md={4}>
              <MarketingCards />
            </Grid>
            <Grid item md={4}>
              <MarketingCards />
            </Grid>
            <Grid item md={4}>
              <MarketingCards />
            </Grid>
            <Grid item md={4}>
              <MarketingCards />
            </Grid>
            <Grid item md={4}>
              <MarketingCards />
            </Grid>
            <Grid item md={4}>
              <MarketingCards />
            </Grid>
            <Grid item md={4}>
              <MarketingCards />
            </Grid>
            <Grid item md={4}>
              <MarketingCards />
            </Grid>
            <Grid item md={4}>
              <MarketingCards />
            </Grid>
          </Grid>
        </div>
      </div> */}
      <div className="flex flex-row justify-center items-center w-full !h-[calc(100vh-135px)] bg-white rounded-2xl">
        <div className="flex flex-col items-center">
          <img src={ILCoomingSoon} alt="comming soon" />
          <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
            Marketing KIT dalam pengerjaan
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketing;
