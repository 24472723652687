import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import uuid from "react-uuid";
import {
  editDataMaster,
  getDetailDataMaskapai,
} from "../../../../../stores/actions/datamaster";

export const columns = (
  handleOpen,
  setActionHandle,
  setIsModal,
  openModal,
  dispatch,
  formikValue,
  setDuplicateCode,
  setDuplicateLogo,
  setDuplicateDescription,
  permissions
) => [
  {
    title: "Logo",
    dataIndex: "logo",
    render: (img) => (
      <img
        className="rounded-full w-10 h-10 object-cover"
        alt="logo maskapai"
        src={img}
      />
    ),
  },
  {
    title: "Nama Maskapai",
    dataIndex: "name",
  },
  {
    title: "Kode Maskapai",
    dataIndex: "code",
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    className: "whitespace-nowrap relative",
    render: (s, record) => (
      <div className="w-full relative flex flex-row justify-between">
        {s !== null && s?.length > 150 ? `${s?.substr(0, 150)}...` : s}

        <div className="action">
          <div className="action-content">
            {permissions?.includes("delete_master-data") && (
              <div className="action-content-item">
                <Trash
                  onClick={() => {
                    handleOpen();
                    setActionHandle(record.id_maskapai);
                  }}
                  size="16"
                  color="#141414"
                  className="cursor-pointer"
                />
              </div>
            )}
            {permissions?.includes("create_master-data") && (
              <div className="action-content-item">
                <DocumentCopy
                  onClick={() => {
                    setDuplicateCode(record.code);
                    setDuplicateLogo(record.logo);
                    setDuplicateDescription(record.description);
                    formikValue("code", record.code);
                    formikValue("name", `${record.name} - CLONE`);
                    formikValue("logo", record.logo);
                    formikValue("description", record.description);
                    setIsModal(true);
                  }}
                  size="16"
                  color="#141414"
                  className="cursor-pointer"
                />
              </div>
            )}
            {permissions.includes("update_master-data") && (
              <div className="action-content-item">
                <Edit
                  className="cursor-pointer"
                  onClick={() => {
                    openModal(true);
                    dispatch(editDataMaster("edit maskapai"));
                    dispatch(getDetailDataMaskapai(record.id_maskapai, uuid()));
                  }}
                  size="16"
                  color="#141414"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ),
  },
];
