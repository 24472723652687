import { handleError, handleErrorBE } from "stores/actions/errorGeneral";
import {
  CLEAR_ERROR,
  ERROR_FETCHING_ABOUT,
  SET_EDIT_ABOUT,
  SET_LOADING,
  START_FETCHING_ABOUT,
  SUCCESS_FETCHING_ABOUT,
} from "./actionTypes";
import { SettingAboutAPI } from "utils/api/setting/about";

const startFetchingAbout = () => ({
  type: START_FETCHING_ABOUT,
});

const errorFetchingAbout = () => ({
  type: ERROR_FETCHING_ABOUT,
});

const successFetchingAbout = (payload) => ({
  type: SUCCESS_FETCHING_ABOUT,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const setEditAbout = (payload) => ({
  type: SET_EDIT_ABOUT,
  payload,
});

const handleFetchListAbout = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(startFetchingAbout());
      const response = await SettingAboutAPI.getList(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(successFetchingAbout(response.data));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(errorFetchingAbout());
      dispatch(handleError(error));
    }
  };
};

const handleEditAbout = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await SettingAboutAPI.postData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        // dispatch(setEditAbout(response.data));
        dispatch(setLoading(false));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const SettingAboutAction = {
  fetchData: handleFetchListAbout,
  postData: handleEditAbout,
};

export default SettingAboutAction;
