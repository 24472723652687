import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Popup from "../../../popup";
import Inputs from "../../../form/inputs";
import Buttons from "../../../buttons";
import { makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import { handleUpdatePassword } from 'stores/actions/profile'
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
const useStyles = makeStyles({
  card_popup_profile: {
    width: "100%",
    padding: "16px",
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "rgba(64, 72, 82, 0.1)",
    borderRadius: "16px 16px 0px 0px",
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  },
  container_button: {
    marginTop: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px",
    borderWidth: "0px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "rgba(64, 72, 82, 0.1)",
    borderRadius: "0px 0px 16px 16px",
    gap: "8px"
  },
});

const GantiKataSandi = ({
  openProfileSandi,
  handleClosePopupProfileSandi,
  dataProfileSandi,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles();

  const { isLoading } = useSelector(({ profile }) => profile);

  const formik = useFormik({
    initialValues: {
      'password': '',
      'password_confirmation': ''
    },
    validationSchema: Yup.object().shape({
      'password': Yup.string()
        .required('Password wajib diisi')
        .min(6, 'Password harus memiliki setidaknya 6 karakter'), // Aturan: Minimal 6 karakter
      'password_confirmation': Yup.string()
        .oneOf([Yup.ref('password'), null], 'Password konfirmasi harus sama dengan password')
        .required('Konfirmasi password wajib diisi')
        .min(6, 'Konfirmasi password harus memiliki setidaknya 6 karakter') // Aturan: Minimal 6 karakter
    })
  });

  useEffect(() => {
    formik.resetForm()
  }, [openProfileSandi])

  const handleSave = () => {
    dispatch(handleUpdatePassword(formik.values, (errData) => {
      const errors = errData?.data?.data

      Object.keys(errors).forEach((field) => {
        formik.setFieldError(field, errors[field][0]);
      });
    }))
  }

  return (
    <Popup
      type={"secondary"}
      width="1000px"
      height="416px"
      open={openProfileSandi}
      handleClose={handleClosePopupProfileSandi}
      title="Ganti Kata Sandi"
    >
      <div className={classes.card_popup_profile}>
        <Inputs
          id="sandi_new"
          placeholder="Masukan sandi baru"
          name="password"
          label="Kata sandi baru"
          type="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          defaultValue={formik.values.password}
          error={
            formik.touched.password && formik.errors.password
          }
        />
        <Inputs
          id="confirm_sandi"
          placeholder="Konfirmasi kata sandi"
          name="password_confirmation"
          label="Konfirmasi sandi baru"
          type="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          defaultValue={formik.values.password_confirmation}
          error={
            formik.touched.password_confirmation && formik.errors.password_confirmation
          }
        />
      </div>
      
      <div className={classes.container_button}>
        <Buttons text="Kembali" onClick={handleClosePopupProfileSandi} customClass="btn-primary btn-outline btn-large" />
        <Buttons 
          text="Simpan" 
          disabled={!(formik.isValid && formik.dirty)} 
          loading={isLoading}
          onClick={handleSave}
          customClass="btn-primary btn-large" />
      </div>
    </Popup>
  );
};

GantiKataSandi.propTypes = {
  dataProfileSandi: PropTypes.object.isRequired,
  openProfileSandi: PropTypes.bool.isRequired,
  handleClosePopupProfileSandi: PropTypes.func.isRequired,
};
GantiKataSandi.defaultProps = {
  dataProfileSandi: {},
  openProfileSandi: false,
  handleClosePopupProfileSandi: () => {},
};

export default React.memo(GantiKataSandi);
