import { handleError, handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import {
  ERROR_FETCHING_GALLERY,
  SET_LOADING,
  START_FETCHING_GALLERY,
  SUCCESS_FETCHING_GALLERY,
  CLEAR_ERROR,
  SET_ADD_GALLERY,
  SET_DELETE_GALLERY,
} from "./actionTypes";
import { AdminGalleryAPI } from "utils/api/apps/gallery";

const startFetchingArticle = () => ({
  type: START_FETCHING_GALLERY,
});

const errorFetchingArticle = () => ({
  type: ERROR_FETCHING_GALLERY,
});

const successFetchingArticle = (payload) => ({
  type: SUCCESS_FETCHING_GALLERY,
  payload,
});

export const setLoading = () => ({
  type: SET_LOADING,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const setAddGallery = (payload) => ({
  type: SET_ADD_GALLERY,
  payload,
});

export const setDeleteGallery = (payload) => ({
  type: SET_DELETE_GALLERY,
  payload,
});

const handleFetchListGallery = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      dispatch(startFetchingArticle());
      const response = await AdminGalleryAPI.getList(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(successFetchingArticle(response.data));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(errorFetchingArticle());
      dispatch(handleError(error));
    }
  };
};

const handleAddGallery = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await AdminGalleryAPI.postData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setAddGallery(response.data));
        dispatch(handleSuccess(null, { message: "Berhasil mengunggah", code: 200 }))
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const GalleryAction = {
  fetchData: handleFetchListGallery,
  postData: handleAddGallery,
  //   deleteData: handleDeleteArticle,
};

export default GalleryAction;
