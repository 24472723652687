import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Buttons from "components/buttons";
import Badge from "components/badge";
import EditIcon from "assets/icons/edit.svg";
import TrashIcon from "assets/icons/trash.svg";
import LocationIcon from "assets/icons/location-black.svg";
import CalendarIcon from "assets/icons/colored/calendar-icon.svg";
import ClockIcon from "assets/icons/colored/clock-icon.svg";
import Places from "components/map";
import moment from "moment";

import { sendManasikInvitationAPI } from 'utils/api/paket'
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

const ViewItem = ({
  data,
  setIsEdit,
  removeItem,
  generalInfo,
  onClickEditGeneralInfo,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  
  const [address, setAddress] = useState("");

  const [sendingInvitationManasik, setSendingInvitationManasik] = useState(false)

  const handleSendInvitation = async (manasik) => {
    setSendingInvitationManasik(true)
    const response = await sendManasikInvitationAPI(params.id, manasik.id_manasik)
    setSendingInvitationManasik(false)

    if(response.status === 200 || response.status === 201){
      dispatch(handleSuccess(response))
    } else {
      dispatch(handleError(response.data))
    }

  }

  return (
    <>
      <div className="w-full flex justify-between">
        <Badge
          label={data.id}
          backgroundColor="#E0E0E0"
          color="#141414"
          customClass="capitalize w-[34px]"
        />

        {/* {auth.user?.access_data === 'all_branches' && ( */}
          {permissions?.includes("update_package") && (
            <>
              <>
                <div className="flex gap-4">
                  <button
                    onClick={() => {
                      if (generalInfo) {
                        onClickEditGeneralInfo(data);
                      } else {
                        setIsEdit(true);
                      }
                    }}
                  >
                    <img src={EditIcon} alt="edit icon" width={22} />
                  </button>
                  <button onClick={() => removeItem(data.id)}>
                    <img src={TrashIcon} alt="trash icon" width={22} />
                  </button>
                </div>
              </>
            </>
          )}
        {/* )} */}
      </div>
      <div className="w-full flex justify-between  text-sm">
        <div className="mt-4">
          <h4 className="font-bold mb-1">Koordinat Lokasi</h4>
          <p className="flex gap-2">
            <img src={LocationIcon} alt="location icon" />
            {data.koordinat.lat ? data.lokasi : "-"}
          </p>
          <h4 className="mt-4 mb-1 font-bold">Pelaksanaan</h4>
          <div className="flex gap-3">
            <div className="flex gap-2 items-center">
              <img src={CalendarIcon} className="w-6 h-6" alt="calendar icon" />
              {data?.tanggal_pelaksanaan
                ? moment(data?.tanggal_pelaksanaan)
                    ?.locale("id")
                    ?.format("dddd, D MMMM YYYY")
                : "-"}
            </div>
            <div className="flex gap-2 items-center">
              <img src={ClockIcon} className="w-6 h-6" alt="clock icon" />
              {data?.waktu_pelaksanaan ? (
                <div dangerouslySetInnerHTML={{ __html: data?.waktu_pelaksanaan }} />
              ) : (
                "-"
              )}
            </div>
          </div>
          <Buttons
            disabled={!data.id_manasik}
            text="Kirim Undangan"
            customClass="btn-outline btn-small !w-fit mt-3"
            onClick={() => handleSendInvitation(data)}
            loading={sendingInvitationManasik}
          />
        </div>
        <div className="w-1/2">
          <div className="shadow-soft p-[8px] w-full  mt-5 rounded-2xl">
            {data.koordinat.lat ? (
              <Places
                style={{
                  borderRadius: "10px",
                  width: "100%",
                  height: "120px",
                }}
                withSearch={false}
                selected={data.koordinat}
                address={address}
                setAddress={(e) => setAddress(e)}
              />
            ) : (
              <div className="bg-gray-100 w-full h-auto rounded-md"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewItem;
