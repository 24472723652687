import React, { useEffect, useState } from "react";
import "./index.scss";
import FotoKabah from "../../../../assets/images/foto-kabah.jpg";
import Threedots from "../../../../assets/icons/three-dots.svg";
import Calendar from "../../../../assets/icons/calendar-gray.svg";
import Dokumen from "../../../../assets/icons/Dokumen.svg";
import Buttons from "components/buttons";
import Selects from "components/form/selects";
import { makeStyles } from "@material-ui/core";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { handleGetAllMarketing } from "stores/actions/marketingKit";

const useStyle = makeStyles({
  selectCustom: {
    "& .react-dropdown-select-input": {
      display: "none",
    },

    "& .react-dropdown-select .custom-select": {
      width: "60px",
      height: "32px",
      background: "#fff",
    },

    "& .custom-select": {
      background: "transparent !important",
      border: "1px solid grey",
    },

    "& .react-dropdown-select-dropdown": {
      width: "280px",
      height: "188px",
    },
  },
});

const MarketingCards = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [isDuplicate, setIsDuplicate] = React.useState(false);
  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  // const { dataMarketing, isLoading } = useSelector((state) => state.marketing);
  // console.log(dataMarketing, "dataMarketing");

  // useEffect(() => {
  //   dispatch(handleGetAllMarketing());
  // }, []);

  const classes = useStyle();
  return (
    <>
      <div className="marketing-cards-container">
        <div className="w-full h-fit">
          <img
            src={FotoKabah}
            alt="foto kabah"
            className="marketing-image-kabah"
          />
        </div>
        <div className="w-full h-fit p-[16px]">
          <div className="flex flex-row justify-between items-center w-full h-[40px]">
            <div>
              <h1 className="font-[#141414] text-[14px] font-semibold">
                Poster Umrah Desember 2022 - Bersama Muhammad Abduh Tuasikal
              </h1>
            </div>
            <div onClick={() => setIsClicked(!isClicked)} className="relative">
              <div className="flex flex-row gap-[16px] flex-row-reverse">
                <img
                  src={Threedots}
                  alt="dots"
                  className="w-[20px] h-[20px] cursor-pointer"
                />
                {isClicked && (
                  <div className="absolute z-50 right-[20px] top-[-20px] bg-white w-[152px] h-[56px] border rounded-lg p-[16px]">
                    <div className="flex flex-row justify-end items-center gap-[24px] w-[120px] h-[24px]">
                      {permissions?.includes("update_apps") && (
                        <Edit
                          onClick={() => alert(`edit id:`)}
                          size="15"
                          color="#141414"
                          className="cursor-pointer w-[24px] h-[24px]"
                        />
                      )}
                      {permissions?.includes("delete_apps") && (
                        <Trash
                          onClick={() => alert(`delete id:`)}
                          size="15"
                          color="#141414"
                          className="cursor-pointer w-[24px] h-[24px]"
                        />
                      )}
                      {permissions?.includes("create_apps") && (
                        <DocumentCopy
                          onClick={() => setIsDuplicate(true)}
                          size="15"
                          color="#141414"
                          className="cursor-pointer w-[24px] h-[24px]"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-[8px] flex flex-row items-center gap-[4px]">
            <div>
              <img src={Calendar} alt="calendar" />
            </div>
            <p className="text-[#828282] font-sm">
              20 November - 21 Desember 2022
            </p>
          </div>
          <div className="w-full h-[50px] mt-[8px]">
            <p className="font-sm text-[#828282]">
              Lorem ipsum dolor sit amet consectetur. Facilisis nunc aenean eget
              dolor amet eros.
            </p>
          </div>
          <div className="flex flex-row items-start justify-between gap-[16px]">
            <div>
              <Selects
                placeholder="Text Input"
                icon={Dokumen}
                iconPosition="left"
                customClass={classes.selectCustom}
                // options={options}
              />
            </div>
            <Buttons
              text="Lihat Selengkapnya"
              customClass="btn-outline btn-medium !w-full !h-[32px]"
              containerClass="!w-[200px] !h-[32px] mt-[8px]"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingCards;
