import { Grid } from "@material-ui/core";
import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import {
  getMasterStarting,
} from "stores/actions/datamaster/starting";

import Cards from "components/cards";
import Steppers from "components/steps/Steppers";
import {
  actCreateCabang,
  actCreateWaEmail,
  getAllCabang,
} from "stores/actions/cabang";
import Alamat from "./alamat";
import EmailSettings from "./email";
import Informasi from "./informasi";
import { detailById } from "stores/actions/general";
import { handleSuccess } from "stores/actions/errorGeneral";
import { resetDataUpload } from "stores/actions/uploadFile";

const items = [
  {
    title: "Info Dasar",
  },
  {
    title: "Alamat & Koordinat",
  },
  {
    title: "Email Settings",
  },
  // {
  //   title: "Whatsapp Settings",
  // },
];

const AddOrEditCabang = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { byDetail, global } = useSelector((state) => state.general);
  const { isLoading, createCabang } = useSelector((state) => state.cabang);
  const [currentStep, setCurrentStep] = useState(0);

  const { allStarting } = useSelector(
    (state) => state.adminStarting
  );

  const validationStep = () => {
    switch (currentStep) {
      case 0:
        return {
          name: Yup.string().required("This field is required"),
          images: Yup.string().required("This field is required"),
          is_pusat: Yup.string().required("This field is required"),
          url_website: Yup.string().required("This field is required"),
        };

      case 1:
        return {
          latitude: Yup.number().required("This field is required"),
          longitude: Yup.number().required("This field is required"),
        };

      default:
        return {};
    }
  };

  const handleGetMasterStartings = () => {
    dispatch(getMasterStarting({
      page_number: 1,
      per_page: 1000,
      keyword: "",
      sort_column: "id_starting",
      sort_order: "ASC",
    }))
  }

  const addCabang = useFormik({
    initialValues: {
      gokilbet: byDetail?.data?.img || "",
      images: "",
      name: byDetail?.data?.name || "",
      url_website: byDetail?.data?.url_website || "",
      color: byDetail?.data?.color || "",
      province_id: byDetail?.data?.province_id || "",
      city_id: byDetail?.data?.city_id || "",
      subdistrict_id: byDetail?.data?.subdistrict_id || "",
      address_details: byDetail?.data?.address_details || "",
      address_lat_long: byDetail?.data?.address_lat_long || "",
      latitude: byDetail?.data?.latitude || "",
      longitude: byDetail?.data?.longitude || "",
      is_pusat: byDetail?.data?.is_pusat || "0",
      device_key_wa: byDetail?.data?.device_key_wa || "",
      whatsapp: byDetail?.data?.whatsapp || "",
      id: byDetail?.data?.id_cabang || null,
      control_startings: byDetail?.data?.control_startings || [],
    },
    validationSchema: Yup.object(validationStep()),
  });

  const reload = () => {
    dispatch(
      getAllCabang({
        page_number: global?.currentpage,
        per_page: global?.size,
        sort_column: "",
        sort_order: "Desc",
        keyword: "",
      })
    );
    dispatch(detailById({}));
  };

  const updateWA = useFormik({
    initialValues: {
      email_mailer: byDetail?.data?.email_mailer || "",
      email_host: byDetail?.data?.email_host || "",
      email_port: byDetail?.data?.email_port || "",
      email_username: byDetail?.data?.email_username || "",
      email_password: byDetail?.data?.email_password || "",
      email_encryption: byDetail?.data?.email_encryption || "",
      email_from_address: byDetail?.data?.email_from_address || "",
      email_from_name: byDetail?.data?.email_from_name || "",
    },
    validationSchema: Yup.object().shape({
      // email_mailer: Yup.string().required("This field is required"),
      // email_host: Yup.string().required("This field is required"),
      // email_port: Yup.string().required("This field is required"),
      // email_username: Yup.string().required("This field is required"),
      // email_password: Yup.string().required("This field is required"),
      // email_encryption: Yup.string().required("This field is required"),
      // email_from_address: Yup.string().required("This field is required"),
      // email_from_name: Yup.string().required("This field is required"),
    }),

    onSubmit: (val, actions) => {
      let cabang = addCabang?.values;
      dispatch(
        actCreateCabang(cabang, (resp) => {
          dispatch(
            actCreateWaEmail(
              { ...val, id: resp.data?.id_cabang },
              (message) => {
                reload();
                resetDataUpload()
                dispatch(handleSuccess(message));
              }
            )
          );
        })
      );
    },
  });

  useEffect(() => {
    handleGetMasterStartings()
  }, [])

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <Informasi
            allStartings={allStarting}
            formik={addCabang}
            values={addCabang?.values}
            errors={addCabang.errors}
            handleChange={addCabang?.handleChange}
            handleBlur={addCabang?.handleBlur}
            setFieldValue={addCabang?.setFieldValue}
            setCurrentStep={(data) => setCurrentStep(data)}
            isLoading={isLoading}
            touch={addCabang.touched}
          />
        );
      case 1:
        return (
          <Alamat
            formik={addCabang}
            values={addCabang?.values}
            errors={addCabang?.errors}
            handleChange={addCabang?.handleChange}
            handleBlur={addCabang?.handleBlur}
            setFieldValue={addCabang?.setFieldValue}
            setCurrentStep={(step) => setCurrentStep(step)}
            isLoading={isLoading}
          />
        );
      case 2:
        return (
          <EmailSettings
            formik={updateWA}
            values={updateWA.values}
            errors={updateWA.errors}
            touched={updateWA.touched}
            handleChange={updateWA.handleChange}
            handleBlur={updateWA.handleBlur}
            setFieldValue={updateWA.setFieldValue}
            setCurrentStep={setCurrentStep}
            onHandleSubmit={updateWA.handleSubmit}
            isLoading={isLoading}
          />
        );
      // case 3:
      //   return (
      //     <WhatsappSettings
      //       values={values}
      //       errors={errors}
      //       handleChange={handleChange}
      //       handleBlur={handleBlur}
      //       setCurrentStep={setCurrentStep}
      //     />
      //   );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="flex gap-4">
        <div>
          <Cards containerClass="bg-white !rounded-2xl !shadow-soft !py-12 !px-7 flex items-center h-full">
            <Steppers
              containerClass="custom-stepper-vertical"
              labelPlacement="vertical"
              direction="vertical"
              items={items}
              current={currentStep}
            />
          </Cards>
        </div>
        <div className="flex flex-col flex-1">{getContent()}</div>
      </div>
    </>
  );
};

export default React.memo(AddOrEditCabang);
