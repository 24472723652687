import Switch from "components/form/switch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getAllCabang,
  } from "../../../../../../../stores/actions/cabang";
import { Grid } from "@material-ui/core";
import Badge from "components/badge";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";

import {getPublishSettingService} from "../../../../../../../utils/api/paket"

import { AgenMemberAPI } from "../../../../../../../utils/api/member";

import * as Yup from "yup";
import { handleError, handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import Buttons from "components/buttons";
import Scroll from "components/scroll";
import Spinner from "components/spinner";
import { handleName } from "utils/helpers";

import IconJamPasir from "assets/icons/Jam-Pasir.svg";

import { updatePublishSettingService } from "utils/api/paket";
import PaketDetailGeneralInfoAction from "stores/actions/paket/generalInfo";

const PublishSetting = ({ data, closePopup, setSuccessEdit }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { dataAllCabang, isLoading } = useSelector((state) => state.cabang);

    const [isLoadingGetAgents, setIsLoadingGetAgents] = useState(false);
    const [isLoadingGetAgentBranch, setIsLoadingGetAgentBranch] = useState(false);

    const [agents, setAgents] = useState([]);

    const [agentSelected, setAgentSelected] = useState([]);
    const [filterAgent, setFilterAgent] = useState('all');
    const [tempAgentSelected, setTempAgentSelected] = useState([]);

    const [branchSwitch, setBranchSwitch] = useState(false)
    const [agentSwitch, setAgentSwitch] = useState(false)

    const [nextCursorAgent, setNextCursorAgent] = useState(null);

    const [saving, setSaving] = useState(false);

    const handleGetBranch = () => {
        const payload = {
            page_number: 1,
            per_page: 100000000,
            sort_column: "",
            sort_order: "Desc",
            keyword: "",
        }

        dispatch(getAllCabang(payload))
    }

    const formik = useFormik({
        initialValues: {
            main_booking: true,
            public: true, 
            branch: [],
            agent: []
        },
        validationSchema: Yup.object({
            main_booking: Yup.boolean(),
            public: Yup.boolean(),
            branch: Yup.array(),
            agent: Yup.array(),
        }),
    })

    const handlePushMainBranch = () => {
        const mainBranch = dataAllCabang?.data?.filter(item => item.is_pusat)[0]

        // jika belum ada di formik branch maka tambahkan
        if(!formik.values.branch.includes(mainBranch?.id_cabang)) {
            formik.setFieldValue("branch", [...formik.values.branch, mainBranch?.id_cabang])
        }
    }

    const handleGetPublishSettings = async () => {
        const res = await getPublishSettingService(params.id)

        if(res.status == 200) {
            const data = res.data.data

            formik.setFieldValue('main_booking', data.main_booking)
            formik.setFieldValue('public', data.public)
            formik.setFieldValue('branch', data.branch)

            // untuk agen, ambil id dari data.branchnya aja kemudian masukin ke formik agen
            if(data.agent?.length > 0) {
                formik.setFieldValue('agent', data.agent.map(item => item.id))
                setAgentSelected(data.agent)
                setTempAgentSelected(data.agent)
            }

        } else {
            dispatch(handleError(res.data))
        }
    }

    const handleSelectBranch = (branchID) => {
        // jika branch id sudah ada di formkik branch maka hapus, jika belum ada maka tambahkan
        const index = formik.values.branch.indexOf(branchID)
        if (index > -1) {
            formik.setFieldValue("branch", [
                ...formik.values.branch.slice(0, index),
                ...formik.values.branch.slice(index + 1),
            ])
            handleRemoveAgentBranch(branchID)
        } else {
            formik.setFieldValue("branch", [...formik.values.branch, branchID])
            handleGetAgentBranch(branchID)
        }
        // handleGetAgentBranch(branchID)
    }

    const handleSelectAgent = (agent) => {
        const index = formik.values.agent.indexOf(agent.id)
        if (index > -1) {
            formik.setFieldValue("agent", [
                ...formik.values.agent.slice(0, index),
                ...formik.values.agent.slice(index + 1),
            ])
            setAgentSelected(agentSelected.filter(item => item.id !== agent.id))
        } else {
            formik.setFieldValue("agent", [...formik.values.agent, agent.id])

            setAgentSelected([...agentSelected, agent])
        }
    }

    const handleGetAgents = async () => {

        let payload = {
            page_number: 1,
            per_page: 12,
            sort_column: "",
            sort_order: "Desc",
            keyword: "",
            pagination: 'cursor'
        }
        setIsLoadingGetAgents(true)
        if(agents.length > 0 && nextCursorAgent) {
            payload.cursor = nextCursorAgent
        }
        const res = await AgenMemberAPI.getListPage(payload)
        setIsLoadingGetAgents(false)
        if(res.status == 200) {
            if(agents.length > 0 && nextCursorAgent) {
                setAgents([...agents, ...res.data.data])
            } else {
                setAgents(res.data.data)
            }
            setNextCursorAgent(res.data.next_cursor)
        } else {
            dispatch(handleError(res.data))
        }

    }

    const handleGetAgentBranch = async (branchID) => {

        let payload = {
            page_number: 1,
            per_page: 1000000,
            sort_column: "",
            sort_order: "Desc",
            keyword: "",
            branch: branchID
        }

        setIsLoadingGetAgentBranch(true)
        const res = await AgenMemberAPI.getListPage(payload)
        setIsLoadingGetAgentBranch(false)
        if(res.status == 200) {
            const dataAgents = res.data.data
            const agentsID = dataAgents.map(item => item.id)
            const agentsData = dataAgents

            if(agentSwitch) {
                formik.setFieldValue("agent", [...formik.values.agent, ...agentsID])
                setAgentSelected([...agentSelected, ...agentsData])
            } else {
                formik.setFieldValue("agent", [...tempAgentSelected.map(item => item.id), ...agentsID])
                setAgentSelected([...tempAgentSelected, ...agentsData])
                setAgentSwitch(true)
            }

        } else {
            dispatch(handleError(res.data))
        }
    }

    const handleRemoveAgentBranch = (branchID) => {

        // hapus data di formik agent untuk ambil agent idnya ambil dari agentSelected berdasarkan branchID
        const agentsID = agentSelected
            .filter(item => item.cabang_id == branchID)
            .map(item => item.id)

        formik.setFieldValue("agent", formik.values.agent.filter(item => !agentsID.includes(item)))

        // hapus data di agentSelected yang memilik branchID sama
        setAgentSelected(agentSelected.filter(item => item.cabang_id != branchID))
    }

    const handleSave = async () => {
        // console.log(formik.values)
        const payload = {
            main_booking: formik.values.main_booking,
            public: formik.values.public,
            branch: formik.values.branch,
            agent: formik.values.agent
        }

        setSaving(true)
        const res = await updatePublishSettingService(params.id, payload)
        setSaving(false)

        if(res.status == 200) {
            dispatch(handleSuccess(res))
            dispatch(PaketDetailGeneralInfoAction.fetchData(params.id));
        } else {
            dispatch(handleError(res.data))
        }

    }

    const handleSwitchBranch = (status) => {
        setBranchSwitch(status)
    }

    useEffect(() => {
        handleGetBranch()
        handleGetPublishSettings()
        handleGetAgents()
    }, [])

    useEffect(() => {
        if(formik.values.branch.length && !formik.values.public) {
            setBranchSwitch(true)
            // handlePushMainBranch()
        }

    }, [formik.values.public, formik.values.branch])

    useEffect(() => {
        if(formik.values.public) {
            setBranchSwitch(false)
            setAgentSwitch(false)
        } else {
            setBranchSwitch(true)
            handlePushMainBranch()
        }
    }, [formik.values.public])

    useEffect(() => {
        if(branchSwitch) {
            handlePushMainBranch()
            setAgentSwitch(true)
        } else {
            formik.setFieldValue('branch', [])
            formik.setFieldValue('agent', [])
            setAgentSelected([])
            setTempAgentSelected([])
        }
    }, [branchSwitch])

    useEffect(() => {
        if(formik.values.branch.length) {
            formik.values.branch.map((branch, idx) => {
                // jika branch ada di agentSelected key branchID maka replace dengan agentSelected, jika belum ada maka tambahkan
                if(agentSelected.filter(item => item.cabang_id == branch).length > 0) {
                    // make sync for data agentSelected with cabang_id == branch
                    formik.setFieldValue("agent", formik.values.agent.map(item => item.cabang_id == branch ? agentSelected.filter(item => item.cabang_id == branch)[0].id : item))

                } else {
                    // formik.setFieldValue("agent", formik.values.agent.map(item => item.cabang_id == branch ? agentSelected.filter(item => item.cabang_id == branch)[0].id : item))
                    handleGetAgentBranch(branch)
                }
            })
        }


    }, [formik.values.branch])

    useEffect(() => {
        if(!agentSwitch) {
            formik.setFieldValue('agent', [])
            setAgentSelected([])
        } else {
            setAgentSelected(tempAgentSelected)
            formik.setFieldValue("agent", tempAgentSelected.map(item => item.id))
        }
    }, [agentSwitch])

    useEffect(() => {
        if(agentSelected.length > 0) {
            setTempAgentSelected(agentSelected)
        }
    }, [agentSelected])

    return (
        <>
            <div className="shadow-highlight">
                <Scroll height="calc(100vh - 311px)">
                    <div className="p-4 shadow-soft rounded-2xl m-4">
                        <div className="my-4 font-semibold">
                            Booking Setting
                        </div>
                        <div className="mb-4">
                            <div
                                className="flex items-center gap-4"
                                >
                                <Switch checked={formik.values.main_booking} onChange={() => formik.setFieldValue("main_booking", !formik.values.main_booking)} />
                                <span className="font-semibold">Pusat bisa Booking</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 shadow-soft rounded-2xl m-4">
                        <div className="my-4 font-semibold">
                            Publish Setting
                        </div>
                        <div className="mb-4">
                            <div
                                className="flex items-center gap-4"
                                >
                                <Switch 
                                    onChange={() => formik.setFieldValue("public", !formik.values.public)} 
                                    checked={formik.values.public} />
                                <span className="font-semibold">Umum</span>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div
                                className="flex items-center gap-4 mb-4"
                                >
                                <Switch 
                                    checked={branchSwitch}
                                    onChange={(status) => handleSwitchBranch(status)}
                                    disabled={formik.values.public || (!formik.values.public && branchSwitch)}
                                    />
                                <span className="font-semibold">Cabang</span>
                            </div>
                            <Grid container spacing={2}>
                                {dataAllCabang.data?.map((item, index) => (
                                    <Grid item md={3}>
                                        <div 
                                            className={`bg-white ${formik.values.branch?.includes(item?.id_cabang) && "!bg-red-5"} shadow-soft rounded-xl p-4 flex justify-between items-center`}>
                                            <Badge
                                                label={item?.name}
                                                backgroundColor={item?.color}
                                                color={item?.color_text}
                                                />
                                            <Switch
                                                onChange={() => handleSelectBranch(item?.id_cabang)}
                                                checked={formik.values.branch?.includes(item?.id_cabang)}
                                                disabled={item?.is_pusat || !branchSwitch}
                                                />
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                        <div className="mb-4">
                            <div
                                className="flex justify-between items-center"
                                >
                                <div className="flex gap-4">
                                    <Switch 
                                        checked={agentSwitch}
                                        onChange={() => setAgentSwitch(!agentSwitch)}
                                        />
                                    <span className="font-semibold">Agen</span>
                                </div>
                                <div className="flex gap-2">
                                    <Buttons 
                                        text="Semua"
                                        customClass={`${filterAgent == 'all' ? 'btn-secondary' : 'btn-outline-light'} btn-small text-[12px]`}
                                        onClick={() => setFilterAgent('all')}
                                        />
                                    <Buttons 
                                        text={`On (${formik.values.agent.length})`}
                                        customClass={`${filterAgent == 'on' ? 'btn-secondary' : 'btn-outline-light'} btn-small text-[12px]`}
                                        onClick={() => setFilterAgent('on')}
                                        loading={isLoadingGetAgentBranch}
                                        />
                                    <Buttons 
                                        text="Off"
                                        customClass={`${filterAgent == 'off' ? 'btn-secondary' : 'btn-outline-light'} btn-small text-[12px]`}
                                        onClick={() => setFilterAgent('off')}
                                        />
                                </div>
                            </div>
                        </div>
                        <div>
                            {filterAgent == 'all' && (
                                <>
                                    {isLoadingGetAgents && agents.length == 0 ? (
                                        <div className="flex justify-center items-center h-[300px]">
                                            <Spinner size="50px" />
                                        </div>
                                    ) : (
                                        <>
                                            {agents.length > 0 ? (
                                                <>
                                                    <Grid container spacing={2}>
                                                        {agents?.map((item, index) => (
                                                            <Grid item md={4}>
                                                                <div 
                                                                    className={`bg-white ${formik.values.agent?.includes(item?.id) && "!bg-red-5"} shadow-soft rounded-xl p-4 flex justify-between items-center`}>
                                                                    <div className="flex flex-col gap-2">
                                                                        <div className="text-xs font-semibold">{handleName(item?.title, item?.name)}</div>
                                                                        <Badge
                                                                            label={item?.cabang_name}
                                                                            backgroundColor={item?.color_bg_cabang}
                                                                            color={item?.color_text_cabang}
                                                                            />
                                                                    </div>
                                                                    <Switch
                                                                        onChange={() => handleSelectAgent(item)}
                                                                        checked={formik.values.agent?.includes(item?.id)}
                                                                        />
                                                                </div>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                    {nextCursorAgent && (
                                                        <div className="mt-4 flex justify-center items-center">
                                                            <Buttons 
                                                                customClass="btn-outline btn-small" 
                                                                text="Lihat lagi" 
                                                                loading={isLoadingGetAgents && agents.length > 0}
                                                                onClick={() => handleGetAgents()}
                                                                />
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <div className="flex flex-col h-[300px] justify-center items-center bg-white">
                                                    <img
                                                        src={IconJamPasir}
                                                        alt="Cooming Soon"
                                                        className="mb-1 w-[212px] h-[220px]"
                                                    />
                                                    <p className="text-sm text-black">
                                                        Belum ada agen
                                                    </p>  
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {filterAgent == 'on' && (
                                <>
                                    {agentSelected.length > 0 ? (
                                        <>
                                            <Grid container spacing={2}>
                                                {agentSelected?.map((item, index) => (
                                                    <Grid item md={4}>
                                                        <div 
                                                            className={`bg-white ${formik.values.agent?.includes(item?.id) && "!bg-red-5"} shadow-soft rounded-xl p-4 flex justify-between items-center`}>
                                                            <div className="flex flex-col gap-2">
                                                                <div className="text-xs font-semibold">{handleName(item?.title, item?.name)}</div>
                                                                <Badge
                                                                    label={item?.cabang_name}
                                                                    backgroundColor={item?.color_bg_cabang}
                                                                    color={item?.color_text_cabang}
                                                                    />
                                                            </div>
                                                            <Switch
                                                                onChange={() => handleSelectAgent(item)}
                                                                checked={formik.values.agent?.includes(item?.id)}
                                                                />
                                                        </div>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <div className="flex flex-col h-[300px] justify-center items-center bg-white">
                                            <img
                                                src={IconJamPasir}
                                                alt="Cooming Soon"
                                                className="mb-1 w-[212px] h-[220px]"
                                            />
                                            <p className="text-sm text-black">
                                                Belum ada agen yang dipilih
                                            </p>  
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            {filterAgent == 'off' && (
                                <>
                                    {isLoadingGetAgents && agents.length == 0 ? (
                                        <div className="flex justify-center items-center h-[300px]">
                                            <Spinner size="50px" />
                                        </div>
                                    ) : (
                                        <>
                                            {agents.filter((item) => !formik.values.agent.includes(item.id)).length > 0 ? (
                                                <>
                                                    <Grid container spacing={2}>
                                                        {agents?.map((item, index) => (
                                                            // menampilkan item agent yang selain dipilih
                                                            <>
                                                                {!formik.values.agent?.includes(item?.id) && (
                                                                    <Grid item md={4}>
                                                                        <div 
                                                                            className={`bg-white ${formik.values.agent?.includes(item?.id) && "!bg-red-5"} shadow-soft rounded-xl p-4 flex justify-between items-center`}>
                                                                            <div className="flex flex-col gap-2">
                                                                                <div className="text-xs font-semibold">{handleName(item?.title, item?.name)}</div>
                                                                                <Badge
                                                                                    label={item?.cabang_name}
                                                                                    backgroundColor={item?.color_bg_cabang}
                                                                                    color={item?.color_text_cabang}
                                                                                    />
                                                                            </div>
                                                                            <Switch
                                                                                onChange={() => handleSelectAgent(item)}
                                                                                checked={formik.values.agent?.includes(item?.id)}
                                                                                />
                                                                        </div>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        ))}
                                                    </Grid>
                                                    {nextCursorAgent && (
                                                        <div className="mt-4 flex justify-center items-center">
                                                            <Buttons 
                                                                customClass="btn-outline btn-small" 
                                                                text="Lihat lagi" 
                                                                loading={isLoadingGetAgents && agents.length > 0}
                                                                onClick={() => handleGetAgents()}
                                                                />
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <div className="flex flex-col h-[300px] justify-center items-center bg-white">
                                                        <img
                                                            src={IconJamPasir}
                                                            alt="Cooming Soon"
                                                            className="mb-1 w-[212px] h-[220px]"
                                                        />
                                                        <p className="text-sm text-black">
                                                            Belum ada agen yang bisa dipilih
                                                        </p>  
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Scroll>
                <div className="flex justify-end px-4 pt-5">
                <Buttons 
                    disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => handleSave()}
                    loading={saving}
                    text="Simpan" 
                    customClass="btn-primary btn-large" />
                </div>
            </div>
        </>
    )

}

export default React.memo(PublishSetting);