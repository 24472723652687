
import React, { useEffect, useState } from 'react'
import IconEdit from 'assets/icons/edit.svg'

import { useSelector } from 'react-redux'

import { Grid } from '@material-ui/core';
import Progressbars from 'components/progressbars';
import { Eye } from 'iconsax-react';

// import EditManifestForm from './EditManifestForm'



const DetailManifest = ({
  manifest,
  setIsUpdateManifest,
  setUpdateManifestKey,
  setUpdateManifestTitle,
  setDataManifestSelected
}) => {

  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  // const [isUpdateManifest, setIsUpdateManifest] = useState(false)
  // const [updateManifestKey, setUpdateManifestKey] = useState('pas_photo_4x6')
  // const [updateManifestTitle, setUpdateManifestTitle] = useState("Pas Photo 4x6")

  const handleCountPercent = (ready, total) => {
    return Math.round((ready / total) * 100)
  }

  useEffect(() => {
    console.log("Manifest", manifest);
  }, [manifest])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <div className="bg-white soft-shadow rounded-2xl p-4">
            <div className="flex flex-row justify-between items-start">
              <div className="text-[16px] font-semibold">
                Pas Foto 4x6
              </div>
              <div 
                onClick={() => {
                  setIsUpdateManifest(true)
                  setUpdateManifestKey('pas_photo_4x6')
                  setUpdateManifestTitle("Pas Photo 4x6")
                  setDataManifestSelected(manifest)
                }}
                className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer shadow-md">
                {permissions?.includes('update_manifest') && auth.user.access_data === "all_branches" ? (
                  <img
                    className="w-[18px] h-[18px]"
                    src={IconEdit}
                    alt="user-img"
                  />
                ) : (
                  <Eye 
                    size={20}
                    />
                )}
              </div>
            </div>
            <Progressbars
              customInfoClass="!top-[-20px]"
              containerClass="mt-6" 
              percent={
                  handleCountPercent(manifest?.manifests?.pas_photo_4x6?.total_filled, manifest?.manifests?.pas_photo_4x6?.total)
              } 
              info={`
              ${manifest?.manifests?.pas_photo_4x6?.total_filled}/${manifest?.manifests?.pas_photo_4x6?.total}`} />
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="bg-white soft-shadow rounded-2xl p-4">
            <div className="flex flex-row justify-between items-start">
              <div className="text-[16px] font-semibold">
                Identitas KTP
              </div>
              <div 
                onClick={() => {
                  setIsUpdateManifest(true)
                  setUpdateManifestKey('ktp')
                  setUpdateManifestTitle("Identitas KTP")
                  setDataManifestSelected(manifest)
                }}
                className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer shadow-md">
                {permissions?.includes('update_manifest') && auth.user.access_data === "all_branches" ? (
                  <img
                    className="w-[18px] h-[18px]"
                    src={IconEdit}
                    alt="user-img"
                  />
                ) : (
                  <Eye 
                    size={20}
                    />
                )}
              </div>
            </div>
            <Progressbars
              customInfoClass="!top-[-20px]" 
              containerClass="mt-6" 
              percent={
                  handleCountPercent(manifest?.manifests?.ktp?.total_filled, manifest?.manifests?.ktp?.total)
              } 
              info={`
                  ${manifest?.manifests?.ktp?.total_filled}/${manifest?.manifests?.ktp?.total}`
              }
              />
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="bg-white soft-shadow rounded-2xl p-4">
            <div className="flex flex-row justify-between items-start">
              <div className="text-[16px] font-semibold">
                Passport
              </div>
              <div 
                onClick={() => {
                  setIsUpdateManifest(true)
                  setUpdateManifestKey('passport')
                  setUpdateManifestTitle("Passportku")
                  setDataManifestSelected(manifest)
                }}
                className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer shadow-md">
                {permissions?.includes('update_manifest') && auth.user.access_data === "all_branches" ? (
                  <img
                    className="w-[18px] h-[18px]"
                    src={IconEdit}
                    alt="user-img"
                  />
                ) : (
                  <Eye 
                    size={20}
                    />
                )}
              </div>
            </div>
            <Progressbars
              customInfoClass="!top-[-20px]" 
              containerClass="mt-6" 
              percent={
                  handleCountPercent(manifest?.manifests?.passport?.total_filled, manifest?.manifests?.passport?.total)
              } 
              info={`
                  ${manifest?.manifests?.passport?.total_filled}/${manifest?.manifests?.passport?.total}`
              }
              />
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="bg-white soft-shadow rounded-2xl p-4">
            <div className="flex flex-row justify-between items-start">
              <div className="text-[16px] font-semibold">Akta Kelahiran</div>
              <div 
                onClick={() => {
                  setIsUpdateManifest(true)
                  setUpdateManifestKey('akta_kelahiran')
                  setUpdateManifestTitle("Akta Kelahiran")
                  setDataManifestSelected(manifest)
                }}
                className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer shadow-md">
                {permissions?.includes('update_manifest') && auth.user.access_data === "all_branches" ? (
                  <img
                    className="w-[18px] h-[18px]"
                    src={IconEdit}
                    alt="user-img"
                  />
                ) : (
                  <Eye 
                    size={20}
                    />
                )}
              </div>
            </div>
            <Progressbars
              customInfoClass="!top-[-20px]" 
              containerClass="mt-6" 
              percent={
                  handleCountPercent(manifest?.manifests?.akta_kelahiran?.total_filled, manifest?.manifests?.akta_kelahiran?.total)
              } 
              info={`
                  ${manifest?.manifests?.akta_kelahiran?.total_filled}/${manifest?.manifests?.akta_kelahiran?.total}`
              } />
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="bg-white soft-shadow rounded-2xl p-4">
            <div className="flex flex-row justify-between items-start">
              <div className="text-[16px] font-semibold">
                Informasi Keluarga
              </div>
              <div 
                onClick={() => {
                  setIsUpdateManifest(true)
                  setUpdateManifestKey('family')
                  setUpdateManifestTitle("Info Keluarga")
                  setDataManifestSelected(manifest)
                }}
                className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer shadow-md">
                {permissions?.includes('update_manifest') && auth.user.access_data === "all_branches" ? (
                  <img
                    className="w-[18px] h-[18px]"
                    src={IconEdit}
                    alt="user-img"
                  />
                ) : (
                  <Eye 
                    size={20}
                    />
                )}
              </div>
            </div>
            <Progressbars
              customInfoClass="!top-[-20px]" 
              containerClass="mt-6" 
              percent={
                  handleCountPercent(manifest?.manifests?.family?.total_filled, manifest?.manifests?.family?.total)
              } 
              info={`
                  ${manifest?.manifests?.family?.total_filled}/${manifest?.manifests?.family?.total}`
              } 
              />
          </div>
        </Grid>
        {/* <Grid item md={6}>
          <div className="bg-white soft-shadow rounded-2xl p-4">
            <div className="flex flex-row justify-between items-start">
              <div className="text-[16px] font-semibold">
                Vaksin Covid
              </div>
              <div 
                onClick={() => {
                  setIsUpdateManifest(true)
                  setUpdateManifestKey('vaccine_covid')
                  setUpdateManifestTitle("Vaksin Covid")
                  setDataManifestSelected(manifest)
                }}
                className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer shadow-md">
                {permissions?.includes('update_manifest') && auth.user.access_data === "all_branches" ? (
                  <img
                    className="w-[18px] h-[18px]"
                    src={IconEdit}
                    alt="user-img"
                  />
                ) : (
                  <Eye 
                    size={20}
                    />
                )}
              </div>
            </div>
            <Progressbars
              customInfoClass="!top-[-20px]" 
                containerClass="mt-6" 
              percent={
                  handleCountPercent(manifest?.manifests?.vaccine_covid?.total_filled, manifest?.manifests?.vaccine_covid?.total)
              } 
              info={`
                  ${manifest?.manifests?.vaccine_covid?.total_filled}/${manifest?.manifests?.vaccine_covid?.total}`
              } 
              />
          </div>
        </Grid> */}
        <Grid item md={6}>
          <div className="bg-white soft-shadow rounded-2xl p-4">
            <div className="flex flex-row justify-between items-start">
              <div className="text-[16px] font-semibold">Meningitis</div>
              <div 
                onClick={() => {
                  setIsUpdateManifest(true)
                  setUpdateManifestKey('meningitis_vaccine')
                  setUpdateManifestTitle("Vaksin Meningitis")
                  setDataManifestSelected(manifest)
                }}
                className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer shadow-md">
                {permissions?.includes('update_manifest') && auth.user.access_data === "all_branches" ? (
                  <img
                    className="w-[18px] h-[18px]"
                    src={IconEdit}
                    alt="user-img"
                  />
                ) : (
                  <Eye 
                    size={20}
                    />
                )}
              </div>
            </div>
            <Progressbars
              customInfoClass="!top-[-20px]" 
              containerClass="mt-6" 
              percent={
                  handleCountPercent(manifest?.manifests?.meningitis_vaccine?.total_filled, manifest?.manifests?.meningitis_vaccine?.total)
              } 
              info={`
                  ${manifest?.manifests?.meningitis_vaccine?.total_filled}/${manifest?.manifests?.meningitis_vaccine?.total}`
              } />
          </div>
        </Grid>
        {/* <Grid item md={6}>
          <div className="bg-white soft-shadow rounded-2xl p-4">
            <div className="flex flex-row justify-between items-start">
              <div className="text-[16px] font-semibold">BPJS</div>
              <div 
                onClick={() => {
                  setIsUpdateManifest(true)
                  setUpdateManifestKey('bpjs')
                  setUpdateManifestTitle("BPJS Kesehatan")
                  setDataManifestSelected(manifest)
                }}
                className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer shadow-md">
                {permissions?.includes('update_manifest') && auth.user.access_data === "all_branches" ? (
                  <img
                    className="w-[18px] h-[18px]"
                    src={IconEdit}
                    alt="user-img"
                  />
                ) : (
                  <Eye 
                    size={20}
                    />
                )}
              </div>
            </div>
            <Progressbars
              customInfoClass="!top-[-20px]" 
              containerClass="mt-6" 
              percent={
                  handleCountPercent(manifest?.manifests?.bpjs?.total_filled, manifest?.manifests?.bpjs?.total)
              } 
              info={`
                  ${manifest?.manifests?.bpjs?.total_filled}/${manifest?.manifests?.bpjs?.total}`
              } />
          </div>
        </Grid> */}
      </Grid>
    </>
  )
}

export default DetailManifest