import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Badge from "../../../../../../components/badge";
import Buttons from "../../../../../../components/buttons";

import IconDotsBlue from "../../../../../../assets/icons/dots-blue.svg";
import IconAirplaneBlue from "../../../../../../assets/icons/airplane-blue.svg";
import DashedBlue from "../../../../../../assets/icons/dashed-blue.svg";
import DashedGrey from "../../../../../../assets/icons/dashed-grey.svg";
import Garuda from "../../../../../../assets/images/garuda.jpg";
import { Grid } from "@material-ui/core";
import moment from "moment";
import Departure from "./Departure";
import { useSelector } from 'react-redux'

import IconJamPasir from "assets/icons/Jam-Pasir.svg";

import { deleteFlightInternational } from 'utils/api/paket'
import Alert from "components/popup/alert";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

const Arrival = ({
  setOpenPopupAddOrEditFlight,
  data = [],
  setFormInternational,
  setIsMaskapai,
  setIsRadio,
  onEditPenerbanganInternational = (data) => {},
  reloadData
}) => {

  const handleAddInternationalFlight = () => {
    setFormInternational({
      terbang_dari: null,
      teransit_di: null,
      is_transit: 0,
      terminal_terbang: "",
      kode_penerbangan: "",
      date_keberangkatan: null,
      date_kedatangan: null,
      time_keberangkatan: null,
      time_kedatangan: null,
      mendarat_di: null,
      terminal_transit: "",
      kode_penerbangan_transit: "",
      date_keberangkatan_transit: null,
      date_kedatangan_transit: null,
      time_keberangkatan_transit: null,
      time_kedatangan_transit: null,
    });
    setOpenPopupAddOrEditFlight({
      label: "Add",
      show: true,
    });
  }
  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const params = useParams();

  const [removePopup, setRemovePopup] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [flightRemove, setFlightRemove] = useState(null);

  const handleRemoveFlightInternational = async () => {
    setIsLoadingDelete(true);
    const res = await deleteFlightInternational(params.id, flightRemove?.id);
    setIsLoadingDelete(false);

    if (res.status === 200) {
      dispatch(handleSuccess(res)),
      setRemovePopup(false)
      reloadData()
    } else {
      dispatch(handleError(res.data));
    }

  }

  return (
    <div>
      <Alert
        open={removePopup}
        title="Hapus Penerbangan"
        subtitle={`Yakin ingin menghapus penerbangan ini?`}
        handleClose={() => {
          setRemovePopup(false)
        }}
        handleContinue={() => handleRemoveFlightInternational()}
        text_accept="Yakin"
        text_cancel="Tidak"
        loading={isLoadingDelete}
        />
        
      <div className="flex flex-row justify-between items-center gap-3 p-4">
        <div className=" flex flex-row gap-3 items-center">
          <div className="text-[18px] font-semibold">
            Keberangkatan - Kedatangan
          </div>
          <div>
            <Badge
              label="Internasional"
              backgroundColor={"#F07EF733"}
              color="#973AA8"
            />
          </div>
        </div>
        <div className="flex gap-4 justify-end">
          {auth.user?.access_data === 'all_branches' && (
            <>
              {permissions?.includes('create_flight') && (
                <>
                  <Buttons
                    text="Tambah Penerbangan"
                    customClass="btn-primary btn-small"
                    onClick={() => {
                      handleAddInternationalFlight()
                    }}
                  />
                </>
              )}
            </>
          )}

          {auth.user.access_data === 'all_branches' && (
            <>
              {permissions?.includes('update_flight') && (
                <>
                  <div
                      className="flex flex-row justify-center items-center py-[8px] px-[12px] rounded-lg bg-[#FFDCDC] font-semibold text-[#E82320] text-[12px] cursor-pointer"
                      onClick={() => {
                        setIsMaskapai(true);
                        setIsRadio(null);
                      }}
                    >
                      Pilih Maskapai
                  </div>
                </>
              )}
            </>
          )}
        </div>

      </div>
      <div className="flex flex-row gap-4 overflow-x-scroll scrolling-touch items-start pb-4 pl-[16px] costumScrollbarHorizontal">
        {data?.length ? (
          <>
            {data?.map((item, idx) => {
              if (item.is_transit === 0) {
                return (
                  <Departure
                    key={idx}
                    first={idx === 0 ? true : false}
                    last={idx === data?.length - 1 ? true : false}
                    data={item}
                    isInternational={true}
                    // setIsDomestik={setIsDomestik}
                    handleEditKeberangkatan={(val) =>
                      onEditPenerbanganInternational(val)
                    }
                    reloadData={reloadData}
                  />
                );
              } else {
                return (
                  <div className="bg-[#FBF3F2] rounded-[24px] min-w-[566px] p-4">
                    <div className="bg-white rounded-[32px] p-4 min-w-[281px]">
                      <div className="flex flex-row justify-between items-start relative w-full mb-6">
                        <div className="w-2/12">
                          <div className="txt-green text-[18px] font-semibold">
                            {item?.airport_departure_code}
                          </div>
                          <div className="text-[#828282] line-clamp-1">
                            {item?.airport_departure_city}
                          </div>
                        </div>

                        <div className="w-3/12 flex flex-row justify-between items-center relative mt-2">
                          <img src={IconDotsBlue} alt="dots-left" />
                          <div className="h-fit w-full left-0 right-0">
                            <div
                              className="w-full h-[1px]"
                              style={{
                                backgroundImage: `url(${DashedBlue})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                                backgroundSize: "cover",
                              }}
                            ></div>
                            <div>
                              <img
                                className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white"
                                src={IconAirplaneBlue}
                                alt="airplane"
                              />
                              <div className="absolute top-[16px] left-[50%] translate-x-[-50%]  text-[8px] font-medium text-[#828282]">
                                {item?.duration}
                              </div>
                            </div>
                          </div>
                          <img src={IconDotsBlue} alt="dots-left" />
                        </div>

                        <div className="w-2/12">
                          <div className="txt-green text-[18px] font-semibold text-center">
                            {item?.transit?.airport_transit_code}
                          </div>
                          <div className="text-[#828282] text-right line-clamp-1">
                            {item?.transit?.airport_transit_city}
                          </div>
                        </div>

                        <div className="w-3/12 flex flex-row justify-between items-center relative mt-2">
                          <img src={IconDotsBlue} alt="dots-left" />
                          <div className="h-fit w-full left-0 right-0">
                            <div
                              className="w-full h-[1px]"
                              style={{
                                backgroundImage: `url(${DashedBlue})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                                backgroundSize: "cover",
                              }}
                            ></div>
                            <div>
                              <img
                                className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white"
                                src={IconAirplaneBlue}
                                alt="airplane"
                              />
                              <div className="absolute top-[16px] left-[50%] translate-x-[-50%]  text-[8px] font-medium text-[#828282]">
                                {item?.transit?.transit_duration}
                              </div>
                            </div>
                          </div>
                          <img src={IconDotsBlue} alt="dots-left" />
                        </div>

                        <div className="w-2/12">
                          <div className="txt-green text-[18px] font-semibold text-right line-clamp-1">
                            {item.airport_arrival_code}
                          </div>
                          <div className="text-[#828282] text-right line-clamp-1">
                            {item.airport_arrival_city}
                          </div>
                        </div>
                      </div>
                      <Grid container spacing={1} className="relative">
                        <Grid item md={6} className="!pr-4">
                          <div className="flex flex-row justify-between">
                            <div>
                              <div className="text-[#828282] text-[12px]">
                                Keberangkatan
                              </div>
                              <div className="txt-green font-semibold">
                                {item?.departure_time}
                              </div>
                              <div className="text-[#828282] text-[8px] font-medium">
                                {item?.departure_date !== null &&
                                  moment(item?.departure_date, "DD-MM-YYYY").format(
                                    "DD MMMM YYYY"
                                  )}
                              </div>
                            </div>
                            <div className="text-right">
                              <div className="text-[#828282] text-[12px]">
                                Kedatangan Transit
                              </div>
                              <div className="txt-green font-semibold">
                                {item?.transit?.transit_arrival_time}
                              </div>
                              <div className="text-[#828282] text-[8px] font-medium">
                                {item?.transit?.transit_arrival_date !== null &&
                                  moment(
                                    item?.transit?.transit_arrival_date,
                                    "DD-MM-YYYY"
                                  ).format("DD MMMM YYYY")}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row justify-between mt-6">
                            <div>
                              <div className="text-[#828282] text-[12px]">
                                Kode Penerbangan
                              </div>
                              <div className="txt-green font-semibold">
                                {item?.flight_code}
                              </div>
                            </div>
                            <div className="text-right">
                              <div className="text-[#828282] text-[12px]">
                                Terminal
                              </div>
                              <div className="txt-green font-semibold">
                                {item?.terminal}
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <div className="w-[0.5px] h-full bg-[#BDBDBD] absolute top-0 bottom-0 left-[50%]"></div>
                        <Grid item md={6} className="!pl-4">
                          <div className="flex flex-row justify-between">
                            <div>
                              <div className="text-[#828282] text-[12px]">
                                Keberangkatan Transit
                              </div>
                              <div className="txt-green font-semibold">
                                {item.transit?.transit_departure_time}
                              </div>
                              <div className="text-[#828282] text-[8px] font-medium">
                                {item?.transit?.transit_departure_date !== null &&
                                  moment(
                                    item?.transit?.transit_departure_date,
                                    "DD-MM-YYYY"
                                  ).format("DD MMMM YYYY")}
                              </div>
                            </div>
                            <div className="text-right">
                              <div className="text-[#828282] text-[12px]">
                                Kedatangan
                              </div>
                              <div className="txt-green font-semibold">
                                {item?.arrival_time}
                              </div>
                              <div className="text-[#828282] text-[8px] font-medium">
                                {item?.arrival_date !== null &&
                                  moment(item?.arrival_date, "DD-MM-YYYY").format(
                                    "DD MMMM YYYY"
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row justify-between mt-6">
                            <div>
                              <div className="text-[#828282] text-[12px]">
                                Kode Penerbangan
                              </div>
                              <div className="txt-green font-semibold">
                                {item?.transit?.transit_flight_code}
                              </div>
                            </div>
                            <div className="text-right">
                              <div className="text-[#828282] text-[12px]">
                                Terminal
                              </div>
                              <div className="txt-green font-semibold">
                                {item?.transit.transit_terminal}
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <div className="relative flex flex-row justify-between items-center mt-6">
                        <div className="w-[26px] h-[26px] bg-[#FBF3F2] rounded-full absolute left-[-28px]"></div>
                        <div
                          className="h-[1px] m-auto"
                          style={{
                            backgroundImage: `url(${DashedGrey})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                            backgroundSize: "cover",
                            width: "calc(100% - 20px)",
                          }}
                        ></div>
                        <div className="w-[26px] h-[26px] bg-[#FBF3F2] rounded-full absolute right-[-28px]"></div>
                      </div>

                      <div className="flex flex-row justify-start items-center mt-4">
                        <div className="text-[14px] font-semibold">
                          {item?.airplane?.name}
                        </div>
                        <img
                          className="w-[48px] h-[48px] rounded-full ml-4"
                          src={item?.airplane?.logo}
                          alt="maskapai"
                        />
                      </div>

                      {auth.user?.access_data === 'all_branches' && (
                        <>
                          <div className="flex gap-4">
                            {permissions?.includes('update_flight') && (
                              <>
                                <Buttons
                                  text="Edit"
                                  containerClass="mt-4 !w-full"
                                  customClass="btn-outline btn-medium !py-1"
                                  onClick={() => {
                                    onEditPenerbanganInternational(item);
                                  }}
                                />

                                <Buttons
                                  text="Hapus"
                                  containerClass="mt-4 !w-full"
                                  customClass="btn-outline btn-medium !py-1"
                                  onClick={() => {
                                    setRemovePopup(true);
                                    setFlightRemove(item);
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </>
        ) : (
          <>
            <div className="flex w-full flex-row justify-center items-center">
              <div className="flex flex-col justify-center items-center h-[350px]">
                <div className="flex flex-col h-full justify-center items-center !gap-y-[16px]">
                  <img src={IconJamPasir} alt="jam_pasir" />
                  <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
                    Belum ada penerbangan Internasional
                  </div>
                  {auth.user?.access_data === 'all_branches' && (
                    <>
                     {permissions?.includes('create_flight') && (
                       <>
                         <Buttons
                            text="Tambah Penerbangan"
                            customClass="btn-primary !font-semibold btn-small"
                            onClick={() => handleAddInternationalFlight()}
                          />
                       </>
                     )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(Arrival);
