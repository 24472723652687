import axios from "axios";
import { BASE_URL_ENDPOINT } from "../../../../constant/constanta";

export const getListBusById = (id) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/paket/get-bus/${id}`);

export const saveBus = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-bus`, payload);

export const handleRemoveBusAPI = (packageID, busID) => {
  return axios.delete(`${BASE_URL_ENDPOINT}/admin/paket/${packageID}/delete-bus/${busID}`);
}