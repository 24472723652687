import React, { useEffect, useState } from "react";
import MoslemeTable from "../../../../../components/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteDataAkomodasi,
  duplicateDataAkomodasi,
  getAllDataAkomodasi,
} from "../../../../../stores/actions/datamaster";
import { columns } from "./column";
import useDebounce from "../../../../../utils/helpers/useDebounce";
import AlertPopup from "../../../../../components/popup/alert";
import Grid from "@mui/material/Grid";
import Buttons from "../../../../../components/buttons";
import Popup from "../../../../../components/popup";
import Inputs from "../../../../../components/form/inputs";
import CheckboxMaster from "../../checkbox";
import { useFormik } from "formik";
function Akomodasi({ filter, openModal }) {
  // Selection & type state
  const auth = useSelector(({ auth }) => auth)
  const [selectionType, setSelectionType] = useState("checkbox");
  const [open, setOpen] = useState(false);
  const [actionHandle, setActionHandle] = useState();
  const [isModal, setIsModal] = useState(false);
  const [checkName, setCheckName] = useState("");

  const formik = useFormik({
    initialValues: {
      id: "",
      logo: true,
      kategori: true,
      kelas_bintang: true,
      map: true,
      deskripsi: true,
      name: ''
    },
  });

  // data table
  const { dataAkomodasi, status } = useSelector((state) => state.datamaster);
  let dataTable = dataAkomodasi.data;

  const dispatch = useDispatch();

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = dataAkomodasi.total_data;

  // Handle action table
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    dispatch(deleteDataAkomodasi(actionHandle));
    setOpen(false);
  };

  // filter data table
  const searchKeyword = useDebounce(filter, 1000);

  useEffect(() => {
    dispatch(
      getAllDataAkomodasi({
        page_number: currentpage,
        per_page: size,
        keyword: searchKeyword,
        sort_column: "",
        sort_order: "DESC",
      })
    );
  }, [currentpage, dispatch, searchKeyword, size]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    // console.log(currentpage, pageSize);
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // submit form
  const handleSave = () => {
    dispatch(
      duplicateDataAkomodasi({
        ...formik.values,
        logo: formik.values.logo === true ? 1 : 0,
        kategori: formik.values.kategori === true ? 1 : 0,
        kelas_bintang: formik.values.kelas_bintang === true ? 1 : 0,
        map: formik.values.map === true ? 1 : 0,
        deskripsi: formik.values.deskripsi === true ? 1 : 0,
      })
    );
    setIsModal(false);
  };

  return (
    <div>
      <AlertPopup
        open={open}
        handleClose={handleClose}
        handleContinue={handleContinue}
      />
      <Popup
        type="primary"
        title="Duplikat Akomodasi"
        open={isModal}
        handleClose={() => setIsModal(false)}
      >
        <div>
          <Inputs
            name="name"
            label="Nama Akomodasi"
            placeholder="Masukan nama akomodasi"
            defaultValue={formik.values.name}
            />
        </div>
        <div className="my-4">
          <CheckboxMaster
            title="Logo"
            checked={formik.values.logo === true}
            onChange={(e) => {
              formik.setFieldValue("logo", !formik.values.logo);
            }}
          />
        </div>
        <div className="my-4">
          <CheckboxMaster
            title="Map"
            checked={formik.values.map === true}
            onChange={(e) => {
              formik.setFieldValue("map", !formik.values.map);
            }}
          />
        </div>
        <div className="my-4">
          <CheckboxMaster
            title="Kategori"
            customClass="capitalize"
            checked={formik.values.kategori === true}
            onChange={(e) => {
              formik.setFieldValue("kategori", !formik.values.kategori);
            }}
          />
        </div>
        <div className="my-4">
          <CheckboxMaster
            title="Deskripsi"
            checked={formik.values.deskripsi === true}
            onChange={(e) => {
              formik.setFieldValue("deskripsi", !formik.values.deskripsi);
            }}
          />
        </div>
        <div className="my-4">
          <CheckboxMaster
            title="Kelas Bintang"
            checked={formik.values.kelas_bintang === true}
            onChange={(e) => {
              formik.setFieldValue(
                "kelas_bintang",
                !formik.values.kelas_bintang
              );
            }}
          />
        </div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6}>
            <Buttons
              onClick={() => setIsModal(false)}
              text="Batal"
              customClass="btn-secondary btn-large"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Buttons
              onClick={handleSave}
              // disabled={formik.values.name === checkName ? true : false}
              text="Simpan"
              customClass="btn-primary  btn-large"
            />
          </Grid>
        </Grid>
      </Popup>

      <MoslemeTable
        columns={columns(
          handleOpen,
          setActionHandle,
          setIsModal,
          openModal,
          dispatch,
          formik.setFieldValue,
          setCheckName,
          auth?.user?.permissions
        )}
        loading={status === "process"}
        data={dataTable}
        rowId={(record) => record.id_akomodasi}
        withSelection={true}
        selectionType={selectionType}
        rowKey={(record) => record.id_akomodasi}
        pagination={pagination}
        customPaginationClass="pr-4"
        customClass="capitalize"
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
      />
    </div>
  );
}

export default Akomodasi;
