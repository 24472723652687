import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Dropdowns, { closeDropdown } from "components/dropdowns";

import { Eye, EyeSlash } from "iconsax-react";
import TrashIcon from "assets/icons/trash.svg"

import { PaketCouponAPI } from "utils/api/paket/coupon";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { IDRFormater, handleName } from "utils/helpers";
import Radio from "components/form/radio";
import Scroll from "components/scroll"
import Inputs from "components/form/inputs";
import Buttons from "components/buttons";
import { useFormik } from "formik";

import * as Yup from 'yup';
import Avatar from "components/avatar";
import Badge from "components/badge";

const CouponForm = ({
  packageID,
  jamaahOrders,
  branch,
  thema = "default",
  couponDefaultValue,
  jamaahDefaultValue,
  onChange = () => {},
  onReset = () => {},
  onCancel = () => {},
  label = "Kupon",
  couponListFill= null,
  setCouponListFill = () => {}
}) => {

  const dispatch = useDispatch()
  
  const [selectedCoupon, setSelectedCoupon] = useState({})

  const [couponSelectorLabel, setCouponSelectorLabel] = useState("Pilih Kupon")
  const [jamaahSelectorLabel, setJamaahSelectorLabel] = useState("Pilih Jamaah")

  const [isAddJamaah, setIsAddJamaah] = useState(false)
  const [isPossibleAddJamaah, setIsPossibleAddJamaah] = useState(true)

  const [jamaahTempSelected, setJamaahTempSelected] = useState(null)

  const [couponPrivateList, setCouponPrivateList] = useState([])
  const [isLoadingCheckCoupon, setIsloadingCheckCoupon] = useState(false)

  const [couponList, setCouponList] = useState([])

  const [codeCoupon, setCodeCoupon] = useState(null)
  
  const [typeCoupon, setTypeCoupon] = useState(1)

  const [typeOptions, setType] = useState([
    {
      value: (
        <div className="flex items-center">
          <Eye variant="Bold" className="mr-2" /> <p>Public</p>
        </div>
      ),
      label: "Pilih Kupon",
      key: 1,
    },
    {
      value: (
        <div className="flex items-center">
          <EyeSlash variant="Bold" className="mr-2" /> <p>Private</p>
        </div>
      ),
      label: "Masukan Kupon",
      key: 2,
    },
  ]);

  const form = useFormik({
    initialValues: {
      id_coupon: null,
      jamaah_order_id: []
    },
    validationSchema: Yup.object().shape({
      "id_coupon": Yup.number().required("Kupon harus diisi"),
      jamaah_order_id: Yup.array()
        .of(Yup.number().integer("Id Jamaah harus berupa angka").required("Jamaah harus dipilih"))
        .min(1, "Setidaknya ada satu Jamaah")
        .required("Jamaah harus diisi")
    })
  })

  const handleGetCouponPrivate = async () => {
    setCouponPrivateList([])
    if(codeCoupon != '') {
      const params = {
        code_coupon: codeCoupon,
        package_id: packageID
      }
      setIsloadingCheckCoupon(true)
      const response = await PaketCouponAPI.getCouponForOrder(params)
      const { status, data } = response
      setIsloadingCheckCoupon(false)
      if(status === 200) {
        const couponData = data.data

        setCouponPrivateList(couponData)
      } else {
        dispatch(handleError(data))
      }  
    }
  }

  const handleSelectCoupon = (coupon) => {
    
    form.setFieldValue('id_coupon', coupon.id_coupon)
  
    setSelectedCoupon(coupon)

    const label = `${coupon.code_coupon} - Diskon ${coupon.jenis_diskon === 1 ? IDRFormater(coupon.nominal_diskon) : `${coupon.nominal_diskon}%`}`
    setCouponSelectorLabel(label)

    if(coupon.tipe_coupon === 2) {
      handleAddCouponPrivateOnCouponList(coupon)
    }

    {thema !== 'booking' && onChange(coupon.id_coupon, form.values.jamaah_order_id)}

    closeDropdown()
  }

  const handleAddCouponPrivateOnCouponList = (coupon) => {
    let _tempCouponList = [...couponList]

    if(!_tempCouponList.find(_coupon => _coupon.id_coupon === coupon.id_coupon)) {
      _tempCouponList.push(coupon)
    }

    if(couponListFill) {
      setCouponListFill(_tempCouponList)
    } else {
      setCouponList(_tempCouponList)
    }

  }

  const handleSelectJamaah = (jamaah) => {
    if(!form.values.jamaah_order_id.includes(jamaah.id)) {
      let _tempJamaah = form.values.jamaah_order_id
      _tempJamaah.push(jamaah.id)

      form.setFieldValue('jamaah_order_id', _tempJamaah)

      setIsAddJamaah(false)
      
      {thema !== 'booking' && onChange(form.values.id_coupon, _tempJamaah)}
    }

    setJamaahTempSelected(null)
    setJamaahSelectorLabel("Pilih Jamaah")
  }

  const handleRemoveJamaah = (jamaahId) => {
    if(form.values.jamaah_order_id.includes(jamaahId)) {
      let _tempJamaah = form.values.jamaah_order_id
      
      const index = _tempJamaah.indexOf(jamaahId)
      if (index > -1) { // only splice array when item is found
        _tempJamaah.splice(index, 1); // 2nd parameter means remove one item only
      }

      form.setFieldValue('jamaah_order_id', _tempJamaah)

      {thema !== 'booking' && onChange(form.values.id_coupon, _tempJamaah)}
    }
  }
  
  const handleGetCoupons = async () => {
    const params = {
      code_coupon: null,
      package_id: packageID
    }
    const response = await PaketCouponAPI.getCouponForOrder(params)
    const { status, data } = response

    if(status === 200) {
      setCouponList(data.data)
    } else {
      dispatch(handleError(data))
    }

  }

  const handleResetCoupon = () => {
    form.setFieldValue('id_coupon', null)

    const label = `Pakai Kupon`
    setCouponSelectorLabel(label)
    setJamaahSelectorLabel("Pilih Jamaah")

    form.setFieldValue('jamaah_order_id', [])
    
    onReset()
  }

  const handleCheckJamaahCoupon = (jamaahId) => {
    const couponId = form.values.id_coupon
    
    const jamaahCurrent = jamaahOrders.find((jamaah) => jamaah.id === jamaahId)

    if(jamaahCurrent?.copons.some((coupon) => coupon.id_coupon === couponId)) {
      return true
    }

    return false
  }

  const handleGetJamaahSelected = (id) => {
    return jamaahOrders.find((jamaah) => jamaah.id === id)
  }


  useEffect(() => {

    if(form.values.jamaah_order_id.length === 0) {
      setIsAddJamaah(true)
    } 
    
    if(form.values.jamaah_order_id.length === jamaahOrders.length) {
      setIsAddJamaah(false)
      setIsPossibleAddJamaah(false)
    } else {
      setIsPossibleAddJamaah(true)
    }

  }, [form.values.jamaah_order_id?.length, jamaahOrders?.length])

  useEffect(() => {
    if(couponListFill === null ) {
      handleGetCoupons()
    } else {
      setCouponList(couponListFill)
    }
  }, [couponListFill])

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded' || e.message === "ResizeObserver loop completed with undelivered notification") {
        console.log("errorku", e.message)
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
        }
    });
  }, []);

  useEffect(() => {
    console.log("jamaahOrders", jamaahOrders)
  }, [jamaahOrders])

  return (
    <>
      <div className="mb-4">
        <div className="font-semibold text-sm mb-2 flex justify-between">
          <div className="flex gap-2 items-baseline">
            <span>{label}</span>
            <div onClick={handleResetCoupon} className="cursor-pointer text-red-1 text-xs">Reset</div>
          </div>
          <div>
            <img 
              src={TrashIcon}
              className="cursor-pointer"
              onClick={() => {
                onCancel()
              }} 
              />
          </div>
        </div>
        <Dropdowns
          customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between  items-center`}
          placement="bottom"
          labelDropdown={couponSelectorLabel}
          dropdownArrow={true}
        >

          <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
            <div>
              <Radio 
                options={typeOptions} 
                onChange={(e) => {
                  const selectedType = typeOptions.find(
                    (option) => option.value === e.target.value
                  )

                  if(selectedType) {
                    setTypeCoupon(selectedType.key);
                  }

                }}
                />
            </div>
            {typeCoupon === 1 && (
              <>
                <Scroll 
                  height="350px"
                  customClass="mt-4 pr-4"
                  >
                  {couponList.filter((coupon) => coupon.tipe_coupon === 1)?.map((coupon, index) => {
                    return (
                      <>
                        <div 
                          key={index}
                          onClick={() => handleSelectCoupon(coupon)}
                          className="border p-4 rounded-xl mb-4 cursor-pointer">
                          <div className=" text-xl font-semibold mb-2">
                            {coupon.code_coupon}
                          </div>
                          <div className="text-base text-[#F2994A]">
                            {coupon.jenis_diskon === 1 && (
                              <>
                                Diskon {IDRFormater(coupon?.nominal_diskon)}
                              </>
                            )}
                            {coupon.jenis_diskon === 2 && (
                              <>
                                Diskon {coupon.nominal_diskon}%
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </Scroll>
              </>
            )}
            {typeCoupon === 2 && (
              <>
                <div className="flex items-center gap-2 mt-4">
                  <div className="w-4/5">
                    <Inputs 
                      placeholder="Masukan Kode Kupon"
                      onChange={(e) => setCodeCoupon(e.target.value)}
                      />
                  </div>
                  <div className="w-1/5">
                    <Buttons
                      text="Cek Kupon"
                      customClass="btn btn-primary btn-medium !py-4"
                      onClick={() => handleGetCouponPrivate()}
                      loading={isLoadingCheckCoupon}
                      />
                  </div>
                </div>
                <div className="mt-4">
                  {couponPrivateList?.map((coupon, index) => {
                      return (
                        <>
                          <div 
                            onClick={() => handleSelectCoupon(coupon)}
                            className="border p-4 rounded-xl mb-4 cursor-pointer">
                            <div className=" text-xl font-semibold mb-2">
                              {coupon.code_coupon}
                            </div>
                            <div className="text-base text-[#F2994A]">
                              {coupon.jenis_diskon === 1 && (
                                <>
                                  Diskon {IDRFormater(coupon?.nominal_diskon)}
                                </>
                              )}
                              {coupon.jenis_diskon === 2 && (
                                <>
                                  Diskon {coupon.nominal_diskon}%
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )
                    })}
                </div>
              </>
            )}
          </div>

        </Dropdowns>
      </div>
      {form.values.id_coupon && (
        <>
          <div className={`mb-4 p-4 rounded-2xl ${thema === 'booking' ? 'bg-gray-5' : 'bg-[#FBF3F2]'}`}>
            <div className="text-sm flex justify-between items-center mb-4">
              <div className="font-semibold">
              Jamaah
              </div>
              <div className="font-semibold">
                <Buttons 
                  disabled={isAddJamaah || !isPossibleAddJamaah}
                  customClass="btn-primary btn-small" 
                  onClick={() => {
                    setIsAddJamaah(true)
                  }}
                  text="Tambah Jamaah" />
              </div>
            </div>

            <div className="flex flex-col gap-4">

              {form.values.jamaah_order_id.map((jamaahId, index) => (
                <>
                  <div ley={index} className="bg-white p-4 rounded-xl flex justify-between items-center">
                    <div className="flex gap-2 items-center">
                      <Avatar 
                          containerClass="items-center justify-center  w-[56px] h-[56px] rounded-[100%]  bg-slate-100"
                          customClass= "object-cover w-[56px] h-[56px] rounded-[100%]"
                          value={handleGetJamaahSelected(jamaahId)} />

                      <div className="ml-3">
                        <div className="text-xs mb-2">
                          {handleName(handleGetJamaahSelected(jamaahId).jamaah_title, handleGetJamaahSelected(jamaahId).jamaah_name)}
                        </div>
                        <div className="flex justify-start items-center gap-2">
                          <Badge 
                            backgroundColor={branch?.color}
                            color={branch?.color_text}
                            label={branch?.name}
                            />
                          <Badge 
                            backgroundColor={handleGetJamaahSelected(jamaahId)?.package_starting?.bg_color}
                            color={handleGetJamaahSelected(jamaahId)?.package_starting?.text_color}
                            label={handleGetJamaahSelected(jamaahId)?.name_package_starting}
                            />
                        </div>
                      </div>
                    </div>
                    <div>
                      <img 
                        src={TrashIcon} 
                        onClick={() => handleRemoveJamaah(jamaahId)}
                        className="!w-6 !h-6 cursor-pointer" />
                    </div>
                  </div>
                </>
              ))}

              {isAddJamaah && (
                <>
                  <div className="bg-white p-4 rounded-xl">
                    <div className="font-semibold text-sm mb-4 flex justify-between items-center">
                      <span>
                        Pilih Jamaah
                      </span>
                      {form.values.jamaah_order_id.length > 0 && (
                        <div>
                          <img 
                          src={TrashIcon} 
                          className="cursor-pointer" 
                          onClick={() => (
                            setIsAddJamaah(false)
                          )}
                          />
                        </div>
                      )}
                    </div>
                    <Dropdowns
                      customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between  items-center mb-4`}
                      placement="bottomw"
                      labelDropdown={jamaahSelectorLabel}
                      dropdownArrow={true}
                    >

                      <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
                          <>
                            <Scroll 
                              height="350px"
                              customClass="mt-4 pr-4"
                              >
                              {jamaahOrders?.map((jamaah, index) => {
                                return (
                                  <>
                                    {!jamaah.coupons.some((coupon) => coupon.id_coupon === form.values.id_coupon) &&  !form.values.jamaah_order_id?.includes(jamaah.id) && (
                                      <>
                                        <div 
                                          key={index}
                                          onClick={() => {
                                            setJamaahTempSelected(jamaah)
                                            setJamaahSelectorLabel(handleName(jamaah?.jamaah_title, jamaah?.jamaah_name))

                                            closeDropdown()
                                          }}
                                          className="border p-4 rounded-xl mb-4 cursor-pointer flex justify-start gap-3">
                                          
                                          <Avatar 
                                            containerClass="items-center justify-center  w-[56px] h-[56px] rounded-[100%]  bg-slate-100"
                                            customClass= "object-cover w-[56px] h-[56px] rounded-[100%]"
                                            value={jamaah} />

                                          <div className="ml-3">
                                            <div className="text-xs mb-2">
                                              {handleName(jamaah.jamaah_title, jamaah.jamaah_name)}
                                            </div>
                                            <div className="flex justify-start items-center gap-2">
                                              <Badge 
                                                backgroundColor={branch?.color}
                                                color={branch?.color_text}
                                                label={branch?.name}
                                                />
                                              <Badge 
                                                backgroundColor={jamaah?.package_starting?.bg_color}
                                                color={jamaah?.package_starting?.text_color}
                                                label={jamaah?.name_package_starting}
                                                />
                                            </div>
                                          </div>

                                        </div>
                                      </>
                                    )}
                                  </>
                                )
                              })}
                            </Scroll>
                          </>
                      </div>

                    </Dropdowns>
                    <Buttons 
                      customClass="btn-primary btn-medium"
                      disabled={jamaahTempSelected == null}
                      onClick={() => handleSelectJamaah(jamaahTempSelected)}
                      text="Tambah"
                      />
                  </div>
                </>
              )}

            </div>

          </div>
        </>
      )}
      {thema === 'booking' && (
        <>
          <div>
            <Buttons 
              customClass="btn-primary btn-medium" 
              text="Simpan" 
              disabled={!(form.isValid && form.dirty) || isAddJamaah}
              onClick={() => {
                onChange(form.values.id_coupon, form.values.jamaah_order_id, selectedCoupon)
              }}
              />
          </div>
        </>
      )}
    </>
  )
}

CouponForm.propTypes = {
  packageID: PropTypes.number,
  jamaahOrders: PropTypes.array,
  branch: PropTypes.object,
  onChange: PropTypes.func,
  thema: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
}

export default CouponForm