import axios from "axios";
import {
  BASE_URL_ENDPOINT
} from "../../../constant/constanta";


export const getDataMaskapai = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-maskapai-by-month-and-year`, payload);

export const getDataAkomodasi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-akomodasi-by-month-and-year`, payload);

export const getTourCountry = () =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-tour`);

export const getTourCountryByMonthYear = (month, year) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-tour-by-month-year`, { params: { month, year } });

export const getDataAkomodasiTourCountry = (payload) =>
axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-tour-akomodasi-by-month-and-year`, payload);

export const getAirplaneDomestics = () =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-operationals-airplane-domestics`);

export const getEquipmentAdditionals = () =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-operationals-equipment-additionals`);

export const getTourCity = () =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-city-tour`);

export const checkPackage = (payload) => 
  axios.post(`${BASE_URL_ENDPOINT}/admin/request-paket/cek-harga-paket`, payload)

export const requestPaket = (payload) => 
  axios.post(`${BASE_URL_ENDPOINT}/admin/request-paket/save`, payload)

export const getAllRequestPaket = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/request-paket/get-all`, payload)

export const confirmationRequestPaket = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/request-paket/confirmation`, payload)
