import Buttons from "components/buttons"
import Dropdowns, { closeDropdown } from "components/dropdowns"
import Inputs from "components/form/inputs"
import Textarea from "components/form/textarea"
import Scroll from "components/scroll"
import { Trash } from "iconsax-react"
import { useEffect, useState } from "react"
import { IDRFormater } from "utils/helpers"

import IconAdditional from 'assets/icons/Additional1.svg'
import { useFormik } from "formik"



import Step3AdditionalJamaah from "./Step3AdditionalJamaah"

import * as Yup from 'yup'

const Step3AdditionalForm = ({ additionals, orderJamaahList, additionalsFormik, handleTriggerAddAdditional, index }) => {

  const [additionalSelected, setAdditionalSelected] = useState({})
  const [additionalSelectorLabel, setAdditionalSelectorLabel] = useState('Pilih Additional')
  const [additionalList, setAdditionalList] = useState([])
  const [allowAddJamaah, setAllowAddJamaah] = useState(true)
  // const [jammaahEditData, setJamaahEditData] = useState({})
  const [isDisDisabledSelectAdditonal, setIsDisDisabledSelectAdditonal] = useState(false)

  const handleSelectAdditional = (additional) => {
    setAdditionalSelected(additional)

    additionalFormik.setFieldValue("id", additional.id)
    additionalFormik.setFieldValue("name", additional.name)
    additionalFormik.setFieldValue("price", additional.price)
    additionalFormik.setFieldValue("is_wajib", additional.is_wajib)

    setTimeout(() => {
      additionalFormik.setFieldTouched("id", 1)
      additionalFormik.setFieldTouched("name", 1)
      additionalFormik.setFieldTouched("price", 1)
      additionalFormik.setFieldTouched("is_wajib",1)
    
    })

  }

  Yup.addMethod(Yup.array, 'unique', function(message, mapper = a => a) {
      return this.test('unique', message, function(list) {
          return list.length  === new Set(list.map(mapper)).size;
      });
  });

  const additionalFormik = useFormik({
    initialValues: {
      id: null,
      name: null,
      price: null,
      is_wajib: null,
      jamaah: [],
    },
    validationSchema: Yup.object({
      id: Yup.number().required('Harus pilih additional'),
      jamaah: Yup.array().required('Jamaah wajib diisi').of(
        Yup.object({
          name: Yup.string().required('Harus pilih jamaah'),
          ktp_nik: Yup.string().required('Harus pilih jamaah'),
          quantity: Yup.number().required('Quantity wajib diisi').min(1, 'Quantity minimal 1'),
        })
      )
    })
  })

  const handleNewJamaah = () => {
    const currentJamaah = additionalFormik.values.jamaah
    const newJamaah = {
      quantity: null,
      name: null,
      title: null,
      ktp_nik: null,
      note: null
    }

    currentJamaah.push(newJamaah)
    additionalFormik.setFieldValue('jamaah', currentJamaah)
  }

  const handleRemoveJamaah = (index) => {
    let currentJamaah = additionalFormik.values.jamaah
    currentJamaah.splice(index, 1)

    additionalFormik.setFieldValue('jamaah', currentJamaah)

    setTimeout(() => {
      additionalFormik.setFieldTouched('jamaah', 1)
    })

    setAllowAddJamaah(true)
    
  }

  const handleAddAdditional = () => {

    additionalsFormik.setFieldValue(`paket_additionals[${index}]`, additionalFormik.values)
    
    setTimeout(() => {
      // additionalsFormik.setFieldTouched(`paket_additionals[${index}]`, 1)
    })

    handleTriggerAddAdditional()
  }

  useEffect(() => {
    
    if(Object.keys(additionalSelected).length > 0) {
      setAdditionalSelectorLabel(`${additionalSelected.name} - ${IDRFormater(additionalSelected.price)}`)
    }

  }, [additionalSelected])

  useEffect(() => {
    
    setAdditionalList(additionals.filter(add => add.is_wajib !== '1'))

  }, [additionals])

  useEffect(() => {
    if(additionalFormik.values?.jamaah?.length === 0) {
      handleNewJamaah()
    }
    
    if(additionalsFormik.values?.paket_additionals[index].id) {
      const dataEdit = additionalsFormik.values.paket_additionals[index]

      console.log(dataEdit);

      additionalFormik.setFieldValue('id', dataEdit?.id)
      additionalFormik.setFieldValue('name', dataEdit?.name)
      additionalFormik.setFieldValue('price', dataEdit?.price)
      additionalFormik.setFieldValue('is_wajib', dataEdit?.is_wajib)
      additionalFormik.setFieldValue('jamaah', dataEdit?.jamaah)
      
      setTimeout(() => {
        additionalFormik.setFieldTouched('id', 1)
        additionalFormik.setFieldTouched('name', 1)
        additionalFormik.setFieldTouched('price', 1)
        additionalFormik.setFieldTouched('is_wajib', 1)
        additionalFormik.setFieldTouched('jamaah', 1)
      })

      setAdditionalSelected(dataEdit)
      setIsDisDisabledSelectAdditonal(true)
    } else {
      setIsDisDisabledSelectAdditonal(false)
    }


  }, [])

  useEffect(() => {
    console.log("JAMAAHKU", additionalFormik.values)
    if(additionalFormik.values.jamaah?.length === orderJamaahList.length) {
      setAllowAddJamaah(false)
    } else {
      setAllowAddJamaah(true)
    }
  }, [additionalFormik.values])
  

  return (
      <>
        <div className="bg-white p-4 mt-4 rounded-xl">
          <div className="mb-4">
            <Dropdowns
                customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
                placement="bottom"
                labelDropdown={additionalSelectorLabel}
                dropdownArrow={isDisDisabledSelectAdditonal ? false : true}
                disabled={isDisDisabledSelectAdditonal}
                error={additionalFormik?.touched?.id && additionalFormik?.errors?.id}
              >
              
              <div className="bg-white w-full soft-shadow rounded-2xl overflow-hidden">
                <Scroll height="350px">
                  {additionalList?.map((additional, idx) => {
                    return (
                      <>
                        <div 
                          onClick={() => {
                            handleSelectAdditional(additional)
                            // handleSetRoomPrice(
                            //     startingSelected.id_kamar, 
                            //     `quad`, 
                            //     `4 pax/room`,
                            //     isBusinessClass ? startingSelected.price_quad_business : startingSelected.price_quad
                            // )
                            closeDropdown()
                          }}
                          className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                          <div className="flex items-center gap-2 mb-2">
                            <img 
                              src={IconAdditional}
                              />
                            <div className="font-base font-semibold">
                              {additional.name}
                            </div>
                          </div>
                          <div className="green-money font-semibold text-xl">
                            {IDRFormater(additional.price)}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </Scroll>
              </div>

              </Dropdowns>
          </div>
          <div className="bg-gray-5 p-4 rounded-2xl">
            <h3 className="text-sm flex justify-between items-center">
              <span className="font-semibold">Jamaah</span>
              <Buttons
                text="Tambah Jamaah"
                customClass="btn btn-primary btn-small"
                disabled={!allowAddJamaah}
                onClick={handleNewJamaah}
                />
            </h3> 
            
            {additionalFormik.values?.jamaah?.map((jamaah, idx) => {
              return (
                <>
                  <Step3AdditionalJamaah 
                    additionalFormik={additionalFormik}
                    jamaahData={jamaah}
                    orderJamaahList={orderJamaahList}
                    index={idx}
                    additionalIndex={index}
                    handleRemoveJamaah={handleRemoveJamaah}
                    additionalsFormik={additionalsFormik}
                    />
                </>
              )
            })}

          </div>
          
          <div className="mt-4">
            <Buttons 
              text="Simpan"
              customClass="btn btn-primary btn-medium"
              onClick={handleAddAdditional}
              disabled={!(additionalFormik.dirty && additionalFormik.isValid)}
              />
          </div>
        </div>  
      </>
  )
}

export default Step3AdditionalForm