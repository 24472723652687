import React from "react";
import PropTypes from "prop-types";
import { Progress } from "antd";

import ProgressBarsStyle from "./ProgressBars.style";
import { Edit } from "iconsax-react";

const ProgressBars = ({
  containerClass,
  customClass,
  label,
  status,
  percent,
  showInfo,
  info,
  strokeColor,
  isEditIcon = false,
  onClick,
  customInfoClass
}) => {
  return (
    <ProgressBarsStyle className={`${containerClass}`}>
      <div className="flex gap-4 items-center justify-start">
        <div className="progressbar-label">{label}</div>
        {isEditIcon && (
          <div className="bg-white shadow-md p-[4px] rounded-[8px]">
            <Edit
              className="cursor-pointer"
              onClick={onClick}
              size="15"
              color="#141414"
            />
          </div>
        )}
      </div>
      <Progress
        className={`custom-progressbar ${customClass}`}
        status={status}
        percent={percent}
        showInfo={showInfo}
        strokeColor={strokeColor}
      />
      {info !== "" && (
        <div
          className={`progressbar-info ${customInfoClass} ${
            percent === 100 && "progressbar-info--success"
          }`}
        >
          {info}
        </div>
      )}
    </ProgressBarsStyle>
  );
};

ProgressBars.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  label: PropTypes.string,
  status: PropTypes.string,
  percent: PropTypes.number,
  showInfo: PropTypes.bool,
  info: PropTypes.string,
  customInfoClass: PropTypes.string,
};
ProgressBars.defaultProps = {
  containerClass: "",
  customClass: "",
  label: "",
  status: "",
  percent: 0,
  showInfo: false,
  info: "",
  customInfoClass: "",
};

export default React.memo(ProgressBars);
