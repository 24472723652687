// faq Actions
// --------------------------------------------------------

import {
  getAllFAQService,
  saveFAQDataService,
  saveEditFAQDataService,
  deleteIdFAQService,
} from "utils/api/faq";
import {
  clearError,
  handleError,
  handleErrorBE,
  handleSuccess,
} from "../errorGeneral";
import { message } from "antd";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "faq/SET_LOADING";
export const CLEAR_ERROR = "faq/CLEAR_ERROR";
export const SET_ERROR = "faq/SET_ERROR";
export const INIT_DATA = "faq/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "faq/SET_DOUBLE_SUBMIT";
export const SET_DATA_FAQ = "faq/SET_DATA_FAQ";
export const SAVE_DATA_FAQ = "faq/SAVE_DATA_FAQ";
export const CLEAR_POPUP_FAQ = "faq/CLEAR_POPUP_FAQ";
export const SET_DELETE_ID_FAQ = "faq/SET_DELETE_ID_FAQ";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setClosePopUp = (payload) => ({
  type: CLEAR_POPUP_FAQ,
  payload: payload,
});

export const setDataFAQ = (payload, edit) => ({
  type: SET_DATA_FAQ,
  payload,
  edit,
});

export const setSaveDataFAQ = (payload) => ({
  payload,
});

export const setDeleteIdFAQ = (id) => ({
  type: SET_DELETE_ID_FAQ,
  payload: id,
});

export const saveDataFAQ = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(clearError());
      // dispatch(setLoading(true));
      const res = await saveFAQDataService(payload);
      // dispatch(setLoading(false));
      if (res.status === 200) {
        dispatch(setClosePopUp(true));
        dispatch(handleSuccess(null, {message: "Berhasil menyimpan data FAQ", code: 200}));
        dispatch(setDataFAQ(res.data));
        dispatch(getDataFAQ({
          "page_number": 1,
          "per_page": 1000,
          "keyword": "",
          "sort_column": "",
          "sort_order": "ASC",
          "category": payload.category
        }));
        return res.status;
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const getDataFAQ = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getAllFAQService(payload);
    dispatch(setLoading(false));
    const { status, data } = response;
    if (status === 200) {
      dispatch(setDataFAQ(response?.data?.data));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const saveEditDataFAQ = (payload) => async (dispatch) => {
  try {
    dispatch(clearError());
    dispatch(setLoading(true));
    const res = await saveEditFAQDataService(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(handleSuccess(res.data));
      return res.status;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const deleteIdFAQ = (id) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    const res = await deleteIdFAQService(id);
    // dispatch(setLoading(false));

    if (res.status === 200) {
      dispatch(setDeleteIdFAQ(res.data));
      dispatch(handleSuccess(null, {message: "Berhasil menghapus data FAQ", code: 200}));
      return res.status;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};
