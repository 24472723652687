import { getAllDataTransport } from "stores/actions/datamaster";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ListView from "./Partials/list-view";
import Scroll from "components/scroll";
import Form from "./Partials/form";
import Buttons from "components/buttons";

const Transportations = () => {
    const dispatch = useDispatch();
    const [formOpen, setFormOpen] = useState(false);

    const auth = useSelector(({ auth }) => auth)
    const permissions = auth?.user?.permissions;

    useEffect(() => {
        dispatch(
            getAllDataTransport({
                page_number: 1,
                per_page: 1000,
                keyword: "",
                sort_column: "",
                sort_order: "DESC",
            })
        );
    }, []);

    return (
        <>
            <div>
                <Scroll height="calc(100vh - 311px)" customClass="p-4">
                    <ListView />
                    {formOpen && (
                        <>
                            <div className="mt-4">
                                <Form
                                    setIsOpen={setFormOpen}
                                />
                            </div>
                        </>
                    )}
                </Scroll>
                {auth?.user?.access_data === 'all_branches' && permissions?.includes('update_package') && (
                    <div className="pt-5">
                        <Buttons
                            text="Tambah Transportasi"
                            customClass="btn-outline btn-medium !w-full"
                            onClick={() => setFormOpen(true)}
                            disabled={formOpen}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default Transportations;