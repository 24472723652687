
import { useEffect, useState } from "react";
import Choice from "components/Choice"

import HotelLabel from "./HotelLabel"
import { Skeleton } from "antd";
import Popup from "components/popup";
import Inputs from "components/form/inputs";
import Buttons from "components/buttons";

import { Edit } from "iconsax-react"
import IconJamPasir from "assets/icons/Jam-Pasir.svg";

import { useFormik } from "formik";
import * as Yup from "yup";

const HotelData = ({
  value,
  options,
  number_of_nights,
  onChange,
  label,
  loadingGet,
  name
}) => {

  const [showUpdateNumberOfNight, setShowUpdateNumberOfNight] = useState(false)

  const formik = useFormik({
    initialValues: {
      number_of_nights: number_of_nights
    },
    validationSchema: Yup.object().shape({
      number_of_nights: Yup.number().required("Jumlah Malam harus diisi").min(1, "Jumlah Malam minimal 1 Malam"),
    }),
  })

  useEffect(() => {
    formik.setFieldValue("number_of_nights", number_of_nights)
  }, [number_of_nights])

  return (
    <>
    <Popup
        open={showUpdateNumberOfNight}
        title={`Edit Jumlah Malam Hotel ${label}`}
        width="640px"
        handleClose={() => {
          //   setSelectedPaket(null);
          setShowUpdateNumberOfNight(false);
        }}
      >
        <div className="border border-gray-5 rounded-xl mt-[7px]">
          <div className="p-4">
            <Inputs
              label="Jumlah Malam"
              placeholder="Jumlah Malam"
              type="number"
              containerClass="w-full mb-2"
              value={formik.values.number_of_nights}
              onChange={(e) => {
                formik.setFieldValue("number_of_nights", e.target.value)
              }}
            />
          </div>
          <div className="border-t border-gray-5 p-4 flex justify-end">
            <Buttons
              text="Simpan"
              customClass="btn-primary btn-large"
              disabled={!(formik.isValid && formik.dirty)}
              onClick={() => {
                onChange(value, formik.values.number_of_nights)
                setShowUpdateNumberOfNight(false);
              }}
            />
          </div>
        </div>
      </Popup>
      <div>
        <div className="flex items-center gap-2">
          <div className="text-sm flex gap-2 items-center">
            <span className="font-semibold">{label}</span>
            <span className="text-red-3">({number_of_nights} malam)</span>
          </div>
          <div 
            onClick={() => setShowUpdateNumberOfNight(true)}
            className="inline-block p-2 bg-white shadow-hard rounded-lg cursor-pointer">
            <Edit 
              size={16}
              />
          </div>
        </div>
              
        <div className="grid grid-cols-3 mt-4 gap-4">
        {loadingGet ? (
            <>
              {[...Array(6)].map((_, index) => (
                <Skeleton.Input key={index} active={true} size="large" block={true} />
              ))}
            </>
          ) : (
            <>
              {options.map((item, index) => (
                  <>
                    <div className="w-full" key={index}>
                      <Choice 
                        type="radio"
                        name={name?.toLowerCase()}
                        checked={value === item.akomodasi_id}
                        id={`${name?.toLowerCase()}-${index}`}
                        onChange={(e) => onChange(item.akomodasi_id, formik.values.number_of_nights)}
                      >
                        <HotelLabel 
                          thumbnail={item._akomodasi.logo}
                          name={item._akomodasi.name}
                          stars={item._akomodasi.bintang}
                          />
                      </Choice>
                    </div>
                  </>
                ))}
            </>
          )}
        </div>
        {(options.length === 0 && !loadingGet) && (
          <div className="flex flex-col justify-center items-center w-full h-[320px]">
            <img src={IconJamPasir} alt="jam_pasir" width="150px" />
            <div className="text-[#141414] text-[14px] font-normal text-center font-sans mt-2 whitespace-pre-line w-64">
              Di Bulan & Tahun yang Anda tentukan belum ada hotel {label?.toLowerCase()} yang tersedia
            </div>
          </div>
        )}
      </div>
    </>
  )

}

export default HotelData