import Buttons from "components/buttons"
import Textarea from "components/form/textarea"
import React, { useState } from "react"

const ReasonCancelBooking = ({
    handleContinue,
    handleCancel,
    loading,
    onChange
}) => {

    const [reason, setReason] = useState("")

    return (
        <>
            <div className="border p-4 rounded-tr-2xl rounded-tl-2xl">
                <Textarea 
                    value={reason} 
                    onChange={(e) => {
                        setReason(e.target.value)
                        onChange(e.target.value)
                    }} 
                    label="Alasan membatalkan bookingan" 
                    placeholder="Masukan alasan anda" />
                <div className="mt-4">
                    * Bookingan yang sudah dibatalkan tidak dapat dikembalikan
                </div>
            </div>
            <div className="border border-t-0 p-4 rounded-br-2xl rounded-bl-2xl flex justify-end">
                <div></div>
                <div className="flex gap-4">
                     <Buttons
                        customClass="btn-outline btn-medium"
                        text="Kembali"
                        onClick={() => handleCancel()}
                        />
                    <Buttons
                        customClass="btn-primary btn-medium"
                        text="Yakin"
                        disabled={reason === ""}
                        onClick={() => handleContinue()}
                        loading={loading}
                        />
                </div>
            </div>
        </>
    )
}

ReasonCancelBooking.defaultProps = {
    loading: false,
    handleContinue: () => {},
    handleCancel: () => {},
}

export default React.memo(ReasonCancelBooking)