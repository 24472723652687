import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import IconSearch from "assets/icons/search-normal.svg";
import Buttons from "components/buttons";
import Badge from "components/badge";
import fotoProfil from "assets/images/Avatar-ikhwan-1.svg";
import fotoProfilAkhwat from "assets/images/foto-profil-ibadahku.svg";
import JPGIcon from "assets/icons/colored/JPG.svg";
import PDFIcon from "assets/icons/colored/PDF.svg";
import IconClipboardSuccess from "assets/icons/colored/clipboard-tick.svg";
import IconClipboardFailed from "assets/icons/colored/clipboard-close.svg";
import Popup from "components/popup";
import PopupUploadVisa from "./PopupUploadVisa";
import { useDispatch, useSelector } from "react-redux";
import { getEvisaDataJamaah } from "stores/actions/paket/ibadahku";
import useDebounce from "utils/helpers/useDebounce";
import { handleDetectFile, toCapital } from "utils/helpers";
import PesanInfoEvisa from "./PesanInfoEvisa";

import { onHandleSendInfoEvisaService } from "utils/api/paket/evisa";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import PopupViewEvisa from "./PoupViewEvisa";
import Spinner from "components/spinner";
import Pagination from "components/pagination";

import IconJamPasir from "assets/icons/Jam-Pasir.svg";

const Visa = () => {
  const [openPopupAddVisa, setOpenPopupAddVisa] = useState(false);
  const [options, setOptions] = useState([
    {
      id: 1,
      value: "semua-visa",
      label: "Semua Visa",
    },
    {
      id: 2,
      value: "visa-tidak-ditampilkan",
      label: "Visa Tidak Ditampilkan",
    },
    {
      id: 3,
      value: "visa-ditampilkan",
      label: "Visa Ditampilkan",
    },
  ]);
  const [search, setSearch] = useState("")
  const searchDebounce = useDebounce(search, 1000);
  const [evisaSelected, setEvisaSelected] = useState(null);
  const auth = useSelector((state) => state.auth);

  const [openPopupMessageInfo, setOpenPopupMessageInfo] = useState(false);
  const [openViewEvisa, setOpenViewEvisa] = useState(false);

  const [sending, setSending] = useState(false);
  const [sendingID, setSendingID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalData, setTotalData] = useState(0);

  const { dataEvisaJamaah, isLoadingEvisa } = useSelector(
    (state) => state.ibadahku
  );
  const dispatch = useDispatch();

  let { id } = useParams();

  const handleGetEVisa = async () => {
    const payload = {
      "keyword" : searchDebounce,
      "per_page" : size,
      "page_number" : currentpage
    }
    setIsLoading(true)
    await dispatch(getEvisaDataJamaah(id, payload, (data) => {
      setTotalData(data.total_data)
    }));
    setIsLoading(false)
  }

  const handleSendInfoEvisa = async (jamaahOrderID) => {
    setSendingID(jamaahOrderID)
    setSending(true)
    const payload = {
      order_kamar_mapping_id: jamaahOrderID
    }
    const response = await onHandleSendInfoEvisaService(id, payload);
    setSending(false)

    if (response.status === 200) {
      dispatch(handleSuccess(response))
      handleGetEVisa()
    } else {
      dispatch(handleError(response.data))
      handleGetEVisa()
    }

  }


  useEffect(() => {
    handleGetEVisa()
  }, [currentpage, size]);

  useEffect(() => {
    setCurrentpage(1);
    handleGetEVisa()
  }, [searchDebounce]);


  useEffect(() => {
    if (dataEvisaJamaah.length) {
      setEvisaSelected(dataEvisaJamaah[0])
    }
  }, [isLoading]);


  return (
    <>
      {auth.user.access_data === "all_branches" && (
        <Popup
          open={openPopupAddVisa}
          handleClose={() => {
            setOpenPopupAddVisa(false);
          }}
          title="Upload E-Visa"
          containerClass=""
          children={
          <PopupUploadVisa 
            jamaahEvisa={evisaSelected}
            onUploaded={() => {
              handleGetEVisa()
              setOpenPopupAddVisa(false);
            }}
          />}
        />
      )}

      <Popup
        open={openViewEvisa}
        handleClose={() => {
          setOpenViewEvisa(false);
        }}
        title="View E-Visa"
        containerClass=""
        children={
        <PopupViewEvisa 
          jamaahEvisa={evisaSelected}
          onRemoved={() => {
            handleGetEVisa()
            setOpenViewEvisa(false)
          }}
        />}
      />

      {auth.user.access_data === "all_branches" && (
        <Popup
          open={openPopupMessageInfo}
          handleClose={() => {
            setOpenPopupMessageInfo(false);
          }}
          title="Pesan Info E-Visa"
          containerClass=""
          children={
            <PesanInfoEvisa />
          }
        />
      )}

      <div className="p-4">
        <div className="flex flex-row justify-between items-center p-[16px] shadow-soft rounded-2xl">
          <div className="flex flex-row items-center gap-[20px]">
            <h2 className="font-semibold font-base">Daftar Jamaah</h2>
          </div>
          <div>
            <Inputs
              id="test"
              placeholder="Search"
              customClass="!w-[240px]"
              iconPosition="left"
              icon={IconSearch}
              onChange={e => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end my-4">
          {auth.user.access_data === "all_branches" && (
            <Buttons
              text="Edit Pesan Info E-Visa"
              customClass="btn-outline btn-medium"
              onClick={() => setOpenPopupMessageInfo(true)}
            />
          )}
        </div>

        {isLoading ? (
          <div className="w-full h-[264px] flex justify-center items-center">
            <Spinner width="40px" height="40px" />
          </div>
        ) : (
          <>
            
            {dataEvisaJamaah.length ? (
              <>
                {dataEvisaJamaah?.map((item) => {
                  const photo = item?.photo
                    ? item?.photo
                    : item?.title === "Ny"
                    ? fotoProfilAkhwat
                    : fotoProfil;
                  return (
                    <div
                      className="flex flex-col justify-center items-start mb-4 p-[16px] gap-[16px] bg-white shadow-soft rounded-2xl"
                      key={item?.id}
                    >
                      <div className="flex flex-row justify-between items-center gap-[8px] w-full">
                        <div className="flex flex-row items-center gap-[16px]">
                          <div className="flex flex-row items-center gap-[16px]">
                            <img
                              src={photo}
                              alt="foto profil"
                              className="w-[40px] h-[40px] rounded-full"
                            />
                            <h2>{toCapital(item?.title)}. {item?.name}</h2>
                          </div>
                          <div className="flex flex-row items-start gap-[8px]">
                            <Badge
                              label={item?.branch.toUpperCase()}
                              color={item?.branch_color_text}
                              backgroundColor={item?.branch_color_bg}
                            />
                            <Badge
                              label={item?.starting_name}
                              color={item?.starting_color_text}
                              backgroundColor={item?.starting_color_bg}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row items-start gap-[20px]">
                          {!item._evisa.length && (
                            <>
                              <div className="flex flex-row items-center py-[4px] px-[11px] gap-[8px] border border-red-600 rounded-2xl bg-red-200 h-[32px]">
                                <p className="red-linear-text text-xs">E-Visa</p>
                                <img src={IconClipboardFailed} alt="icon success" />
                              </div>
                            </>
                          )}
                          {!!item._evisa.length && (
                            <>
                              {item._evisa.map(evisa => {
                                return (
                                  <>
                                    {handleDetectFile(evisa.file) === 'pdf' ? (
                                      <img src={PDFIcon} />
                                    ) : (
                                      <img src={JPGIcon} />
                                    )}
                                  </>
                                )
                              })}
                              <div 
                                style={{backgroundColor: `rgba(37, 211, 102, 0.20)`}}
                                className="flex flex-row items-center py-[4px] px-[11px] gap-[8px] border border-green-2 rounded-2xl  h-[32px]">
                                <p className="green-linear-text text-xs">E-Visa</p>
                                <img src={IconClipboardSuccess} alt="icon success" />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row justify-between items-center gap-[16px] w-full">
                        <div className="flex flex-row items-center gap-[16px]">
                          {auth.user.access_data === 'all_branches' && (
                            <Buttons
                              text="Kirim Info E-Visa"
                              loading={sending && (item.id === sendingID)}
                              onClick={() => handleSendInfoEvisa(item.id)}
                              customClass="btn-outline btn-small"
                              disabled={!item._evisa.length}
                              />
                          )}
                          <div>
                            <Badge
                              label={
                                item?.send_evisa === 1
                                  ? "Evisa Sudah Dikirim"
                                  : item?.send_evisa === 0
                                  ? "Evisa Belum Dikirim"
                                  : "Evisa Gagal Dikirim"
                              }
                              color={
                                item?.send_evisa === 1
                                  ? "#71BE0E"
                                  : item?.send_evisa === 0
                                  ? "#FAAB36"
                                  : "#E82320"
                              }
                              backgroundColor={
                                item?.send_evisa === 0
                                  ? "#FEEED7"
                                  : "rgba(0, 183, 40, 0.04)"
                              }
                              customClass="!font-semibold"
                            />
                          </div>
                        </div>
                        <div className="flex gap-2 ">
                          {auth.user.access_data === 'all_branches' && (
                            <Buttons
                              text="Upload E-Visa"
                              customClass="btn-secondary btn-medium"
                              onClick={() => {
                                setOpenPopupAddVisa(true)
                                setEvisaSelected(item);
                              }}
                            />
                          )}
                          <Buttons
                            text="Lihat E-Visa"
                            customClass="btn-secondary btn-medium"
                            onClick={() => {
                              setOpenViewEvisa(true)
                              setEvisaSelected(item);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="flex flex-row justify-center items-center w-full h-full bg-white py-[10%]">
                <div className="flex flex-col h-full">
                  <img src={IconJamPasir} alt="jam_pasir" />
                  <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
                    Belum ada data Jamaah
                  </div>
                </div>
                </div>
              </>
            )}
            
            <Pagination 
              defaultCurrent={currentpage}
              pageSize={size}
              total={totalData}
              onChange={(page) => {
                setCurrentpage(page)
              }}
              onShowSizeChange={(current, pageSize) => {
                setCurrentpage(current)
                setSize(pageSize)
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Visa;
