import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import MoslemeTable from "../../../components/Table";
import Buttons from "../../../components/buttons";
import Cards from "../../../components/cards";
import Inputs from "../../../components/form/inputs";

import { useDispatch, useSelector } from "react-redux";
import { detailById, globalState } from "stores/actions/general";
import IconAddRoleWhite from "../../../assets/icons/Add-Role-White.svg";
import IconActivity from "../../../assets/icons/activity.svg";
import IconDownload from "../../../assets/icons/receive-square.svg";
import IconSearch from "../../../assets/icons/search-normal.svg";
import IconSquare from "../../../assets/icons/send-square.svg";
import { columns } from "./columns";
import { useHistory } from "react-router-dom";
import { pathnameCONFIG } from "configuration/pathConfig";
import { getAllCabang } from "stores/actions/cabang";
import useDebounce from "utils/helpers/useDebounce";

const ListCabang = () => {
  // Selection & type state
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectionType, setSelectionType] = useState("checkbox");
  const auth = useSelector(({auth}) => auth)
  const { global } = useSelector((state) => state.general);
  const { dataAllCabang, isLoading } = useSelector((state) => state.cabang);
  const [size, setSize] = useState(10);
  const [currentpage, setCurrentpage] = useState(1);
  const [searchValue, setSearchValue] = useState('')
  const searchDebounce = useDebounce(searchValue, 1000);


  const total = dataAllCabang?.total_data;

  const permissions = auth?.user?.permissions;

  useEffect(() => {
    dispatch(
      getAllCabang({
        per_page: size,
        page_number: currentpage,
        sort_column: "",
        sort_order: "",
        keyword: searchDebounce,
      })
    );
  }, [currentpage, dispatch, searchDebounce, size]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const allCabang =
    dataAllCabang?.total_data !== 0 &&
    dataAllCabang?.data?.map((item, idx) => {
      return {
        ...item,
        key: idx + 1,
      };
    });

  return (
    <Cards containerClass="!p-0">
      <Grid
        container
        className="py-[9px] px-4 w-full !m-0 border-b border-solid !overflow-hidden"
        style={{ borderColor: "rgb(224, 224, 224)" }}
      >
        <Grid item md={2} className="!p-0">
          <Inputs
            id="test"
            placeholder="Search"
            // customClass=""
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            iconPosition="left"
            icon={IconSearch}
          />
        </Grid>
        <Grid item md={10} className="flex justify-end items-center">
          {permissions?.includes("create_branch") && (
            <Buttons
              text="Tambah Cabang"
              customClass="btn-primary btn-small !w-fit"
              onClick={() => dispatch(detailById({ add: true }))}
            />
          )}
          <img
            className="ml-4 cursor-pointer"
            src={IconActivity}
            alt="activity"
            onClick={() =>
              history.push(pathnameCONFIG.LOG_ACTIVITY_CABANG.DETAIL)
            }
          />
          <img className="ml-4 cursor-pointer" src={IconSquare} alt="square" />
          <img
            className="ml-4 cursor-pointer"
            src={IconDownload}
            alt="square"
          />
        </Grid>
      </Grid>

      <MoslemeTable
        expandable={false}
        columns={columns(dispatch, permissions)}
        data={allCabang}
        withSelection={true}
        selectionType={selectionType}
        pagination={pagination}
        customPaginationClass="pr-4"
        loading={isLoading}
        onPageChange={
          (page) => setCurrentpage(page)
        }
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
      />
    </Cards>
  );
};

export default React.memo(ListCabang);
