import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const PaketCouponAPI = {
  getList: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-coupon`, payload),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-coupon`, payload),
  deleteData: (id) => axios.delete(`${BASE_URL_ENDPOINT}/admin/coupon/${id}`),
  duplicateCoupon: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/coupon/duplicate`, payload),
  getCouponForOrder: (params) =>
    axios.get(`${BASE_URL_ENDPOINT}/admin/coupon-order`, {params})
};

export { PaketCouponAPI };
