import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import Accordions from "../../../../components/accordions";
import Badge from "../../../../components/badge";
import Buttons from "../../../../components/buttons";
import Progressbars from "../../../../components/progressbars";
import Tab from "../../../../components/tab";
import MoslemeTable from "../../../../components/Table";

import AvatarRed from "../../../../assets/images/Avatar-ikhwan-red.svg";
import IconEdit from "../../../../assets/icons/edit.svg";
import IconSMS from "../../../../assets/icons/sms.svg";
import IconWhatsapp from "../../../../assets/icons/whatsapp.svg";
import AdditionalTable from "./AdditionalTable";
import JamaahEquipment from "./JamaahEquipment";
import { toCapital } from "utils/helpers";

import DataJamaah from "./DataJamaah";

import ChecklistRoundeIcon from 'assets/icons/Checklist-Round.svg'
import Popup from "components/popup";
import TautkanAccount from "./TautkanAccount";

const BookingDetailDataJamaah = ({ 
  bookingDataJamaah,
  order
 }) => {

  const [isExpand, setIsExpand] = useState([]);
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  const [openTautkanAccount, setOpenTautkanAccount] = useState(false);
  const [jamaahTautkanAccount, setJamaahTautkanAccount] = useState({})

  useEffect(() => {
    let newIsExpand = isExpand
    bookingDataJamaah.map((jamaah, index) => {
      if(!isExpand[index] || isExpand[index] === undefined) {
        newIsExpand[index] = false
      }
    })

    setIsExpand(newIsExpand)
  }, [bookingDataJamaah])

  return (
    <>
      <Popup
        open={openTautkanAccount}
        handleClose={() => setOpenTautkanAccount(false)}
        title="Tautkan Akun"
        customClass=""
        children={
          <>
            <TautkanAccount 
              jamaahData={jamaahTautkanAccount}
              tertaut={() => setOpenTautkanAccount(false)}
              />
          </>  
        }
      />
      <div className="pt-4 border-t mx-[-16px] px-4">
        {!!bookingDataJamaah.length && bookingDataJamaah.map((jamaah, index) => (
          <>
            <Accordions
              AvatarJamaah={jamaah}
              customClass="!rounded-2xl !mb-4"
              onChange={(e) => setIsExpand({ ...isExpand, [index]: e })}
              
              title={
                <>
                  <div className="flex flex-row gap-3 items-center">
                    <span>
                      {jamaah?.jamaah_title? `${toCapital(jamaah?.jamaah_title)}. ` : ''} {jamaah?.jamaah_name?.toUpperCase()}
                    </span>
                    <div>
                      {jamaah?.user_jamaah_id ? (
                        <>
                          <div className="bg-green-5 p-2 rounded-lg flex gap-2 items-center text-xs font-normal">
                            <div>
                              <img 
                                className="w-4 h-4"
                                src={ChecklistRoundeIcon}
                                />
                            </div>
                            <span>
                              Jamaah Tertaut Akun
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          {order?.payment_status != 4 && (
                            <>
                              {permissions.includes("update_booking-data") && (
                                <>
                                  <Buttons
                                    text="Tautkan Akun"
                                    customClass="btn-outline btn-small btn-primary"
                                    onClick={() => {
                                      setJamaahTautkanAccount(jamaah)
                                      setOpenTautkanAccount(true)
                                    }}
                                    />
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              }
              customTitleClass="font-semibold"
              content={
              <DataJamaah
                  order={order}
                  jamaahData={jamaah}
                  isExpand={isExpand[index]}
                  />
                }
            />
          </>
        ))}
      </div>
    </>
  );
};

export default React.memo(BookingDetailDataJamaah);
