import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import DateRangePicker from "components/datepicker/DateRangePicker";
import MoslemeTable from "components/Table";
import Badge from "components/badge";

import IconBack from "assets/icons/arrow-left-dark-icon.svg";
import IconBNI from "assets/images/bni-bank.png";
import IconBCA from "assets/images/bca-bank.png";
import AvatarRed from "assets/images/Avatar-ikhwan-red.svg";

import { getWithdrawHistoryService } from "utils/api/fee";
import { IDRFormater, formatRekening } from "utils/helpers";
import moment from "moment";

const PenarikanHistory = ({ setHistory }) => {
  const [filterStatus, setFilterStatus] = useState("");
  const dispatch = useDispatch();
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(true);

  const [withdrawFeeRequests, setWithdrawFeeRequests] = useState([]);

  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  const handleGetWithdrawHistories = async () => {
    setIsLoading(true);
    const payload = {
      page: currentpage,
      limit: size,
      recipient: 'agent',
      status: filterStatus,
      start_date: filterStartDate,
      end_date: filterEndDate
    ,
    };
    const response = await getWithdrawHistoryService(payload);
    if (response?.data?.data) {
      setWithdrawFeeRequests(response?.data?.data);
      setTotal(response?.data?.total);
    } else {}
    setIsLoading(false);
  }

  const columns = [
    {
      title: "Akun Bank Pengirim",
      dataIndex: "sender_account",
      render: (sender) => (
        <div className="flex flex-row justify-between items-center gap-5 border border-[#E0E0E0] rounded-lg px-4 py-2">
          <div>
            <div className="text-[12px]">Nama Akun</div>
            <div className="text-[12px] font-semibold">{sender?.account_owner}</div>
          </div>
          <div>
            <div className="text-[12px]">Nomor Rekening</div>
            <div className="text-[12px] font-semibold">{formatRekening(sender?.account_number)}</div>
          </div>
          <img className="h-8 object-cover" src={sender?.bank_logo} alt="bank" />
        </div>
      ),
    },
    {
      title: "Akun Bank Penerima",
      dataIndex: "account_bank_withdraw",
      render: (receiver) => (
        <div className="flex flex-row justify-between items-center gap-5 border border-[#E0E0E0] rounded-lg px-4 py-2">
          <div>
            <div className="text-[12px]">Nama Akun</div>
            <div className="text-[12px] font-semibold">{receiver.account_bank_name}</div>
          </div>
          <div>
            <div className="text-[12px]">Nomor Rekening</div>
            <div className="text-[12px] font-semibold">{formatRekening(receiver.account_bank_number)}</div>
          </div>
          <img className="h-8 object-cover" src={receiver.bank.logo} alt="bank" />
        </div>
      ),
    },
    {
      title: "Tanggal & Waktu",
      dataIndex: "created_at",
      render: (date) => <div>{moment(date).format("dddd, DD MMMM YYYY H:mm")} WIB</div>,
    },
    {
      title: "Jumlah Ditarik",
      dataIndex: "nominal",
      render: (nominal) => <div className="font-semibold">{IDRFormater(nominal)}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record) => (
        <>
          {record === "success" && (
            <Badge label="Berhasil" color="#00B728" backgroundColor="rgba(0, 183, 40, .08)" />
          )}
          {record === "cancel" && (
            <Badge color="#8A1212" backgroundColor={'rgb(138, 18, 18, .08)'} label="Dibatalkan" />
          )}
        </>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      bank_sender: "Abdul Wahid Ngatono",
      bank_receiver: "Abdul Wahid Ngatono",
      date: "21/08/2022 11:27:29",
      total_withdrawal: "10.500.000",
      status: "Gagal",
    },
    {
      key: "2",
      bank_sender: "Abdul Wahid Ngatono",
      bank_receiver: "Abdul Wahid Ngatono",
      date: "21/08/2022 11:27:29",
      total_withdrawal: "10.500.000",
      status: "Gagal",
    },
    {
      key: "3",
      bank_sender: "Abdul Wahid Ngatono",
      bank_receiver: "Abdul Wahid Ngatono",
      date: "21/08/2022 11:27:29",
      total_withdrawal: "10.500.000",
      status: "Gagal",
    },
  ];

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };
  // manipulated data with action
  const sourceData = data;

  const onDatePickerChange = (val) => {
    // console.log(val);
    let _tempStartDate = val[0];
    let _tempEndDate = val[1];

    setFilterStartDate(_tempStartDate);
    setFilterEndDate(_tempEndDate);
  };

  useEffect(() => {
    handleGetWithdrawHistories();
  }, [dispatch, currentpage, size, filterStatus, filterStartDate, filterEndDate]);

  return (
    <>
      <div className="flex flex-row justify-between items-center py-3">
        <div className="flex flex-row items-center gap-2">
          <div className="flex flex-row gap-4">
            <img
              className="w-[24px] h-[24px] cursor-pointer"
              src={IconBack}
              alt="back"
              onClick={() => setHistory(false)}
            />
            <div className="text-[16px] font-semibold">
              History Penarikan Agen
            </div>
          </div>

          {/* <div className="flex flex-row items-center gap-2 border border-[#E0E0E0] rounded-lg p-2">
            <img src={AvatarRed} alt="avatar" />
            <div>Dimas Andreyan Prana Putra</div>
          </div> */}
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex flex-row gap-1 border border-[#E0E0E0] rounded-[20px] px-3 py-1">
            <div
              className={`border border-[#E0E0E0] px-2 py-1 rounded-xl cursor-pointer ${
                filterStatus === "" && "border-[#8a1212]"
              }`}
              onClick={() => setFilterStatus("")}
            >
              <div className={`${filterStatus === "" && "txt-maroon"}`}>
                All
              </div>
            </div>
            <div
              className={`border border-[#E0E0E0] px-2 py-1 rounded-xl cursor-pointer ${
                filterStatus === "success" && "border-[#8a1212]"
              }`}
              onClick={() => setFilterStatus("success")}
            >
              <div className={`${filterStatus === "success" && "txt-maroon"}`}>
                Berhasil
              </div>
            </div>
            <div
              className={`border border-[#E0E0E0] px-2 py-1 rounded-xl cursor-pointer ${
                filterStatus === "cancel" && "border-[#8a1212]"
              }`}
              onClick={() => setFilterStatus("cancel")}
            >
              <div className={`${filterStatus === "cancel" && "txt-maroon"}`}>
                Dibatalkan
              </div>
            </div>
          </div>
          <DateRangePicker
            customClass="!px-4 !py-2"
            placeholder="Tanggal Awal"
            placeholder2={"Tanggal Akhir"}
            onCalendarChange={onDatePickerChange}
          />
        </div>
      </div>
      <div className="mt-4 mx-[-1rem]">
        <MoslemeTable
          loading={isLoading}
          columns={columns}
          data={withdrawFeeRequests}
          withSelection={withSelection}
          selectionType={selectionType}
          pagination={pagination}
          customClass="min-h-[600px]"
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      </div>
    </>
  );
};

export default React.memo(PenarikanHistory);
