import React, { useState, useCallback } from "react";
import Inputs from "components/form/inputs";
import IconSearch from "assets/icons/search-normal.svg";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Buttons from "components/buttons";
import Activity from "assets/icons/activity.svg";
import SendSquare from "assets/icons/send-square.svg";
import Filter from "assets/icons/filter.svg";
import Checkbox from "components/checkbox/index";
import Dropdowns from "components/dropdowns";
import AddPaketPopup from "../AddPaketPopup";
import "./Header.scss";
import Popup from "components/popup";
import CheckPackagePopup from "../CheckPackagePopup";

const useStyle = makeStyles({
  datepickerContainer: {
    "& .ant-picker-input": {
      width: "154px !important",
      "& .ant-picker-suffix": {
        width: "154px !important",
      },
    },
  },
});

const Header = ({
  onSearch,
  onStatus,
  onStatusKeberangkatan,
  status,
  status_keberangkatan,
  onSuccessAdd
}) => {
  const classes = useStyle();
  const router = useHistory();

  const [indeterminate, setIndeterminate] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  // tgl
  const [tanggal, setTanggal] = useState(null);

  const handleClosePopup = useCallback(() => {
    setOpenPopup(false);
  }, []);

  return (
    <>
      <div className="flex flex-row justify-between">
        <div>
          <Inputs
            id="test"
            placeholder="Search"
            customClass="!w-[240px]"
            iconPosition="left"
            icon={IconSearch}
            onChange={onSearch}
          />
        </div>
        <div className="flex flex-row justify-end items-center gap-[16px]">

          <div>
            <Buttons
              text="Cek Paket"
              customClass="btn-primary btn-sm !w-[106px]  mr-[16px] ml-[16px]"
              onClick={() => setOpenPopup(true)}
            />

            {/* <AddPaketPopup
              open={openPopup}
              handleClose={handleClosePopup}
            ></AddPaketPopup> */}

          <Popup
            open={openPopup}
            handleClose={handleClosePopup}
            title="Request Paket"
            height="648px"
            width="1250px"
          >
            <CheckPackagePopup 
              onSuccess={() => {
                setOpenPopup(false);
                onSuccessAdd();
              }}
              />
          </Popup>

          </div>
          {/* <div className="flex flex-row gap-[8xp]">
            <img
              src={Activity}
              alt="activity"
              style={{ marginRight: "16px" }}
            />
            <img
              src={SendSquare}
              alt="activity"
              style={{ marginRight: "16px" }}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Header;
