// admin
export const START_FETCHING_MEMBER_ADMIN =
  "features/Member/Admin/START_FETCHING_MEMBER_ADMIN";
export const ERROR_FETCHING_MEMBER_ADMIN =
  "features/Member/Admin/ERROR_FETCHING_MEMBER_ADMIN";
export const SUCCESS_FETCHING_MEMBER_ADMIN =
  "features/Member/Admin/SUCCESS_FETCHING_MEMBER_ADMIN";
export const SET_PAGE_ADMIN = "features/Member/Admin/SET_PAGE";
export const SET_SIZE_ADMIN = "features/Member/Admin/SET_SIZE";
export const SET_TOTAL_ADMIN = "features/Member/Admin/SET_TOTAL_ADMIN";
export const SET_KEYWORD_ADMIN = "features/Member/Admin/SET_KEYWORD_ADMIN";
export const SET_ID_ROLE = "features/Member/Admin/SET_ID_ROLE";
export const SET_ID_ADMIN = "features/Member/Admin/SET_ID_ADMIN";
export const SET_SAVE_ADMIN_LOADING = "features/Member/Admin/SET_SAVE_ADMIN_LOADING"

// agen
export const START_FETCHING_MEMBER_AGEN =
  "features/Member/Agen/START_FETCHING_MEMBER_AGEN";
export const ERROR_FETCHING_MEMBER_AGEN =
  "features/Member/Agen/ERROR_FETCHING_MEMBER_AGEN";
export const SUCCESS_FETCHING_MEMBER_AGEN =
  "features/Member/Agen/SUCCESS_FETCHING_MEMBER_AGEN";
export const SET_PAGE_AGEN = "features/Member/Agen/SET_PAGE";
export const SET_SIZE_AGEN = "features/Member/Agen/SET_SIZE";
export const SET_TOTAL_AGEN = "features/Member/Agen/SET_TOTAL_AGEN";
export const SET_KEYWORD_AGEN = "features/Member/Agen/SET_KEYWORD_AGEN";
export const SET_ID_ROLE_AGEN = "features/Member/Agen/SET_ID_ROLE_AGEN";
export const SET_ID_AGEN = "features/Member/Agen/SET_ID_AGEN";

// jamaah
export const START_FETCHING_MEMBER_JAMAAH =
  "features/Member/Jamaah/START_FETCHING_MEMBER_JAMAAH";
export const ERROR_FETCHING_MEMBER_JAMAAH =
  "features/Member/Jamaah/ERROR_FETCHING_MEMBER_JAMAAH";
export const SUCCESS_FETCHING_MEMBER_JAMAAH =
  "features/Member/Jamaah/SUCCESS_FETCHING_MEMBER_JAMAAH";
export const SET_PAGE_JAMAAH = "features/Member/Jamaah/SET_PAGE";
export const SET_SIZE_JAMAAH = "features/Member/Jamaah/SET_SIZE";
export const SET_TOTAL_JAMAAH = "features/Member/Jamaah/SET_TOTAL_JAMAAH";
export const SET_KEYWORD_JAMAAH = "features/Member/Jamaah/SET_KEYWORD_JAMAAH";
export const SET_ID_ROLE_JAMAAH = "features/Member/Jamaah/SET_ID_ROLE_JAMAAH";
export const SET_ID_JAMAAH = "features/Member/Jamaah/SET_ID_JAMAAH";