import IconGalery from "assets/icons/icon-gallery.svg";
import IconTestimoni from "assets/icons/Testimoni-Grey.svg";
import IconSlide from "assets/icons/Slide-Grey.svg";
import IconArtikel from "assets/icons/Artikel-Desktop.svg";
import IconGuide from "assets/icons/Muthowwif-Guide.svg";
import IcondDataStaff from "assets/icons/Data-Staff.svg";
import IconDataMitra from "assets/icons/Data-Mitra.svg";

import IconTestimoniActive from 'assets/icons/icon-testimoni-maroon.svg';
import IconGaleryActive from "assets/icons/galery-active.svg";
import IconSlideActive from 'assets/icons/icon-slide-maroon.svg'
import IconArtikelActive from 'assets/icons/icon-article-maroon.svg'
import IconGuideActive from 'assets/icons/icon-guide-marron.svg'
import IcondDataStaffActive from 'assets/icons/icon-staff-maroon.svg'
import IconDataMitraActive from 'assets/icons/icon-mitra-maroon.svg'

export const appSidebarList = [
  {
    title: "Galeri",
    key: "galeri",
    icon: IconGalery,
    iconActive: IconGaleryActive,
    order: 0,
  },
  {
    title: "Testimoni",
    key: "testimoni",
    icon: IconTestimoni,
    iconActive: IconTestimoniActive,
    order: 1,
  },
  {
    title: "Slide",
    key: "slide",
    icon: IconSlide,
    iconActive: IconSlideActive,
    order: 2,
  },
  {
    title: "Artikel",
    key: "artikel",
    icon: IconArtikel,
    iconActive: IconArtikelActive,
    order: 3,
  },
  {
    title: "Pembimbing & Guide",
    key: "guide",
    icon: IconGuide,
    iconActive: IconGuideActive,
    order: 4,
  },
  {
    title: "Data Staff",
    key: "staff",
    icon: IcondDataStaff,
    iconActive: IcondDataStaffActive,
    order: 5,
  },
  {
    title: "Data Mitra",
    key: "mitra",
    icon: IconDataMitra,
    iconActive: IconDataMitraActive,
    order: 3,
  },
];
