import React, { useEffect } from "react";

import Cards from "../../../../components/cards";
import Tab from "../../../../components/tab";
import MobileAdmin from "./components/MobileAdmin";
import MobileCompany from "./components/MobileCompany";
import MobileAgen from "./components/MobileAgen";
import MobileGuide from "./components/MobileGuide";
import MobileJamaah from "./components/MobileJamaah";
import { useDispatch, useSelector } from "react-redux";
import { getAllCabang } from "stores/actions/cabang";

const SettingApps = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ cabang }) => cabang);
  useEffect(() => {
    dispatch(getAllCabang());
  }, [dispatch]);

  return (
    <Cards containerClass="!p-0">
      <div className="flex flex-row justify-between items-center p-4">
        <div className="text-[20px] font-semibold">Aplikasi & Website</div>
      </div>
      <div className="divider"></div>
      <Tab
        containerClass="p-4 mb-4"
        defaultActiveKey="1"
        items={[
          {
            label: "Website Company",
            key: "1",
            children: <MobileCompany store={store} />,
          },
          { label: "Mobile Jamaah", key: "2", children: <MobileJamaah /> },
          { label: "Mobile Agen", key: "3", children: <MobileAgen /> },
          { label: "Mobile Admin", key: "4", children: <MobileAdmin /> },
        ]}
      />
    </Cards>
  );
};

export default React.memo(SettingApps);
