import React, { useEffect, useRef, useState } from "react";
import { Pagination } from "antd";
import { makeStyles } from "@material-ui/core";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllPaket } from "stores/actions/paket/landing";
import useDebounce from "../../../../utils/helpers/useDebounce";

import Header from "./components/Header";
import PackageDetailPopUp from "./components/PackageDetailPopUp";
import PaketCard from "./components/PaketCard";
import Spinner from "components/spinner";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";

const useStyle = makeStyles((theme) => ({
  content: {
    background: "#fff",
    borderRadius: "16px",
    boxShadow:
      "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
    "& .content_header": {
      padding: "9px 16px",
      borderBottom: "1px solid #E0E0E0",
    },
    "& .content_list": {
      display: "grid",
      gap: "10px",
      gridTemplateColumns: "repeat(3,minmax(0,1fr))",
      height: "calc(100vh - 260px)",
      overflowY: "auto",
      overflowX: "hidden",
      justifyContent: "space-between",
      "&::-webkit-scrollbar": {
        width: "4px",
        // height: scrollHorizontal ? "5px" : "100px",
      },
      "&::-webkit-scrollbar-track": {
        width: "4px",
        borderRight: "10px solid #fff",
        borderLeft: "10px solid #fff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#E0E0E0",
        height: "30px",
        width: "4px",
        borderRadius: "16px",
      },
      "&::-webkit-scrollbar-track-piece:start": {
        background: "#fff",
      },
      "&::-webkit-scrollbar-track-piece:end": {
        background: "#fff",
      },
      scrollBehavior: "smooth",
      "& .paket_cards_container": {},

      "& .paket_card": {
        borderRadius: "0px 40px 40px 0px",
        // transition: "transform 0.3s ease-in-out",
        // position: "relative",
        width: '100%',
        margin: "0 auto",
        "&:hover ": {
          // position: "absolute",
          top: "0",
          right: "auto",
          // zIndex: '99',
        },
        // zIndex: '99',
        height: "350px",
        background: "#FBF3F2",
        borderRadius: "40px",
        cursor: "pointer",
        fontSize: "General Sans, sans-serif",
        padding: "51px 12px 12px 12px",
        // position: "relative",
        // zIndex: 1,
        "& .card_content": {
          height: "100%",
          background: "#fff",
          borderRadius: "32px",
          boxShadow:
            "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
          display: "flex",
          padding: "10px 10px 12px 12px",
        },
        "& .card_image_wrapper": {
          borderRadius: "16px",
          boxShadow: "0px 6px 64px -4px rgba(0, 0, 0, 0.04)",
          // filter:
          //   "drop-shadow(0px 10px 16px rgba(0, 0, 0, 0.04)) drop-shadow(0px 6px 32px rgba(0, 0, 0, 0.04))",
          boxShadow: '0px 6px 32px 0px rgba(0, 0, 0, 0.04), 0px 10px 16px 0px rgba(0, 0, 0, 0.04), 0px 6px 64px -4px rgba(0, 0, 0, 0.04)',
          overflow: "hidden",
          position: "relative",
          "& .card_image": {
            height: "100%",
            objectFit: "cover",
            width: "280px !important",
          },
        },
        "& .card_text_wrapper": {
          paddingLeft: "12px",
          width: "100%",
          "& .card_text_title": {
            borderBottom: "1px dashed #BDBDBD",
            borderSpacing: "5px",
            paddingBottom: "8px",
            position: "relative",
            "& h2": {
              fontWeight: 600,
              fontSize: "14px",
              textTransform: "uppercase",
              lineHeight: "20px",
            },
            "& .card_badge": {
              background: "#2eb24333",
              borderRadius: "4px",
              color: "#333D29",
              fontSize: "8px",
              fontWeight: "600",
              padding: "4px 8px",
              position: "absolute",
              bottom: "10px",
              right: "0",
              width: "fit-content",
            },
          },
          "& .card_detail_item": {
            display: "flex",
            gap: "8px",
            alignItems: "center",
            marginTop: "12px",
            fontSize: "12px",
            "& .green_bold_text": {
              background:
                "linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%)",
              fontWeight: 700,
              "-webkit-background-clip": "text",
              "-webkit-text-fill-color": "transparent",
            },
          },
        },
      },
    },
    "& .content_footer": {
      display: "flex",
      justifyContent: "flex-end",
      padding: "14px",
    },
  },

  // Media query for large screens (min-width: 992px) and (max-width: 1199px)
  [theme.breakpoints.between(992, 1199)]: {
    // Your styles for large screens go here
    
  },

  // Media query for extra large screens (min-width: 1200px)
  [theme.breakpoints.up('xl')]: {
    // Your styles for extra large screens go here
  },

  // Media query for screens around 1400px
  [theme.breakpoints.down(1440)]: {
    content: {
      "& .content_list": {
        gridTemplateColumns: "repeat(2,minmax(0,1fr))",
        "& .paket_card": {
          "&:first-child": {
            borderRadius: "0px 0px 40px 0px!important",
          },
          "&:nth-child(2)": {
            borderRadius: "0px 0px 0px 40px!important",
          },
          "&:nth-child(-n+2)": {
            borderRadius: "0px 0px 40px 40px",
          },
          "&:nth-child(2n + 1)": {
            borderRadius: "0px 40px 40px 0px",
          },
          "&:nth-child(2n + 2)": {
            borderRadius: "40px 0px 0px 40px",
          },
        }
      }
    }
  },

  [theme.breakpoints.up(1440)]: {
    content: {
      "& .content_list": {
        "& .paket_card": {
          "&:first-child": {
            borderRadius: "0px 0px 40px 0px!important",
          },
          "&:nth-child(3)": {
            borderRadius: "0px 0px 0px 40px!important",
          },
          "&:nth-child(-n+3)": {
            borderRadius: "0px 0px 40px 40px",
          },
          "&:nth-child(3n + 1)": {
            borderRadius: "0px 40px 40px 0px",
          },
          "&:nth-child(3n + 3)": {
            borderRadius: "40px 0px 0px 40px",
          },
        }
      }
    }
  },
}))

const Umroh = () => {
  const classes = useStyle();
  const { allPaket, isLoading } = useSelector((state) => state.landingPaket);
  const dispatch = useDispatch();
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const [hoveredPaketIndex, setClickedPaketIndex] = useState(null);
  const [positionLeftClickPackage, setPositionLeftClickPackage] = useState(0)

  // search state
  const [searchValue, setSearchValue] = useState("");
  const searchDebounce = useDebounce(searchValue, 1000);
  const [status, setStatus] = useState({
    publish: false,
    draft: false,
  });
  const [status_keberangkatan, setStatusKeberangkatan] = useState({
    sudah_berangkat: false,
    sedang_berangkat: false,
    belum_berangkat: false,
  });

  // pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  const packageDetailRef = useRef(null);

  const handlePackageClick = (event, index) => {

    const hoveredElement = event.target
    const windowWidth = window.innerWidth;
    if (hoveredElement.classList.contains('paket_card')) {
      
      const rect = hoveredElement.getBoundingClientRect();

      let positionLeft = rect.left;
      const positionRight = rect.right;
      const differenceWindowsRigth = windowWidth - positionRight
     
      // 456 is full width of container hover element
      // 228 is half width of container hover element
      if(differenceWindowsRigth > 456) {
        positionLeft += (rect.width) + 228
      } else {
        positionLeft -= 228
      }
      
      
      setPositionLeftClickPackage((positionLeft))
      setClickedPaketIndex(index);
    } else {
      const paketCardParent = findPaketCardParent(hoveredElement);

      if(paketCardParent) {
        const rect = paketCardParent.getBoundingClientRect();
        let positionLeft = rect.left;
        const positionRight = rect.right;
        const differenceWindowsRigth = windowWidth - positionRight

        // 456 is full width of container hover element
        // 228 is half width of container hover element
        if(differenceWindowsRigth > 456) {
          positionLeft += (rect.width) + 228
        } else {
          positionLeft -= 228
        }
        
        setPositionLeftClickPackage((positionLeft))
        setClickedPaketIndex(index);
      }

    }
    
  };

  const findPaketCardParent = (element) => {
    // Mencari parent dengan kelas 'paket_card' secara rekursif
    while (element && !element.classList.contains('paket_card')) {
      element = element.parentNode;
    }
    return element;
  };

  const handlePackagePopupClose = () => {
    // setTimeout(() => {
      setClickedPaketIndex(null);
    // }, 1000000);
  };

  const renderStatus = () => {
    if (status.publish && status.draft) {
      return [1, 0];
    } else if (status.publish) {
      return [1];
    } else if (status.draft) {
      return [0];
    } else {
      return [];
    }
  };

  const handleSizeChange = (current, size) => {
    setCurrentPage(current);
    setPageSize(size);
  }
  

  const renderStatusKebrangkatan = () => {
    if (
      status_keberangkatan.sudah_berangkat &&
      status_keberangkatan.sedang_berangkat &&
      status_keberangkatan.belum_berangkat
    ) {
      return [0, 1, 2];
    } else if (
      status_keberangkatan.sudah_berangkat &&
      status_keberangkatan.sedang_berangkat
    ) {
      return [2, 1];
    } else if (
      status_keberangkatan.sudah_berangkat &&
      status_keberangkatan.belum_berangkat
    ) {
      return [2, 0];
    } else if (
      status_keberangkatan.sedang_berangkat &&
      status_keberangkatan.belum_berangkat
    ) {
      return [1, 0];
    } else if (status_keberangkatan.sudah_berangkat) {
      return [2];
    } else if (status_keberangkatan.sedang_berangkat) {
      return [1];
    } else if (status_keberangkatan.belum_berangkat) {
      return [0];
    } else {
      return [];
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const payload = {
        tipe_paket: 2,
        keyword: searchValue,
        per_page: pageSize,
        page_number: currentPage,
        sort_column: "id_paket",
        sort_order: "DESC",
        status: renderStatus(),
        status_keberangkatan: renderStatusKebrangkatan(),
      };
      dispatch(getAllPaket(payload));
    }, 600000); // 600000 milidetik = 10 menit

    return () => clearInterval(interval); // Membersihkan interval saat komponen di-unmount
  }, []);

  useLayoutEffect(() => {
    const payload = {
      tipe_paket: 2,
      keyword: searchValue,
      per_page: pageSize,
      page_number: currentPage,
      sort_column: "id_paket",
      sort_order: "DESC",
      status: renderStatus(),
      status_keberangkatan: renderStatusKebrangkatan(),
    };
    dispatch(getAllPaket(payload));
  }, [currentPage, searchDebounce, pageSize]);

  const handleStatus = (data, key) => {
    setStatus({ ...status, [key]: data });
  };

  const handleStatusKeberangkatan = (data, key) => {
    setStatusKeberangkatan({ ...status_keberangkatan, [key]: data });
  };

  const handleClickTerapkan = () => {
    setIsLoadingAction(true);
    const payload = {
      tipe_paket: 2,
      keyword: searchValue,
      per_page: pageSize,
      page_number: currentPage,
      sort_column: "id_paket",
      sort_order: "DESC",
      status: renderStatus(),
      status_keberangkatan: renderStatusKebrangkatan(),
    };
    dispatch(
      getAllPaket(payload, (message) => {
        setIsLoadingAction(false);
      })
    );
  };

  const handleOutsideClick = (e) => {
    if (packageDetailRef.current && !packageDetailRef.current.contains(e.target)) {
      handlePackagePopupClose();
    }
  };

  useEffect(() => {
    // console.log(containerSelectedId, containerId)
    // if (containerSelectedId === containerId) {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    // }
  }, []);

  return (
    <>
      <div className={classes.content}>
        <div className="content_header">
          <Header
            onSearch={(e) => setSearchValue(e.target.value)}
            onStatus={handleStatus}
            status={status}
            onStatusKeberangkatan={handleStatusKeberangkatan}
            status_keberangkatan={status_keberangkatan}
            handleClickTerapkan={handleClickTerapkan}
            isLoading={isLoadingAction}
          />
        </div>
        {isLoading && (
          <div className="content_list !flex !justify-center items-center !w-full">
            <Spinner width="56px" height="56px" />
          </div>
         )}
        {!isLoading && (
          <>
            {allPaket?.data?.length ? (
              <>
                <div className="content_list">
                  {allPaket.data &&
                    allPaket.data.length > 0 &&
                    allPaket?.data?.map((item) => (
                      <div
                        className="paket_card"
                        key={item.id_paket}
                        onClick={(e) => handlePackageClick(e, item.id_paket)}
                      >
                        {hoveredPaketIndex === item.id_paket && (
                          <div ref={packageDetailRef} className="!cursor-default">
                            <PackageDetailPopUp positionLeft={positionLeftClickPackage} data={item} />
                          </div>
                        )}
                        <PaketCard data={item} />
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row justify-center items-center w-full h-full bg-white py-[10%]">
                <div className="flex flex-col h-full">
                  <img src={IconJamPasir} alt="jam_pasir" />
                  <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
                    Belum ada Paket Umrah yang tersedia
                  </div>
                </div>
                </div>
              </>
            )}
          </>
        )}
        <div className="content_footer mosleme-pagination">
          <Pagination
            showSizeChanger={true}
            current={currentPage}
            pageSize={pageSize}
            defaultPageSize={12}
            pageSizeOptions={["12", "24", "60", "120"]}
            defaultCurrent={1}
            total={allPaket?.total_data}
            onChange={(e) => setCurrentPage(e)}
            onShowSizeChange={(current, pageSize) => handleSizeChange(current, pageSize)}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(Umroh);
