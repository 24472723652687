const SkeletonView = () => {
  return (
    <div className="space-y-4">
      {[1, 2, 3, 4, 5].map((item) => (
        <div
          key={item}
          className="flex items-start border border-gray-4 rounded-2xl p-4 justify-between animate-pulse"
        >
          <div className="flex items-start gap-6">
            {/* Image skeleton */}
            <div className="w-[88px] h-[88px] bg-gray-200 rounded-2xl" />
            
            <div className="flex-1">
              {/* Hotel name skeleton */}
              <div className="h-6 bg-gray-200 rounded-md w-48" />
              
              {/* Location and rating skeleton */}
              <div className="flex items-center gap-3 mt-[10px]">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-gray-200 rounded-full" />
                  <div className="h-4 bg-gray-200 rounded w-24" />
                </div>
                <div className="w-[2px] h-[12px] bg-gray-200" />
                <div className="flex gap-1">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <div key={star} className="w-4 h-4 bg-gray-200 rounded" />
                  ))}
                </div>
              </div>

              {/* Check-in/Check-out skeleton */}
              <div className="mt-4 flex items-center gap-4">
                {/* Check In */}
                <div className="flex flex-col gap-2">
                  <div className="h-5 bg-gray-200 rounded w-16" />
                  <div className="flex items-center gap-2 mt-[10px]">
                    <div className="w-6 h-6 bg-gray-200 rounded" />
                    <div className="h-4 bg-gray-200 rounded w-32" />
                    <div className="w-[2px] h-[12px] bg-gray-200" />
                    <div className="h-4 bg-gray-200 rounded w-12" />
                  </div>
                </div>

                {/* Check Out */}
                <div className="flex flex-col gap-2">
                  <div className="h-5 bg-gray-200 rounded w-16" />
                  <div className="flex items-center gap-2 mt-[10px]">
                    <div className="w-6 h-6 bg-gray-200 rounded" />
                    <div className="h-4 bg-gray-200 rounded w-32" />
                    <div className="w-[2px] h-[12px] bg-gray-200" />
                    <div className="h-4 bg-gray-200 rounded w-12" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Action buttons skeleton */}
          <div className="flex gap-4">
            <div className="w-[22px] h-[22px] bg-gray-200 rounded" />
            <div className="w-[22px] h-[22px] bg-gray-200 rounded" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonView;
