import React, { useCallback, useEffect, useState } from "react";

import MoslemeTable from "components/Table";
import Buttons from "components/buttons";
import Popup from "components/popup";
import AppsAddGuide from "./components/AddGuide";
import Close from "assets/icons/Close.svg";
import AlertPopup from "components/popup/alert";
import { useDispatch, useSelector } from "react-redux";
import GuideAction from "stores/actions/apps/guide";
import {
  detailById,
  globalState,
  setValidation,
  set_after_validation,
} from "stores/actions/general";
import columns from "./columns";
import Inputs from "components/form/inputs";
import CheckboxMaster from "pages/master/components/checkbox";
import { resetDataUpload } from "stores/actions/uploadFile";

const AppsGuide = () => {
  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  const { byDetail, afterValidation, validation, global } = useSelector(
    (state) => state?.general
  );
  const { listGuide, isLoading } = useSelector((state) => state?.guide);
  const { dataUpload } = useSelector((state) => state.uploadFile);

  // Pagination state
  const [input, setInput] = useState({
    name: "",
    type: true,
    image_photo: true,
    description: true,
  });

  useEffect(() => {
    byDetail?.data &&
      setInput({
        name: `clone - ${byDetail?.data?.name}`,
        type: true,
        image_photo: true,
        description: true,
      });
  }, [byDetail?.data, byDetail?.data?.name]);

  const total = listGuide?.total_data;

  const getAllGuide = useCallback(
    (per_page, page_number) => {
      dispatch(
        GuideAction.list({
          page_number: global?.currentpage,
          per_page: global?.size,
          sort_column: "id_mentor",
          sort_order: "Desc",
          keyword: "",
        })
      );
    },
    [dispatch, global?.currentpage, global?.size]
  );

  useEffect(() => {
    getAllGuide();
  }, [getAllGuide]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: global?.currentpage,
    pageSize: global?.size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  const confirmDeleteArtikel = async () => {
    dispatch(GuideAction.delete(byDetail?.data?.id_mentor)).then(() => {
      dispatch(detailById({}));
      getAllGuide(10, 1);
    });
  };

  const onHandleDuplicate = () => {
    input.id = byDetail?.data?.id_mentor;
    input.type = input.type ? 1 : 0;
    input.image_photo = input.image_photo ? 1 : 0;
    input.description = input.description ? 1 : 0;
    // console.log("delete dplikat", byDetail?.data);
    dispatch(GuideAction.duplicate(input));
  };

  useEffect(() => {
    if (afterValidation?.code === 200) {
      dispatch(detailById({}));
      getAllGuide();
      dispatch(setValidation({}));
      dispatch(set_after_validation({}));
    }
  }, [afterValidation?.code, dispatch, getAllGuide]);

  return (
    <>
      <Popup
        open={byDetail.addOrUpdate}
        handleClose={() => {
          dispatch(detailById({}));
          dispatch(resetDataUpload({}));
        }}
        title={
          byDetail?.type === "update"
            ? "Update Pembimbing"
            : "Tambah Pembimbing"
        }
        width="982px"
        children={<AppsAddGuide />}
      />

      <Popup
        open={byDetail?.duplicate}
        handleClose={() => {
          dispatch(detailById({}));
          dispatch(set_after_validation({}));
          dispatch(setValidation({}));
        }}
        title="Duplicate Pembimbing"
      >
        <Inputs
          id="test"
          placeholder="Text Input"
          value={input.name}
          onChange={(e) => setInput({ ...input, name: e.target.value })}
          error={validation?.data?.name && validation?.data?.name[0]}
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Tipe"
          value={input.type}
          checked={input.type}
          onChange={(e) =>
            setInput({
              ...input,
              type: e.target.checked,
            })
          }
        />
        {validation?.data?.type && (
          <div className="flex flex-row items-center gap-3">
            <img src={Close} alt="close" />
            <div className="input-error-msg">{validation?.data?.type}</div>
          </div>
        )}
        <CheckboxMaster
          customClass="mt-4"
          title="Gambar Pembimbing"
          value={input.image_photo}
          checked={input.image_photo}
          onChange={(e) =>
            setInput({
              ...input,
              image_photo: e.target.checked,
            })
          }
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Description"
          value={input.description}
          checked={input.description}
          onChange={(e) =>
            setInput({
              ...input,
              description: e.target.checked,
            })
          }
        />
        <div className="grid grid-cols-2 mt-4 items-center gap-x-4">
          <Buttons
            onClick={() => dispatch(detailById({}))}
            text="Batal"
            customClass="btn-secondary btn-large"
          />

          <Buttons
            onClick={onHandleDuplicate}
            text="Simpan"
            loading={isLoading ? true : false}
            disabled={isLoading ? true : false}
            customClass="btn-primary btn-large"
          />
        </div>
      </Popup>
      <AlertPopup
        open={byDetail.delete}
        subtitle={`Apakah anda yakin untuk menghapus Pembimbing ${byDetail?.data?.name}?`}
        handleClose={() => dispatch(detailById({}))}
        handleContinue={confirmDeleteArtikel}
        loading={isLoading}
      />
      <div className="bg-white soft-shadow rounded-[20px]">
        <div className="flex flex-row justify-between p-4">
          <div className="text-[20px] font-semibold">Pembimbing</div>
          <div className="flex flex-row gap-4">
            {permissions?.includes("create_apps") && (
              <Buttons
                text="Tambah"
                customClass="btn-primary btn-small"
                onClick={() => dispatch(detailById({ addOrUpdate: true }))}
              />
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <MoslemeTable
            customClass="mt-4"
            loading={isLoading}
            columns={columns(dispatch, dataUpload, permissions)}
            data={listGuide?.data}
            withSelection={true}
            selectionType={"checkbox"}
            pagination={pagination}
            onPageChange={(page) =>
              dispatch(globalState({ currentpage: page, size: global?.size }))
            }
            onSizeChange={(currentpage, pageSize) =>
              dispatch(
                globalState({ currentpage: currentpage, size: pageSize })
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(AppsGuide);
