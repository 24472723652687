import Inputs from "components/form/inputs";
import Popup from "components/popup";
import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import { createWaEmailApi, updateWebsiteService } from "utils/api/cabang";
import EditorToolbar, {
  modules,
  formats,
} from "components/richTextEditor/EditorToolbar";
import Buttons from "components/buttons";
import { useDispatch } from "react-redux";
import { getAllCabang } from "stores/actions/cabang";
import { convertBase64 } from "utils/helpers/convertBase64";
import { useFormik } from "formik";

import * as Yup from "yup";
import { handleError, handleSuccess } from "stores/actions/errorGeneral";

function EditDescription({ show, close, id, data_detail }) {
  const dispatch = useDispatch();
  const [detail, setDetail] = React.useState(null);
  const [status, setStatus] = React.useState("idle");

  const onClose = () => {
    close();
    setDetail(null);
  };

  const formik = useFormik({
    initialValues: {
      branch_id: null,
      website_url: null,
      website_title: null,
      description: null,
    },
    validationSchema: Yup.object({
      branch_id: Yup.number().required("Cabang wajib diisi"),
    })
  });

  const handleSave = async () => {
    setStatus("submitting");
    const res = await updateWebsiteService(formik.values)

    if (res.status === 200) {
      setStatus("success");
      dispatch(getAllCabang());
      dispatch(handleSuccess(null, {
        message: "Berhasil mengupdate website",
        code: 200
      }));
      onClose();
    } else {
      setStatus("error");
      dispatch(handleError(res));
    }
  }

  useEffect(() => {
    setDetail(data_detail);
    formik.setValues({
      website_url: data_detail?.url_website,
      website_title: data_detail?.website_title,
      description: data_detail?.description,
      branch_id: data_detail?.id_cabang
    });
  }, [data_detail]);
  
  return (
    <Popup
      width="982px"
      open={show}
      handleClose={onClose}
      title="Edit Deskripsi"
    >
      <div className="grid grid-cols-2 gap-4 border p-4 rounded-tl-2xl rounded-tr-2xl">
        <Inputs
          id="website_title"
          placeholder="Masukan Judul Website"
          label="Judul Website"
          name="website_title"
          value={formik.values.website_title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Inputs
          id="url_website"
          label="Domain Website"
          placeholder="Masukan url website"
          name="website_url"
          value={formik.values.website_url}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      <div className="py-3 flex justify-end border border-t-0 rounded-bl-2xl rounded-br-2xl px-3">
        <Buttons
          loading={status === "submitting"}
          onClick={handleSave}
          text="Simpan"
          customClass="btn-primary btn-medium !w-fit"
        />
      </div>
    </Popup>
  );
}

export default EditDescription;
