import styled from "styled-components";

const ProgressBarsStyle = styled.div`
  position: relative;

  .progressbar-label {
    font-size: 14px;
    font-weight: 600;
    color: #4f4f4f;
    // min-height: 25px;
  }

  .custom-progressbar {
    .ant-progress-outer {
      .ant-progress-inner {
        background: #e0e0e0;
      }
    }

    .ant-progress-bg {
      background: linear-gradient(180deg, #EA3634 0%, #94110F 100%);
    }

    .progressbar-blue .ant-progress-outer .ant-progress-inner {
      .ant-progress .ant-progress-bg {
        background: #1677ff;
        box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.34),
          0px 6px 8px -2px rgba(0, 0, 0, 0.12),
          0px 2px 16px -2px rgba(0, 0, 0, 0.06);
      }
    }

    .ant-progress-outer .ant-progress-inner .ant-progress .ant-progress-bg {
      background: #e82320;
      box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.34),
        0px 6px 8px -2px rgba(0, 0, 0, 0.12),
        0px 2px 16px -2px rgba(0, 0, 0, 0.06);
    }
  }

  .ant-progress.ant-progress-status-success .ant-progress-bg {
    background: #71be0e;
    box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.34),
      0px 6px 8px -2px rgba(0, 0, 0, 0.12),
      0px 2px 16px -2px rgba(0, 0, 0, 0.06);
  }

  .progressbar-info {
    position: absolute;
    top: 1px;
    right: 0;
    font-size: 16px;
    font-weight: 600;
    color: #101010;
  }

  .progressbar-info--success {
    color: #71be0e;
  }
`;

export default ProgressBarsStyle;
