import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Buttons from "components/buttons";
import MoslemeTable from "components/Table";
import Badge from "components/badge";

import { useDispatch, useSelector } from "react-redux";

import TaskIcon from "assets/icons/task-table.svg";
import IconCSV from "assets/icons/CSV.svg";
import AvatarRed from "assets/images/Avatar-ikhwan-red.svg";
import IconSingle from "assets/icons/Single.svg";
import IconDouble from "assets/icons/Double.svg";
import IconChecklist from "assets/icons/Checklist-Round.svg";
import IconCloseRed from "assets/icons/icon-close-maroon.svg";
import IconSearch from "assets/icons/search-normal.svg";

import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import {
  exportLaporanManasikAbsensi,
  getLaporanManasikAbsensi,
  getLaporanManasikLokasi,
} from "stores/actions/paket/laporan";
import { formattorbase64, toCapital } from "utils/helpers";
import RoomBadge from "pages/paket/detail/pages/Roomlist/components/RoomBadge";
import Dropdowns from "components/dropdowns";

import { handleError } from "stores/actions/errorGeneral";

import { onHandleInputAbsensiAPI } from "utils/api/paket/laporan";
import Spinner from "components/spinner";
import Avatar from "components/avatar";
import Inputs from "components/form/inputs";
import useDebounce from "utils/helpers/useDebounce";
import DropdownCustom from "components/DropdownCustom";


const Absensi = () => {
  const [activeFilter, setActiveFilter] = useState({
    branch_name: null,
    branch_id: null,
  });
  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  
  const [isExpand, setIsExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  const { dataLaporanManasikAbsensi, dataLaporanManasikLokasi, isLoading } =
    useSelector((state) => state.laporan);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [abcencing, setAbcencing] = useState(false);
  const [manasikSelected, setManasikSelected] = useState(null);

  const [keyword, setKeyword] = useState('')
  const keywordDebounce = useDebounce(keyword, 800)

  const dispatch = useDispatch();

  let { id } = useParams();

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  useEffect(() => {
    const payload = {
      keyword: keyword,
      sort_column: "created_at",
      sort_order: "ASC",
      per_page: size,
      page_number: currentpage,
      branch_id: activeFilter.branch_id ? [activeFilter.branch_id] : [],
    };
    dispatch(getLaporanManasikAbsensi(id, payload, (data) => {
      setTotal(data.total_data)
    }));
  }, [activeFilter.branch_id, currentpage, dispatch, id, size, keywordDebounce]);

  useEffect(() => {
    dispatch(getLaporanManasikLokasi(id));
  }, []);
  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };
  // manipulated data with action

  const getFilterCabang = () => {
    return dataLaporanManasikLokasi.map((val, idx) => {
      return (
        <div
          key={idx}
          className={`border-[1px] border-[#4048521a] rounded-lg px-4 py-1 cursor-pointer uppercase font-semibold ${
            activeFilter.branch_name === val.branch_name && "bg-[#F3CFCE]"
          }`}
          onClick={() =>
            setActiveFilter({
              branch_name: val.branch_name,
              branch_id: val.branch_id,
            })
          }
        >
          <div
            className={`${activeFilter.branch_name === val && "txt-maroon"}`}
          >
            {val.branch_name ? val.branch_name : "All"}
          </div>
        </div>
      );
    });
  };

  const handleDownloadCSV = () => {
    dispatch(
      exportLaporanManasikAbsensi(id, (message) => {
        const { base64, type, file_name } = message;
        const insertPrefix = formattorbase64(type, base64);
        var a = document.createElement("a"); //Create <a>
        a.href = `${insertPrefix}`; //Image Base64 Goes here
        a.download = `${file_name}`; //File name Here
        a.click();
      })
    );
  };

  const handleInputAbsensi = async (jamaahOrderID, manasikID, isePresent) => {
    setAbcencing(true)
    setManasikSelected(manasikID)
    const payload = {
      "manasik_id": manasikID,
      "order_kamar_mapping_id": jamaahOrderID,
      "is_present": isePresent,
    }
    const response = await onHandleInputAbsensiAPI(id, payload)
    setAbcencing(false)

    if (response.status === 200) {
      dispatch(getLaporanManasikAbsensi(id, {
        keyword: keyword,
        sort_column: "jamaah_name",
        sort_order: "asc",
        per_page: size,
        page_number: currentpage,
        branch_id:  activeFilter.branch_id ? [activeFilter.branch_id] : [],
      }, (data) => {
        setTotal(data.total_data)
      }))
    } else {
      dispatch(handleError(response.data))
    }

  }

  const columns = [
    {
      title: "Foto",
      dataIndex: "jamaah_photo",
      render: (jamaah_photo, data) => {
        return <Avatar value={data} />;
      },
    },
    {
      title: "Nama",
      dataIndex: "jamaah_name",
      render: (name, data) => {
        return <div>{`${toCapital(data?.jamaah_title)}. ${name.toUpperCase()}`}</div>;
      },
    },
    {
      title: "Jenis Kelamin",
      dataIndex: "jamaah_gender",
    },
    {
      title: "Nomor HP",
      dataIndex: "jamaah_phone",
    },
    {
      title: "Starting",
      dataIndex: "starting_name",
      render: (starting, data) => {
        return (
          <Badge
            label={starting}
            backgroundColor={data?.starting_bg_color}
            color={data?.starting_text_color}
          />
        );
      },
    },
    {
      title: "Cabang",
      dataIndex: "branch_name",
      render: (branch, data) => (
        <div>
          <Badge
            label={branch.toUpperCase()}
            backgroundColor={data?.branch_bg_color}
            color={data?.branch_text_color}
          />
        </div>
      ),
    },
    {
      title: "Tempat Manasik ",
      dataIndex: "manasik_branch",
      render: (data, manasik) => (
        <>
          <div className="flex gap-2 items-center">
            <Badge
              label={data?.name}
              backgroundColor={data?.color}
              color={data?.color_text}
            />
          </div>
        </>
      ),
    },
    {
      title: "Absensi Manasik",
      dataIndex: "manasik",
      render: (manasik, data) => (
        <div className={`
            inline-flex gap-2 border rounded-lg py-[6px] px-2 !text-xs
            ${manasik.total_manasik_present && '!border-green-3 !bg-green-5'}
            `
          }>
          <img src={TaskIcon} />
          <span className="">Absensi {manasik.total_manasik_present}/{manasik.total_manasik}</span>
        </div>
      ),
    },
  ];
  

  return (
    <div className="">
      {auth.user.access_data === 'all_branches' && (
        <>
          <div className="p-4 border-[1px] border-[#4048521a] rounded-2xl m-4">
            <div className="font-medium">Filter Cabang</div>
            <div className="flex flex-row flex-wrap gap-4 mt-4">
              <div
                className={`border-[1px] border-[#4048521a] rounded-lg px-4 py-1 cursor-pointer uppercase font-semibold ${
                  activeFilter.branch_name === null && "bg-[#F3CFCE]"
                }`}
                onClick={() => {
                  setActiveFilter({
                    branch_name: null,
                    branch_id: null,
                  });
                }}
              >
                <div className={`${activeFilter.branch_name === null && "txt-maroon"}`}>
                  All
                </div>
              </div>
              {getFilterCabang()}
            </div>
          </div>
        </>
      )}
      <div className="flex flex-row justify-between items-center mt-4 border-t border-b p-4">
        <div className="font-semibold text-[16px]">
          Daftar Jamaah
        </div>
        <div className="flex flex-row gap-4 items-center">
          <Inputs
            containerClass=""
            label=""
            placeholder="Pencarian"
            customClass="!w-[400px]"
            iconPosition="left"
            icon={IconSearch}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          {/* <Buttons
            text="Export CSV"
            customClass="btn-outline btn-small !py-3"
            icon={IconCSV}
            onClick={handleDownloadCSV}
          /> */}
        </div>
      </div>
      <div className="">
        <MoslemeTable
          loading={isLoading}
          expandable={isExpand && expandable}
          columns={columns}
          data={dataLaporanManasikAbsensi}
          withSelection={withSelection}
          selectionType={selectionType}
          pagination={pagination}
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      </div>
    </div>
  );
};

export default React.memo(Absensi);
