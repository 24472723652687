import React, { useEffect, useState } from "react";
import {useDispatch} from 'react-redux'

import Buttons from "components/buttons";
import Checkbox from "components/checkbox";
import Inputs from "components/form/inputs";

import PaketCouponAction from "stores/actions/paket/coupon";

import { useFormik } from "formik";
import * as Yup from "yup";

function Duplicate({ couponSelected, handleSuccess }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState({
    tipe_coupon_and_diskon: false,
    periode: false,
  });

  const formik = useFormik({
    initialValues: {
        code_coupon: `${couponSelected.code_coupon} - CLONE`,
    },
    validationSchema: Yup.object().shape({
        code_coupon: Yup.string().required("Wajib memilih additional"),
    })
  })
  
  const handleDuplicate = () => {
    const payload = {
      ...formik.values,
      ...checked,
      id: couponSelected?.id_coupon 
    }
    setIsLoading(true)
    dispatch(PaketCouponAction.duplicate(payload, (successData) => {
      handleSuccess()
      setIsLoading(false)
    }, () => {
      setIsLoading(false)
    }))
  }

  return (
    <>
      <div className="border border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <Inputs
            id="code_coupon"
            name="code_coupon"
            defaultValue={formik.values.code_coupon}
            label="Kode Coupon"
            onChange={formik.handleChange}
            onBlur={formik.onBlur}
            error={formik.touched.code_coupon && formik.errors.code_coupon}
            placeholder="Masukan kode Coupon"
          />
          <div className="flex flex-col gap-4 mt-4">
            <div className="flex items-center">
              <Checkbox
                checked={checked.tipe_coupon_and_diskon}
                onChange={(e) => {
                  setChecked({
                    tipe_coupon_and_diskon: e.target.checked,
                    periode: checked.periode,
                  });
                }}
              />
              <p className="ml-2">Tipe Coupon & Diskon</p>
            </div>
            <div className="flex items-center">
              <Checkbox
                checked={checked.periode}
                onChange={(e) => {
                  setChecked({
                    tipe_coupon_and_diskon: checked.tipe_coupon_and_diskon,
                    periode: e.target.checked,
                  });
                }}
              />
              <p className="ml-2">Periode</p>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end p-4">
          <Buttons 
            text="Duplikat" 
            customClass="btn-primary btn-large" 
            loading={isLoading}
            disabled={!(formik.isValid && formik.dirty)}
            onClick={() => handleDuplicate()}
            />
        </div>
      </div>
    </>
  );
}

export default Duplicate;
