import { useEffect } from "react"

import { useSelector } from "react-redux"

import PasPhoto4x6 from "./pas_photo_4x6"
import KTP from "./ktp";
import AktaKelahiran from './akta'
import Passport from "./passport";
import Family from "./family";
import VaccineCovid from "./vaccine_covid";
import Miningitiss from "./miningitis";
import BPJS from "./bpjs";

const ManifestForm = ({
  manifestKey,
  dataManifest,
  onUpdate,
}) => {
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;
  return (
    <>
      {manifestKey === 'pas_photo_4x6' && (
        <PasPhoto4x6 
          data={dataManifest?.manifests?.pas_photo_4x6?.data}
          pembimbingPaketID={dataManifest.id_pembimbing_paket}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          mentorData={dataManifest}
          />
      )}
      {manifestKey === 'ktp' && (
        <KTP 
          data={dataManifest?.manifests?.ktp?.data}
          pembimbingPaketID={dataManifest.id_pembimbing_paket}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          mentorData={dataManifest}
          />
      )}
      {manifestKey === 'akta_kelahiran' && (
        <AktaKelahiran 
          data={dataManifest?.manifests?.akta_kelahiran?.data}
          pembimbingPaketID={dataManifest.id_pembimbing_paket}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          mentorData={dataManifest}
          />
      )}
      {manifestKey === 'passport' && (
        <Passport 
          data={dataManifest?.manifests?.passport?.data}
          pembimbingPaketID={dataManifest.id_pembimbing_paket}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          mentorData={dataManifest}
          />
      )}
      {manifestKey === 'family' && (
        <Family 
          data={dataManifest?.manifests?.family?.data}
          pembimbingPaketID={dataManifest.id_pembimbing_paket}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          mentorData={dataManifest}
          />
      )}
      {manifestKey === 'vaccine_covid' && (
        <VaccineCovid 
          data={dataManifest?.manifests?.vaccine_covid?.data}
          pembimbingPaketID={dataManifest.id_pembimbing_paket}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          mentorData={dataManifest}
          />
      )}
      {manifestKey === 'meningitis_vaccine' && (
        <Miningitiss 
          data={dataManifest?.manifests?.meningitis_vaccine?.data}
          pembimbingPaketID={dataManifest.id_pembimbing_paket}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          mentorData={dataManifest}
          />
      )}
      {manifestKey === 'bpjs' && (
        <BPJS 
          data={dataManifest?.manifests?.bpjs?.data}
          pembimbingPaketID={dataManifest.id_pembimbing_paket}
          onSuccess={onUpdate}
          auth={auth}
          permissions={permissions}
          mentorData={dataManifest}
          />
      )}
    </>
  )
}

export default ManifestForm