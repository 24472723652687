import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const TestimoniAPI = {
    saveData: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/save-testimoni`, payload),

    saveDataByIbadahku: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/save-testimoni-ibadahku`, payload),
    
    getData: (payload) =>
        axios.post(`${BASE_URL_ENDPOINT}/admin/get-testimoni`, payload),
    deleteData: (payload) =>
        axios.delete(`${BASE_URL_ENDPOINT}/admin/testimoni/${payload}`),
};

export default TestimoniAPI;
