import React, { useEffect } from "react";
import { Table, Pagination, Spin } from "antd";
import PropTypes from "prop-types";

import useStyles from "./Table.style";
import UpIC from "../.../../../assets/icons/ic_up.svg";
import DownIC from "../.../../../assets/icons/ic_down.svg";

import "./styles.css"
import "./pagination.css";

/**
 * Selection table
 * @property {'checkbox' | 'radio'} [selectionType]
 * @property {true | false} [withSelection]
 */

/**
 * Columns
 * @param {Array} Columns of table
 * Data
 * @param {Array} Data record array to be displayed
 */

/**
 * Pagination
 * @typedef {onPageChange} Function to change page
 * @typedef {onSizeChange} Function to change size data
 */

const MoslemeTable = (props) => {
  const {
    data,
    columns,
    bordered,
    customClass,
    withPagination,
    withSelection,
    selectionType,
    pagination,
    customPaginationClass,
    onPageChange,
    onSizeChange,
    onRow,
    expandable,
    loading,
    rowKey,
    rowSelection,
    scroll
  } = props;
  const classes = useStyles();

  const selectionConfig = {
    type: selectionType,
    ...rowSelection,
  };

  const paginationConfig = {
    ...pagination,
    onChange: (page) => onPageChange(page),
    onShowSizeChange: (current, pageSize) => onSizeChange(current, pageSize),
  };

  useEffect(() => {
    const theadTH = document.getElementsByClassName('ant-table-thead')
    const thead = theadTH[0]

  }, [])

  const expandableConfig = {
    ...expandable,
    expandRowByClick: false,
    expandIcon: ({ expanded, onExpand, record }) => (
      <img
        src={expanded ? UpIC : DownIC}
        alt="up"
        className="cursor-pointer"
        onClick={(e) => onExpand(record, e)}
      />
    ),
  };

  return (
    <div className="flex flex-col">
      <Table
        bordered={bordered}
        className={`mosleme-table ${customClass}`}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? `${classes.rowStripped} ${classes.rowHover} rows`
            : `${classes.rowLight} ${classes.rowHover} rows`;
        }}
        rowSelection={withSelection && selectionConfig}
        columns={columns}
        dataSource={data}
        pagination={false}
        onRow={onRow}
        rowKey={rowKey}
        expandable={expandable && expandableConfig}
        scroll={scroll}
        loading={
          loading && {
            indicator: <Spin size="large" />,
          }
        }
      />

      {withPagination && (
        <div
          className={`flex justify-end py-4 mosleme-pagination !text-xs ${customPaginationClass}`}
        >
          <Pagination {...paginationConfig} />
        </div>
      )}
    </div>
  );
};

MoslemeTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  bordered: PropTypes.bool,
  customClass: PropTypes.string,
  selectionType: PropTypes.string,
  withSelection: PropTypes.bool,
  withPagination: PropTypes.bool,
  pagination: PropTypes.object,
  customPaginationClass: PropTypes.string,
  onPageChange: PropTypes.func,
  onSizeChange: PropTypes.func,
  onRow: PropTypes.func,
  expandable: PropTypes.any,
  loading: PropTypes.bool,
  rowSelection: PropTypes.any,
};

const defaultPaginationConfig = {
  showSizeChanger: true,
  total: 100,
  current: 1,
  pageSize: 10,
  defaultPageSize: 10,
  pageSizeOptions: ["10", "20", "50", "100"],
};

MoslemeTable.defaultProps = {
  bordered: false,
  customClass: "",
  selectionType: "checkbox",
  withSelection: false,
  withPagination: true,
  pagination: defaultPaginationConfig,
  rowSelection: {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  },
};

export default MoslemeTable;
