import React, { useState, useEffect } from "react";
import { ReactComponent as Location } from "../../../../../../assets/icons/location.svg";
import PopupBandara from "../../../popupdatamaster";
import Places from "../../../../../../components/map";
import  LocationBlack  from "../../../../../../assets/icons/location-black.svg";
import Selects from "../../../../../../components/form/selects";
import TextAreas from "../../../../../../components/form/textarea";
import { useSelector, useDispatch } from "react-redux";
import { getAllRegion } from "stores/actions/datamaster";
import Buttons from "../../../../../../components/buttons";
import Inputs from "components/form/inputs";

const style = {
  width: "auto",
  height: "318px",
  borderRadius: "40px",
};

function EditAddress({
  valueAddress,
  valueLat,
  valueLong,
  valueAddressDetail,
  formikOnChange,
  handleSave,
  formikValue,
  valueCity,
  loading,
  formikDataValues
}) {
  const [isLocation, setIsLocation] = useState(false);
  const [selected, setSelected] = useState({
    lat: parseFloat(valueLat),
    lng: parseFloat(valueLong),
  });
  const [address, setAdress] = useState(valueAddress);
  const [cities, setCities] = useState([]);

  const dispatch = useDispatch();
  const { dataRegion } = useSelector((state) => state.datamaster);
  // console.log(dataRegion);
  const handleSetAdress = (data) => {
    setAdress(data);
    formikValue('address_lat_long', data)
  };

  const handleSelected = (data) => {

    setSelected(data);
    formikValue("latitude", parseFloat(data?.lat));
    formikValue("longitude", parseFloat(data?.lng));
  };

  useEffect(() => {
    setCities([
      {
        'value': 'Makkah',
        'label': 'Makkah'
      },
      {
        'value': 'Madinah',
        'label': 'Madinah'
      } 
    ])
  }, []);

  // useEffect(() => {
  //   if (valueAddress !== null) {
  //     dispatch(
  //       getAllRegion({
  //         keyword: valueAddress?.split(",")[0],
  //       })
  //     );
  //   }
  //   if (address) {
  //     getAllRegion({
  //       keyword: address?.split(",")[0],
  //     });
  //   }
  // }, [address]);

  return (
    <>
      <div className="flex w-full">
        <div className="w-1/2 mr-6">
          <Places
            style={style}
            selected={selected}
            setSelected={handleSelected}
            address={address}
            setAdress={handleSetAdress}
          />
        </div>
        <div className="w-1/2">
          <p className="p-4 bg-[#DDD3AE66] text-red-700 text-lg rounded-md border border-red-700">
            Pastikan Koordinat yang dipilih tidak berbeda jauh dengan alamat
            yang akan diisi di Alamat Lengkap
          </p>
          <div className="mt-8">
            <label className="text-lg font-semibold">Koordinat Lokasi</label>
            <div className="flex my-4 items-baseline">
              <img className="w-4 h-4" src={LocationBlack} alt="location"/>
              <span className="text-lg ml-4 mb-1">{address}</span>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="w-full cursor-pointer rounded-lg h-16 border-dashed border-2 mt-6 border-gray-200 flex items-center justify-center">
        <Location />
        <span className="ml-2 text-red-700">{address} </span>
      </div>
      <div className="mt-4">
        <Selects
          label="Pilih Kota"
          placeholder="Pilih Kota"
          iconPosition="left"
          options={dataRegion}
          onChange={(value) => value.map((data) => setCity(data.value))}
        />
      </div> */}
      <div className="mt-4">
        <Selects
          searchable
          label="Pilih Kota"
          placeholder="Pilih Kota"
          iconPosition="left"
          options={cities}
          create={true}
          values={[{value: valueCity, label: valueCity}]}
          onChange={(value) => formikValue('city', value[0]?.value)}
        />
      </div>
      <div className="mt-4">
        {valueCity === "Makkah" && (
          <>
            <Inputs 
              type="number"
              label="Jarak dari Halaman Masjid Al-Haram (meter)"
              placeholder="Masukan Jarak dalam meter"
              name="distance_alharam"
              onChange={formikOnChange}
              value={formikDataValues?.distance_alharam}
              />
          </>
        )}
        {valueCity === "Madinah" && (
          <>
            <Inputs 
              type="number"
              label="Jarak dari Halaman Masjid Nabawi (meter)"
              placeholder="Masukan Jarak dalam meter"
              name="distance_nabawi"
              value={formikDataValues?.distance_nabawi}
              onChange={formikOnChange}
              />
          </>
        )}
      </div>
      <div className="w-full mt-4">
        <TextAreas
          id="alamat_lengkap"
          name="alamat_lengkap"
          defaultValue={valueAddressDetail}
          placeholder="Cth. Jalan, No. Rumah, Blok"
          label="Alamat Lengkap"
          onChange={formikOnChange}
        />
      </div>

      <div className="w-full flex justify-end pt-4 mt-4 items-center border-t border-gray-300">
        <div className="w-64">
          <Buttons
            onClick={handleSave}
            text="Simpan"
            customClass="btn-primary btn-large"
            loading={loading}
            />
        </div>
      </div>
    </>
  );
}

export default EditAddress;
