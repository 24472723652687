import React, { useEffect, useState } from "react";

import JamaahForm from "./JamaahForm";

import Buttons from "components/buttons";

import './style.css'
import JamaahCard from "./JamaahCard";

const AddJamaah = ({ dataForm, packageID }) => {

  const [isUpdateJamaah, setIsUpdateJamaah] = useState(false);
  const [dataOrders, setDataOrders] = useState([])

  const [isOrderJamaahSelected, setIsOrderJamaahSelected] = useState(false)
  const [orderJamaahSelectedIndex, setJamaahSelectedIndex] = useState(0)

  useEffect(() => {
    if(dataForm.values.order_kamars.length === 0) {
      handleNewOrderRoom()
    }
  }, [])

  const handleNewOrderRoom = () => {
    const newOrderRoom = [
      {
        "jamaah_title": "tn",
        "jamaah_name": null,
        "jamaah_passport_number": null,
        "jamaah_identity_number": null,
        "jamaah_phone": null,
        "jamaah_email": null,
        "user_jamaah_id": null,
        "paket_kamar": {
            "id": null,
            "kamar_name": null,
            "kamar_description": null,
            "price": null
        },
        "starting": {
          "name": null,
          "bg_color": null,
          "text_color": null
        }
      }
    ]

    if(dataForm.values.order_kamars.length === 0) {
      setJamaahSelectedIndex(0)
    } else {
      const index = dataForm.values.order_kamars.length
      setJamaahSelectedIndex(index)
    }

    dataForm.setFieldValue("order_kamars", dataForm.values.order_kamars.length === 0 ? newOrderRoom : dataForm.values.order_kamars.concat(newOrderRoom))
    setIsOrderJamaahSelected(true)
  }

  const handleSaveOrderJamaah = (data) => {
    dataForm.setFieldValue(`order_kamars.${orderJamaahSelectedIndex}`, data)
    setIsOrderJamaahSelected(false)
  }

  return (
      <>
          <div className="bg-[#FBF3F2] rounded-2xl p-4 mt-4">
              <h3 className="text-sm flex justify-between items-center">
                <span className="font-semibold">Jamaah</span>
                <Buttons 
                  text="Tambah Jamaah"
                  customClass="btn btn-primary btn-sm"
                  disabled={isOrderJamaahSelected}
                  onClick={handleNewOrderRoom}
                  />
              </h3>

              {/* <JamaahCard /> */}
              
              {dataForm?.values?.order_kamars?.map((val, idx) => (
                <>
                  {isOrderJamaahSelected && idx === orderJamaahSelectedIndex ? (
                    <>
                      <JamaahForm
                        packageID={packageID}
                        index={idx}
                        dataForm={dataForm}
                        handleSaveOrderJamaah={handleSaveOrderJamaah}
                      />
                    </>
                  ) : (
                    <>
                      <JamaahCard 
                        dataForm={dataForm}
                        index={idx}
                        />
                    </>
                  )}
                </>
              ))}

          </div>
      </>
  )
}

export default React.memo(AddJamaah);