import MoslemeTable from "components/Table"
import Avatar from "components/avatar"
import Badge from "components/badge"
import { Edit, Trash } from "iconsax-react"
import { useEffect, useState } from "react"
import { IDRFormater, toCapital } from "utils/helpers"

const AdditionalJamaahCard = ({
    additionalsFormik, 
    index,
    order,
    handleRemove,
    handleEdit
}) => {

    const [additionals, setAdditionals] = useState([])

    const columns = [
        {
            title: "Jumlah",
            align: 'center',
            width: '50px',
            dataIndex: 'quantity'
        },
        {
            title: "Catatan",
            dataIndex: 'note'
        },
        {
            title: "Harga",
            render: (record) => (
                <>
                    {IDRFormater(record.quantity * additionalsFormik.values.paket_additional_price)}
                </>
            )
        }
    ]

    useEffect(() => {
        setAdditionals([additionalsFormik.values.list_jamaah[index]])
    }, [])

    return (
        <>
            <div className="bg-white mt-4 rounded-2xl p-4">
                <div className="flex justify-between">
                    <div className="flex gap-3 items-center text-sm">
                        <Avatar 
                            value={additionalsFormik.values.list_jamaah[index]}
                            containerClass="items-center justify-center  w-[56px] h-[56px] rounded-[100%]  bg-slate-100"
                            customClass="object-cover w-[56px] h-[56px] rounded-[100%]"
                            />
                        <div className="flex flex-col gap-1">
                            <span>{toCapital(additionalsFormik.values.list_jamaah[index].title)}. {additionalsFormik.values.list_jamaah[index].name}</span>
                            <div  className="flex gap-2 items-center">
                                <Badge 
                                    label={order.cabang.name.toUpperCase()}
                                    backgroundColor={order.cabang.color}
                                    color={order.cabang.color_text}
                                    />
                                <Badge 
                                    label={additionalsFormik.values.list_jamaah[index].starting_name}
                                    backgroundColor={additionalsFormik.values.list_jamaah[index].starting_bg_color}
                                    color={additionalsFormik.values.list_jamaah[index].starting_text_color}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-6">
                        <Edit 
                            size={24}
                            className="cursor-pointer"
                            onClick={handleEdit}
                            />
                        {additionalsFormik.values.list_jamaah.length > 1 && (
                            <>
                                <Trash 
                                    size={24}
                                    className="cursor-pointer"
                                    onClick={() => handleRemove(index)}
                                    />
                            </>
                        )}
                    </div>
                </div>
                <div className="mt-2">
                    <MoslemeTable 
                        withSelection={false}
                        selectionType={false}
                        withPagination={false}
                        data={additionals}
                        columns={columns}
                        />
                </div>
            </div>
        </>
    )
}

export default AdditionalJamaahCard