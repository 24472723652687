import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import Buttons from "components/buttons";
import AntdSelect from "components/form/antdSelect";
import "../styles/index.scss";

import IconTrash from "assets/icons/trash.svg";
import Scroll from "components/scroll";
import { getAllDataPembimbing } from "stores/actions/datamaster";
import { handleSaveOrEditPembimbing } from "stores/actions/paket/infoDasar";
import { handleSuccess } from "stores/actions/errorGeneral";
import { useParams } from "react-router-dom";

import PaketDetailGeneralInfoAction from "stores/actions/paket/generalInfo";

let data_state = {
  guide: {
    id: null,
    name: "",
    description:
      "",
    photo:
      "",
    value: "",
    label: "",
  },
  pembimbing: {
    id: null,
    name: "",
    description:
      "",
    photo:
      "",
    value: "",
    label: "",
  },
};

const PembimbingGuide = ({
  info,
  closePopup,
  setSuccessEdit,
  selectedPembimbing,
  selectedGuide,
}) => {
  const { id } = useParams()
  const dispatch = useDispatch();
  const store = useSelector(({ datamaster }) => datamaster.dataPembimbing);
  let data = info;
  const [listPembimbing, setListPembimbing] = useState([]);
  const [listGuide, setListGuide] = useState([]);

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      forms: [data_state],
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values, actions) => {
      let value = [];
      console.log("VALUES :", values);

      values.forms.map((val, idx) => {
        // console.log(val);
        if (val.pembimbing) {
          if(val.pembimbing.id) {
            value.push(val.pembimbing.id);
          } else 
            value.push(val.pembimbing.pembimbing_id);
          }
        if (val.guide) {
          if(val.guide.id) {
            value.push(val.guide.id);
          } else {
            value.push(val.guide.pembimbing_id);
          }
          
        }
      });
      

      dispatch(
        handleSaveOrEditPembimbing(
          {
            paket_id: data.id_paket,
            pembimbing_id: value,
          },
          (message) => {
            // actions.resetForm();
            dispatch(handleSuccess(null, {message: "Berhasil memperbarui pembimbing & guide", code:200}));
            setSubmitting(false);
            dispatch(PaketDetailGeneralInfoAction.fetchData(id));
          },
          (error) => {
            setSubmitting(false);
          }
        )
      );
    },
  });

  useEffect(() => {
    dispatch(
      getAllDataPembimbing({
        sort_column: "",
        sort_order: "Desc",
        keyword: "",
      })
    );
  }, []);

  useEffect(() => {
    let loopGuide = [];
    let loopPembimbing = [];
    store?.data?.map((val, idx) => {
      if (val.type === "guide") {
        loopGuide.push({
          id: val.id_mentor,
          name: val.name,
          description: val.description,
          photo: val.photo,
          value: val.name,
          label: val.name,
        });
        setListGuide(loopGuide);
      } else {
        loopPembimbing.push({
          id: val.id_mentor,
          name: val.name,
          description: val.description,
          photo: val.photo,
          value: val.name,
          label: val.name,
        });
        setListPembimbing(loopPembimbing);
      }
    });
  }, [store?.data, store?.data?.length]);

  useEffect(() => {
    if (info.pembimbing_paket?.length > 0) {
      setFieldValue("forms", info.pembimbing_paket);
    }
  }, [info]);

  return (
    <>
      <div className="shadow-highlight">
        <Scroll height="calc(100vh - 311px)" customClass="p-4">
          {values.forms?.map((item, idx) => (
            <div className="flex flex-row items-center justify-between gap-[16px] w-[100%] mb-8">
              <div className="w-[100%]">
                <AntdSelect
                  label={`Pembimbing ${idx + 1}`}
                  placeholder="Select Pembimbing"
                  options={listPembimbing}
                  containerClass="!w-[423px]"
                  error={touched.forms && errors.forms && errors.forms}
                  customClass={`!w-[423px] ${
                    touched.forms && errors.forms && "select-error"
                  }`}
                  value={item.pembimbing?.name}
                  onChange={(value, opt) => {
                    let _temp = [...values.forms];
                    _temp[idx]["pembimbing"] = opt;
                    setFieldValue("forms", _temp);
                  }}
                >
                  {listPembimbing.map((val, idx) => {
                    return (
                      <div
                        value={val.value}
                        data={val}
                        label={val.label}
                        key={idx}
                      >
                        {val.label}
                      </div>
                    );
                  })}
                </AntdSelect>
              </div>
              <div className="w-[100%]">
                <AntdSelect
                  label={`Guide ${idx + 1}`}
                  placeholder="Select Guide"
                  options={listGuide}
                  containerClass="!w-[423px]"
                  error={touched.forms && errors.forms && errors.forms}
                  customClass={`!w-[423px] ${
                    touched.forms && errors.forms && "select-error"
                  }`}
                  value={item.guide.name}
                  onChange={(value, opt) => {
                    let _temp = [...values.forms];
                    _temp[idx]["guide"] = opt;
                    setFieldValue("forms", _temp);
                  }}
                >
                  {listGuide.map((val, idx) => {
                    return (
                      <div
                        value={val.value}
                        data={val}
                        label={val.label}
                        key={idx}
                      >
                        {val.label}
                      </div>
                    );
                  })}
                </AntdSelect>
              </div>
              {idx !== 0 && (
                <img
                  src={IconTrash}
                  className="cursor-pointer h-[24px] w-[24px] relative top-6"
                  onClick={() => {
                    let _temp = [...values.forms];
                    _temp.splice(idx, 1);
                    setFieldValue("forms", _temp);
                  }}
                  alt="trash"
                />
              )}
            </div>
          ))}
          <div className="w-[100%] mt-10">
            <Buttons
              text="Tambah Pembimbing & Guide"
              customClass="btn-outline !w-full btn-medium"
              onClick={() => {
                let _temp = [...values.forms, { ...values.forms[0] }];
                setFieldValue("forms", _temp);
              }}
            />
          </div>
        </Scroll>
        <div className="flex justify-end px-4 pt-5 border-t">
          <Buttons
            text="Simpan"
            loading={isSubmitting}
            customClass={`btn-primary btn-large ${
              isSubmitting && "loading"
            }`}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

PembimbingGuide.propTypes = {
  info: PropType.object,
  closePopup: PropType.func,
  setSuccessEdit: PropType.func,
};

export default React.memo(PembimbingGuide);
