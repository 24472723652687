import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Spin } from "antd";

import Buttons from "../../../../../components/buttons";

import Whatsapp from "../../../../../assets/icons/whatsapp-white.svg";
import Email from "../../../../../assets/icons/sms-white.svg";
import Dropdowns from "../../../../../components/dropdowns";

import { Edit, Trash } from "iconsax-react";
import IconMore from "../../../../../assets/icons/More.svg";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteKontakAction,
    getKontakAction,
} from "stores/actions/pusatBantuan/kontak";
import Spinner from "components/spinner";

const KontakHelpCenter = ({ setOpenPopupAddContact, handleEdit }) => {
    const { kontakEmailData, kontakWhatsappData, isLoading } = useSelector(
        (state) => state.pusatBantuanKontak
    );

    const dispatch = useDispatch();
    const [contactID, setContactID] = useState("");

    const payload = {
        keyword: "",
        per_page: 10,
        page_number: 1,
        sort_column: "kontak",
        sort_order: "ASC",
    };

    const deleteKontak = async (id) => {
        setContactID(id);
        await dispatch(deleteKontakAction({ id: id }));
        await dispatch(getKontakAction(payload));
    };

    useEffect(() => {
        dispatch(getKontakAction(payload));
    }, []);

    return (
        <div className="mt-4">
            <Buttons
                text="Tambah Kontak"
                customClass="btn-primary btn-small !w-fit absolute right-[0] top-[-45px]"
                onClick={() => setOpenPopupAddContact(true)}
            />

            <Grid container spacing={2}>
                <Grid item md={6}>
                    <div className="border-[1px] border-[#E0E0E0] rounded-[12px]">
                        <div className="flex flex-row gap-2 p-4 green-bg rounded-tl-[12px] rounded-tr-[12px]">
                            <img src={Whatsapp} alt="Whatsapp" />
                            <div className="text-[16px] text-white font-semibold">
                                Whatsapp
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 p-4">
                            {kontakWhatsappData.map((item) => (
                                <div className="border-[1px] border-[#E0E0E0] rounded-[12px] p-4 flex flex-row justify-between items-center">
                                    <div>
                                        <div className="text-[16px]">
                                            {item.pemilik}
                                        </div>
                                        <div className="text-[16px] font-semibold">
                                            {item.kontak}
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-6 bg-white hard-shadow rounded-[6px] px-4 py-2">
                                        <Edit
                                            className="cursor-pointer"
                                            onClick={() => handleEdit(item)}
                                            size="16"
                                            color="#141414"
                                        />
                                        {isLoading && (contactID === item.id)  ? (
                                            <Spinner size="16px" />
                                        ) : (
                                            <Trash
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    deleteKontak(item.id)
                                                }
                                                size="16"
                                                color="#141414"
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div className="border-[1px] border-[#E0E0E0] rounded-[12px]">
                        <div className="flex flex-row gap-2 p-4 red-bg rounded-tl-[12px] rounded-tr-[12px]">
                            <img src={Email} alt="Email" />
                            <div className="text-[16px] text-white font-semibold">
                                Email
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 p-4">
                            {kontakEmailData.map((item) => (
                                <div
                                    className="border-[1px] border-[#E0E0E0] rounded-[12px] p-4 flex flex-row justify-between items-center"
                                    key={item.id}
                                >
                                    <div className="flex w-full !justify-between items-center">
                                        <div className="text-[16px]">
                                            {item.kontak}
                                        </div>
                                        <div className="flex flex-row items-center gap-4">
                                            <Edit
                                                className="cursor-pointer"
                                                onClick={() => handleEdit(item)}
                                                size="16"
                                                color="#141414"
                                            />
                                            {isLoading && (contactID === item.id) ? (
                                                <Spinner width="16px" />
                                            ) : (
                                                <Trash
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        deleteKontak(item.id)
                                                    }
                                                    size="16"
                                                    color="#141414"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {/* <Dropdowns iconOnly iconImg={IconMore}>
                                        <div className="flex flex-row gap-6 bg-white hard-shadow rounded-[6px] px-4 py-2">
                                            
                                        </div>
                                    </Dropdowns> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default KontakHelpCenter;
