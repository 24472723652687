import axios from "axios";
import {
  BASE_URL_ENDPOINT,
  BASE_URL_ENDPOINT_V2,
} from "../../../../constant/constanta";

export const getAllDokumentasiService = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/paket/get-dokumentasi/${payload}`);

export const getDocumentJamaahServiceV2 = (id, params, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/jamaah-orders?data=${params.data}`,
    payload
  );

export const uploadDocumentServiceV2 = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/document/upload`,
    payload
  );

export const uploadDocumentPassportServiceV2 = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/document/upload-passport`,
    payload
  );

export const getPembimbingDocuments = (id) =>
  axios.get(
    `${BASE_URL_ENDPOINT}/admin/paket/${id}/pembimbing/documents`
  );

