import React, { useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "react-quill/dist/quill.snow.css";
import "./styles.css";
import Buttons from "components/buttons";
import Popup from "components/popup";
import ShortcodeModal from "./components/ShortcodeModal";

const RichTextEditor = ({ className, data, onChange, label, placeholder, shortcode, shortcodeData, renderShortcode }) => {
  const useStyles = makeStyles({
    textArea: {
      "& .ql-container.ql-snow": {
        backgroundColor: "#EDF2F4",
        width: "100%",
        height: "376px",
      },
    },
  });
  const classes = useStyles();

  const [state, setState] = useState({ value: null });

  const [openShortcode, setOpenShortcode] = useState(false);

  const handleChange = (value) => {
    setState({ value });
  };
  return (
    <>
      <Popup
        type={"secondary"}
        open={openShortcode}
        handleClose={() => setOpenShortcode(false)}
        title="Daftar Shortcode"
        width="786px"
        children={<ShortcodeModal renderShortcode={renderShortcode} shortcodeData={shortcodeData} />}
      />
      <h1 className={`font-semibold text-[#000000] mb-2 ${className} text-sm`}>
        {label}
      </h1>
      <div className={`text-editor ${className} relative`}>
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          value={data.value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          className={`${classes.textArea}`}
        />
        {shortcode && (
          <Buttons
            text="Shortcode"
            customClass="btn-secondary btn-small inline-block absolute top-4 right-[60px] !w-auto"
            onClick={() => setOpenShortcode(true)}
          />
        )}
      </div>
    </>
  );
};

RichTextEditor.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  shortcode: PropTypes.bool,
};
RichTextEditor.defaultProps = {
  data: { value: null },
  onChange: (e) => {},
  label: "Deskripsi",
  placeholder: "Masukkan deskripsi",
  shortcode: false,
};
export default React.memo(RichTextEditor);
