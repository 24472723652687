import React, { useEffect, useState } from "react";
import Card from "../../../../../../components/cards";
import Tab from "../../../../../../components/tab";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { IMAGE } from "constant/constanta";

import "./tabs.css";
import EditInfo from "./info";
import EditGallery from "./galery";
import { editDataPerlengkapan } from "stores/actions/datamaster";

function EditPerlengkapan({ setOpen }) {
  const { detailData, isLoading } = useSelector((state) => state?.datamaster);
  const { dataMultiple } = useSelector((state) => state.uploadFile);
  const dispatch = useDispatch();
  const [dataVideo, setDataVideo] = useState();
  const [variant, setVariant] = useState();
  const [time, setTime] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      variant: "",
      logo: "",
      thumbnail: "",
      description: "",
      variant_detail: "",
      image: "",
    },
  });

  useEffect(() => {
    if (detailData) {
      formik.setFormikState({
        values: {
          id: detailData?.data?.id_equipment,
          name: detailData?.data?.name,
          variant: "variant",
          logo: detailData?.data?.logo,
          thumbnail: detailData?.data?.thumbnail,
          description: detailData?.data?.description,
          image: detailData?.data?.gallery?.filter((x) => x?.type === "image")?.map((x) => x?.link),
        },
      });
      setDataVideo(
        detailData?.data?.gallery?.filter((x) => x?.type === "video")?.map((x) => x?.link)
      );
      setVariant(detailData?.data?.variant_detail?.map((x) => x?.name));
    }
    setTimeout(() => {
      setTime(true);
    }, 1000);
  }, [detailData]);

  // submit form
  const handleSave = () => {
    dispatch(
      editDataPerlengkapan({
        ...formik.values,
        variant_detail: variant,
        video: dataVideo,
        image: dataMultiple
          .filter((x) => x?.type === "image" && x?.path?.split("/")[2] === IMAGE)
          .map((x) => x?.path),
      })
    );
    setOpen(false);
  };

  return (
    <>
      {time === true ? (
        <Card containerClass=" !shadow-none !rounded-2xl !border !border-gray-200">
          <Tab
            defaultActiveKey="1"
            items={[
              {
                label: "Info Dasar",
                key: "1",
                children: (
                  <EditInfo
                    valueName={formik?.values?.name}
                    valueVariant={variant}
                    setVariant={setVariant}
                    formikOnChange={formik.handleChange}
                    formikValue={formik.setFieldValue}
                    handleSave={handleSave}
                    loading={isLoading}
                  />
                ),
              },
              {
                label: "Galeri & Deskripsi",
                key: "2",
                children: (
                  <EditGallery
                    valueImage={formik?.values?.image}
                    valueDescription={formik?.values?.description}
                    descriptionOnChange={(value) => {
                      formik.setFieldValue("description", value);
                    }}
                    formikOnChange={formik.handleChange}
                    formikValue={formik.setFieldValue}
                    handleSave={handleSave}
                    setDataVideo={setDataVideo}
                    dataVideo={dataVideo}
                    loading={isLoading}
                  />
                ),
              },
            ]}
          />
        </Card>
      ) : (
        <div className="w-full h-[534px] flex justify-center items-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
}

export default EditPerlengkapan;
